import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import { Helmet } from 'react-helmet-async';
import './arhomeinsurance.scss';

const ARHomeInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }

  }, [])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div className="main-fluid">
    <Helmet>
      <title>‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬.</title>
      <meta name="keywords" content="Home Insurance" />
      <meta name="description" content="KR ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫لوسطاء‬ ‫موثوق‬ ‫خيار‬ ‫هو‬ ‫للتأمين‬ ‫عمان‬. ‫عمان‬ ‫في‬ ‫التأمين‬ ‫شركات‬ ‫ألفضل‬ ‫التأمين‬ ‫حلول‬ ‫إلى‬ ‫للوصول‬ ‫معنا‬ ‫شارك‬." />
    </Helmet>
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div className="dsk-tab-btn-viewmode-ar">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1>التأمين الشخصي </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-ar">
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "motor-insurance-ar"}>التأمين على السيارات</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "travel-insurance-ar"}>التأمين أثناء السفر</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "personal-accident-ar"}>تأمين الحوادث الشخصية</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "relocation-insurance-ar"}>التأمين علي الانتقال</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "health-insurance-ar"}>التأمين الصحي</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "art-insurance-ar"}>التأمين الفني</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "golf-insurance-ar"}>التأمين على الجولف</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "housemaid-insurance-ar"}>تأمين العمالة المنزلية</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "yacht-insurance-ar"}>التأمين على اليخوت</div>
                <div className="inner-banner-btn-item-active-ar" onClick={() => window.location.href = "home-insurance-ar"}>التأمين على المنازل</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "life-insurance-ar"}>التأمين على الحياة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="bdy-innercont-en">
            <div className="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-lft-ar">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/home_insurance_ar.png"} alt="التأمين على المنازل " />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-rgt-ar">
                <h2 className="pagetitle-ar">التأمين على المنازل </h2>

                <p className="pg-txt-con-ar">هل سمعت من قبل عن التأمين على المنزل؟ أتتساءل عما يكون؟ </p>

                <p className="pg-txt-con-ar">في عالم التأمين، غالبًا ما تكون بوالص التأمين على المنزل واحدة من أكثر البوالص شمولًا. عادةً ما توفر بوليصة التأمين على المنزل حماية شاملة تغطيك وتغطي منزلك وأفراد أسرتك وأيضًا أي مسؤولية قد تنشأ. يوفر التأمين على المنزل تغطية تمتد إلى ما هو أبعد من مجرد هيكل المنزل. يمكن تصنيف أهم ميزات بوليصة التأمين على المنزل إلى جزأين، بناءً على نوع التغطية التي توفرها.وهم</p>

                <div className="innbdy-rgt-keypoint-ar">
                  <div className="innbdy-rgt-keypoint-item-ar">تغطية المتلفات <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div className="innbdy-rgt-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-rgt-keypoint-item-ar">تغطية الالتزامات <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div className="innbdy-rgt-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                </div>

                <h3 className="bdyinner-heading-ar">التغطية الرئيسية/ التأمين على المنزل </h3>

                <p className="pg-txt-con-ar">تغطي بوليصة التأمين على المنزل مجموعة متنوعة من الجوانب وتوفر تغطية أكثر أهمية لحماية منزلك. الملامح الرئيسية كالآتي:</p>
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

            <div className="bdyinner-keypoint-ar">
              <div className="bdyinner-keypoint-item-ar">التغطية ضد الأحداث غير المتوقعة والكوارث الطبيعية</div>
              <div className="bdyinner-keypoint-itemimg-ar">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">تضمن كيمجي رامداس لخدمات التأمين لك منزلًا مؤمن ضد الكوارث مثل الحرائق والزلازل والبرق والعواصف والفيضانات والغارات وآثار أعمال الشغب وتسرب المياه والسرقة وما إلى ذلك </div>
              <div className="bdyinner-keypoint-itemimg-ar">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">استبدال القديم بالجدي </div>
              <div className="bdyinner-keypoint-itemimg-ar">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">يتم تغطية جميع محتويات المنزل تقريبًا بموجب خطة التأمين على المنزل. ومع ذلك، فهي لا تشمل الملابس والمفروشات المنزلي </div>
              <div className="bdyinner-keypoint-itemimg-ar">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">سكن بديل </div>
              <div className="bdyinner-keypoint-itemimg-ar">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">يحميك من أي مسائلة قانونية في حالة حدوث أي ضرر أو أذى جسدي يحدث لأي شخص وذلك عند حدوث أي ضرر / إصابة جسدية أو ضرر لشخص ثالث أو أغراض، في المبنى الخاص بك أو من خلالك </div>
              <div className="bdyinner-keypoint-itemimg-ar">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>

            <h3 className="bdyinner-whykr-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>
            <div className="clearfix"></div>
            <div className="bdyinner-whykr-keypoints-ar">
              <div className="bdyinner-whykr-kepoints-secitem-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-whykr-kepoints-ftitem-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-whykr-kepoints-secitem-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div className="bdyinner-whykr-kepoints-ftitem-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>

            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1 className="homeinsu-banner-heading-ar">التأمين الشخصي </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-ar">
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "motor-insurance-ar"}>التأمين على السيارات</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "travel-insurance-ar"}>التأمين أثناء السفر</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "personal-accident-ar"}>تأمين الحوادث الشخصية</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "relocation-insurance-ar"}>التأمين علي الانتقال</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "health-insurance-ar"}>التأمين الصحي</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "art-insurance-ar"}>التأمين الفني</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "golf-insurance-ar"}>التأمين على الجولف</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "housemaid-insurance-ar"}>تأمين العمالة المنزلية</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "yacht-insurance-ar"}>التأمين على اليخوت</div>
                <div className="inner-banner-btn-item-active-ar" onClick={() => window.location.href = "home-insurance-ar"}>التأمين على المنازل</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "life-insurance-ar"}>التأمين على الحياة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="tab-imgtxt-ar">
            <h2>التأمين على المنازل </h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/home_insurance_ar.png"} alt="التأمين على المنازل " />
              <p>هل سمعت من قبل عن التأمين على المنزل؟ أتتساءل عما يكون؟ </p>

              <p>في عالم التأمين، غالبًا ما تكون بوالص التأمين على المنزل واحدة من أكثر البوالص شمولًا. عادةً ما توفر بوليصة التأمين على المنزل حماية شاملة تغطيك وتغطي منزلك وأفراد أسرتك وأيضًا أي مسؤولية قد تنشأ. يوفر التأمين على المنزل تغطية تمتد إلى ما هو أبعد من مجرد هيكل المنزل. يمكن تصنيف أهم ميزات بوليصة التأمين على المنزل إلى جزأين، بناءً على نوع التغطية التي توفرها.وهم</p>

              <p>تغطية المتلفات. <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></p>

              <p>تغطية الالتزامات. <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></p>

              <h3 className="tab-sub-title-ar">التغطية الرئيسية/ التأمين على المنزل </h3>

              <p>تغطي بوليصة التأمين على المنزل مجموعة متنوعة من الجوانب وتوفر تغطية أكثر أهمية لحماية منزلك. الملامح الرئيسية كالآتي</p>

              <div className="tabkyfet-ar">
                <div className="tabkeyfet-txt-ar">التغطية ضد الأحداث غير المتوقعة والكوارث الطبيعية </div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">تضمن كيمجي رامداس لخدمات التأمين لك منزلًا مؤمن ضد الكوارث مثل الحرائق والزلازل والبرق والعواصف والفيضانات والغارات وآثار أعمال الشغب وتسرب المياه والسرقة وما إلى ذلك</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">استبدال القديم بالجديد</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">يتم تغطية جميع محتويات المنزل تقريبًا بموجب خطة التأمين على المنزل. ومع ذلك، فهي لا تشمل الملابس والمفروشات المنزلية</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">سكن بديل</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">يحميك من أي مسائلة قانونية في حالة حدوث أي ضرر أو أذى جسدي يحدث لأي شخص وذلك عند حدوث أي ضرر / إصابة جسدية أو ضرر لشخص ثالث أو أغراض، في المبنى الخاص بك أو من خلالك</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>

              <h3 className="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>

              <div className="tab-whykrinsu-pins-list-ar">
                <div className="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div className="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div className="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div className="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              </div>

              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec-ar">
                <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار</a>
              </div>
              {/* ask for quotes button starts here */}

            </div>
          </div>
        </div>

      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div className="inn-banner-bg-main-en hidden-desktop hidden-md-device hidden-lg">
        <div className="dropdown-sec">
          <select className="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"motor-insurance-ar"}>التأمين على السيارات</option>
            <option value={"travel-insurance-ar"}>التأمين أثناء السفر</option>
            <option value={"personal-accident-ar"}>تأمين الحوادث الشخصية</option>
            <option value={"relocation-insurance-ar"}>التأمين علي الانتقال</option>
            <option value={"health-insurance-ar"}>التأمين الصحي</option>
            <option value={"art-insurance-ar"}>التأمين الفني</option>
            <option value={"golf-insurance-ar"}>التأمين على الجولف</option>
            <option value={"housemaid-insurance-ar"}>تأمين العمالة المنزلية</option>
            <option value={"yacht-insurance-ar"}>التأمين على اليخوت</option>
            <option value={"home-insurance-ar"} selected className="active">التأمين على المنازل</option>
            <option value={"life-insurance-ar"}>التأمين على الحياة</option>
          </select>
        </div>
        <div className="innner-mob-body-main-en">
          <h1 className="inner-mob-heading-en">التأمين على المنازل </h1>
          <div className="innner-mob-body-img-en">
            <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/home_insurance_ar.png"} alt="التأمين على المنازل " />
          </div>
          <p className="inner-mob-body-txt-ar">هل سمعت من قبل عن التأمين على المنزل؟ أتتساءل عما يكون؟ ?</p>

          <p className="inner-mob-body-txt-ar">في عالم التأمين، غالبًا ما تكون بوالص التأمين على المنزل واحدة من أكثر البوالص شمولًا. عادةً ما توفر بوليصة التأمين على المنزل حماية شاملة تغطيك وتغطي منزلك وأفراد أسرتك وأيضًا أي مسؤولية قد تنشأ. يوفر التأمين على المنزل تغطية تمتد إلى ما هو أبعد من مجرد هيكل المنزل. يمكن تصنيف أهم ميزات بوليصة التأمين على المنزل إلى جزأين، بناءً على نوع التغطية التي توفرها.وهم</p>

          <div className="inner-mob-pins-list-ar">
            <div className="inner-mob-pins-list-item-ar">تغطية المتلفات.</div>
            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">تغطية الالتزامات. </div>
            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>

          <h3 className="inner-mob-main-cover-title-ar">التغطية الرئيسية/ التأمين على المنزل </h3>

          <p className="inner-mob-body-txt-ar">تغطي بوليصة التأمين على المنزل مجموعة متنوعة من الجوانب وتوفر تغطية أكثر أهمية لحماية منزلك. الملامح الرئيسية كالآتي</p>

          {/* QUESTION SECTION STARTS HERE */}
          <div className="inner-mob-pins-list-ar">
            <div className="inner-mob-pins-list-item-ar">التغطية ضد الأحداث غير المتوقعة والكوارث الطبيعية. </div>
            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">تضمن كيمجي رامداس لخدمات التأمين لك منزلًا مؤمن ضد الكوارث مثل الحرائق والزلازل والبرق والعواصف والفيضانات والغارات وآثار أعمال الشغب وتسرب المياه والسرقة وما إلى ذلك</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">استبدال القديم بالجديد</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">يتم تغطية جميع محتويات المنزل تقريبًا بموجب خطة التأمين على المنزل. ومع ذلك، فهي لا تشمل الملابس والمفروشات المنزلية</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">سكن بديل</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">يحميك من أي مسائلة قانونية في حالة حدوث أي ضرر أو أذى جسدي يحدث لأي شخص وذلك عند حدوث أي ضرر / إصابة جسدية أو ضرر لشخص ثالث أو أغراض، في المبنى الخاص بك أو من خلالك</div>
            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

          </div>
          {/* QUESTION SECTION ENDS HERE */}

          <h3 className="inner-mob-main-cover-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>

          <div className="inner-mob-bdy-whykrlist-ar">
            <div className="inner-mob-bdy-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar"> أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>

          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec-ar">
            <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار</a>
          </div>
          {/* ask for quotes button starts here */}

        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARHomeInsurance;