import { useEffect, useState, React } from 'react';
import { Link } from "react-router-dom";
import HeaderAR from "../headerAR/headerar";
import FooterAR from "../footerAR/footerar";
import './arourinsurancesolutions.scss';

const AROurInsuranceSolutions = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  return (
    <div>
      <div class="main-fluid">
        <HeaderAR />
      </div>
      {/* DESKTOP SECTION STARTS HERE */}
      <div class="d-none d-md-none d-lg-block">
        <div class="ourexp-con-ar">
          <h2 class="ourexp-wedotitle-ar">حلول وخبرات التأمين التي نمتلكها </h2>
          <div class="ourexp-sc-ar"> 
            <div class="ourexp-lft-ar">
              <div class="ourexp-sec-ar">
              <a href="/property-insurance-ar" class="ourexp-expcard-ar">
                  <div class="expe-insimg-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-1.png"} alt="تأمين الأعمال " /></div>
                  <div class="expe-insnm-ar">تأمين الأعمال </div>
                </a>
                <a href="/motor-insurance-ar" class="ourexp-expcard-ar">
                  <div class="expe-insimg-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-2.png"} alt="التأمين الشخصي " /></div>
                  <div class="expe-insnm-ar">التأمين الشخصي </div>
                </a>
                
                <a href="/risk-inspection-services-ar" class="ourexp-expcard-ar">
                  <div class="expe-insimg-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-3.png"} alt="خدمات قيمة " /></div>
                  <div class="expe-insnm-ar">خدمات قيمة </div>
                </a>
                <a href="/report-a-claim-ar" class="ourexp-expcard-ar">
                  <div class="expe-insimg-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-4.png"} alt="خدمات العملاء " /></div>
                  <div class="expe-insnm-ar">خدمات العملاء </div>
                </a>
              </div>
            
            
            </div>           
            <div class="ourexp-rgt-ar">
              <div class="ourinsol-dskmain-ar">
                <div class="ourinsusol-dsk-list-ar">
                  <div class="oursol-dsk-list-txt-ar">
                    <p>نحن فريق تأمين متخصص يعمل بشكل وثيق مع العملاء لفهم متطلبات التأمين الخاصة بهم وكيفية إدارة المخاطر</p>
                  </div>
                  <div class="oursol-dsk-list-img-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-5.png"} class="imgone" alt="icon" /></div>
                </div>
                <div class="ourinsusol-dsk-list-ar">
                  <div class="oursol-dsk-list-txt-ar">
                    <p>لدينا تعاملات مع جميع شركات التأمين في سلطنة عمان. وبالنيابة عن عملائنا، يقوم فريقنا بوضع شروط تأمين شاملة وتنافسية</p>
                  </div>
                  <div class="oursol-dsk-list-img-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-6.png"}  class="imgtwo" alt="icon" /></div>
                </div>
                <div class="ourinsusol-dsk-list-ar">
                  <div class="oursol-dsk-list-txt-ar">
                    <p>نقدم خدمات التأمين ونساعد عملائنا في تسوية مطالبات التأمين (إذا لزم الأمر) بغرض تقديم إنجازات تتجاوز توقعات عملائنا</p>
                  </div>
                  <div class="oursol-dsk-list-img-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-7.png"}  class="imgthree" alt="icon" /></div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* DESKTOP SECTION ENDS HERE */}

      {/* TAB SECTION STARTS HERE  */}
      <div className="d-none d-sm-none d-lg-none d-md-block">
        <div class="taboexp-con-ar">
          <h2 class="taboexp-title-ar">حلول وخبرات التأمين التي نمتلكها </h2>
          <div className="oursecinc-ar">
            <a href="/property-insurance-ar" class="oursecinc-expcard-ar">
                  <div class="oursecinc-insimg-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-1.png"} alt="تأمين الأعمال " /></div>
                  <div class="oursecinc-insnm-ar">تأمين الأعمال </div>
                </a>
                <a href="/motor-insurance-ar" class="oursecinc-expcard-ar">
                  <div class="oursecinc-insimg-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-2.png"} alt="التأمين الشخصي " /></div>
                  <div class="oursecinc-insnm-ar">التأمين الشخصي </div>
                </a>
                
                <a href="/risk-inspection-services-ar" class="oursecinc-expcard-ar">
                  <div class="oursecinc-insimg-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-3.png"} alt="خدمات قيمة " /></div>
                  <div class="oursecinc-insnm-ar">خدمات قيمة </div>
                </a>
                <a href="/report-a-claim-ar" class="oursecinc-expcard-ar">
                  <div class="oursecinc-insimg-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-4.png"} alt="خدمات العملاء " /></div>
                  <div class="oursecinc-insnm-ar">خدمات العملاء </div>
                </a>
          </div>
          <div class="oursecincsectab-ar">
                <div class="oursecincsectab-list-ar">
                  <div class="oursecincsectab-info-ar"> نحن فريق تأمين متخصص يعمل بشكل وثيق مع العملاء لفهم متطلبات التأمين الخاصة بهم وكيفية إدارة المخاطر
                  </div>
                  <div class="oursecincsectab-img-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-5.png"} alt="icon" /></div>
                </div>
                <div class="oursecincsectab-list-ar">
                  <div class="oursecincsectab-info-ar">
                  لدينا تعاملات مع جميع شركات التأمين في سلطنة عمان. وبالنيابة عن عملائنا، يقوم فريقنا بوضع شروط تأمين شاملة وتنافسية
                  </div>
                  <div class="oursecincsectab-img-ar">
                    <img src={process.env.PUBLIC_URL + "/homepage_images/icon-6.png"} className="simg-en" alt="icon" />
                    </div>
                </div>
                <div class="oursecincsectab-list-ar">
                  <div class="oursecincsectab-info-ar">
                  نقدم خدمات التأمين ونساعد عملائنا في تسوية مطالبات التأمين (إذا لزم الأمر) بغرض تقديم إنجازات تتجاوز توقعات عملائنا. 
                  </div>
                  <div class="oursecincsectab-img-ar">
                    <img src={process.env.PUBLIC_URL + "/homepage_images/icon-7.png"} className="simg-en" alt="icon" />
                  </div>
                </div>

              </div>
        </div>
      </div>
      {/* TAB SECTION ENDS HERE  */}


      {/* MOBILE SECTION STARTS HERE */}
      <div class="d-block d-sm-none d-md-none">
        <div class="ourexp-con-ar">
          <h2 class="ourexp-wedotitle-ar">حلول وخبرات التأمين التي نمتلكها </h2>
          <div class="ourexp-sc-mob-ar">
            
            <div class="ourexpcrd-mob-sec-ar">
            <a href="/property-insurance-ar" class="ourexp-crds-mob-ar">
                <span class="ourexp-crds-mob-img-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-1.png"} alt="تأمين الأعمال " /></span>
                <span class="ourexp-crds-mob-txt-ar">تأمين الأعمال </span>
              </a>
              <a href="/motor-insurance-ar" class="ourexp-crds-mob-ar">
                <span class="ourexp-crds-mob-img-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-2.png"} alt="التأمين الشخصي " /></span>
                <span class="ourexp-crds-mob-txt-ar">التأمين الشخصي </span>
              </a>
              
              <a href="/risk-inspection-services-ar" class="ourexp-crds-mob-ar">
                <span class="ourexp-crds-mob-img-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-3.png"} alt="خدمات قيمة " /></span>
                <span class="ourexp-crds-mob-txt-ar">خدمات قيمة </span>
              </a>
              <a href="/report-a-claim-ar" class="ourexp-crds-mob-ar">
                <span class="ourexp-crds-mob-img-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-4.png"} alt="خدمات العملاء " /></span>
                <span class="ourexp-crds-mob-txt-ar">خدمات العملاء </span>
              </a>

            </div>

            <div class="clearfix"></div>

            <div class="ourinsu-sec-mob-ar">
                <div class="ourinsu-list-mob-ar">
                  <div class="ourinsu-list-img-mob-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-5.png"} alt="icon" /></div>
                  <div class="ourinsu-list-info-mob-ar">
                    <p>نحن فريق تأمين متخصص يعمل بشكل وثيق مع العملاء لفهم متطلبات التأمين الخاصة بهم وكيفية إدارة المخاطر. </p>
                  </div>
                </div>
                <div class="ourinsu-list-mob-ar">
                  <div class="ourinsu-list-img-mob-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-6.png"} alt="icon" /></div>
                  <div class="ourinsu-list-info-mob-ar">
                    <p>لدينا تعاملات مع جميع شركات التأمين في سلطنة عمان. وبالنيابة عن عملائنا، يقوم فريقنا بوضع شروط تأمين شاملة وتنافسية. </p>
                  </div>
                </div>
                <div class="ourinsu-list-mob-ar">
                  <div class="ourinsu-list-img-mob-ar"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-7.png"} alt="icon" /></div>
                  <div class="ourinsu-list-info-mob-ar">
                    <p>نقدم خدمات التأمين ونساعد عملائنا في تسوية مطالبات التأمين (إذا لزم الأمر) بغرض تقديم إنجازات تتجاوز توقعات عملائنا. </p>
                  </div>
                </div>

              </div>
          </div>
        </div>
      </div>
      {/* MOBILE SECTION ENDS HERE */}

      <div class="main-fluid">
      <FooterAR />
      </div>
    </div>
  );
}

export default AROurInsuranceSolutions;