import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import { Helmet } from 'react-helmet-async';
import './arhauliersfullvaluecargoinsurance.scss';

const HauliersFullValueCargoInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div className="main-fluid">
    <Helmet>
      <title>‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬.</title>
      <meta name="keywords" content="Project insurance, Property Project Insurance"/>
      <meta name="description" content="KR ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫لوسطاء‬ ‫موثوق‬ ‫خيار‬ ‫هو‬ ‫للتأمين‬ ‫عمان‬. ‫عمان‬ ‫في‬ ‫التأمين‬ ‫شركات‬ ‫ألفضل‬ ‫التأمين‬ ‫حلول‬ ‫إلى‬ ‫للوصول‬ ‫معنا‬ ‫شارك‬." />
    </Helmet>
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div className="dsk-tab-btn-viewmode-ar">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1 className="hauvalcarinsu-banner-heading-ar">تأمين الأعمال </h1>

              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-ar">
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div className="inner-banner-btn-item-active-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="bdy-innercont-en">
            <div className="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-lft-ar">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/hauliers_full_value_cargo_insurance_ar.png"} alt="تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-rgt-ar">
                <h2 className="pagetitle-ar">تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</h2>
                <p className="pg-txt-con-ar">الغرض من هذه البوليصة هو توفير تغطية تأمينة لشركة النقل / الناقل لفقد أو ضد تلف البضائع التي يتم مناولتها للعملاء بصفتهم متعهد نقل (أو وكيل شحن)</p>

                <p className="pg-txt-con-ar">منتج تأمين خاص يلبي احتياجات الناقلات الصغيرة والمتوسطة الحجم التي تعمل بعقود محددة لنقل البضائع بالشاحنات داخل دول مجلس التعاون الخليجي. وهي توفر تغطية كاملة للقيمة ضد الخسارة أو التلف الذي يلحق بالبضائع أثناء تواجدها في رعاية الشركة المتعهدة بالنقل وحيازتها ومراقبتها</p>

                <p className="pg-txt-con-ar">تطبق أسعار تنافسية على رسوم النقل السنوية المعلنة على الحدود الشهرية</p>

                <p className="pg-txt-con-ar">تغطي البوليصة التأخير الناجم عن انسداد الطرق والظروف الجوية السيئة. كما يغطي الخسارة المسددة حتى استيفاء القيمة الكاملة للبضائع</p>

              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

            <h3 className="bdyinner-heading-ar">التغطية الرئيسية / التأمين على البضائع بالقيمة الكاملة الخاصة بالمتعهدين بالنقل</h3>

            <p className="pg-txt-con-ar">النقاط الرئيسية في التغطية التأمينية، والمسؤولية القانونية للشركة المتعهدة بالنقل</p>

            <div className="bdyinner-keypoint-ar">
              <div className="bdyinner-keypoint-item-ar">المسؤوليات عن فقدان أو تلف البضائع</div>

              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">الالتزامات الناشئة عن الأخطاء والسهو بما في ذلك التأخير والتسليم غير المصرح به</div>

              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">التزامات تجاه الاطراف الثالثة</div>

              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">غرامات المخالفات النظامية مثل الجمارك والتلوث والسلامة في العمل</div>

              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">تكاليف التحقيق والدفاع والتخفيف</div>

              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">تكاليف التخلص بعد وقوع حادث</div>

              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">تكاليف الحجر الصحي والتطهير</div>
              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>

            <p className="pg-txt-con-ar"><strong>تغطي إضافية</strong></p>
              
            <p className="pg-txt-con-ar"> يمكن أن تشمل التغطية الإضافية لمشغلي الخدمات اللوجستية ما يلي</p>

            <div className="bdyinner-keypoint-ar">
              <div className="bdyinner-keypoint-item-ar">مخاطر توقف العمل</div>

              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">سندات اللجنة البحرية الفيدرالية للناقل المشترك الذي لا يعمل على متن السفن</div>

              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">العوارية العامة وضمانات الانقاذ والاشتراكات</div>

              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">فقدان أو تلف المعدات المملوكة أو المؤجرة</div>

              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">تأمين الممتلكات مثل المباني والمحتويات والآلات ومعدات المناولة</div>

              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">البنية التحتية للطرق والسكك الحديدية</div>

              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">التزامات وكالة السفن</div>
              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            </div>

            <h3 className="bdyinner-whykr-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>
            <div className="clearfix"></div>
            <div className="bdyinner-whykr-keypoints-ar">
              <div className="bdyinner-whykr-kepoints-secitem-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-whykr-kepoints-ftitem-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-whykr-kepoints-secitem-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div className="bdyinner-whykr-kepoints-ftitem-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1 className="hauvalcarinsu-banner-heading-ar">تأمين الأعمال </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-ar">
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div className="inner-banner-btn-item-active-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="tab-imgtxt-ar">
            <h2>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/hauliers_full_value_cargo_insurance_ar.png"} alt="تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل" />
              <p>الغرض من هذه البوليصة هو توفير تغطية تأمينة لشركة النقل / الناقل لفقد أو ضد تلف البضائع التي يتم مناولتها للعملاء بصفتهم متعهد نقل (أو وكيل شحن)</p>

              <p>منتج تأمين خاص يلبي احتياجات الناقلات الصغيرة والمتوسطة الحجم التي تعمل بعقود محددة لنقل البضائع بالشاحنات داخل دول مجلس التعاون الخليجي. وهي توفر تغطية كاملة للقيمة ضد الخسارة أو التلف الذي يلحق بالبضائع أثناء تواجدها في رعاية الشركة المتعهدة بالنقل وحيازتها ومراقبتها.</p>

              <p>تطبق أسعار تنافسية على رسوم النقل السنوية المعلنة على الحدود الشهرية</p>

              <p>تغطي البوليصة التأخير الناجم عن انسداد الطرق والظروف الجوية السيئة. كما يغطي الخسارة المسددة حتى استيفاء القيمة الكاملة للبضائع</p>

              <h3 className="tab-sub-title-ar">التغطية الرئيسية / التأمين على البضائع بالقيمة الكاملة الخاصة بالمتعهدين بالنقل</h3>

              <p>النقاط الرئيسية في التغطية التأمينية، والمسؤولية القانونية للشركة المتعهدة بالنقل</p>
              
              <div className="tabkyfet-ar">
                <div className="tabkeyfet-txt-ar">المسؤوليات عن فقدان أو تلف البضائع</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-ar">الالتزامات الناشئة عن الأخطاء والسهو بما في ذلك التأخير والتسليم غير المصرح به</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-ar">التزامات تجاه الاطراف الثالثة</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-ar">غرامات المخالفات النظامية مثل الجمارك والتلوث والسلامة في العمل</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-ar">تكاليف التحقيق والدفاع والتخفيف</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-ar">تكاليف التخلص بعد وقوع حادث</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-ar">تكاليف الحجر الصحي والتطهير</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              </div>
              
              <p><strong>تغطي إضافية</strong></p>
              
              <p> يمكن أن تشمل التغطية الإضافية لمشغلي الخدمات اللوجستية ما يلي</p>
              
              <div className="tabkyfet-ar">
                <div className="tabkeyfet-txt-ar">مخاطر توقف العمل</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-ar">سندات اللجنة البحرية الفيدرالية للناقل المشترك الذي لا يعمل على متن السفن</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-ar">العوارية العامة وضمانات الانقاذ والاشتراكات</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-ar">فقدان أو تلف المعدات المملوكة أو المؤجرة</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-ar">تأمين الممتلكات مثل المباني والمحتويات والآلات ومعدات المناولة</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-ar">البنية التحتية للطرق والسكك الحديدية</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-ar">التزامات وكالة السفن</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              </div>

              <h3 className="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
              
              <div className="tab-whykrinsu-pins-list-ar">
                <div className="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div className="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            

            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div className="inn-banner-bg-main-en hidden-desktop hidden-md-device hidden-lg">
        <div className="dropdown-sec">
          <select className="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"project-insurance-ar"}>تأمين المشاريع </option>
            <option value={"property-insurance-ar"}>تأمين الممتلكات</option>
            <option value={"terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </option>
            <option value={"motor-fleet-insurance-ar"} >تأمين أسطول المركبات</option>
            <option value={"group-health-insurance-ar"}>التأمين الصحي الجماعي</option>
            <option value={"group-life-insurance-ar"}>التأمين الجماعي على الحياة</option>
            <option value={"fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</option>
            <option value={"burglary-insurance-ar"}>التأمين ضد السرقة</option>
            <option value={"workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</option>
            <option value={"group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</option>
            <option value={"cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</option>
            <option value={"jewellers-block-insurance-ar"}>التأمين على المجوهرات</option>
            <option value={"stock-throughput-insurance-ar"}>تأمين صافي المخزون</option>
            <option value={"hauliers-full-value-cargo-insurance-ar"} selected className="active">تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</option>
            <option value={"speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</option>
            <option value={"liability-insurance-ar"}>التأمين عن المسؤولية</option>
            <option value={"marine-insurance-ar"}>التأمين البحري</option>
            <option value={"energy-insurance-ar"}>التأمين على الطاقة</option>
          </select>
        </div>
        <div className="innner-mob-body-main-en">
          <h1 className="inner-mob-heading-en">تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</h1>
          <div className="innner-mob-body-img-en">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/hauliers_full_value_cargo_insurance_ar.png"} alt="تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل" />
          </div>

          <p className="inner-mob-body-txt-ar">الغرض من هذه البوليصة هو توفير تغطية تأمينة لشركة النقل / الناقل لفقد أو ضد تلف البضائع التي يتم مناولتها للعملاء بصفتهم متعهد نقل (أو وكيل شحن)</p>

          <p className="inner-mob-body-txt-ar">منتج تأمين خاص يلبي احتياجات الناقلات الصغيرة والمتوسطة الحجم التي تعمل بعقود محددة لنقل البضائع بالشاحنات داخل دول مجلس التعاون الخليجي. وهي توفر تغطية كاملة للقيمة ضد الخسارة أو التلف الذي يلحق بالبضائع أثناء تواجدها في رعاية الشركة المتعهدة بالنقل وحيازتها ومراقبتها.</p>

          <p className="inner-mob-body-txt-ar">تطبق أسعار تنافسية على رسوم النقل السنوية المعلنة على الحدود الشهرية</p>

          <p className="inner-mob-body-txt-ar">تغطي البوليصة التأخير الناجم عن انسداد الطرق والظروف الجوية السيئة. كما يغطي الخسارة المسددة حتى استيفاء القيمة الكاملة للبضائع</p>

          <h3 className="hauvalcarinsu-subtitle-ar">التغطية الرئيسية / التأمين على البضائع بالقيمة الكاملة الخاصة بالمتعهدين بالنقل</h3>

          <p className="inner-mob-body-txt-ar">النقاط الرئيسية في التغطية التأمينية، والمسؤولية القانونية للشركة المتعهدة بالنقل</p>

          <div className="inner-mob-pins-list-ar">
            <div className="inner-mob-pins-list-item-ar">المسؤوليات عن فقدان أو تلف البضائع</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">الالتزامات الناشئة عن الأخطاء والسهو بما في ذلك التأخير والتسليم غير المصرح به</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">التزامات تجاه الاطراف الثالثة</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">غرامات المخالفات النظامية مثل الجمارك والتلوث والسلامة في العمل</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">تكاليف التحقيق والدفاع والتخفيف</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">تكاليف التخلص بعد وقوع حادث</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">تكاليف الحجر الصحي والتطهير</div>
            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>

          <p className="inner-mob-body-txt-ar"><strong>تغطي إضافية</strong></p>

          <p className="inner-mob-body-txt-ar">يمكن أن تشمل التغطية الإضافية لمشغلي الخدمات اللوجستية ما يلي</p>

          <div className="inner-mob-pins-list-ar">
            <div className="inner-mob-pins-list-item-ar">مخاطر توقف العمل</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">سندات اللجنة البحرية الفيدرالية للناقل المشترك الذي لا يعمل على متن السفن</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">العوارية العامة وضمانات الانقاذ والاشتراكات</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">فقدان أو تلف المعدات المملوكة أو المؤجرة</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">تأمين الممتلكات مثل المباني والمحتويات والآلات ومعدات المناولة</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">البنية التحتية للطرق والسكك الحديدية</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">التزامات وكالة السفن</div>
            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>

          <h3 className="inner-mob-main-cover-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>

          <div className="inner-mob-bdy-whykrlist-ar">
            <div className="inner-mob-bdy-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar"> أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            

        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default HauliersFullValueCargoInsurance;