import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './marineinsurance.scss';

const MarineInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div className="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div className="dsk-tab-btn-viewmode-en">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1>Business Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-en">
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "property-insurance"}>Property Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "project-insurance"}>Project Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "motor-fleet-insurance"}>Motor Fleet Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "group-health-insurance"}>Group Health Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "group-life-insurance"}>Group Life Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "burglary-insurance"}>Burglary Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "workmen-compensation-insurance"}>Workmen Compensation Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "group-personal-accident-insurance"}>Group Personal Accident Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "cyber-crime-insurance"}>Cyber Crime Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "jewellers-block-insurance"}>Jewellers Block Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "stock-throughput-insurance"}>Stock Throughput Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "liability-insurance"}>Liability Insurance</div>
                <div className="inner-banner-btn-item-active-en" onClick={() => window.location.href = "marine-insurance"}>Marine Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "energy-insurance"}>Energy Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="bdy-innercont-en">
            <div className="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-lft-en">
                <h2 className="pagetitle-en">Marine Insurance</h2>

                <p className="pg-txt-con">Marine insurance offers coverage in case of damage or loss of cargo, ships, terminals, and any transport by which any property is acquired, transferred or held between the point of origin and its destination.</p>

                <p className="pg-txt-con">Marine insurance covers property exposed either onshore and/or offshore, marine casualty, marine liability, and hull damages.</p>

                <p className="pg-txt-con">In case goods are transported using a courier service, shipping insurance can be purchased.</p>

                <p className="pg-txt-con">KR Insurance Services offers unique products with the sole intention of safeguarding your cargo whether in the sea, over land or in the air.</p>

                <p className="pg-txt-con">KR Insurance Services have  access to the resources and in-depth expertise necessary to handle all types of types of account, however complex, and to offer a service - from risk assessment through to claims support - which ensures that whatever the size of your client's operation, they can be sure of receiving the high level of personal service that their individual requirements demand.</p>

                <p className="pg-txt-con">We can cater for all aspects of marine insurance:</p>

                <div className="innbdy-lft-keypoint-en">
                  <div className="innbdy-lft-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-lft-keypoint-item-en">Cargo</div>
                  <div className="innbdy-lft-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-lft-keypoint-item-en">Fine Arts</div>
                  <div className="innbdy-lft-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-lft-keypoint-item-en">Hull and Machinery</div>
                  <div className="innbdy-lft-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-lft-keypoint-item-en">Loss of Hire</div>
                  <div className="innbdy-lft-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-lft-keypoint-item-en">Protection and Indemnity</div>
                  <div className="innbdy-lft-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-lft-keypoint-item-en">Marine Liability</div>
                  <div className="innbdy-lft-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-lft-keypoint-item-en">Ransom / Piracy</div>
                  <div className="innbdy-lft-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-lft-keypoint-item-en">Breakup Voyages</div>
                </div>

              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/marine_insurance.png"} alt="Marine Insurance" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

            <p className="pg-txt-con">KR Insurance Services has a unique risk management services which provides consultancy for vessel selection, warehousing and packaging. We are also providing route surveys on case to case basis. We have emergency helplines operating 24x7, assuring best claim settlement services to our clients.</p>

            <h3 className="bdyinner-heading-en">Main Cover / Marine Insurance</h3>

            <p className="pg-txt-con">KR Insurance products go beyond the standard coverage and provide a free of cost, one of a kind combination in the form of over 30 cover extensions.</p>

            <div className="innbdy-keypoint-en">
              <div className="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="innbdy-keypoint-item-en">Fire or explosion, stranding, sinking etc. - can’t predict these situations in advance. Fire or explosion, sinking, stranding are common issues faced during a cargo journey. A marine insurance plan is formulated to take care of such dire situations.</div>

              <div className="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="innbdy-keypoint-item-en">Collision, overturning or derailment of land conveyance - Sometimes, no matter how hard the captain tries, he can’t take control of unfavourable damage causing situations. Having a marine insurance plan neutralizes the risk of damages caused by collision, overturning or derailment of land conveyance.</div>

              <div className="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="innbdy-keypoint-item-en">Discharge of cargo at port of distress - a cargo is a discharged from a port of disturbance or distress, it threatens the safety of the cargo. This is the reason why it is included in the basic insurance coverage.</div>

              <div className="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="innbdy-keypoint-item-en">General average sacrifice salvage charges - marine insurance plan provides coverage for general average sacrifice salvage charges.</div>

              <div className="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="innbdy-keypoint-item-en">Expenses such as survey fees, forwarding expenses, reconditioning costs and sue charges - These expenses are unexpected at times which leaves you with little time to pay for these expenses. This is why having a marine insurance plan is of great help.</div>

              <div className="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="innbdy-keypoint-item-en">Jettison or washing overboard - Situations of jettison and washing overboard are included in a marine insurance plan.</div>

              <div className="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="innbdy-keypoint-item-en">Earthquake or lightning -&nbsp;marine insurance plan provides you coverage from natural calamities such as earthquake or lightning.</div>

              <div className="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="innbdy-keypoint-item-en">Total loss of package lost overboard or dropped in loading or unloading - No matter how irksome a package loss can be, every time you can’t create a foolproof plan to prevent this situation when your package is being transported in a cargo. This is why it is included in a basic marine insurance plan.</div>
            </div>

            <h3 className="bdyinner-whykr-title-en">Why KR Insurance Services ?</h3>

            <div className="clearfix"></div>

            <div className="bdyinner-whykr-keypoints-en">
              <div className="bdyinner-whykr-kepoints-ftitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
              <div className="bdyinner-whykr-kepoints-secitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
              <div className="bdyinner-whykr-kepoints-ftitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
              <div className="bdyinner-whykr-kepoints-secitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
            </div>

            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec">
              <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
            </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1>Business Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-en">
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "property-insurance"}>Property Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "project-insurance"}>Project Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "motor-fleet-insurance"}>Motor Fleet Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "group-health-insurance"}>Group Health Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "group-life-insurance"}>Group Life Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "burglary-insurance"}>Burglary Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "workmen-compensation-insurance"}>Workmen Compensation Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "group-personal-accident-insurance"}>Group Personal Accident Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "cyber-crime-insurance"}>Cyber Crime Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "jewellers-block-insurance"}>Jewellers Block Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "stock-throughput-insurance"}>Stock Throughput Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "liability-insurance"}>Liability Insurance</div>
                <div className="inner-banner-btn-item-active-en" onClick={() => window.location.href = "marine-insurance"}>Marine Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "energy-insurance"}>Energy Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="tab-imgtxt-en">
            <h2>Marine Insurance</h2>
            <div className="tabs-cont-en">
              <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/marine_insurance.png"} alt="Marine Insurance" />
              
              <p>Marine insurance offers coverage in case of damage or loss of cargo, ships, terminals, and any transport by which any property is acquired, transferred or held between the point of origin and its destination.</p>
              
              <p>Marine insurance covers property exposed either onshore and/or offshore, marine casualty, marine liability, and hull damages.</p>
              
              <p>In case goods are transported using a courier service, shipping insurance can be purchased.</p>
              
              <p>KR Insurance Services offers unique products with the sole intention of safeguarding your cargo whether in the sea, over land or in the air.</p>
              
              <p>KR Insurance Services have  access to the resources and in-depth expertise necessary to handle all types of types of account, however complex, and to offer a service - from risk assessment through to claims support - which ensures that whatever the size of your client's operation, they can be sure of receiving the high level of personal service that their individual requirements demand.</p>
              
              <p>We can cater for all aspects of marine insurance:</p>
              
              <div className="tabkyfet-en">
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-en">Cargo</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-en">Fine Arts</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-en">Hull and Machinery</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-en">Loss of Hire</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-en">Protection and Indemnity</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-en">Marine Liability</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-en">Ransom / Piracy</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-en">Breakup Voyages</div>
              </div>
              
              <p>KR Insurance Services has a unique risk management services which provides consultancy for vessel selection, warehousing and packaging. We are also providing route surveys on case to case basis. We have emergency helplines operating 24x7, assuring best claim settlement services to our clients.</p>
              
              <h3 className="tab-sub-title-en">Main Cover / Marine Insurance</h3>
              
              <p>KR Insurance products go beyond the standard coverage and provide a free of cost, one of a kind combination in the form of over 30 cover extensions.</p>
              
              <div className="tabkyfet-en">
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-en">Fire or explosion, stranding, sinking etc. - can’t predict these situations in advance. Fire or explosion, sinking, stranding are common issues faced during a cargo journey. A marine insurance plan is formulated to take care of such dire situations.</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-en">Collision, overturning or derailment of land conveyance - Sometimes, no matter how hard the captain tries, he can’t take control of unfavourable damage causing situations. Having a marine insurance plan neutralizes the risk of damages caused by collision, overturning or derailment of land conveyance.</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-en">Discharge of cargo at port of distress - a cargo is a discharged from a port of disturbance or distress, it threatens the safety of the cargo. This is the reason why it is included in the basic insurance coverage.</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-en">General average sacrifice salvage charges - marine insurance plan provides coverage for general average sacrifice salvage charges.</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-en">Expenses such as survey fees, forwarding expenses, reconditioning costs and sue charges - These expenses are unexpected at times which leaves you with little time to pay for these expenses. This is why having a marine insurance plan is of great help.</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-en">Jettison or washing overboard - Situations of jettison and washing overboard are included in a marine insurance plan.</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-en">Earthquake or lightning -&nbsp;marine insurance plan provides you coverage from natural calamities such as earthquake or lightning.</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-en">Total loss of package lost overboard or dropped in loading or unloading - No matter how irksome a package loss can be, every time you can’t create a foolproof plan to prevent this situation when your package is being transported in a cargo. This is why it is included in a basic marine insurance plan.</div>
              </div>

              <h3 className="tab-whykrinsu-title-en">Why KR Insurance Services ?</h3>
              
              <div className="tab-whykrinsu-pins-list-en">
                <div className="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
                <div className="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
                <div className="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
                <div className="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
              </div>
              
              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec">
                <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
              </div>
              {/* ask for quotes button starts here */}

            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div className="inn-banner-bg-main-en hidden-desktop hidden-md-device hidden-lg">
        <div className="dropdown-sec">
          <select className="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"property-insurance"}>Property Insurance</option>
            <option value={"project-insurance"}>Project Insurance</option>
            <option value={"terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</option>
            <option value={"motor-fleet-insurance"}>Motor Fleet Insurance</option>
            <option value={"group-health-insurance"}>Group Health Insurance</option>
            <option value={"group-life-insurance"}>Group life Insurance</option>
            <option value={"fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</option>
            <option value={"burglary-insurance"}>Burglary Insurance</option>
            <option value={"workmen-compensation-insurance"}>Workmen Compensation Insurance</option>
            <option value={"group-personal-accident-insurance"}>Group Personal Accident Insurance</option>
            <option value={"cyber-crime-insurance"}>Cyber Crime Insurance</option>
            <option value={"jewellers-block-insurance"}>Jewellers Block Insurance</option>
            <option value={"stock-throughput-insurance"}>Stock Throughput Insurance</option>
            <option value={"hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</option>
            <option value={"speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</option>
            <option value={"liability-insurance"}>Liability Insurance</option>
            <option value={"marine-insurance"} selected className="active">Marine Insurance</option>
            <option value={"energy-insurance"}>Energy Insurance</option>
          </select>
        </div>
        <div className="innner-mob-body-main-en">
          <h1 className="inner-mob-heading-en">Marine Insurance</h1>
          <div className="innner-mob-body-img-en">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/marine_insurance.png"} alt="Marine Insurance" />
          </div>

          <p className="inner-mob-body-txt-en">Marine insurance offers coverage in case of damage or loss of cargo, ships, terminals, and any transport by which any property is acquired, transferred or held between the point of origin and its destination.</p>

          <p className="inner-mob-body-txt-en">Marine insurance covers property exposed either onshore and/or offshore, marine casualty, marine liability, and hull damages.</p>

          <p className="inner-mob-body-txt-en">In case goods are transported using a courier service, shipping insurance can be purchased.</p>

          <p className="inner-mob-body-txt-en">KR Insurance Services offers unique products with the sole intention of safeguarding your cargo whether in the sea, over land or in the air.</p>

          <p className="inner-mob-body-txt-en">KR Insurance Services have  access to the resources and in-depth expertise necessary to handle all types of types of account, however complex, and to offer a service - from risk assessment through to claims support - which ensures that whatever the size of your client's operation, they can be sure of receiving the high level of personal service that their individual requirements demand.</p>

          <p className="inner-mob-body-txt-en">We can cater for all aspects of marine insurance:</p>

          <div className="inner-mob-pins-list-en">
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Cargo</div>
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Fine Arts</div>
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Hull and Machinery</div>
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Loss of Hire</div>
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Protection and Indemnity</div>
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Marine Liability</div>
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Ransom / Piracy</div>
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Breakup Voyages</div>
          </div>

          <h3 className="inner-mob-main-cover-title-en">Main Cover / Marine Insurance</h3>

          <p className="inner-mob-body-txt-en">KR Insurance products go beyond the standard coverage and provide a free of cost, one of a kind combination in the form of over 30 cover extensions.</p>

          <div className="inner-mob-pins-list-en">
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Fire or explosion, stranding, sinking etc. - can't predict these situations in advance. Fire or explosion, sinking, stranding are common issues faced during a cargo journey. A marine insurance plan is formulated to take care of such dire situations.</div>

            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Collision, overturning or derailment of land conveyance - Sometimes, no matter how hard the captain tries, he can't take control of unfavourable damage causing situations. Having a marine insurance plan neutralizes the risk of damages caused by collision, overturning or derailment of land conveyance.</div>

            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Discharge of cargo at port of distress - a cargo is a discharged from a port of disturbance or distress, it threatens the safety of the cargo. This is the reason why it is included in the basic insurance coverage.</div>

            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">General average sacrifice salvage charges - marine insurance plan provides coverage for general average sacrifice salvage charges.</div>

            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Expenses such as survey fees, forwarding expenses, reconditioning costs and sue charges - These expenses are unexpected at times which leaves you with little time to pay for these expenses. This is why having a marine insurance plan is of great help.</div>

            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Jettison or washing overboard - Situations of jettison and washing overboard are included in a marine insurance plan.</div>

            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Earthquake or lightning -&nbsp;marine insurance plan provides you coverage from natural calamities such as earthquake or lightning.</div>

            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Total loss of package lost overboard or dropped in loading or unloading - No matter how irksome a package loss can be, every time you can’t create a foolproof plan to prevent this situation when your package is being transported in a cargo. This is why it is included in a basic marine insurance plan.</div>
          </div>

          <h3 className="inner-mob-main-cover-title-en">Why KR Insurance Services ?</h3>

          <div className="inner-mob-bdy-whykrlist-en">
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>
          
          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec">
            <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
          </div>
          {/* ask for quotes button starts here */}

        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default MarineInsurance;