import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import { Helmet } from 'react-helmet-async';
import './aremployeewellnessprogram.scss';

const AREmployeeWellnessProgram = () => {

  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }

  }, [])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <Helmet>
        <title>‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬.</title>
        <meta name="keywords" content="Employee Wellness Progaram Insurance" />
        <meta name="description" content="KR ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫لوسطاء‬ ‫موثوق‬ ‫خيار‬ ‫هو‬ ‫للتأمين‬ ‫عمان‬. ‫عمان‬ ‫في‬ ‫التأمين‬ ‫شركات‬ ‫ألفضل‬ ‫التأمين‬ ‫حلول‬ ‫إلى‬ ‫للوصول‬ ‫معنا‬ ‫شارك‬." />
      </Helmet>
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div className="dsk-tab-btn-viewmode-ar">
        <div class="inn-banner-bg-main-en">
          <div class="inner-con-en">
            <div class="inner-banner-btn-ar">
              <h1>خدمات قيمة</h1>

              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-ar">
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "risk-inspection-services-ar"}>خدمات فحص المخاطر </div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "insurance-audits-ar"}>تدقيقات التأمين</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "valuation-services-ar"}>خدمات التقييم</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "thermography-testing-insurance-ar"}>اختبار التصوير الحراري</div>
                <div className="inner-banner-btn-item-active-ar" onClick={() => window.location.href = "employee-wellness-program-ar"}>برنامج صحة الموظفين</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="inner-con-en">
          <div class="bdy-innercont-en">
            <div class="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="innerbdy-dlex-lft-ar">
                <img src={process.env.PUBLIC_URL + "/riskmanagementservices_images/tab_images/employee_wellness_program_ar.png"} alt="برنامج صحة الموظفين" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="innerbdy-dlex-rgt-ar">
                <h2 class="pagetitle-ar">برنامج صحة الموظفين الخاص بشركة كيمجي رامداس لخدمات التأمين </h2>

                <p class="pg-txt-con-ar"><strong>! نقوم بإنشاء برامج صحية مخصصة ومثيرة لك</strong></p>
                <br /> <br /> <br /> <br /><br /> <br />
                <p className="pg-txt-con-ar">في سعينا لتقديم أفضل الخدمات إضافة قيمة إلى برنامج التأمين الصحي الخاص بك، نقترح خدمات ذات قيمة مضافة
                  للموظفين المرتبطين بمنظمتك الموقرة. هذه مجرد مبادرة تم اتخاذها من جانبنا لصالح موظفيك مع عدم وجود أي
                  تكاليف
                  تعاونت شركة كيمجي رامداس لخدمات التأمين مع المستشفيات المشهورة في ُعمان لنشر الوعي الصحي وتحفيز
                  الممارسات الصحية الجيدة بين جميع موظفيك</p>

                <div class="clearfix"></div>
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

            <h3 className="bdyinner-whykr-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>
            <div className="clearfix"></div>
            <div className="bdyinner-whykr-keypoints-ar">
              <div className="bdyinner-whykr-kepoints-secitem-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-whykr-kepoints-ftitem-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-whykr-kepoints-secitem-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div className="bdyinner-whykr-kepoints-ftitem-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>

          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div class="insuraudits-tab-imagebannermain-ar">
          <div className="insuraudits-con-ar">
            <div className="insurauditstab-banne-sec-ar">
              <h1 className="insuraudits-banner-heading-ar">خدمات قيمة</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="insuraudits-tab-btn-ar">
                <div className="insuraudits-tab-btn-item-ar" onClick={() => window.location.href = "risk-inspection-services-ar"}>خدمات فحص المخاطر </div>
                <div className="insuraudits-tab-btn-item-ar" onClick={() => window.location.href = "insurance-audits-ar"}>تدقيقات التأمين</div>
                <div className="insuraudits-tab-btn-item-ar" onClick={() => window.location.href = "valuation-services-ar"}>خدمات التقييم</div>
                <div className="insuraudits-tab-btn-item-ar" onClick={() => window.location.href = "thermography-testing-insurance-ar"}>اختبار التصوير الحراري</div>
                <div className="insuraudits-tab-btn-item-active-ar" onClick={() => window.location.href = "employee-wellness-program-ar"}>برنامج صحة الموظفين</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="insuraudits-banner-con-ar">
          <div className="tab-imgtxt-ar">
            <h2>برنامج صحة الموظفين الخاص بشركة كيمجي رامداس لخدمات التأمين </h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/riskmanagementservices_images/tab_images/employee_wellness_program_ar.png"} alt="برنامج صحة الموظفين" />

              {/* <p><strong>!كل ةريثمو ةصصخم ةيحص جمارب ءاشنإب موقن</strong></p> */}
              <p><strong>! نقوم بإنشاء برامج صحية مخصصة ومثيرة لك</strong></p>

              <p>في سعينا لتقديم أفضل الخدمات إضافة قيمة إلى برنامج التأمين الصحي الخاص بك، نقترح خدمات ذات قيمة مضافة
                للموظفين المرتبطين بمنظمتك الموقرة. هذه مجرد مبادرة تم اتخاذها من جانبنا لصالح موظفيك مع عدم وجود أي
                تكاليف
                تعاونت شركة كيمجي رامداس لخدمات التأمين مع المستشفيات المشهورة في ُعمان لنشر الوعي الصحي وتحفيز
                الممارسات الصحية الجيدة بين جميع موظفيك</p>


              <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>

              <div class="tab-whykrinsu-pins-list-ar">
                <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
              </div>

            </div>
          </div>

        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="inn-banner-bg-main-en hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"risk-inspection-services-ar"}>خدمات فحص المخاطر </option>
            <option value={"insurance-audits-ar"}>تدقيقات التأمين</option>
            <option value={"valuation-services-ar"}>خدمات التقييم</option>
            <option value={"thermography-testing-insurance-ar"}>اختبار التصوير الحراري</option>
            <option value={"employee-wellness-program-ar"} selected className="active">برنامج صحة الموظفين</option>
          </select>
        </div>
        <div class="innner-mob-body-main-en">
          <h1 class="inner-mob-heading-en">برنامج صحة الموظفين الخاص بشركة كيمجي رامداس لخدمات التأمين </h1>
          <div class="innner-mob-body-img-en">
            <img src={process.env.PUBLIC_URL + "/riskmanagementservices_images/tab_images/employee_wellness_program_ar.png"} alt="برنامج صحة الموظفين" />
          </div>
          <p class="inner-mob-body-txt-ar"><strong>! نقوم بإنشاء برامج صحية مخصصة ومثيرة لك</strong></p>
          <br /> <br /> <br />
          <p className="inner-mob-body-txt-ar">في سعينا لتقديم أفضل الخدمات إضافة قيمة إلى برنامج التأمين الصحي الخاص بك، نقترح خدمات ذات قيمة مضافة
            للموظفين المرتبطين بمنظمتك الموقرة. هذه مجرد مبادرة تم اتخاذها من جانبنا لصالح موظفيك مع عدم وجود أي
            تكاليف
            تعاونت شركة كيمجي رامداس لخدمات التأمين مع المستشفيات المشهورة في ُعمان لنشر الوعي الصحي وتحفيز
            الممارسات الصحية الجيدة بين جميع موظفيك</p>


          <h3 className="inner-mob-main-cover-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>

          <div className="inner-mob-bdy-whykrlist-ar">
            <div className="inner-mob-bdy-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar"> أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>


        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default AREmployeeWellnessProgram;
