import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import { Helmet } from 'react-helmet-async';
import './arfidelityguaranteeinsurance.scss';

const ARFidelityGuaranteeInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
    <Helmet>
      <title>‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬.</title>
      <meta name="keywords" content="KR Insurance Fidelity Guarantee Insurance"/>
      <meta name="description" content="KR ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫لوسطاء‬ ‫موثوق‬ ‫خيار‬ ‫هو‬ ‫للتأمين‬ ‫عمان‬. ‫عمان‬ ‫في‬ ‫التأمين‬ ‫شركات‬ ‫ألفضل‬ ‫التأمين‬ ‫حلول‬ ‫إلى‬ ‫للوصول‬ ‫معنا‬ ‫شارك‬." />
    </Helmet>
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-ar">
        <div class="inn-banner-bg-main-en">
          <div class="inner-con-en">
            <div class="inner-banner-btn-en">
              <h1>تأمين الأعمال </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="inner-banner-btn-ar">
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="inner-banner-btn-item-active-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="inner-con-en">
          <div class="bdy-innercont-en">
            <div class="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="innerbdy-dlex-lft-ar">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/fidelity_guarantee_insurance_ar.png"} alt="تأمين ضد حالات خيانة الأمانة" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="innerbdy-dlex-rgt-ar">
                <h2 class="pagetitle-ar">تأمين ضد حالات خيانة الأمانة</h2>
                <p class="pg-txt-con-ar">تتعرض الشركات لخسائر مالية كبيرة سنويًا بسبب الجرائم التي يرتكبها الموظفون وتفيد التقارير أن عمليات الاحتيال في تزايد مستمر. إلى جانب النمو في استخدام البيانات الإلكترونية وتحويل الأصول، تزداد الخسائر الناتجة عن خيانة الأمانة التي يواجهها أصحاب العمل بشكل كبير. غالبًا ما تسعى الشركات إلى تأمين ضد خيانة الأمانة حيث من المرجح أن يتسبب الموظفون في خسارة مالية بسبب تعرضهم المستمر للتعامل مع الأموال النقدية، والأسهم أو الأصول الأخرى. الخسائر المحتملة التي يمكن أن تنشأ عن خيانة الأمانة، خاصة مع زيادة جرائم الاحتيال الإلكتروني، تجعل من الملائم أن يستخدم أصحاب العمل المعرضون للخطر تأمين ضد حالات خيانة الأمانة كوسيلة لإدارة المخاطر</p>
                
                <h3 class="bdyinner-heading-ar">التغطية الرئيسية / تأمين على ضمان الأمانة</h3>

                <p class="pg-txt-con-ar">تأمين على ضمان الأمانة هو بوليصة تأمين مصممة لتعويض المؤمن له (صاحب العمل) عن خسارة الأموال أو الممتلكات التي تكبدها كنتيجة مباشرة لأعمال الاحتيال أو السرقة أو عدم الأمانة من قبل الموظف أثناء العمل</p>

              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

            <p class="pg-txt-con-ar">تغطي البوليصة الخسارة التي تكبدها صاحب العمل بسبب أي عمل من أعمال التزوير و/ أو الاحتيال و/ أو عدم الأمانة في التصرف في أموال و/ أو سلع صاحب العمل من جانب الموظف المؤمن عليه التي ارتكبت في وقت بدء البوليصة أو بعده أثناء الخدمة المستمرة مع صاحب العمل</p>

            <p class="pg-txt-con-ar">يجب الكشف عن الخسارة أثناء استمرار البوليصة أو في غضون 12 شهرًا تقويميًا من انتهاء صلاحية البوليصة وفي حالة وفاة الموظف أو فصله أو تقاعده خلال 12 شهرًا تقويميًا من الوفاة أو الفصل أو التقاعد أيهما أسبق</p>

            <p class="pg-txt-con-ar">يتم تقديم تأمين على ضمان الأمانة بموجب أنواع مختلفة من البوليصات التي تشمل الفردية والجماعية والممتلكات الشخصية غير المجدولة. قبل أن يتم دفع أي نوع من المطالبات، يجب على الشركة أولاً إثبات أن أحد الموظفين المشمولين بالبوليصة قد ارتكب فعلاً من أعمال خيانة الأمانة</p>

            <h3 class="bdyinner-whykr-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>
            <div class="clearfix"></div>
            <div class="bdyinner-whykr-keypoints-ar">
              <div class="bdyinner-whykr-kepoints-secitem-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-whykr-kepoints-ftitem-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-whykr-kepoints-secitem-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="bdyinner-whykr-kepoints-ftitem-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            

          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div class="inn-banner-bg-main-en">
          <div class="inner-con-en">
            <div class="inner-banner-btn-en">
              <h1 class="inner-mob-heading-en">تأمين الأعمال </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="inner-banner-btn-ar">
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="inner-banner-btn-item-active-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="inner-con-en">
          <div className="tab-imgtxt-ar">
            <h2>تأمين ضد حالات خيانة الأمانة</h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/fidelity_guarantee_insurance_ar.png"} alt="تأمين ضد حالات خيانة الأمانة" />
              <p>تتعرض الشركات لخسائر مالية كبيرة سنويًا بسبب الجرائم التي يرتكبها الموظفون وتفيد التقارير أن عمليات الاحتيال في تزايد مستمر. إلى جانب النمو في استخدام البيانات الإلكترونية وتحويل الأصول، تزداد الخسائر الناتجة عن خيانة الأمانة التي يواجهها أصحاب العمل بشكل كبير. غالبًا ما تسعى الشركات إلى تأمين ضد خيانة الأمانة حيث من المرجح أن يتسبب الموظفون في خسارة مالية بسبب تعرضهم المستمر للتعامل مع الأموال النقدية، والأسهم أو الأصول الأخرى. الخسائر المحتملة التي يمكن أن تنشأ عن خيانة الأمانة، خاصة مع زيادة جرائم الاحتيال الإلكتروني، تجعل من الملائم أن يستخدم أصحاب العمل المعرضون للخطر تأمين ضد حالات خيانة الأمانة كوسيلة لإدارة المخاطر</p>
              
              <h3 class="tab-sub-title-ar">التغطية الرئيسية / تأمين على ضمان الأمانة</h3>

              <p>تأمين على ضمان الأمانة هو بوليصة تأمين مصممة لتعويض المؤمن له (صاحب العمل) عن خسارة الأموال أو الممتلكات التي تكبدها كنتيجة مباشرة لأعمال الاحتيال أو السرقة أو عدم الأمانة من قبل الموظف أثناء العمل</p>

              <p>تغطي البوليصة الخسارة التي تكبدها صاحب العمل بسبب أي عمل من أعمال التزوير و/ أو الاحتيال و/ أو عدم الأمانة في التصرف في أموال و/ أو سلع صاحب العمل من جانب الموظف المؤمن عليه التي ارتكبت في وقت بدء البوليصة أو بعده أثناء الخدمة المستمرة مع صاحب العمل</p>

              <p>يجب الكشف عن الخسارة أثناء استمرار البوليصة أو في غضون 12 شهرًا تقويميًا من انتهاء صلاحية البوليصة وفي حالة وفاة الموظف أو فصله أو تقاعده خلال 12 شهرًا تقويميًا من الوفاة أو الفصل أو التقاعد أيهما أسبق</p>

              <p>يتم تقديم تأمين على ضمان الأمانة بموجب أنواع مختلفة من البوليصات التي تشمل الفردية والجماعية والممتلكات الشخصية غير المجدولة. قبل أن يتم دفع أي نوع من المطالبات، يجب على الشركة أولاً إثبات أن أحد الموظفين المشمولين بالبوليصة قد ارتكب فعلاً من أعمال خيانة الأمانة</p>

              <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
              
              <div class="tab-whykrinsu-pins-list-ar">
                <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
              </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="inn-banner-bg-main-en hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"project-insurance-ar"}>تأمين المشاريع </option>
            <option value={"property-insurance-ar"}>تأمين الممتلكات</option>
            <option value={"terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </option>
            <option value={"motor-fleet-insurance-ar"} >تأمين أسطول المركبات</option>
            <option value={"group-health-insurance-ar"}>التأمين الصحي الجماعي</option>
            <option value={"group-life-insurance-ar"}>التأمين الجماعي على الحياة</option>
            <option value={"fidelity-guarantee-insurance-ar"} selected class="active">تأمين ضد حالات خيانة الأمانة</option>
            <option value={"burglary-insurance-ar"}>التأمين ضد السرقة</option>
            <option value={"workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</option>
            <option value={"group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</option>
            <option value={"cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</option>
            <option value={"jewellers-block-insurance-ar"}>التأمين على المجوهرات</option>
            <option value={"stock-throughput-insurance-ar"}>تأمين صافي المخزون</option>
            <option value={"hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</option>
            <option value={"speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</option>
            <option value={"liability-insurance-ar"}>التأمين عن المسؤولية</option>
            <option value={"marine-insurance-ar"}>التأمين البحري</option>
            <option value={"energy-insurance-ar"}>التأمين على الطاقة</option>
          </select>
        </div>
        <div class="innner-mob-body-main-en">
          <h1 class="inner-mob-heading-en">تأمين ضد حالات خيانة الأمانة</h1>
          <div class="innner-mob-body-img-en">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/fidelity_guarantee_insurance_ar.png"} alt="تأمين ضد حالات خيانة الأمانة" />
          </div>

          <p class="inner-mob-body-txt-ar">تتعرض الشركات لخسائر مالية كبيرة سنويًا بسبب الجرائم التي يرتكبها الموظفون وتفيد التقارير أن عمليات الاحتيال في تزايد مستمر. إلى جانب النمو في استخدام البيانات الإلكترونية وتحويل الأصول، تزداد الخسائر الناتجة عن خيانة الأمانة التي يواجهها أصحاب العمل بشكل كبير. غالبًا ما تسعى الشركات إلى تأمين ضد خيانة الأمانة حيث من المرجح أن يتسبب الموظفون في خسارة مالية بسبب تعرضهم المستمر للتعامل مع الأموال النقدية، والأسهم أو الأصول الأخرى. الخسائر المحتملة التي يمكن أن تنشأ عن خيانة الأمانة، خاصة مع زيادة جرائم الاحتيال الإلكتروني، تجعل من الملائم أن يستخدم أصحاب العمل المعرضون للخطر تأمين ضد حالات خيانة الأمانة كوسيلة لإدارة المخاطر</p>
          
          <h3 class="inner-mob-main-cover-title-ar">التغطية الرئيسية / تأمين على ضمان الأمانة</h3>

          <p class="inner-mob-body-txt-ar">تأمين على ضمان الأمانة هو بوليصة تأمين مصممة لتعويض المؤمن له (صاحب العمل) عن خسارة الأموال أو الممتلكات التي تكبدها كنتيجة مباشرة لأعمال الاحتيال أو السرقة أو عدم الأمانة من قبل الموظف أثناء العمل</p>

          <p class="inner-mob-body-txt-ar">تغطي البوليصة الخسارة التي تكبدها صاحب العمل بسبب أي عمل من أعمال التزوير و/ أو الاحتيال و/ أو عدم الأمانة في التصرف في أموال و/ أو سلع صاحب العمل من جانب الموظف المؤمن عليه التي ارتكبت في وقت بدء البوليصة أو بعده أثناء الخدمة المستمرة مع صاحب العمل</p>

          <p class="inner-mob-body-txt-ar">يجب الكشف عن الخسارة أثناء استمرار البوليصة أو في غضون 12 شهرًا تقويميًا من انتهاء صلاحية البوليصة وفي حالة وفاة الموظف أو فصله أو تقاعده خلال 12 شهرًا تقويميًا من الوفاة أو الفصل أو التقاعد أيهما أسبق</p>

          <p class="inner-mob-body-txt-ar">يتم تقديم تأمين على ضمان الأمانة بموجب أنواع مختلفة من البوليصات التي تشمل الفردية والجماعية والممتلكات الشخصية غير المجدولة. قبل أن يتم دفع أي نوع من المطالبات، يجب على الشركة أولاً إثبات أن أحد الموظفين المشمولين بالبوليصة قد ارتكب فعلاً من أعمال خيانة الأمانة</p>

          <h3 class="inner-mob-main-cover-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>

          <div class="inner-mob-bdy-whykrlist-ar">
            <div class="inner-mob-bdy-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-bdy-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-bdy-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-bdy-whykrlist-item-ar"> أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARFidelityGuaranteeInsurance;