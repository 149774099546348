import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import { Helmet } from 'react-helmet-async';
import './argrouplifeinsurance.scss';

const ARGroupLifeInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
    <Helmet>
      <title>‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬.</title>
      <meta name="keywords" content="Project insurance, Property Project Insurance"/>
      <meta name="description" content="KR ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫لوسطاء‬ ‫موثوق‬ ‫خيار‬ ‫هو‬ ‫للتأمين‬ ‫عمان‬. ‫عمان‬ ‫في‬ ‫التأمين‬ ‫شركات‬ ‫ألفضل‬ ‫التأمين‬ ‫حلول‬ ‫إلى‬ ‫للوصول‬ ‫معنا‬ ‫شارك‬." />
    </Helmet>
     <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-ar">
        <div class="inn-banner-bg-main-en">
          <div class="inner-con-en">
            <div class="inner-banner-btn-en">
              <h1>تأمين الأعمال</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="inner-banner-btn-ar">
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="inner-banner-btn-item-active-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="inner-con-en">
          <div class="bdy-innercont-en">
            <div class="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="innerbdy-dlex-lft-ar">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/group_life_insurance_ar.png"} alt="التأمين الجماعي على الحياة" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="innerbdy-dlex-rgt-ar">
                <h2 class="pagetitle-ar">التأمين الجماعي على الحياة</h2>

                <p class="pg-txt-con-ar">توفير حلول التأمين الصحي والتأمين على الحياة المدمجة بشكل ملائم من خلال إدارة واحدة مبسطة، تقدم شركة كيمجي رامداس لخدمات التأمين مجموعة واسعة من المنتجات المخصصة مع إمكانات تقديم الخدمة. بمساعدة خبراتنا في مجال الأعمال، تهدف شركة كيمجي رامداس لخدمات التأمين إلى الالتزام بجميع متطلباتك من خلال تقديم المشورة والتوجيه أثناء إجراءات عرض السعر. لدعم موظفيها وموظفي المكاتب الأخرى. بمساعدة التأمين الجماعي على الحياة، من الممكن مساعدة الموظفين من الناحية المالية في أعقاب إصابات العمل وكذلك تنظيم أي من عمليات العودة إلى الوطن إذا لزم الأمر. يمكن أن تكون سياسات التأمين الجماعي على الحياة مصدرًا ممتازًا لتوفير التأمين أثناء المواقف الخطيرة، مثل الوفاة أو أي حوادث تسبب العجز. كما أنه مفيد أيضًا عندما لا يكون التأمين الطبي كافيًا كما هو الحال في حالات المرض أو المرض العضال. توفر شركة كيمجي رامداس لخدمات التأمين أفضل تغطية جماعية على الحياة وأكثرها فعالية من حيث التكلفة لعملائها مع دعم كامل لتسوية المطالبات</p>

              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>          

            <h3 class="bdyinner-heading-ar">التغطية الرئيسية / التأمين الجماعي على الحياة</h3>

            <p class="pg-txt-con-ar">تشمل بعض الفوائد الرئيسية</p>

            <div class="bdyinner-keypoint-ar">
              <div class="bdyinner-keypoint-item-ar">الوفاة الناتجة عن أي سبب</div>
              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-ar">مخصصات الوفاة العرضية تغطي أيضًا أي عجز دائم أو عجز كلي</div>

              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-ar">العجز الجزئي الدائم</div>

              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-ar">العجز الكلي المؤقت</div>

              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-ar">تعويض المصاريف الطبية نتيجة الإصابات العرضية</div>

              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-ar">مرض خطير (مزايا اختيارية)</div>

              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-ar">نفقات العودة إلى الوطن</div>

              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-ar">المخاطر السلبية للحروب</div>

              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-ar">تغطية حالات الانتحار</div>

              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-ar">تغطية حالات الاختفاء</div>
              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>

            <h3 class="bdyinner-whykr-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>
            <div class="clearfix"></div>
            <div class="bdyinner-whykr-keypoints-ar">
              <div class="bdyinner-whykr-kepoints-secitem-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-whykr-kepoints-ftitem-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-whykr-kepoints-secitem-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="bdyinner-whykr-kepoints-ftitem-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div class="inn-banner-bg-main-en">
          <div class="inner-con-en">
            <div class="inner-banner-btn-en">
              <h1 class="grplifeinsu-banner-heading-ar">تأمين الأعمال</h1>

              {/* TAB BUTTON SECTION STARTS HERE */}              
              <div class="inner-banner-btn-ar">
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="inner-banner-btn-item-active-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="inner-con-en">
          <div className="tab-imgtxt-ar">
            <h2>التأمين الجماعي على الحياة</h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/group_life_insurance_ar.png"} alt="التأمين الجماعي على الحياة" />

              <p>توفير حلول التأمين الصحي والتأمين على الحياة المدمجة بشكل ملائم من خلال إدارة واحدة مبسطة، تقدم شركة كيمجي رامداس لخدمات التأمين مجموعة واسعة من المنتجات المخصصة مع إمكانات تقديم الخدمة. بمساعدة خبراتنا في مجال الأعمال، تهدف شركة كيمجي رامداس لخدمات التأمين إلى الالتزام بجميع متطلباتك من خلال تقديم المشورة والتوجيه أثناء إجراءات عرض السعر. لدعم موظفيها وموظفي المكاتب الأخرى. بمساعدة التأمين الجماعي على الحياة، من الممكن مساعدة الموظفين من الناحية المالية في أعقاب إصابات العمل وكذلك تنظيم أي من عمليات العودة إلى الوطن إذا لزم الأمر. يمكن أن تكون سياسات التأمين الجماعي على الحياة مصدرًا ممتازًا لتوفير التأمين أثناء المواقف الخطيرة، مثل الوفاة أو أي حوادث تسبب العجز. كما أنه مفيد أيضًا عندما لا يكون التأمين الطبي كافيًا كما هو الحال في حالات المرض أو المرض العضال. توفر شركة كيمجي رامداس لخدمات التأمين أفضل تغطية جماعية على الحياة وأكثرها فعالية من حيث التكلفة لعملائها مع دعم كامل لتسوية المطالبات</p>

              <h3 class="tab-sub-title-ar">التغطية الرئيسية / التأمين الجماعي على الحياة</h3>
              
              <p>تشمل بعض الفوائد الرئيسية</p>

              <div className="tabkyfet-ar">
                <div class="tabkeyfet-txt-ar">الوفاة الناتجة عن أي سبب</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">مخصصات الوفاة العرضية تغطي أيضًا أي عجز دائم أو عجز كلي</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">العجز الجزئي الدائم</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">العجز الكلي المؤقت</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">تعويض المصاريف الطبية نتيجة الإصابات العرضية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">مرض خطير (مزايا اختيارية)</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">نفقات العودة إلى الوطن</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">المخاطر السلبية للحروب</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">تغطية حالات الانتحار</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-ar">تغطية حالات الاختفاء</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>
              
              <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
              
              <div class="tab-whykrinsu-pins-list-ar">
                <div class="tab-whykrinsu-pinlft-two-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> خدمات المطالبات الفورية</div>
                <div class="tab-whykrinsu-pinlft-one-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" />  أقل الأسعار</div>
                <div class="tab-whykrinsu-pinlft-two-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> أفضل ممارسات صناعة التأمين</div>
                <div class="tab-whykrinsu-pinlft-one-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> مقارنات عروض أسعار واسعة</div>
              </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="inn-banner-bg-main-en hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"project-insurance-ar"}>تأمين المشاريع </option>
            <option value={"property-insurance-ar"}>تأمين الممتلكات</option>
            <option value={"terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </option>
            <option value={"motor-fleet-insurance-ar"} >تأمين أسطول المركبات</option>
            <option value={"group-health-insurance-ar"}>التأمين الصحي الجماعي</option>
            <option value={"group-life-insurance-ar"} selected class="active">التأمين الجماعي على الحياة</option>
            <option value={"fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</option>
            <option value={"burglary-insurance-ar"}>التأمين ضد السرقة</option>
            <option value={"workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</option>
            <option value={"group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</option>
            <option value={"cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</option>
            <option value={"jewellers-block-insurance-ar"}>التأمين على المجوهرات</option>
            <option value={"stock-throughput-insurance-ar"}>تأمين صافي المخزون</option>
            <option value={"hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</option>
            <option value={"speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</option>
            <option value={"liability-insurance-ar"}>التأمين عن المسؤولية</option>
            <option value={"marine-insurance-ar"}>التأمين البحري</option>
            <option value={"energy-insurance-ar"}>التأمين على الطاقة</option>
          </select>
        </div>
        <div class="innner-mob-body-main-en">
          <h1 class="inner-mob-heading-en">التأمين الجماعي على الحياة</h1>
          <div class="innner-mob-body-img-en">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/group_life_insurance_ar.png"} alt="التأمين الجماعي على الحياة" />
          </div>

          <p class="inner-mob-body-txt-ar">توفير حلول التأمين الصحي والتأمين على الحياة المدمجة بشكل ملائم من خلال إدارة واحدة مبسطة، تقدم شركة كيمجي رامداس لخدمات التأمين مجموعة واسعة من المنتجات المخصصة مع إمكانات تقديم الخدمة. بمساعدة خبراتنا في مجال الأعمال، تهدف شركة كيمجي رامداس لخدمات التأمين إلى الالتزام بجميع متطلباتك من خلال تقديم المشورة والتوجيه أثناء إجراءات عرض السعر. لدعم موظفيها وموظفي المكاتب الأخرى. بمساعدة التأمين الجماعي على الحياة، من الممكن مساعدة الموظفين من الناحية المالية في أعقاب إصابات العمل وكذلك تنظيم أي من عمليات العودة إلى الوطن إذا لزم الأمر. يمكن أن تكون سياسات التأمين الجماعي على الحياة مصدرًا ممتازًا لتوفير التأمين أثناء المواقف الخطيرة، مثل الوفاة أو أي حوادث تسبب العجز. كما أنه مفيد أيضًا عندما لا يكون التأمين الطبي كافيًا كما هو الحال في حالات المرض أو المرض العضال. توفر شركة كيمجي رامداس لخدمات التأمين أفضل تغطية جماعية على الحياة وأكثرها فعالية من حيث التكلفة لعملائها مع دعم كامل لتسوية المطالبات</p>

          <h3 class="inner-mob-main-cover-title-ar">التغطية الرئيسية / التأمين الجماعي على الحياة</h3>

          <p class="inner-mob-body-txt-ar">تشمل بعض الفوائد الرئيسية</p>


          <div class="inner-mob-pins-list-ar">
            <div class="inner-mob-pins-list-item-ar">الوفاة الناتجة عن أي سبب</div>

            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-ar">مخصصات الوفاة العرضية تغطي أيضًا أي عجز دائم أو عجز كلي</div>

            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-ar">العجز الجزئي الدائم</div>

            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-ar">العجز الكلي المؤقت</div>

            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-ar">تعويض المصاريف الطبية نتيجة الإصابات العرضية</div>

            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-ar">مرض خطير (مزايا اختيارية)</div>

            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-ar">نفقات العودة إلى الوطن</div>

            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-ar">المخاطر السلبية للحروب</div>

            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-ar">تغطية حالات الانتحار</div>

            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div class="inner-mob-pins-list-item-ar">تغطية حالات الاختفاء</div>
            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>

          <h3 class="inner-mob-main-cover-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>

          <div class="inner-mob-bdy-whykrlist-ar">
            <div class="inner-mob-bdy-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-bdy-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-bdy-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-bdy-whykrlist-item-ar"> أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
        <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARGroupLifeInsurance;