import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import { Helmet } from 'react-helmet-async';
import './propertyinsurance.scss';

const PropertyInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
    <Helmet>
      <title>Best Business Property Insurance - KR Insuranc</title>
      <meta name="keywords" content="Business property insurance"/>
      <meta name="description" content="KR Insurance provides complete business property insurance solutions for brokers & companies in Oman. Safeguard your valuable assets with our reliable coverage." />
    </Helmet>
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-en">
        <div class="inn-banner-bg-main-en">
          <div class="inner-con-en">
            <div class="inner-banner-btn-en">
              <h1>Business Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="inner-banner-btn-en">
                <div class="inner-banner-btn-item-active-en" onClick={() => window.location.href = "property-insurance"}>Property Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "project-insurance"}>Project Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "motor-fleet-insurance"}>Motor Fleet Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "group-health-insurance"}>Group Health Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "group-life-insurance"}>Group Life Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "burglary-insurance"}>Burglary Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "workmen-compensation-insurance"}>Workmen Compensation Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "group-personal-accident-insurance"}>Group Personal Accident Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "cyber-crime-insurance"}>Cyber Crime Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "jewellers-block-insurance"}>Jewellers Block Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "stock-throughput-insurance"}>Stock Throughput Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "liability-insurance"}>Liability Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "marine-insurance"}>Marine Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "energy-insurance"}>Energy Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="inner-con-en">
          <div class="bdy-innercont-en">
            <div class="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="innerbdy-dlex-lft-en">
                <h2 class="pagetitle-en">Property Insurance</h2>
                <p class="pg-txt-con">From the point of view of business, Property is probably the investment vehicle with the most substantial value. Land and building, furniture and fixtures, machinery etc, are essential for the company and it is the responsibility of the management; towards the stakeholders to safeguard these assets from risks and possible damages.</p>

                <p class="pg-txt-con">KR Insurance services offers property insurance plans which caters specifically to the needs of a business entity.  The management can stay rest assured in case of any undesired incidents.</p>

                <p class="pg-txt-con">The value added services as proposed by KR Insurance Services to their valued clients are -</p>

                <div class="innbdy-lft-keypoint-en">
                  <div class="innbdy-lft-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="innbdy-lft-keypoint-item-en">Single window solutions through our dedicated relationship manager.</div>
                  <div class="innbdy-lft-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="innbdy-lft-keypoint-item-en">We arrange wider terms, tailor made policies at best discounted pricing from the insurers.</div>
                  <div class="innbdy-lft-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="innbdy-lft-keypoint-item-en">Clients get benefitted through our In-depth knowledge of insurance terms, policy placement, claim settlement services and other insurance practices.</div>
                  <div class="innbdy-lft-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="innbdy-lft-keypoint-item-en">Hassel free &amp; prompt services leads to the savings for our clients in terms of man-hours and administrative costs.</div>
                </div>

              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="innerbdy-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/property_insurance.png"} alt="property insurance" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
            <div class="bdyinner-keypoint-en">
              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">Insurance audit with NIL cost implications of our corporate client’s insurance program.</div>
              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">Re-insurance support to underwrite mega projects with wider terms and rates.</div>
              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">Site inspection, soil inspection, through our network risk engineering team.</div>
              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">Arrangement for thermography testing for large and complex warehouses / manufacturing units.</div>
              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">We have exposure to global markets for obtaining international terms.</div>
              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">Dedicated claims team with hands on experience for settling large and complex property claims.</div>
            </div>


            <h3 class="bdyinner-heading-en">Main Cover / Property Insurance</h3>

            <p class="pg-txt-con">Property Insurance provides a vast array of covers which includes :-</p>
            <div class="bdyinner-keypoint-en">
              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">Fire: The policy offers coverage for any damage caused owing to the fire. However, damage caused by natural heating, own fermentation or spontaneous burning are excluded.</div>

              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">Lightning: Damages caused by lightning is covered. If lightning cause fire, or other damages like cracks in the building or roof area, it will be covered by fire insurance.</div>

              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">Explosion: Fire damage caused by an explosion is covered by this policy.</div>

              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">Aircraft Damage: Fire and any other damage caused by aircraft or other airborne devices, damage due to articles dropped by an aircraft is covered by fire insurance policy.</div>

              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">Riot Strike, Malicious Damages: If the insured property is damaged due to a strike, riot or any terrorist activity, the loss is covered by fire insurance.</div>

              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">Natural Disasters: Apart from the above, this policy also includes damages owing to natural disasters including storm, cyclone, typhoon, tornado, flood. The hazardous situation and consequences of a natural disaster are covered by fire insurance.</div>

              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">Landslide/Rockslide: Your insurer will offer protection under fire insurance for the destruction caused to your property to a notable or even a small part of the site where the property exists by rockslide/landslide.</div>

              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">Bursting of water/overflowing of Water Tank (Inundation): your property is damaged due to the bursting of water or overflowing of the water tank, the policy covers the damage.</div>

              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">Bush Fire: At the time you set fire to overgrown plants and bushes, the possibility is where you end up damaging the property. Fret not! The fire insurance policy that you own covers you for this as well. However, the policy wouldn’t cover the destruction due to the forest fire.</div>

              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">Terrorism : Optional cover</div>
            </div>
            <h3 class="bdyinner-whykr-title-en">Why KR Insurance Services ?</h3>
            <div class="clearfix"></div>
            <div class="bdyinner-whykr-keypoints-en">
              <div class="bdyinner-whykr-kepoints-ftitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
              <div class="bdyinner-whykr-kepoints-secitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
              <div class="bdyinner-whykr-kepoints-ftitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
              <div class="bdyinner-whykr-kepoints-secitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
            </div>
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec">
              <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
            </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
        <div class="inn-banner-bg-main-en">
          <div class="inner-con-en">
            <div class="inner-banner-btn-en">
              <h1 class="inner-banner-heading-en">Business Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="inner-banner-btn-en">
                <div class="inner-banner-btn-item-active-en" onClick={() => window.location.href = "property-insurance"}>Property Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "project-insurance"}>Project Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "motor-fleet-insurance"}>Motor Fleet Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "group-health-insurance"}>Group Health Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "group-life-insurance"}>Group Life Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "burglary-insurance"}>Burglary Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "workmen-compensation-insurance"}>Workmen Compensation Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "group-personal-accident-insurance"}>Group Personal Accident Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "cyber-crime-insurance"}>Cyber Crime Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "jewellers-block-insurance"}>Jewellers Block Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "stock-throughput-insurance"}>Stock Throughput Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "liability-insurance"}>Liability Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "marine-insurance"}>Marine Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "energy-insurance"}>Energy Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="inner-con-en">
          <div className="tab-imgtxt-en">
            <h2>Property Insurance</h2>
            <div className="tabs-cont-en">
              <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/property_insurance.png"} alt="property insurance" />

              <p>From the point of view of business, Property is probably the investment vehicle with the most substantial value. Land and building, furniture and fixtures, machinery etc, are essential for the company and it is the responsibility of the management; towards the stakeholders to safeguard these assets from risks and possible damages.</p>

              <p>KR Insurance services offers property insurance plans which caters specifically to the needs of a business entity.  The management can stay rest assured in case of any undesired incidents.</p>

              <p>The value added services as proposed by KR Insurance Services to their valued clients are -</p>

              <p className="txtimg"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Single window solutions through our dedicated relationship manager.</p>
              <p className="txtimg"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> We arrange wider terms, tailor made policies at best discounted pricing from the insurers.</p>
              <p className="txtimg"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Clients get benefitted through our In-depth knowledge of insurance terms, policy placement, claim settlement services and other insurance practices.</p>
              <p className="txtimg"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Hassel free &amp; prompt services leads to the savings for our clients in terms of man-hours and administrative costs.</p>

              <div class="tabkyfet-en">
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Insurance audit with NIL cost implications of our corporate client’s insurance program.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Re-insurance support to underwrite mega projects with wider terms and rates.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Site inspection, soil inspection, through our network risk engineering team.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Arrangement for thermography testing for large and complex warehouses / manufacturing units.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">We have exposure to global markets for obtaining international terms.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Dedicated claims team with hands on experience for settling large and complex property claims.</div>
              </div>

              <h3 class="tab-sub-title-en">Main Cover / Property Insurance</h3>

              <p>Property Insurance provides a vast array of covers which includes :-</p>

              <div class="tabkyfet-en">
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Fire: The policy offers coverage for any damage caused owing to the fire. However, damage caused by natural heating, own fermentation or spontaneous burning are excluded.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Lightning: Damages caused by lightning is covered. If lightning cause fire, or other damages like cracks in the building or roof area, it will be covered by fire insurance.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Explosion: Fire damage caused by an explosion is covered by this policy.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Aircraft Damage: Fire and any other damage caused by aircraft or other airborne devices, damage due to articles dropped by an aircraft is covered by fire insurance policy.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Riot Strike, Malicious Damages: If the insured property is damaged due to a strike, riot or any terrorist activity, the loss is covered by fire insurance.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Natural Disasters: Apart from the above, this policy also includes damages owing to natural disasters including storm, cyclone, typhoon, tornado, flood. The hazardous situation and consequences of a natural disaster are covered by fire insurance.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Landslide/Rockslide: Your insurer will offer protection under fire insurance for the destruction caused to your property to a notable or even a small part of the site where the property exists by rockslide/landslide.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Bursting of water/overflowing of Water Tank (Inundation): your property is damaged due to the bursting of water or overflowing of the water tank, the policy covers the damage.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Bush Fire: At the time you set fire to overgrown plants and bushes, the possibility is where you end up damaging the property. Fret not! The fire insurance policy that you own covers you for this as well. However, the policy wouldn’t cover the destruction due to the forest fire.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tabkeyfet-txt-en">Terrorism : Optional cover</div>
              </div>

              <h3 class="tab-whykrinsu-title-en">Why KR Insurance Services ?</h3>

              <div class="tab-whykrinsu-pins-list-en">
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
              </div>
              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec">
                <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
              </div>
              {/* ask for quotes button starts here */}
            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}


      {/* MOBILE PAGE STARTS HERE */}
      <div class="inn-banner-bg-main-en hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"property-insurance"} selected class="active">Property Insurance</option>
            <option value={"project-insurance"}>Project Insurance</option>
            <option value={"terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</option>
            <option value={"motor-fleet-insurance"}>Motor Fleet Insurance</option>
            <option value={"group-health-insurance"}>Group Health Insurance</option>
            <option value={"group-life-insurance"}>Group life Insurance</option>
            <option value={"fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</option>
            <option value={"burglary-insurance"}>Burglary Insurance</option>
            <option value={"workmen-compensation-insurance"}>Workmen Compensation Insurance</option>
            <option value={"group-personal-accident-insurance"}>Group Personal Accident Insurance</option>
            <option value={"cyber-crime-insurance"}>Cyber Crime Insurance</option>
            <option value={"jewellers-block-insurance"}>Jewellers Block Insurance</option>
            <option value={"stock-throughput-insurance"}>Stock Throughput Insurance</option>
            <option value={"hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</option>
            <option value={"speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</option>
            <option value={"liability-insurance"}>Liability Insurance</option>
            <option value={"marine-insurance"}>Marine Insurance</option>
            <option value={"energy-insurance"}>Energy Insurance</option>
          </select>
        </div>
        <div class="innner-mob-body-main-en">
          <h1 class="inner-mob-heading-en">Property Insurance</h1>
          <div class="innner-mob-body-img-en">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/property_insurance.png"} alt="property insurance" />
          </div>
          <p class="inner-mob-body-txt-en">From the point of view of business, Property is probably the investment vehicle with the most substantial value. Land and building, furniture and fixtures, machinery etc, are essential for the company and it is the responsibility of the management; towards the stakeholders to safeguard these assets from risks and possible damages.</p>

          <p class="inner-mob-body-txt-en">KR Insurance services offers property insurance plans which caters specifically to the needs of a business entity.  The management can stay rest assured in case of any undesired incidents.</p>

          <p class="inner-mob-body-txt-en">The value added services as proposed by KR Insurance Services to their valued clients are -</p>

          <div class="inner-mob-pins-list-en">
            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Single window solutions through our dedicated relationship manager.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">We arrange wider terms, tailor made policies at best discounted pricing from the insurers.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Clients get benefitted through our In-depth knowledge of insurance terms, policy placement, claim settlement services and other insurance practices.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Hassel free & prompt services leads to the savings for our clients in terms of man-hours and administrative costs.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Insurance audit with NIL cost implications of our corporate client's insurance program.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Re-insurance support to underwrite mega projects with wider terms and rates.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Site inspection, soil inspection, through our network risk engineering team.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Arrangement for thermography testing for large and complex warehouses / manufacturing units.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">We have exposure to global markets for obtaining international terms.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Dedicated claims team with hands on experience for settling large and complex property claims.</div>
          </div>
          
          <h3 class="inner-mob-main-cover-title-en">Main Cover / Property Insurance</h3>

          <p class="inner-mob-body-txt-en">Property Insurance provides a vast array of covers which includes :-</p>

          <div class="inner-mob-pins-list-en">
            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Fire: The policy offers coverage for any damage caused owing to the fire. However, damage caused by natural heating, own fermentation or spontaneous burning are excluded.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Lightning: Damages caused by lightning is covered. If lightning cause fire, or other damages like cracks in the building or roof area, it will be covered by fire insurance.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Explosion: Fire damage caused by an explosion is covered by this policy.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Aircraft Damage: Fire and any other damage caused by aircraft or other airborne devices, damage due to articles dropped by an aircraft is covered by fire insurance policy.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Riot Strike, Malicious Damages: If the insured property is damaged due to a strike, riot or any terrorist activity, the loss is covered by fire insurance.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Natural Disasters: Apart from the above, this policy also includes damages owing to natural disasters including storm, cyclone, typhoon, tornado, flood. The hazardous situation and consequences of a natural disaster are covered by fire insurance.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Landslide/Rockslide: Your insurer will offer protection under fire insurance for the destruction caused to your property to a notable or even a small part of the site where the property exists by rockslide/landslide.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Bursting of water/overflowing of Water Tank (Inundation): your property is damaged due to the bursting of water or overflowing of the water tank, the policy covers the damage.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Bush Fire: At the time you set fire to overgrown plants and bushes, the possibility is where you end up damaging the property. Fret not! The fire insurance policy that you own covers you for this as well. However, the policy wouldn’t cover the destruction due to the forest fire.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Terrorism : Optional cover</div>
          </div>
          <h3 class="inner-mob-main-cover-title-en">Why KR Insurance Services ?</h3>

          <div class="inner-mob-bdy-whykrlist-en">
            <div class="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div class="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div class="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div class="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>
          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec">
            <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
          </div>
          {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default PropertyInsurance;