import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './relocationinsurance.scss';

const RelocationInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }

  }, [])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div className="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div className="dsk-tab-btn-viewmode-en">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1>Personal Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-en">
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "motor-insurance"}>Motor Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "travel-insurance"}>Travel Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "personal-accident"}>Personal Accident Insurance</div>
                <div className="inner-banner-btn-item-active-en" onClick={() => window.location.href = "relocation-insurance"}>Relocation Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "health-insurance"}>Health Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "art-insurance"}>Art insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "golf-insurance"}>Golf Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "housemaid-insurance"}>Housemaid Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "yacht-insurance"}>Yacht Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "home-insurance"}>Home Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "life-insurance"}>Life Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="bdy-innercont-en">
            <div className="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-lft-en">
                <h2 className="pagetitle-en">Relocation Insurance</h2>
                <p className="pg-txt-con">Is it time to move on? Are you Relocating to a new place? Is it in a new city or are you planning to shift to another country or just moving into a new home that is down the street? You must be concerned about your precious possessions. How do you ensure that nothing untoward happens to them?</p>

                <p className="pg-txt-con">KR Insurance Services offers insurance plans which are designed specifically for this purpose i.e. “The Relocation Insurance”. The Relocation Insurance coverage plan safeguards your assets and protects them from the moment you start your packing till the time you have arrived in your new home and unpacked it all.</p>

                <h3 className="bdyinner-heading-en">Main Cover / Relocation Insurance</h3>

                <p className="pg-txt-con">The Relocation coverage plan is a unique plan which provides benefits unlike any other scheme in the market. There are different options to choose from depending upon the packaging desired.</p>

              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/relocation_insurance.png"} alt="relocation insurance" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

            {/* <p className="relocationinsu-txt-con">The Relocation coverage plan is a unique plan which provides benefits unlike any other scheme in the market. There are different options to choose from depending upon the packaging desired.</p> */}

            {/* QUESTION SECTION STARTS HERE */}
            <div className="innbdy-keyft-en">
              <p className="innbdy-keyft-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Full conditions - professionally packed effects.</p>

              <p className="innbdy-keyft-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Restricted conditions - owner packed effects.</p>

              <p className="innbdy-keyft-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Cover for motor vehicles.</p>

              <p className="innbdy-keyft-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Special extensions - for personal effects.</p>

              <p className="innbdy-keyft-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Storage extensions.</p>
            </div>
            {/* QUESTION SECTION ENDS HERE */}

            <h3 className="bdyinner-whykr-title-en">Why KR Insurance Services ?</h3>

            <div className="clearfix"></div>

            <div className="bdyinner-whykr-keypoints-en">
              <div className="bdyinner-whykr-kepoints-ftitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
              <div className="bdyinner-whykr-kepoints-secitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
              <div className="bdyinner-whykr-kepoints-ftitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
              <div className="bdyinner-whykr-kepoints-secitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
            </div>

            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec">
              <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
            </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1>Personal Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-en">
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "motor-insurance"}>Motor Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "travel-insurance"}>Travel Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "personal-accident"}>Personal Accident Insurance</div>
                <div className="inner-banner-btn-item-active-en" onClick={() => window.location.href = "relocation-insurance"}>Relocation Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "health-insurance"}>Health Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "art-insurance"}>Art insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "golf-insurance"}>Golf Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "housemaid-insurance"}>Housemaid Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "yacht-insurance"}>Yacht Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "home-insurance"}>Home Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "life-insurance"}>Life Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="tab-imgtxt-en">
            <h2>Relocation Insurance</h2>
            <div className="tabs-cont-en">
              <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/relocation_insurance.png"} alt="relocation insurance" />
              <p>Is it time to move on? Are you Relocating to a new place? Is it in a new city or are you planning to shift to another country or just moving into a new home that is down the street? You must be concerned about your precious possessions. How do you ensure that nothing untoward happens to them?</p>

              <p>KR Insurance Services offers insurance plans which are designed specifically for this purpose i.e. “The Relocation Insurance”. The Relocation Insurance coverage plan safeguards your assets and protects them from the moment you start your packing till the time you have arrived in your new home and unpacked it all.</p>

              <h3 className="motorinsu-page-sbu-title-en">Main Cover / Relocation Insurance</h3>

              <p>The Relocation coverage plan is a unique plan which provides benefits unlike any other scheme in the market. There are different options to choose from depending upon the packaging desired.</p>

              <div className="tabkyfet-en">
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Full conditions - professionally packed effects.</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Restricted conditions - owner packed effects.</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Cover for motor vehicles.</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Special extensions - for personal effects.</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Storage extensions.</div>
              </div>
              <h3 className="motorinsu-page-sbu-title-en">Why KR Insurance Services ?</h3>

              <div className="tab-whykrinsu-pins-list-en">
                <div className="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
                <div className="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
                <div className="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
                <div className="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
              </div>

              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec">
                <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
              </div>
              {/* ask for quotes button starts here */}

            </div>
          </div>

        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div className="inn-banner-bg-main-en hidden-desktop hidden-md-device hidden-lg">
        <div className="dropdown-sec">
          <select className="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"motor-insurance"}>Motor Insurance</option>
            <option value={"travel-insurance"}>Travel Insurance</option>
            <option value={"personal-accident"}>Personal Accident Insurance</option>
            <option value={"relocation-insurance"} selected className="active">Relocation Insurance</option>
            <option value={"health-insurance"}>Health Insurance</option>
            <option value={"art-insurance"}>Art insurance</option>
            <option value={"golf-insurance"}>Golf Insurance</option>
            <option value={"housemaid-insurance"}>Housemaid Insurance</option>
            <option value={"home-insurance"}>Home Insurance</option>
            <option value={"yacht-insurance"}>Yacht Insurance</option>
            <option value={"life-insurance"}>Life Insurance</option>
          </select>
        </div>
        <div className="innner-mob-body-main-en">
          <h1 className="inner-mob-heading-en">Relocation Insurance</h1>
          <div className="innner-mob-body-img-en">
            <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/relocation_insurance.png"} alt="relocation insurance" />
          </div>
          <p className="inner-mob-body-txt-en">Is it time to move on? Are you Relocating to a new place? Is it in a new city or are you planning to shift to another country or just moving into a new home that is down the street? You must be concerned about your precious possessions. How do you ensure that nothing untoward happens to them?</p>

          <p className="inner-mob-body-txt-en">KR Insurance Services offers insurance plans which are designed specifically for this purpose i.e. “The Relocation Insurance”. The Relocation Insurance coverage plan safeguards your assets and protects them from the moment you start your packing till the time you have arrived in your new home and unpacked it all.</p>

          <h3 className="inner-mob-main-cover-title-en">Main Cover / Relocation Insurance</h3>

          <p className="inner-mob-body-txt-en">The Relocation coverage plan is a unique plan which provides benefits unlike any other scheme in the market. There are different options to choose from depending upon the packaging desired.</p>

          {/* QUESTION SECTION STARTS HERE */}
          <div className="inner-mob-pins-list-en">
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Full conditions - professionally packed effects.</div>

            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Restricted conditions - owner packed effects.</div>

            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Cover for motor vehicles.</div>

            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Special extensions - for personal effects.</div>

            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Storage extensions.</div>
          </div>
          {/* QUESTION SECTION ENDS HERE */}

          <h3 className="inner-mob-main-cover-title-en">Why KR Insurance Services ?</h3>

          <div className="inner-mob-bdy-whykrlist-en">
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>
          
          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec">
            <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
          </div>
          {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default RelocationInsurance;