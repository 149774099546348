import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import { Helmet } from 'react-helmet-async';
import './healthinsurance.scss';

const HealthInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div className="main-fluid">
      <Helmet>
        <title>Best Health Insurance in Oman | KR Insurance</title>
        <meta name="keywords" content="oman health insurance, medical health insurance, family health insurance in oman, best health insurance in oman"/>
        <meta name="description" content="Get the best health insurance in Oman with comprehensive medical coverage for your family. Protect your loved ones with top-quality health insurance plans." />
      </Helmet>
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div className="dsk-tab-btn-viewmode-en">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1>Personal Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-en">
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "motor-insurance"}>Motor Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "travel-insurance"}>Travel Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "personal-accident"}>Personal Accident Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "relocation-insurance"}>Relocation Insurance</div>
                <div className="inner-banner-btn-item-active-en" onClick={() => window.location.href = "health-insurance"}>Health Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "art-insurance"}>Art insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "golf-insurance"}>Golf Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "housemaid-insurance"}>Housemaid Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "yacht-insurance"}>Yacht Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "home-insurance"}>Home Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "life-insurance"}>Life Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="bdy-innercont-en">
            <div className="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-lft-en">
                <h2 className="pagetitle-en">Health Insurance</h2>
                <p className="pg-txt-con">We all know that health is wealth and spending a nominal amount of money to ensure your health is the best investment you can ever make. You might be concerned about your well-being, or you would naturally want to secure the health of your near and dear ones. The Health Insurance solutions by KR Insurance Services are best suited to your requirements, which you can rely on.</p>
                <p className="pg-txt-con">We offer best health insurance coverage plans for you and your family.</p>

                <h3 className="bdyinner-heading-en">Main Cover / Health Insurance</h3>

                <p className="pg-txt-con">The Health Insurance services as offered by KR Insurance includes :-</p>

                <div className="innbdy-lft-keypoint-en">
                  <div className="innbdy-lft-keypoint-itemimg-en">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-lft-keypoint-item-en">Cashless treatment facility during hospitalization.</div>
                  <div className="innbdy-lft-keypoint-itemimg-en">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-lft-keypoint-item-en">In-patient treatment coverage in private hospitals.</div>
                  <div className="innbdy-lft-keypoint-itemimg-en">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-lft-keypoint-item-en">Consultation, diagnostics and treatment covering Out-patient facilities.</div>
                  <div className="innbdy-lft-keypoint-itemimg-en">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-lft-keypoint-item-en">Coverage for pre/post hospitalization.</div>
                  <div className="innbdy-lft-keypoint-itemimg-en">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-lft-keypoint-item-en">Coverage of any declared pre-existing conditions and also pre-existing policy from the first day of the policy.</div>
                  <div className="innbdy-lft-keypoint-itemimg-en">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-lft-keypoint-item-en">Coverage of Drugs costs depending on the condition; whether pre-existing or non-pre-existing.</div>
                  <div className="innbdy-lft-keypoint-itemimg-en">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-lft-keypoint-item-en">Ambulance charges.</div>
                  <div className="innbdy-lft-keypoint-itemimg-en">
                    <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-lft-keypoint-item-en">Worldwide emergency medical assistance.</div>
                </div>      
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/health_insurance.png"} alt="health insurance" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

            <h3 class="bdyinner-whykr-title-en">Why KR Insurance Services ?</h3>
            <div class="clearfix"></div>
            <div class="bdyinner-whykr-keypoints-en">
              <div class="bdyinner-whykr-kepoints-ftitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
              <div class="bdyinner-whykr-kepoints-secitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
              <div class="bdyinner-whykr-kepoints-ftitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
              <div class="bdyinner-whykr-kepoints-secitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
            </div>
            
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec">
                    <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
                  </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1>Personal Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-en">
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "motor-insurance"}>Motor Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "travel-insurance"}>Travel Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "personal-accident"}>Personal Accident Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "relocation-insurance"}>Relocation Insurance</div>
                <div className="inner-banner-btn-item-active-en" onClick={() => window.location.href = "health-insurance"}>Health Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "art-insurance"}>Art insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "golf-insurance"}>Golf Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "housemaid-insurance"}>Housemaid Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "yacht-insurance"}>Yacht Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "home-insurance"}>Home Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "life-insurance"}>Life Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="tab-imgtxt-en">
            <h2>Health Insurance</h2>
            <div className="tabs-cont-en">
              <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/health_insurance.png"} alt="health insurance" />

              <p>We all know that health is wealth and spending a nominal amount of money to ensure your health is the best investment you can ever make. You might be concerned about your well-being, or you would naturally want to secure the health of your near and dear ones. The Health Insurance solutions by KR Insurance Services are best suited to your requirements, which you can rely on.</p>
                <p>We offer best health insurance coverage plans for you and your family.</p>

                <h3 className="motorinsu-page-sbu-title-en">Main Cover / Health Insurance</h3>

                <p>The Health Insurance services as offered by KR Insurance includes :-</p>
                <div className="tabkyfet-en">
                  <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div className="tabkeyfet-txt-en">Cashless treatment facility during hospitalization.</div>
                  <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div className="tabkeyfet-txt-en">In-patient treatment coverage in private hospitals.</div>
                  <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div className="tabkeyfet-txt-en">Consultation, diagnostics and treatment covering Out-patient facilities.</div>
                  <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div className="tabkeyfet-txt-en">Coverage for pre/post hospitalization.</div>
                  <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div className="tabkeyfet-txt-en">Coverage of any declared pre-existing conditions and also pre-existing policy from the first day of the policy.</div>
                  <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div className="tabkeyfet-txt-en">Coverage of Drugs costs depending on the condition; whether pre-existing or non-pre-existing.</div>
                  <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div className="tabkeyfet-txt-en">Ambulance charges.</div>
                  <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div className="tabkeyfet-txt-en">Worldwide emergency medical assistance.</div>
                </div>
                <h3 className="tab-whykrinsu-title-en">Why KR Insurance Services ?</h3>

            <div className="tab-whykrinsu-pins-list-en">
              <div className="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
              <div className="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
              <div className="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
              <div className="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
            </div>

            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec">
              <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
            </div>
            {/* ask for quotes button starts here */}
            </div>
          </div>
          
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div className="inn-banner-bg-main-en hidden-desktop hidden-md-device hidden-lg">
        <div className="dropdown-sec">
          <select className="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"motor-insurance"}>Motor Insurance</option>
            <option value={"travel-insurance"}>Travel Insurance</option>
            <option value={"personal-accident"}>Personal Accident Insurance</option>
            <option value={"relocation-insurance"}>Relocation Insurance</option>
            <option value={"health-insurance"} selected className="active">Health Insurance</option>
            <option value={"art-insurance"}>Art insurance</option>
            <option value={"golf-insurance"}>Golf Insurance</option>
            <option value={"housemaid-insurance"}>Housemaid Insurance</option>
            <option value={"home-insurance"}>Home Insurance</option>
            <option value={"yacht-insurance"}>Yacht Insurance</option>
            <option value={"life-insurance"}>Life Insurance</option>
          </select>
        </div>
        <div className="mob-main-en">
          <h1 className="mob-page-heading-en">Health Insurance</h1>
          <div className="mob-banner-img-en">
            <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/health_insurance.png"} alt="health insurance" />
          </div>
          <p className="mob-txt-en">We all know that health is wealth and spending a nominal amount of money to ensure your health is the best investment you can ever make. You might be concerned about your well-being, or you would naturally want to secure the health of your near and dear ones. The Health Insurance solutions by KR Insurance Services are best suited to your requirements, which you can rely on.</p>

          <p className="mob-txt-en">We offer best health insurance coverage plans for you and your family.</p>

          <h3 className="mob-txt-en">Main Cover / Health Insurance</h3>

          <p className="mob-txt-en">The Health Insurance services as offered by KR Insurance includes :-</p>

          {/* QUESTION SECTION STARTS HERE */}
          <div className="mob-keyfeatures-en">
            <div className="mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="mob-keyfeatures-q-en">Cashless treatment facility during hospitalization.</div>

            <div className="mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="mob-keyfeatures-q-en">In-patient treatment coverage in private hospitals.</div>

            <div className="mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="mob-keyfeatures-q-en">Consultation, diagnostics and treatment covering Out-patient facilities.</div>

            <div className="mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="mob-keyfeatures-q-en">Coverage for pre/post hospitalization.</div>

            <div className="mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="mob-keyfeatures-q-en">Coverage of any declared pre-existing conditions and also pre-existing policy from the first day of the policy.</div>

            <div className="mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="mob-keyfeatures-q-en">Coverage of Drugs costs depending on the condition; whether pre-existing or non-pre-existing.</div>

            <div className="mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="mob-keyfeatures-q-en">Ambulance charges.</div>

            <div className="mob-keyfeatures-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="mob-keyfeatures-q-en">Worldwide emergency medical assistance.</div>
          </div>
          {/* QUESTION SECTION ENDS HERE */}

          <h3 className="mob-whykrinsu-title-en">Why KR Insurance Services ?</h3>

          <div className="mob-whykrlist-en">
            <div className="mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div className="mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div className="mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div className="mob-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>
          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec">
                    <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
                  </div>
            {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default HealthInsurance;