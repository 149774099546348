import { useEffect, useState, React } from 'react';
import { Link } from "react-router-dom";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import { Helmet } from 'react-helmet-async';
import './arenergyinsurance.scss';

const AREnergyInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }

  }, [])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div className="main-fluid">
      <Helmet>
        <title>‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬.</title>
        <meta name="keywords" content="Energy Insurance" />
        <meta name="description" content="KR ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫لوسطاء‬ ‫موثوق‬ ‫خيار‬ ‫هو‬ ‫للتأمين‬ ‫عمان‬. ‫عمان‬ ‫في‬ ‫التأمين‬ ‫شركات‬ ‫ألفضل‬ ‫التأمين‬ ‫حلول‬ ‫إلى‬ ‫للوصول‬ ‫معنا‬ ‫شارك‬." />
      </Helmet>
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div className="dsk-tab-btn-viewmode-ar">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1>تأمين الأعمال </h1>

              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-ar">
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div className="inner-banner-btn-item-active-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="bdy-innercont-en">
            <div className="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-lft-ar">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/energy_insurance_ar.png"} alt="التأمين على الطاقة" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-rgt-ar">
                <h2 className="pagetitle-ar">التأمين على الطاقة</h2>
                <p className="pg-txt-con-ar">تلتزم شركة كيمجي رامداس لخدمات التأمين بحماية المخاطر التي يواجهها قطاع الطاقة، سواء كنت تعمل في النفط، والغاز، أو الطاقة، والمرافق أو الطاقة البديلة. نحن نخدم كل من صناعات النفط والغاز البرية والبحرية، من خلال نهج مخصص للتحديات العالمية الأكثر تطلبًا في مجال تأمين الطاقة</p>

                <p className="pg-txt-con-ar">يمكننا تقديم قدرة رائدة في السوق لمجموعة واسعة من مخاطر الطاقة، مدعومة بضمانات مالية رائدة في السوق، والحاصلة على تصنيف «AA»(تصنيفات ممتازة) من قبل  ستاندرد آند بورزو، إضافة إلى تصنيفها A+  بواسطة</p>

                <p className="pg-txt-con-ar">تمتلك شركة كيمجي رامداس لخدمات التأمين المطالبات الأكثر خبرة وفرق استشارات المخاطر في خطوط تأمين الطاقة؛ لذا فإننا نفتخر بأنفسنا على اتخاذ قراراتنا السريعة ونهجنا المرن في مواجهة تحديات عملائنا</p>

                <p className="pg-txt-con-ar">التأمين على الطاقة البحرية والبرية</p>
                <h3 className="energyinsu-page-sbu-title-ar">التغطية الرئيسية/ التأمين على الطاقة</h3>

              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>


            <p className="pg-txt-con-ar">تُصمم خدماتنا لهؤلاء العملاء المتخصصين على نحو فردي دائماً، ووفقًا لاحتياجاتهم، وتشمل ما يلي</p>

            <div className="bdyinner-keypoint-ar">
              <div className="bdyinner-keypoint-item-ar">الممتلكات البرية، بما في ذلك تغطية المعدات</div>

              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">الممتلكات البحرية، بما في ذلك الأضرار المادية لأجهزة الحفر والبناء البحري</div>

              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">تغطية البناء لمشاريع الطاقة البرية من قبل فريقنا الهندسي المتخصص</div>

              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">انقطاع العمل/ فقدان إيرادات الإنتاج</div>

              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">مراقبة تكاليف النفقات الإضافية للبئر/المشغلين/إعادة الحفر/ التسرب/ التعرض للتلوث</div>

              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">التأمين الخاص في حالات الخطر/ المظلة</div>

              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">المسؤولية العامة</div>

              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">بدن السفينة والآلات/ إجمالي تغطية الخسارة /فائدة الشحن/ فقد الاستئجار</div>

              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">خيارات الحمولة، بما في ذلك</div>

              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">المغلاة، والآلات</div>

              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">الشحنة، والنقل</div>

              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">مشروع الشحن</div>

              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">معدات المقاول</div>

              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">نفقات إضافية</div>

              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">الالتزامات البحرية المتنوعة</div>

              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-keypoint-item-ar">المسؤولية تجاه الغير</div>
              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            </div>

            <h3 className="bdyinner-whykr-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>
            <div className="clearfix"></div>
            <div className="bdyinner-whykr-keypoints-ar">
              <div className="bdyinner-whykr-kepoints-secitem-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-whykr-kepoints-ftitem-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-whykr-kepoints-secitem-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div className="bdyinner-whykr-kepoints-ftitem-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>

            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}

          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1 className="energyinsu-banner-heading-ar">تأمين الأعمال </h1>

              {/* TAB BUTTON SECTION STARTS HERE */}

              <div className="inner-banner-btn-ar">
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div className="inner-banner-btn-item-active-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="tab-imgtxt-ar">
            <h2>التأمين على الطاقة</h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/energy_insurance_ar.png"} alt="التأمين على الطاقة" />

              <p>تلتزم شركة كيمجي رامداس لخدمات التأمين بحماية المخاطر التي يواجهها قطاع الطاقة، سواء كنت تعمل في النفط، والغاز، أو الطاقة، والمرافق أو الطاقة البديلة. نحن نخدم كل من صناعات النفط والغاز البرية والبحرية، من خلال نهج مخصص للتحديات العالمية الأكثر تطلبًا في مجال تأمين الطاقة</p>

              <p>يمكننا تقديم قدرة رائدة في السوق لمجموعة واسعة من مخاطر الطاقة، مدعومة بضمانات مالية رائدة في السوق، والحاصلة على تصنيف «AA»(تصنيفات ممتازة) من قبل  ستاندرد آند بورزو، إضافة إلى تصنيفها A+  بواسطة</p>

              <p>تمتلك شركة كيمجي رامداس لخدمات التأمين المطالبات الأكثر خبرة وفرق استشارات المخاطر في خطوط تأمين الطاقة؛ لذا فإننا نفتخر بأنفسنا على اتخاذ قراراتنا السريعة ونهجنا المرن في مواجهة تحديات عملائنا</p>

              <p>التأمين على الطاقة البحرية والبرية</p>

              <h3 className="tab-sub-title-ar">التغطية الرئيسية/ التأمين على الطاقة</h3>

              <p>تُصمم خدماتنا لهؤلاء العملاء المتخصصين على نحو فردي دائماً، ووفقًا لاحتياجاتهم، وتشمل ما يلي</p>

              <div className="tabkyfet-ar">
                <div className="tabkeyfet-txt-ar">الممتلكات البرية، بما في ذلك تغطية المعدات</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-ar">الممتلكات البحرية، بما في ذلك الأضرار المادية لأجهزة الحفر والبناء البحري</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-ar">تغطية البناء لمشاريع الطاقة البرية من قبل فريقنا الهندسي المتخصص</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-ar">انقطاع العمل/ فقدان إيرادات الإنتاج</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-ar">مراقبة تكاليف النفقات الإضافية للبئر/المشغلين/إعادة الحفر/ التسرب/ التعرض للتلوث</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-ar">التأمين الخاص في حالات الخطر/ المظلة</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-ar">المسؤولية العامة</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-ar">بدن السفينة والآلات/ إجمالي تغطية الخسارة /فائدة الشحن/ فقد الاستئجار</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-ar">خيارات الحمولة، بما في ذلك</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-ar">المغلاة، والآلات</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-ar">الشحنة، والنقل</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-ar">مشروع الشحن</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-ar">معدات المقاول</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-ar">نفقات إضافية</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-ar">الالتزامات البحرية المتنوعة</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div className="tabkeyfet-txt-ar">المسؤولية تجاه الغير</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              </div>

              <h3 className="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>

              <div className="tab-whykrinsu-pins-list-ar">
                <div className="tab-whykrinsu-pinlft-two-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> خدمات المطالبات الفورية</div>
                <div className="tab-whykrinsu-pinlft-one-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> أقل الأسعار</div>
                <div className="tab-whykrinsu-pinlft-two-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> أفضل ممارسات صناعة التأمين</div>
                <div className="tab-whykrinsu-pinlft-one-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> مقارنات عروض أسعار واسعة</div>
              </div>

              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec-ar">
                <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار</a>
              </div>
              {/* ask for quotes button starts here */}


            </div>
          </div>

        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div className="inn-banner-bg-main-en hidden-desktop hidden-md-device hidden-lg">
        <div className="dropdown-sec">
          <select className="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"project-insurance-ar"}>تأمين المشاريع </option>
            <option value={"property-insurance-ar"}>تأمين الممتلكات</option>
            <option value={"terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </option>
            <option value={"motor-fleet-insurance-ar"} >تأمين أسطول المركبات</option>
            <option value={"group-health-insurance-ar"}>التأمين الصحي الجماعي</option>
            <option value={"group-life-insurance-ar"}>التأمين الجماعي على الحياة</option>
            <option value={"fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</option>
            <option value={"burglary-insurance-ar"}>التأمين ضد السرقة</option>
            <option value={"workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</option>
            <option value={"group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</option>
            <option value={"cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</option>
            <option value={"jewellers-block-insurance-ar"}>التأمين على المجوهرات</option>
            <option value={"stock-throughput-insurance-ar"}>تأمين صافي المخزون</option>
            <option value={"hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</option>
            <option value={"speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</option>
            <option value={"liability-insurance-ar"}>التأمين عن المسؤولية</option>
            <option value={"marine-insurance-ar"}>التأمين البحري</option>
            <option value={"energy-insurance-ar"} selected className="active">التأمين على الطاقة</option>
          </select>
        </div>
        <div className="innner-mob-body-main-en">
          <h1 className="inner-mob-heading-en">التأمين على الطاقة</h1>
          <div className="innner-mob-body-img-en">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/energy_insurance_ar.png"} alt="التأمين على الطاقة" />
          </div>

          <p className="inner-mob-body-txt-ar">تلتزم شركة كيمجي رامداس لخدمات التأمين بحماية المخاطر التي يواجهها قطاع الطاقة، سواء كنت تعمل في النفط، والغاز، أو الطاقة، والمرافق أو الطاقة البديلة. نحن نخدم كل من صناعات النفط والغاز البرية والبحرية، من خلال نهج مخصص للتحديات العالمية الأكثر تطلبًا في مجال تأمين الطاقة</p>

          <p className="inner-mob-body-txt-ar">يمكننا تقديم قدرة رائدة في السوق لمجموعة واسعة من مخاطر الطاقة، مدعومة بضمانات مالية رائدة في السوق، والحاصلة على تصنيف «AA»(تصنيفات ممتازة) من قبل  ستاندرد آند بورزو، إضافة إلى تصنيفها A+  بواسطة</p>

          <p className="inner-mob-body-txt-ar">تمتلك شركة كيمجي رامداس لخدمات التأمين المطالبات الأكثر خبرة وفرق استشارات المخاطر في خطوط تأمين الطاقة؛ لذا فإننا نفتخر بأنفسنا على اتخاذ قراراتنا السريعة ونهجنا المرن في مواجهة تحديات عملائنا</p>

          <p className="inner-mob-body-txt-ar">التأمين على الطاقة البحرية والبرية</p>

          <h3 className="energyinsu-subtitle-ar">التغطية الرئيسية/ التأمين على الطاقة</h3>

          <p className="inner-mob-body-txt-ar">تُصمم خدماتنا لهؤلاء العملاء المتخصصين على نحو فردي دائماً، ووفقًا لاحتياجاتهم، وتشمل ما يلي</p>

          <div className="inner-mob-pins-list-ar">
            <div className="inner-mob-pins-list-item-ar">الممتلكات البرية، بما في ذلك تغطية المعدات</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">الممتلكات البحرية، بما في ذلك الأضرار المادية لأجهزة الحفر والبناء البحري</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">تغطية البناء لمشاريع الطاقة البرية من قبل فريقنا الهندسي المتخصص</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">انقطاع العمل/ فقدان إيرادات الإنتاج</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">مراقبة تكاليف النفقات الإضافية للبئر/المشغلين/إعادة الحفر/ التسرب/ التعرض للتلوث</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">التأمين الخاص في حالات الخطر/ المظلة</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">المسؤولية العامة</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">بدن السفينة والآلات/ إجمالي تغطية الخسارة /فائدة الشحن/ فقد الاستئجار</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">خيارات الحمولة، بما في ذلك</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">المغلاة، والآلات</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">الشحنة، والنقل</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">مشروع الشحن</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">معدات المقاول</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">نفقات إضافية</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">الالتزامات البحرية المتنوعة</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">المسؤولية تجاه الغير</div>
            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>

          <h3 className="inner-mob-main-cover-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>

          <div className="inner-mob-bdy-whykrlist-ar">
            <div className="inner-mob-bdy-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar"> أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>

          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec-ar">
            <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار</a>
          </div>
          {/* ask for quotes button starts here */}

        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default AREnergyInsurance;