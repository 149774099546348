import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import { Helmet } from 'react-helmet-async';
import './arvaluationservices.scss';

const ARValuationServices = () => {

  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }

  }, [])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div className="main-fluid">
      <Helmet>
        <title>‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬.</title>
        <meta name="keywords" content="Valuation Services" />
        <meta name="description" content="KR ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫لوسطاء‬ ‫موثوق‬ ‫خيار‬ ‫هو‬ ‫للتأمين‬ ‫عمان‬. ‫عمان‬ ‫في‬ ‫التأمين‬ ‫شركات‬ ‫ألفضل‬ ‫التأمين‬ ‫حلول‬ ‫إلى‬ ‫للوصول‬ ‫معنا‬ ‫شارك‬." />
      </Helmet>
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div className="dsk-tab-btn-viewmode-ar">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1>خدمات قيمة</h1>

              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-ar">
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "risk-inspection-services-ar"}>خدمات فحص المخاطر </div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "insurance-audits-ar"}>تدقيقات التأمين</div>
                <div className="inner-banner-btn-item-active-ar" onClick={() => window.location.href = "valuation-services-ar"}>خدمات التقييم</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "thermography-testing-insurance-ar"}>اختبار التصوير الحراري</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "employee-wellness-program-ar"}>برنامج صحة الموظفين</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="bdy-innercont-en">
            <div className="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-lft-ar">
                <img src={process.env.PUBLIC_URL + "/riskmanagementservices_images/tab_images/valuation_services_ar.png"} alt="خدمات التقييم" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-rgt-ar">
                <h2 className="pagetitle-ar">خدمات التقييم</h2>

                <p className="pg-txt-con-ar">نحن نقدم خدمات التقييم لدعم جهود مؤسستك لتوفير بيانات مالية دقيقة لأغراض التغطية التأمينية، بحيث تلبي أموال الاسترداد احتياجات الاسترداد بعد الحدث</p>

                <p className="pg-txt-con-ar">من خلال خبرتنا الواسعة في الصناعة ومع المهنيين الذين يفهمون أعمال عملائنا، نقدم خدمات تقييم فعالة من حيث التكلفة والوقت لعملائنا</p>

                <p className="pg-txt-con-ar">نحن في شركة كيمجي رامداس لخدمات التأمين، نتطلع إلى تزويدك بخدمات التقييم لدينا لتحسين تغطيتك التأمينية</p>

                <h3 className="bdyinner-whykr-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>
                <div className="clearfix"></div>
                <div className="bdyinner-whykr-keypoints-ar">
                  <div className="bdyinner-whykr-kepoints-secitem-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="bdyinner-whykr-kepoints-ftitem-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="bdyinner-whykr-kepoints-secitem-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div className="bdyinner-whykr-kepoints-ftitem-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                </div>

              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div className="insuraudits-tab-imagebannermain-ar">
          <div className="insuraudits-con-ar">
            <div className="insurauditstab-banne-sec-ar">
              <h1 className="insuraudits-banner-heading-ar">خدمات قيمة</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-ar">
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "risk-inspection-services-ar"}>خدمات فحص المخاطر </div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "insurance-audits-ar"}>تدقيقات التأمين</div>
                <div className="inner-banner-btn-item-active-ar" onClick={() => window.location.href = "valuation-services-ar"}>خدمات التقييم</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "thermography-testing-insurance-ar"}>اختبار التصوير الحراري</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "employee-wellness-program-ar"}>برنامج صحة الموظفين</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="insuraudits-banner-con-ar">
          <div className="tab-imgtxt-ar">
            <h2>خدمات التقييم</h2>
            <div className="tabs-cont-ar">
              <img src={process.env.PUBLIC_URL + "/riskmanagementservices_images/tab_images/valuation_services_ar.png"} alt="خدمات التقييم" />

              <p>نحن نقدم خدمات التقييم لدعم جهود مؤسستك لتوفير بيانات مالية دقيقة لأغراض التغطية التأمينية، بحيث تلبي أموال الاسترداد احتياجات الاسترداد بعد الحدث</p>

              <p>من خلال خبرتنا الواسعة في الصناعة ومع المهنيين الذين يفهمون أعمال عملائنا، نقدم خدمات تقييم فعالة من حيث التكلفة والوقت لعملائنا</p>

              <p>نحن في شركة كيمجي رامداس لخدمات التأمين، نتطلع إلى تزويدك بخدمات التقييم لدينا لتحسين تغطيتك التأمينية.</p>


              <h3 className="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>

              <div className="tab-whykrinsu-pins-list-ar">
                <div className="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div className="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div className="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div className="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
              </div>

            </div>
          </div>

        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div className="inn-banner-bg-main-en hidden-desktop hidden-md-device hidden-lg">
        <div className="dropdown-sec">
          <select className="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"risk-inspection-services-ar"}>خدمات فحص المخاطر </option>
            <option value={"insurance-audits-ar"}>تدقيقات التأمين</option>
            <option value={"valuation-services-ar"} selected className="active">خدمات التقييم</option>
            <option value={"thermography-testing-insurance-ar"}>اختبار التصوير الحراري</option>
            <option value={"employee-wellness-program-ar"}>برنامج صحة الموظفين</option>
          </select>
        </div>
        <div className="innner-mob-body-main-en">
          <h1 className="inner-mob-heading-en">Valuation Services</h1>
          <div className="innner-mob-body-img-en">
            <img src={process.env.PUBLIC_URL + "/riskmanagementservices_images/tab_images/valuation_services_ar.png"} alt="valuation services" />
          </div>

          <p className="inner-mob-body-txt-ar">نحن نقدم خدمات التقييم لدعم جهود مؤسستك لتوفير بيانات مالية دقيقة لأغراض التغطية التأمينية، بحيث تلبي أموال الاسترداد احتياجات الاسترداد بعد الحدث</p>

          <p className="inner-mob-body-txt-ar">من خلال خبرتنا الواسعة في الصناعة ومع المهنيين الذين يفهمون أعمال عملائنا، نقدم خدمات تقييم فعالة من حيث التكلفة والوقت لعملائنا</p>

          <p className="inner-mob-body-txt-ar">نحن في شركة كيمجي رامداس لخدمات التأمين، نتطلع إلى تزويدك بخدمات التقييم لدينا لتحسين تغطيتك التأمينية</p>

          <h3 className="inner-mob-main-cover-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>

          <div className="inner-mob-bdy-whykrlist-ar">
            <div className="inner-mob-bdy-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar"> أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARValuationServices;
