import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './homeinsurance.scss';

const HomeInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }

  }, [])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div className="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div className="dsk-tab-btn-viewmode-en">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1>Personal Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-en">
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "motor-insurance"}>Motor Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "travel-insurance"}>Travel Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "personal-accident"}>Personal Accident Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "relocation-insurance"}>Relocation Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "health-insurance"}>Health Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "art-insurance"}>Art insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "golf-insurance"}>Golf Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "housemaid-insurance"}>Housemaid Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "yacht-insurance"}>Yacht Insurance</div>
                <div className="inner-banner-btn-item-active-en" onClick={() => window.location.href = "home-insurance"}>Home Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "life-insurance"}>Life Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="bdy-innercont-en">
            <div className="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-lft-en">
                <h2 className="pagetitle-en">Home Insurance</h2>

                <p className="pg-txt-con">Ever heard of Home Insurance? Wondering what this is about</p>
                <p className="pg-txt-con">In the world of insurance, home insurance policies are often one of the most comprehensive ones. Typically a home insurance policy will provide all-round protection which covers you, your house, your family members and also any liability which may arise. Home insurance, provides cover which extends beyond just the structure of the house. The most important features of a home insurance policy may be categorized into two parts, based on the kind of cover they provide. These are - </p>

                             

                  <div className="innbdy-lft-keypoint-en">
                  <div className="innbdy-lft-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div className="innbdy-lft-keypoint-item-en">Cover For Damages</div>
                  <div className="innbdy-lft-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div className="innbdy-lft-keypoint-item-en">Cover for Liabilities.</div>
                </div>

                
                <h3 className="bdyinner-heading-en">Main Cover / Home Insurance</h3>

                <p className="pg-txt-con">The Home insurance policy covers a variety of factors and provides a more significant coverage to safeguard your home. The key features are</p>
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/home_insurance.png"} alt="home insurance" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

            <div className="innbdy-keypoint-en">
              <div className="innbdy-keypoint-itemimg-en">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="innbdy-keypoint-item-en">Coverage against Unexpected events and natural calamities.</div>
              <div className="innbdy-keypoint-itemimg-en">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="innbdy-keypoint-item-en">KR Insurance Services assures that your home is insured against calamities like fire, earthquake, lightning, storms and floods, strikes and riots, water leak, theft etc.</div>
              <div className="innbdy-keypoint-itemimg-en">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="innbdy-keypoint-item-en">New for old.</div>
              <div className="innbdy-keypoint-itemimg-en">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="innbdy-keypoint-item-en">Almost all of the house contents are covered under the home insurance plan. However, it does not include clothing and household linen.</div>
              <div className="innbdy-keypoint-itemimg-en">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="innbdy-keypoint-item-en">Alternative accommodation.</div>
              <div className="innbdy-keypoint-itemimg-en">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="innbdy-keypoint-item-en">Protects you from any legal liabilities which may arise in case of any damage or bodily harm caused to any if there occurs any bodily harm / injury or damage being caused to any third person or property, on your premises or by you.</div>
            </div>

            <h3 className="bdyinner-whykr-title-en">Why KR Insurance Services ?</h3>

            <div className="clearfix"></div>

            <div className="bdyinner-whykr-keypoints-en">
              <div className="bdyinner-whykr-kepoints-ftitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
              <div className="bdyinner-whykr-kepoints-secitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
              <div className="bdyinner-whykr-kepoints-ftitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
              <div className="bdyinner-whykr-kepoints-secitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
            </div>

            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec">
              <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
            </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1>Personal Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-en">
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "motor-insurance"}>Motor Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "travel-insurance"}>Travel Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "personal-accident"}>Personal Accident Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "relocation-insurance"}>Relocation Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "health-insurance"}>Health Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "art-insurance"}>Art insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "golf-insurance"}>Golf Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "housemaid-insurance"}>Housemaid Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "yacht-insurance"}>Yacht Insurance</div>
                <div className="inner-banner-btn-item-active-en" onClick={() => window.location.href = "home-insurance"}>Home Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "life-insurance"}>Life Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="tab-imgtxt-en">
            <h2>Home Insurance</h2>
            <div className="tabs-cont-en">
              <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/home_insurance.png"} alt="home insurance" />
              <p>Ever heard of Home Insurance? Wondering what this is about</p>

              <p>In the world of insurance, home insurance policies are often one of the most comprehensive ones. Typically a home insurance policy will provide all-round protection which covers you, your house, your family members and also any liability which may arise. Home insurance, provides cover which extends beyond just the structure of the house. The most important features of a home insurance policy may be categorized into two parts, based on the kind of cover they provide. These are - </p>

              <p><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Cover For Damages</p>

              <p><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Cover for Liabilities.</p>

              <h3 className="tab-sub-title-en">Main Cover / Home Insurance</h3>

              <p>The Home insurance policy covers a variety of factors and provides a more significant coverage to safeguard your home. The key features are</p>

              <div className="tabkyfet-en">
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Coverage against Unexpected events and natural calamities.</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">KR Insurance Services assures that your home is insured against calamities like fire, earthquake, lightning, storms and floods, strikes and riots, water leak, theft etc.</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">New for old.</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Almost all of the house contents are covered under the home insurance plan. However, it does not include clothing and household linen.</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Alternative accommodation.</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Protects you from any legal liabilities which may arise in case of any damage or bodily harm caused to any if there occurs any bodily harm / injury or damage being caused to any third person or property, on your premises or by you.</div>
              </div>

              <h3 className="tab-whykrinsu-title-en">Why KR Insurance Services ?</h3>

              <div className="tab-whykrinsu-pins-list-en">
                <div className="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
                <div className="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
                <div className="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
                <div className="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
              </div>

              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec">
                <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
              </div>
              {/* ask for quotes button starts here */}

            </div>
          </div>
        </div>

      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div className="inn-banner-bg-main-en hidden-desktop hidden-md-device hidden-lg">
        <div className="dropdown-sec">
          <select className="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"motor-insurance"}>Motor Insurance</option>
            <option value={"travel-insurance"}>Travel Insurance</option>
            <option value={"personal-accident"}>Personal Accident Insurance</option>
            <option value={"relocation-insurance"}>Relocation Insurance</option>
            <option value={"health-insurance"}>Health Insurance</option>
            <option value={"art-insurance"}>Art insurance</option>
            <option value={"golf-insurance"}>Golf Insurance</option>
            <option value={"housemaid-insurance"}>Housemaid Insurance</option>
            <option value={"home-insurance"} selected className="active">Home Insurance</option>
            <option value={"yacht-insurance"}>Yacht Insurance</option>
            <option value={"life-insurance"}>Life Insurance</option>
          </select>
        </div>
        <div className="innner-mob-body-main-en">
          <h1 className="inner-mob-heading-en">Home Insurance</h1>
          <div className="innner-mob-body-img-en">
            <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/home_insurance.png"} alt="home insurance" />
          </div>
          <p className="inner-mob-body-txt-en">Ever heard of Home Insurance? Wondering what this is about?</p>

          <p className="inner-mob-body-txt-en">In the world of insurance, home insurance policies are often one of the most comprehensive ones. Typically a home insurance policy will provide all-round protection which covers you, your house, your family members and also any liability which may arise. Home insurance, provides cover which extends beyond just the structure of the house. The most important features of a home insurance policy may be categorized into two parts, based on the kind of cover they provide. These are –</p>

          <div className="inner-mob-pins-list-en">
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div className="inner-mob-pins-list-item-en">Cover For Damages</div>
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div className="inner-mob-pins-list-item-en">Cover for Liabilities.</div>
          </div>

          <p className="inner-mob-body-txt-en">Home insurance is one of the fascinating services provided by KR Insurance Services. KR Insurance brings best Home Insurance plans to ensure the safety of your home. This policy covers your dream house; whether it is a posh villa or a beautiful apartment from catastrophic events like fire, burglary, theft, liabilities etc.</p>

          <h3 className="inner-mob-main-cover-title-en">Main Cover / Home Insurance</h3>

          <p className="inner-mob-body-txt-en">The Home insurance policy covers a variety of factors and provides a more significant coverage to safeguard your home.  The key features are -</p>

          {/* QUESTION SECTION STARTS HERE */}
          <div className="inner-mob-pins-list-en">
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Coverage against Unexpected events and natural calamities.</div>

            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">KR Insurance Services assures that your home is insured against calamities like fire, earthquake, lightning, storms and floods, strikes and riots, water leak, theft etc.</div>

            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">New for old.</div>

            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Almost all of the house contents are covered under the home insurance plan. However, it does not include clothing and household linen.</div>

            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Alternative accommodation.</div>

            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Protects you from any legal liabilities which may arise in case of any damage or bodily harm caused to any if there occurs any bodily harm / injury or damage being caused to any third person or property, on your premises or by you.</div>

          </div>
          {/* QUESTION SECTION ENDS HERE */}

          <h3 className="inner-mob-main-cover-title-en">Why KR Insurance Services ?</h3>

          <div className="inner-mob-bdy-whykrlist-en">
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>
          
          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec">
            <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
          </div>
          {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default HomeInsurance;