import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import { Helmet } from 'react-helmet-async';
import './armotorfleetinsurance.scss';

const ARMotorFleetInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }

  }, [])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div className="main-fluid">
      <Helmet>
        <title>‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬.</title>
        <meta name="keywords" content="Project insurance, Property Project Insurance" />
        <meta name="description" content="KR ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫لوسطاء‬ ‫موثوق‬ ‫خيار‬ ‫هو‬ ‫للتأمين‬ ‫عمان‬. ‫عمان‬ ‫في‬ ‫التأمين‬ ‫شركات‬ ‫ألفضل‬ ‫التأمين‬ ‫حلول‬ ‫إلى‬ ‫للوصول‬ ‫معنا‬ ‫شارك‬." />
      </Helmet>
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div className="dsk-tab-btn-viewmode-ar">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1>تأمين الأعمال </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-ar">
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div className="inner-banner-btn-item-active-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="bdy-innercont-en">
            <div className="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-lft-ar">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/motor_fleet_insurance_ar.png"} alt="تأمين أسطول المركبات" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-rgt-ar">
                <h2 className="pagetitle-ar">تأمين أسطول المركبات</h2>

                <p className="pg-txt-con-ar">تساعدك شركة كيمجي رامداس لخدمات التأمين في تأمين المركبات بموجب بوليصة واحدة (تأمين أسطول المركبات) لتخفيف عبء التأمين عليها بشكل فردي. علاوة على ذلك، لا يوجد سوى تاريخ تجديد واحد وخطة دفع واحدة ونقطة اتصال واحدة وهي ميزة كبيرة وتوفر الوقت عندما يتعين عليكم التعامل مع المطالبات أو عند إضافة أو إزالة المركبات من بوليصتك الحالية</p>

                <p className="pg-txt-con-ar">إذا كان لديكم العديد من المركبات التي تحتاج إلى التأمين، فقم بالبحث عن شركة كيمجي رامداس لتأمين أسطول المركبات. سواء كنت تمتلك العديد من المركبات عالية الجودة أو أسطولًا من السيارات الخاصة، يمكن تصميم بوليصة التأمين الخاصة بنا وفقًا لاحتياجاتك لضمان سير أعمالك باستمرار وتقديم نتائج حقيقية، مهما حدث! توفر شركة كيمجي رامداس لخدمات التأمين تغطية مصممة خصيصًا لتلائم احتياجاتك وستزودك بالتأمين الصحيح على سيارتك بالقيمة الصحيحة</p>

                <p className="pg-txt-con-ar"><strong>خدمات القيمة المضافة التي تقدمها شركة كيمجي رامداس لخدمات التأمين هي كما يلي</strong></p>

                <div className="innbdy-rgt-keypoint-ar">
                  <div className="innbdy-rgt-keypoint-item-ar">مدير خدمة مخصص للمساعدة في توثيق المطالبات وتسجيل المطالبات وحتى تسوية المطالبات</div>
                  <div className="innbdy-rgt-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-rgt-keypoint-item-ar">أسعار مخفضة شاملة لأساطيل السيارات الكبيرة</div>
                  <div className="innbdy-rgt-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-rgt-keypoint-item-ar">سهولة في تحميل البوليصة في موقع شرطة عمان السلطانية وتحديث الحالة</div>
                  <div className="innbdy-rgt-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-rgt-keypoint-item-ar">خدمات السجادة الحمراء في مرائب الإصلاح المفضلة لدينا</div>
                  <div className="innbdy-rgt-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                </div>
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

            <div className="bdyinner-keypoint-ar">
              <div className="bdyinner-keypoint-item-ar">كوبونات خصم لغسيل وصيانة المركبات من خلال شبكتنا (اختياري)</div>
              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

              <div className="bdyinner-keypoint-item-ar">توافر مركبات بديلة للأغراض العاجلة على أساس الدفع</div>
              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

              <div className="bdyinner-keypoint-item-ar">نحن وسيط التأمين الوحيد الذي حصل على مرائب سيارات داخلية مرخصة من قبل العديد من شركات التأمين المرموقة في سلطنة عمان. يمكننا تقديم خدمات إصلاح عالية الجودة وسريعة لسيارتك. (مرآب مسعر، معتمد من شرطة عمان السلطانية، معتمدة من قبل POD؛ مركبات MOD)</div>
              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            </div>

            <p className="pg-txt-con-ar">نحن متخصصون أيضًا في تصميم المنتجات المصممة خصيصًا للمركبات عالية القيمة، على سبيل المثال فيراري، أستون مارتن، بنتلي ورويس، إلخ. شاحنات، صهاريج، حافلات، سيارات الدفع الرباعي وأنواع خاصة أخرى من المركبات</p>

            <h3 className="bdyinner-heading-ar">التغطية الرئيسية / تأمين أسطول المركبات</h3>

            <p className="pg-txt-con-ar">تتضمن الخيارات النموذجية، من بين أمور أخرى، التعطل والمساعدة في الإصلاح ومزايا التأمين ضد الحوادث الخاصة</p>

            <div className="bdyinner-keypoint-ar">
              <div className="bdyinner-keypoint-item-ar">يوفر منتج أسطول السيارات الخاص بنا تغطية تأمينية شاملة أو تأمينية لطرف ثالث</div>
              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

              <div className="bdyinner-keypoint-item-ar">تغطية لأضرار الطرف الثالث - أضرار الممتلكات والإصابات الجسدية</div>
              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

              <div className="bdyinner-keypoint-item-ar">تأمينالأضرار التي تلحق بالمركبة المؤمن عليها بسبب مخاطر محددة مثل حوادث الطرق، الاصطدام، الانقلاب، السرقة، الأذى المتعمد، الموقد والانفجار، والكوارث الطبيعية</div>
              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

              <div className="bdyinner-keypoint-item-ar">تغطية ضد الحوادث الشخصية</div>
              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

              <div className="bdyinner-keypoint-item-ar">المساعدة على الطريق</div>
              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

              <div className="bdyinner-keypoint-item-ar">خيارات الإصلاح في الوكالة</div>
              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

              <div className="bdyinner-keypoint-item-ar">تغطية ضد تلف قطع الغيار</div>
              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

              <div className="bdyinner-keypoint-item-ar">تغطية ضد حوادث الطرق الوعرة</div>
              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

              <div className="bdyinner-keypoint-item-ar">تغطية التكاليف الطبية</div>
              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            </div>

            <h3 className="bdyinner-whykr-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>
            <div className="clearfix"></div>
            <div className="bdyinner-whykr-keypoints-ar">
              <div className="bdyinner-whykr-kepoints-secitem-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-whykr-kepoints-ftitem-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-whykr-kepoints-secitem-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div className="bdyinner-whykr-kepoints-ftitem-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>
            
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="contactus-ar" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}


          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1 className="motorfleetinsu-banner-heading-ar">تأمين الأعمال </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-ar">
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div className="inner-banner-btn-item-active-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="tab-imgtxt-ar">
            <h2>تأمين أسطول المركبات</h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/motor_fleet_insurance_ar.png"} alt="تأمين أسطول المركبات" />

              <p>تساعدك شركة كيمجي رامداس لخدمات التأمين في تأمين المركبات بموجب بوليصة واحدة (تأمين أسطول المركبات) لتخفيف عبء التأمين عليها بشكل فردي. علاوة على ذلك، لا يوجد سوى تاريخ تجديد واحد وخطة دفع واحدة ونقطة اتصال واحدة وهي ميزة كبيرة وتوفر الوقت عندما يتعين عليكم التعامل مع المطالبات أو عند إضافة أو إزالة المركبات من بوليصتك الحالية</p>

              <p>إذا كان لديكم العديد من المركبات التي تحتاج إلى التأمين، فقم بالبحث عن شركة كيمجي رامداس لتأمين أسطول المركبات. سواء كنت تمتلك العديد من المركبات عالية الجودة أو أسطولًا من السيارات الخاصة، يمكن تصميم بوليصة التأمين الخاصة بنا وفقًا لاحتياجاتك لضمان سير أعمالك باستمرار وتقديم نتائج حقيقية، مهما حدث! توفر شركة كيمجي رامداس لخدمات التأمين تغطية مصممة خصيصًا لتلائم احتياجاتك وستزودك بالتأمين الصحيح على سيارتك بالقيمة الصحيحة</p>

              <p><strong>خدمات القيمة المضافة التي تقدمها شركة كيمجي رامداس لخدمات التأمين هي كما يلي </strong></p>

              <div className="tabkyfet-ar">
                <div className="tabkeyfet-txt-ar">مدير خدمة مخصص للمساعدة في توثيق المطالبات وتسجيل المطالبات وحتى تسوية المطالبات</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

                <div className="tabkeyfet-txt-ar">أسعار مخفضة شاملة لأساطيل السيارات الكبيرة</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

                <div className="tabkeyfet-txt-ar">سهولة في تحميل البوليصة في موقع شرطة عمان السلطانية وتحديث الحالة</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

                <div className="tabkeyfet-txt-ar">خدمات السجادة الحمراء في مرائب الإصلاح المفضلة لدينا</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

                <div className="tabkeyfet-txt-ar">كوبونات خصم لغسيل وصيانة المركبات من خلال شبكتنا (اختياري)</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

                <div className="tabkeyfet-txt-ar">توافر مركبات بديلة للأغراض العاجلة على أساس الدفع</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

                <div className="tabkeyfet-txt-ar">نحن وسيط التأمين الوحيد الذي حصل على مرائب سيارات داخلية مرخصة من قبل العديد من شركات التأمين المرموقة في سلطنة عمان. يمكننا تقديم خدمات إصلاح عالية الجودة وسريعة لسيارتك. (مرآب مسعر، معتمد من شرطة عمان السلطانية، معتمدة من قبل POD؛ مركبات MOD)</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              </div>

              <p>نحن متخصصون أيضًا في تصميم المنتجات المصممة خصيصًا للمركبات عالية القيمة، على سبيل المثال فيراري، أستون مارتن، بنتلي ورويس، إلخ. شاحنات، صهاريج، حافلات، سيارات الدفع الرباعي وأنواع خاصة أخرى من المركبات</p>

              <h3 className="tab-sub-title-ar">التغطية الرئيسية / تأمين أسطول المركبات</h3>

              <p>تتضمن الخيارات النموذجية، من بين أمور أخرى، التعطل والمساعدة في الإصلاح ومزايا التأمين ضد الحوادث الخاصة</p>

              <div className="tabkyfet-ar">
                <div className="tabkeyfet-txt-ar">يوفر منتج أسطول السيارات الخاص بنا تغطية تأمينية شاملة أو تأمينية لطرف ثالث.</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">تغطية لأضرار الطرف الثالث - أضرار الممتلكات والإصاباتinner-con-en"ى الطريق</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">خيارات الإصلاح في الوكالة.</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">تغطية ضد تلف قطع الغيار.</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">تغطية ضد حوادث الطرق الوعرة.</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">تغطية التكاليف الطبية</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>

              <h3 className="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>

              <div className="tab-whykrinsu-pins-list-ar">
                <div className="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>

              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec-ar">
                <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار</a>
              </div>
              {/* ask for quotes button starts here */}

            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div className="inn-banner-bg-main-en hidden-desktop hidden-md-device hidden-lg">
        <div className="dropdown-sec">
          <select className="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"project-insurance-ar"}>تأمين المشاريع </option>
            <option value={"property-insurance-ar"}>تأمين الممتلكات</option>
            <option value={"terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </option>
            <option value={"motor-fleet-insurance-ar"} selected className="active">تأمين أسطول المركبات</option>
            <option value={"group-health-insurance-ar"}>التأمين الصحي الجماعي</option>
            <option value={"group-life-insurance-ar"}>التأمين الجماعي على الحياة</option>
            <option value={"fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</option>
            <option value={"burglary-insurance-ar"}>التأمين ضد السرقة</option>
            <option value={"workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</option>
            <option value={"group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</option>
            <option value={"cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</option>
            <option value={"jewellers-block-insurance-ar"}>التأمين على المجوهرات</option>
            <option value={"stock-throughput-insurance-ar"}>تأمين صافي المخزون</option>
            <option value={"hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</option>
            <option value={"speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</option>
            <option value={"liability-insurance-ar"}>التأمين عن المسؤولية</option>
            <option value={"marine-insurance-ar"}>التأمين البحري</option>
            <option value={"energy-insurance-ar"}>التأمين على الطاقة</option>
          </select>
        </div>
        <div className="innner-mob-body-main-en">
          <h1 className="inner-mob-heading-en">تأمين أسطول المركبات</h1>
          <div className="innner-mob-body-img-en">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/motor_fleet_insurance_ar.png"} alt="تأمين أسطول المركبات" />
          </div>

          <p className="inner-mob-body-txt-ar">تساعدك شركة كيمجي رامداس لخدمات التأمين في تأمين المركبات بموجب بوليصة واحدة (تأمين أسطول المركبات) لتخفيف عبء التأمين عليها بشكل فردي. علاوة على ذلك، لا يوجد سوى تاريخ تجديد واحد وخطة دفع واحدة ونقطة اتصال واحدة وهي ميزة كبيرة وتوفر الوقت عندما يتعين عليكم التعامل مع المطالبات أو عند إضافة أو إزالة المركبات من بوليصتك الحالية</p>

          <p className="inner-mob-body-txt-ar">إذا كان لديكم العديد من المركبات التي تحتاج إلى التأمين، فقم بالبحث عن شركة كيمجي رامداس لتأمين أسطول المركبات. سواء كنت تمتلك العديد من المركبات عالية الجودة أو أسطولًا من السيارات الخاصة، يمكن تصميم بوليصة التأمين الخاصة بنا وفقًا لاحتياجاتك لضمان سير أعمالك باستمرار وتقديم نتائج حقيقية، مهما حدث! توفر شركة كيمجي رامداس لخدمات التأمين تغطية مصممة خصيصًا لتلائم احتياجاتك وستزودك بالتأمين الصحيح على سيارتك بالقيمة الصحيحة</p>

          <p className="inner-mob-body-txt-ar"><strong>خدمات القيمة المضافة التي تقدمها شركة كيمجي رامداس لخدمات التأمين هي كما يلي</strong></p>

          <div className="inner-mob-pins-list-ar">
            <div className="inner-mob-pins-list-item-ar">مدير خدمة مخصص للمساعدة في توثيق المطالبات وتسجيل المطالبات وحتى تسوية المطالبات</div>
            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div className="inner-mob-pins-list-item-ar">أسعار مخفضة شاملة لأساطيل السيارات الكبيرة</div>
            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div className="inner-mob-pins-list-item-ar">سهولة في تحميل البوليصة في موقع شرطة عمان السلطانية وتحديث الحالة</div>
            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div className="inner-mob-pins-list-item-ar">خدمات السجادة الحمراء في مرائب الإصلاح المفضلة لدينا</div>
            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div className="inner-mob-pins-list-item-ar">كوبونات خصم لغسيل وصيانة المركبات من خلال شبكتنا (اختياري)</div>
            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div className="inner-mob-pins-list-item-ar">توافر مركبات بديلة للأغراض العاجلة على أساس الدفع</div>
            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div className="inner-mob-pins-list-item-ar">نحن وسيط التأمين الوحيد الذي حصل على مرائب سيارات داخلية مرخصة من قبل العديد من شركات التأمين المرموقة في سلطنة عمان. يمكننا تقديم خدمات إصلاح عالية الجودة وسريعة لسيارتك (مرآب مسعر، معتمد من شرطة عمان السلطانية، معتمدة من قبل POD؛ مركبات MOD)</div>
            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>

          <p className="inner-mob-body-txt-ar">نحن متخصصون أيضًا في تصميم المنتجات المصممة خصيصًا للمركبات عالية القيمة، على سبيل المثال فيراري، أستون مارتن، بنتلي ورويس، إلخ. شاحنات، صهاريج، حافلات، سيارات الدفع الرباعي وأنواع خاصة أخرى من المركبات </p>

          <h3 className="inner-mob-main-cover-title-ar">التغطية الرئيسية / تأمين أسطول المركبات</h3>

          <p className="inner-mob-body-txt-ar">تتضمن الخيارات النموذجية، من بين أمور أخرى، التعطل والمساعدة في الإصلاح ومزايا التأمين ضد الحوادث الخاصة</p>

          <div className="inner-mob-pins-list-ar">
            <div className="inner-mob-pins-list-item-ar">يوفر منتج أسطول السيارات الخاص بنا تغطية تأمينية شاملة أو تأمينية لطرف ثالث.</div>
            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div className="inner-mob-pins-list-item-ar">تغطية لأضرار الطرف الثالث - أضرار الممتلكات والإصابات الجسدية</div>
            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div className="inner-mob-pins-list-item-ar">تأمينالأضرار التي تلحق بالمركبة المؤمن عليها بسبب مخاطر محددة مثل حوادث الطرق، الاصطدام، الانقلاب، السرقة، الأذى المتعمد، الموقد والانفجار، والكوارث الطبيعية</div>
            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div className="inner-mob-pins-list-item-ar">تغطية ضد الحوادث الشخصية</div>
            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div className="inner-mob-pins-list-item-ar">المساعدة على الطريق</div>
            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div className="inner-mob-pins-list-item-ar">خيارات الإصلاح في الوكالة</div>
            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div className="inner-mob-pins-list-item-ar">تغطية ضد تلف قطع الغيار</div>
            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div className="inner-mob-pins-list-item-ar">تغطية ضد حوادث الطرق الوعرة</div>
            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div className="inner-mob-pins-list-item-ar">تغطية التكاليف الطبية</div>
            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

          </div>

          <h3 className="inner-mob-main-cover-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>

          <div className="inner-mob-bdy-whykrlist-ar">
            <div className="inner-mob-bdy-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar"> أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>

          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec-ar">
            <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار</a>
          </div>
          {/* ask for quotes button starts here */}

        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARMotorFleetInsurance;