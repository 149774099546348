import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import { Helmet } from 'react-helmet-async';
import './aryachtinsurance.scss';

const ARYachtInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }

  }, [])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div className="main-fluid">
      <Helmet>
        <title>‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬.</title>
        <meta name="keywords" content="Yacht Insurance" />
        <meta name="description" content="KR ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫لوسطاء‬ ‫موثوق‬ ‫خيار‬ ‫هو‬ ‫للتأمين‬ ‫عمان‬. ‫عمان‬ ‫في‬ ‫التأمين‬ ‫شركات‬ ‫ألفضل‬ ‫التأمين‬ ‫حلول‬ ‫إلى‬ ‫للوصول‬ ‫معنا‬ ‫شارك‬." />
      </Helmet>
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div className="dsk-tab-btn-viewmode-ar">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1>التأمين الشخصي </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-ar">
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "motor-insurance-ar"}>التأمين على السيارات</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "travel-insurance-ar"}>التأمين أثناء السفر</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "personal-accident-ar"}>تأمين الحوادث الشخصية</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "relocation-insurance-ar"}>التأمين علي الانتقال</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "health-insurance-ar"}>التأمين الصحي</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "art-insurance-ar"}>التأمين الفني</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "golf-insurance-ar"}>التأمين على الجولف</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "housemaid-insurance-ar"}>تأمين العمالة المنزلية</div>
                <div className="inner-banner-btn-item-active-ar" onClick={() => window.location.href = "yacht-insurance-ar"}>التأمين على اليخوت</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "home-insurance-ar"}>التأمين على المنازل</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "life-insurance-ar"}>التأمين على الحياة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="bdy-innercont-en">
            <div className="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-lft-ar">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/yatch_insurance_ar.png"} alt="التأمين على اليخوت" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-rgt-ar">
                <h2 className="pagetitle-ar">التأمين على اليخوت</h2>

                <div className="innbdy-rgt-keypoint-ar">
                  <div className="innbdy-rgt-keypoint-item-ar"> قائمة شاملة بالمنتجات</div>
                  <div className="innbdy-rgt-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-rgt-keypoint-item-ar">إجراء اكتتاب سلمي مع تغطية خاصة</div>
                  <div className="innbdy-rgt-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-rgt-keypoint-item-ar">حدود التجارة المحلية والإقليمية</div>
                  <div className="innbdy-rgt-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-rgt-keypoint-item-ar">فرق متخصصة لمساعدتك</div>
                  <div className="innbdy-rgt-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                </div>

                <p className="pg-txt-con-ar">مع حل التأمين الخاص بنا، ستبحر وأنت مرتاح البال</p>

                <h3 className="bdyinner-heading-ar">التغطية الرئيسية/التأمين على اليخت</h3>


                <p className="pg-txt-con-ar">تقدم كيمجي رامداس لخدمات التأمين خطة تأمينة لليخوت، وهي تغطية مرنة للغاية لتلبية متطلبات مالكي القوارب؛ في حالة الخسائر الناجمة عن الحوادث أو أي تلف في القارب بما في ذلك أي التزامات تجاه أطراف ثالثة قاربك مغطىً أثناء استخدام أو رسيانه على سطح الماء، قد يكون على الشاطئ كذلك أو حال عبوره</p>

                <p className="pg-txt-con-ar">بعض الحوافز الإضافية وهي تغطية القوارب غير المؤمن عليها والبحث والإنقاذ والمكافآت مثل خصم عدم وجود مطالبات</p>

                <h3 className="bdyinner-whykr-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>
                <div className="clearfix"></div>
                <div className="bdyinner-whykr-keypoints-ar">
                  <div className="bdyinner-whykr-kepoints-secitem-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="bdyinner-whykr-kepoints-ftitem-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="bdyinner-whykr-kepoints-secitem-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div className="bdyinner-whykr-kepoints-ftitem-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                </div>

                {/* ask for quotes button starts here */}
                <div className="askquto-btn-sec-ar">
                  <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار</a>
                </div>
                {/* ask for quotes button starts here */}
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1 className="yachtinsu-banner-heading-ar">التأمين الشخصي </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-ar">
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "motor-insurance-ar"}>التأمين على السيارات</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "travel-insurance-ar"}>التأمين أثناء السفر</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "personal-accident-ar"}>تأمين الحوادث الشخصية</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "relocation-insurance-ar"}>التأمين علي الانتقال</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "health-insurance-ar"}>التأمين الصحي</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "art-insurance-ar"}>التأمين الفني</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "golf-insurance-ar"}>التأمين على الجولف</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "housemaid-insurance-ar"}>تأمين العمالة المنزلية</div>
                <div className="inner-banner-btn-item-active-ar" onClick={() => window.location.href = "yacht-insurance-ar"}>التأمين على اليخوت</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "home-insurance-ar"}>التأمين على المنازل</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "life-insurance-ar"}>التأمين على الحياة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="tab-imgtxt-ar">
            <h2>التأمين على اليخوت</h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/yatch_insurance_ar.png"} alt="التأمين على اليخوت" />

              <p><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> قائمة شاملة بالمنتجات</p>
              <p><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />إجراء اكتتاب سلمي مع تغطية خاصة</p>
              <p><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />حدود التجارة المحلية والإقليمية</p>
              <p><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />فرق متخصصة لمساعدتك.</p>

              <p>مع حل التأمين الخاص بنا، ستبحر وأنت مرتاح البال</p>

              <p className="tab-subhead-title-ar">التغطية الرئيسية/التأمين على اليخت</p>

              <p>تقدم كيمجي رامداس لخدمات التأمين خطة تأمينة لليخوت، وهي تغطية مرنة للغاية لتلبية متطلبات مالكي القوارب؛ في حالة الخسائر الناجمة عن الحوادث أو أي تلف في القارب بما في ذلك أي التزامات تجاه أطراف ثالثة.قاربك مغطىً أثناء استخدام أو رسيانه على سطح الماء، قد يكون على الشاطئ كذلك أو حال عبوره</p>

              <p>بعض الحوافز الإضافية وهي تغطية القوارب غير المؤمن عليها والبحث والإنقاذ والمكافآت مثل خصم عدم وجود مطالبات </p>

              <h3 className="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>

              <div className="tab-whykrinsu-pins-list-ar">
                <div className="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div className="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div className="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div className="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              </div>

              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec-ar">
                <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار</a>
              </div>
              {/* ask for quotes button starts here */}

            </div>
          </div>

        </div>

      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div className="inn-banner-bg-main-en hidden-desktop hidden-md-device hidden-lg">
        <div className="dropdown-sec">
          <select className="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"motor-insurance"}>Motor Insurance</option>
            <option value={"travel-insurance"}>Travel Insurance</option>
            <option value={"personal-accident"}>Personal Accident Insurance</option>
            <option value={"relocation-insurance"}>Relocation Insurance</option>
            <option value={"health-insurance"}>Health Insurance</option>
            <option value={"art-insurance"}>Art insurance</option>
            <option value={"golf-insurance"}>Golf Insurance</option>
            <option value={"housemaid-insurance"}>Housemaid Insurance</option>
            <option value={"home-insurance"}>Home Insurance</option>
            <option value={"yacht-insurance"} selected className="active">Yacht Insurance</option>
            <option value={"life-insurance"}>Life Insurance</option>
          </select>
        </div>
        <div className="innner-mob-body-main-en">
          <h1 className="inner-mob-heading-en">التأمين على اليخوت</h1>
          <div className="innner-mob-body-img-en">
            <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/yatch_insurance_ar.png"} alt="التأمين على اليخوت" />
          </div>

          <div className="inner-mob-pins-list-ar">
            <div className="inner-mob-pins-list-item-ar"> قائمة شاملة بالمنتجات</div>
            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div className="inner-mob-pins-list-item-ar">إجراء اكتتاب سلمي مع تغطية خاصة</div>
            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div className="inner-mob-pins-list-item-ar">حدود التجارة المحلية والإقليمية</div>
            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div className="inner-mob-pins-list-item-ar">فرق متخصصة لمساعدتك</div>
            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>


          <p className="inner-mob-body-txt-ar">مع حل التأمين الخاص بنا، ستبحر وأنت مرتاح البا </p>

          <h3 className="yachtinsu-subtitle-ar">التغطية الرئيسية/التأمين على اليخت</h3>

          <p className="inner-mob-body-txt-ar">تقدم كيمجي رامداس لخدمات التأمين خطة تأمينة لليخوت، وهي تغطية مرنة للغاية لتلبية متطلبات مالكي القوارب؛ في حالة الخسائر الناجمة عن الحوادث أو أي تلف في القارب بما في ذلك أي التزامات تجاه أطراف ثالثة.قاربك مغطىً أثناء استخدام أو رسيانه على سطح الماء، قد يكون على الشاطئ كذلك أو حال عبوره</p>

          <p className="inner-mob-body-txt-ar">بعض الحوافز الإضافية وهي تغطية القوارب غير المؤمن عليها والبحث والإنقاذ والمكافآت مثل خصم عدم وجود مطالبات</p>

          <h3 className="inner-mob-main-cover-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>

          <div className="inner-mob-bdy-whykrlist-ar">
            <div className="inner-mob-bdy-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar"> أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>

          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec-ar">
            <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار</a>
          </div>
          {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARYachtInsurance;