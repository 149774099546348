import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import { Helmet } from 'react-helmet-async';
import './arliabilityinsurance.scss';

const ARLiabilityInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
    <Helmet>
      <title>‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬.</title>
      <meta name="keywords" content="Liability Insurance"/>
      <meta name="description" content="KR ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫لوسطاء‬ ‫موثوق‬ ‫خيار‬ ‫هو‬ ‫للتأمين‬ ‫عمان‬. ‫عمان‬ ‫في‬ ‫التأمين‬ ‫شركات‬ ‫ألفضل‬ ‫التأمين‬ ‫حلول‬ ‫إلى‬ ‫للوصول‬ ‫معنا‬ ‫شارك‬." />
    </Helmet>
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-ar">
        <div class="inn-banner-bg-main-en">
          <div class="inner-con-en">
            <div class="inner-banner-btn-en">
              <h1>تأمين الأعمال </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="inner-banner-btn-ar">
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="inner-banner-btn-item-active-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="inner-con-en">
          <div class="bdy-innercont-en">
            <div class="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="innerbdy-dlex-lft-ar">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/liability_insurance_ar.png"} alt="التأمين عن المسؤولية" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="innerbdy-dlex-rgt-ar">
                <h2 class="pagetitle-ar">التأمين عن المسؤولية</h2>

                <p class="inner-mob-body-txt-ar">تنشأ التعرضات الحديثة لتأمين مسؤولية الأعمال من عدة مصادر: من أطراف ثالثة، من المنتجات، من البيئة، من المخاطر الناشئة. إنها تتزايد في الحجم وتعقيد التفاصيل، لكنها تظل متشابهة في بُعد واحد حاسم - طبيعتها طويلة الأجل</p>

                <p class="inner-mob-body-txt-ar">تواجه الشركات الكبرى مخاطر المسؤولية التجارية عبر مواقع متعددة في جميع أنحاء العالم تحتاج إلى شريك موثوق طويل الأجل مع امتداد عالمي</p>

                <p class="inner-mob-body-txt-ar">
                تقدم شركة شركة كيمجي رامداس لخدمات التأمين التي تعد أيضًا شركاء شبكة عالمية لشركة  ويليس تاورز واتسون في عمان الخدمات المالية</p>

                <p class="inner-mob-body-txt-ar">نحن نواجه هذا التحدي من خلال فريق من الخبراء من شركات التأمين والمتخصصين في المطالبات واستشارات المخاطر (بما في ذلك المحامين والمهندسين والعلماء والمتخصصين في الصناعة) مدعومين بقدرة كبيرة لمواجهة أكبر تحديات تأمين المسؤولية التجارية، المضمنة في شبكة خدمة في أكثر من 160 دولة حول العالم</p>

                <h3 class="bdyinner-heading-ar">التغطية الرئيسية/ تأمين المسؤولية</h3>

              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

            <p class="inner-mob-body-txt-ar">نظرة عامة على مخاطر تأمين مسؤولية الشركات كما يلي</p>

            <div class="bdyinner-keypoint-ar">
              <div class="bdyinner-keypoint-item-ar">تأمين المسؤولية العامة - تغطية المسؤولية الناشئة عن عمليات المؤمن له أو مبانيه تجاه الغير</div>

              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-ar">تأمين مسؤولية المنتجات - تغطية الأضرار التي تلحق بالممتلكات أو الإصابات الجسدية الناجمة عن منتجات المؤمن له أو العمل المنجز</div>

              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-ar">المسؤولية عن الأضرار البيئية - مطالبات التلوث الناجم عن أنشطة المؤمن له</div>

              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-ar">حلول إدارة الأزمات - حلول تأمين إدارة الأزمات واسعة النطاق التي تشمل تلوث المنتجات وتأمين سحب المنتجات بالإضافة إلى تغطية أعمال الإرهاب والعنف السياسي بالتأمين</div>
              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-ar">تأمين التجارب السريرية - يغطي المسؤولية القانونية للرعاة (شركات الأدوية) والمحققين (الأطباء والمستشفيات ومؤسسات البحوث السريرية) الناشئة عن التجارب السريرية</div>

              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-ar">مسؤولية صاحب العمل / تأمين المديرين والموظفين - تغطية مسؤولية صاحب العمل عن حوادث العمل / أمراض الموظفين. حلول مسؤولية المديرين والموظفين</div>

              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-ar">تأمين مسؤولية تكنولوجيا المعلومات - يحمي من التعرض للمسؤولية الناشئة على أساس الخسارة المالية البحتة: على سبيل المثال، التعدي على حقوق الخصوصية، والنقل غير المقصود لفيروسات الكمبيوتر، والعبث و "التهديدات الإلكترونية" الأخرى</div>
              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-ar">تأمين مسؤولية الإدارة - يتم تغطيته من خلال خدمات الخطوط المالية لدينا، بما في ذلك تأمين أعضاء مجلس الإدارة والموظفين والتأمين ضد المسؤولية المهنية</div>
              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-ar">مسؤولية الطيران والفضاء - يتم تغطيتها من خلال فرق تأمين الطيران لدينا لشركات الطيران وصناعة الطيران. تغطية الأضرار المادية والمسؤولية للمصنعين والموردين وكذلك للمطارات والمطارات ومعيدي الوقود ومقدمي الخدمات المرتبطين بها 

</div>
              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>

            <h3 className="bdyinner-whykr-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>
            <div className="clearfix"></div>
            <div className="bdyinner-whykr-keypoints-ar">
              <div className="bdyinner-whykr-kepoints-secitem-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-whykr-kepoints-ftitem-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-whykr-kepoints-secitem-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div className="bdyinner-whykr-kepoints-ftitem-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div class="inn-banner-bg-main-en">
          <div class="inner-con-en">
            <div class="inner-banner-btn-en">
              <h1 class="liabilityinsu-banner-heading-ar">تأمين الأعمال </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="inner-banner-btn-ar">
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="inner-banner-btn-item-active-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="inner-con-en">
          <div className="tab-imgtxt-ar">
            <h2>التأمين عن المسؤولية</h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/liability_insurance_ar.png"} alt="التأمين عن المسؤولية" />

              <p>تنشأ التعرضات الحديثة لتأمين مسؤولية الأعمال من عدة مصادر: من أطراف ثالثة، من المنتجات، من البيئة، من المخاطر الناشئة. إنها تتزايد في الحجم وتعقيد التفاصيل، لكنها تظل متشابهة في بُعد واحد حاسم - طبيعتها طويلة الأجل</p>

              <p>تواجه الشركات الكبرى مخاطر المسؤولية التجارية عبر مواقع متعددة في جميع أنحاء العالم تحتاج إلى شريك موثوق طويل الأجل مع امتداد عالمي</p>

              <p>تقدم شركة شركة كيمجي رامداس لخدمات التأمين التي تعد أيضًا شركاء شبكة عالمية لشركة  ويليس تاورز واتسون في عمان الخدمات المالية</p>

              <p>نحن نواجه هذا التحدي من خلال فريق من الخبراء من شركات التأمين والمتخصصين في المطالبات واستشارات المخاطر (بما في ذلك المحامين والمهندسين والعلماء والمتخصصين في الصناعة) مدعومين بقدرة كبيرة لمواجهة أكبر تحديات تأمين المسؤولية التجارية، المضمنة في شبكة خدمة في أكثر من 160 دولة حول العالم</p>

              <h3 class="tab-sub-title-ar">التغطية الرئيسية/ تأمين المسؤولية</h3>
              <p>نظرة عامة على مخاطر تأمين مسؤولية الشركات كما يلي</p>

              <div class="tabkyfet-ar">
                <div class="tabkeyfet-txt-ar">تأمين المسؤولية العامة - تغطية المسؤولية الناشئة عن عمليات المؤمن له أو مبانيه تجاه الغير</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">تأمين مسؤولية المنتجات - تغطية الأضرار التي تلحق بالممتلكات أو الإصابات الجسدية الناجمة عن منتجات المؤمن له أو العمل المنجز</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">المسؤولية عن الأضرار البيئية - مطالبات التلوث الناجم عن أنشطة المؤمن له</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">حلول إدارة الأزمات - حلول تأمين إدارة الأزمات واسعة النطاق التي تشمل تلوث المنتجات وتأمين سحب المنتجات بالإضافة إلى تغطية أعمال الإرهاب والعنف السياسي بالتأمين</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">تأمين التجارب السريرية - يغطي المسؤولية القانونية للرعاة (شركات الأدوية) والمحققين (الأطباء والمستشفيات ومؤسسات البحوث السريرية) الناشئة عن التجارب السريرية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">مسؤولية صاحب العمل / تأمين المديرين والموظفين - تغطية مسؤولية صاحب العمل عن حوادث العمل / أمراض الموظفين. حلول مسؤولية المديرين والموظفين</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">تأمين مسؤولية تكنولوجيا المعلومات - يحمي من التعرض للمسؤولية الناشئة على أساس الخسارة المالية البحتة: على سبيل المثال، التعدي على حقوق الخصوصية، والنقل غير المقصود لفيروسات الكمبيوتر، والعبث و "التهديدات الإلكترونية" الأخرى</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">تأمين مسؤولية الإدارة - يتم تغطيته من خلال خدمات الخطوط المالية لدينا، بما في ذلك تأمين أعضاء مجلس الإدارة والموظفين والتأمين ضد المسؤولية المهنية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-ar">مسؤولية الطيران والفضاء - يتم تغطيتها من خلال فرق تأمين الطيران لدينا لشركات الطيران وصناعة الطيران. تغطية الأضرار المادية والمسؤولية للمصنعين والموردين وكذلك للمطارات والمطارات ومعيدي الوقود ومقدمي الخدمات المرتبطين بها</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              </div>
              
              <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
              
              <div class="tab-whykrinsu-pins-list-ar">
                <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
                <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> </div>
              </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="inn-banner-bg-main-en hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"project-insurance-ar"}>تأمين المشاريع </option>
            <option value={"property-insurance-ar"}>تأمين الممتلكات</option>
            <option value={"terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </option>
            <option value={"motor-fleet-insurance-ar"} >تأمين أسطول المركبات</option>
            <option value={"group-health-insurance-ar"}>التأمين الصحي الجماعي</option>
            <option value={"group-life-insurance-ar"}>التأمين الجماعي على الحياة</option>
            <option value={"fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</option>
            <option value={"burglary-insurance-ar"}>التأمين ضد السرقة</option>
            <option value={"workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</option>
            <option value={"group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</option>
            <option value={"cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</option>
            <option value={"jewellers-block-insurance-ar"}>التأمين على المجوهرات</option>
            <option value={"stock-throughput-insurance-ar"}>تأمين صافي المخزون</option>
            <option value={"hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</option>
            <option value={"speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</option>
            <option value={"liability-insurance-ar"} selected class="active">التأمين عن المسؤولية</option>
            <option value={"marine-insurance-ar"}>التأمين البحري</option>
            <option value={"energy-insurance-ar"}>التأمين على الطاقة</option>
          </select>
        </div>
        <div class="innner-mob-body-main-en">
          <h1 class="inner-mob-heading-en">التأمين عن المسؤولية</h1>
          <div class="innner-mob-body-img-en">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/liability_insurance_ar.png"} alt="التأمين عن المسؤولية" />
          </div>

          <p class="inner-mob-body-txt-ar">تنشأ التعرضات الحديثة لتأمين مسؤولية الأعمال من عدة مصادر: من أطراف ثالثة، من المنتجات، من البيئة، من المخاطر الناشئة. إنها تتزايد في الحجم وتعقيد التفاصيل، لكنها تظل متشابهة في بُعد واحد حاسم - طبيعتها طويلة الأجل.</p>

          <p class="inner-mob-body-txt-ar">تواجه الشركات الكبرى مخاطر المسؤولية التجارية عبر مواقع متعددة في جميع أنحاء العالم تحتاج إلى شريك موثوق طويل الأجل مع امتداد عالمي.</p>

          <p class="inner-mob-body-txt-ar">تقدم شركة شركة كيمجي رامداس لخدمات التأمين التي تعد أيضًا شركاء شبكة عالمية لشركة  ويليس تاورز واتسون في عمان الخدمات المالية</p>

          <p class="inner-mob-body-txt-ar">نحن نواجه هذا التحدي من خلال فريق من الخبراء من شركات التأمين والمتخصصين في المطالبات واستشارات المخاطر (بما في ذلك المحامين والمهندسين والعلماء والمتخصصين في الصناعة) مدعومين بقدرة كبيرة لمواجهة أكبر تحديات تأمين المسؤولية التجارية، المضمنة في شبكة خدمة في أكثر من 160 دولة حول العالم.</p>

          <h3 class="liabilityinsu-subtitle-ar">التغطية الرئيسية/ تأمين المسؤولية</h3>

          <p class="inner-mob-body-txt-ar">نظرة عامة على مخاطر تأمين مسؤولية الشركات كما يلي</p>

          <div class="inner-mob-pins-list-ar">
            <div class="inner-mob-pins-list-item-ar">تأمين المسؤولية العامة - تغطية المسؤولية الناشئة عن عمليات المؤمن له أو مبانيه تجاه الغير</div>

            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-ar">تأمين مسؤولية المنتجات - تغطية الأضرار التي تلحق بالممتلكات أو الإصابات الجسدية الناجمة عن منتجات المؤمن له أو العمل المنجز</div>

            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-ar">المسؤولية عن الأضرار البيئية - مطالبات التلوث الناجم عن أنشطة المؤمن له</div>

            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-ar">حلول إدارة الأزمات - حلول تأمين إدارة الأزمات واسعة النطاق التي تشمل تلوث المنتجات وتأمين سحب المنتجات بالإضافة إلى تغطية أعمال الإرهاب والعنف السياسي بالتأمين</div>

            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-ar">تأمين التجارب السريرية - يغطي المسؤولية القانونية للرعاة (شركات الأدوية) والمحققين (الأطباء والمستشفيات ومؤسسات البحوث السريرية) الناشئة عن التجارب السريرية</div>

            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-ar">مسؤولية صاحب العمل / تأمين المديرين والموظفين - تغطية مسؤولية صاحب العمل عن حوادث العمل / أمراض الموظفين. حلول مسؤولية المديرين والموظفين</div>

            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-ar">تأمين مسؤولية تكنولوجيا المعلومات - يحمي من التعرض للمسؤولية الناشئة على أساس الخسارة المالية البحتة: على سبيل المثال، التعدي على حقوق الخصوصية، والنقل غير المقصود لفيروسات الكمبيوتر، والعبث و "التهديدات الإلكترونية" الأخرى</div>

            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-ar">تأمين مسؤولية الإدارة - يتم تغطيته من خلال خدمات الخطوط المالية لدينا، بما في ذلك تأمين أعضاء مجلس الإدارة والموظفين والتأمين ضد المسؤولية المهنية</div>

            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-ar">مسؤولية الطيران والفضاء - يتم تغطيتها من خلال فرق تأمين الطيران لدينا لشركات الطيران وصناعة الطيران. تغطية الأضرار المادية والمسؤولية للمصنعين والموردين وكذلك للمطارات والمطارات ومعيدي الوقود ومقدمي الخدمات المرتبطين بها </div>
            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

          </div>

          <h3 className="inner-mob-main-cover-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>

          <div className="inner-mob-bdy-whykrlist-ar">
            <div className="inner-mob-bdy-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar"> أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>
          
          {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARLiabilityInsurance;