import { useEffect, useState, React } from 'react';
import { Link } from "react-router-dom";
import HeaderAR from "../headerAR/headerar";
import FooterAR from "../footerAR/footerar";

import './araboutus.scss';

const ARAboutUsPage = () => {
  
  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  return (
    <div>
      <div class="main-fluid">
        <HeaderAR />
      </div>
      <div class="kr-container-about-ar">
        <div class="kr-aboutus-ar">
          <div class="kr-about-banner-ar">
            <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <img src={process.env.PUBLIC_URL + "/aboutus_images/about_us_img.png"} alt="about us" class="img-fluid" />
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div class="kr-banner-titles">
                  <div>
                    <h1 class="abt-title-ar">مجموعة كيمجي رامداس</h1>
                    <p class="abt-banner-txt-ar">مجموعة كيمجي رامداس (كيمجي رامداس) هي مجموعة أعمال رائدة مشهورة بتقديم الخدمات بأعلى جودة فيما يزيد عن مائة عام وتتميز بالابتكار. بدأت بشكل متواضع في بدايات عام 1870، وحافظت على النمو المستمر لتصبح واحدة من أكثر الأسماء التجارية موثوقية واحترامًا في سلطنة عمان</p>

                    <p class="abt-sub-title-ar">مجموعة أعمال رائدة ومُعتمَدة في سلطنة عمان</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="kr-services-ar">
            <h2 class="sev-title-ar">تلتزم مجموعة كيمجي رامداس بما يلي</h2>

            <div class="kr-ser-wrp-ar">
              <div class="kr-serv-card-ar">
                <img src={process.env.PUBLIC_URL + "/aboutus_images/serv-1.png"} alt="Excellence" />
                <p>الالتزام في تقديم خدمة العملاء والتميز فيها</p>
              </div>

              <div class="kr-serv-card-ar">
                <img src={process.env.PUBLIC_URL + "/aboutus_images/serv-3.png"} alt="services" />
                <p>توظيف أكثر من 5000 موظف موزعين في جميع أنحاء سلطنة عمان</p>

              </div>


              <div class="kr-serv-card-ar">
                <img src={process.env.PUBLIC_URL + "/aboutus_images/serv-4.png"} alt="supply chain" />
                <p>تقديم علامات تجارية عالمية رائدة في المنتجات والخدمات، مدعومة بسلسلة توزيع وتوريد عالية الجودة</p>

              </div>

              <div class="kr-serv-card-ar">
                <img src={process.env.PUBLIC_URL + "/aboutus_images/serv-5.png"} alt="Trading" />
                <p>مجموعة متنوعة، مع خبرة متوسعة في التجارة والتوزيع والبيع بالتجزئة والتأمين والتدريب وفي مجال المطاعم والتصنيع</p>

              </div>

              <div class="kr-serv-card-ar">
                <img src={process.env.PUBLIC_URL + "/aboutus_images/serv-6.png"} alt="Turnover" />
                <p>يصل حجم مبيعاتها السنوية لأكثر من 1 مليار دولار أمريكي</p>

              </div>

              <div class="kr-serv-card-ar">
                <img src={process.env.PUBLIC_URL + "/aboutus_images/serv-7.png"} alt="SAP" />
                <p>استخدام "تخطيط موارد المؤسسة" كعملية للأعمال المتكاملة</p>

              </div>

            </div>
          </div>
          <div class="kr-milestones-ar d-none d-md-block">
            <h3>معالم تأمين كيمجي رامداس</h3>
            <img src={process.env.PUBLIC_URL + "/aboutus_images/aboutus_road_map_AR.png"} alt="Road Map" class="rd-img-ar" />
          </div>

          {/* ROAD MAP FOR MOBILE SECTION STARTS HERE */}
          <div class="kr-milestones-mob-en d-block d-sm-none">
            <h3>معالم تأمين كيمجي رامداس</h3>
            <img src={process.env.PUBLIC_URL + "/aboutus_images/mobile_road_AR.png"} alt="Road Map" class="rd-img-ar" />
          </div>
          {/* ROAD MAP FOR MOBILE SECTION ENDS HERE */}

          <div class="kr-csr-ar">
            <h2 class="kr-csr-title-ar">المسؤولية الاجتماعية للشركات</h2>
            <div class="kr-csr-rgt-en">
              <img src={process.env.PUBLIC_URL + "/aboutus_images/csr_img.png"} alt="CSR" />
            </div>
            <div class="kr-csr-lft-ar">
              <p class="csr-txt-ar">منذ بدايتها، كانت مجموعة كيمجي رامداس تسعى باستمرار إلى المساهمة في التقدم الاجتماعي والاقتصادي للبلاد من خلال تطوير حلول مبتكرة تعزز دعمها للمجتمع وتمكين الناس من بناء مستقبل ينبض لهم بالحياة</p>

              <p class="csr-txt-ar">مسترشدة برؤية جلالة السلطان قابوس بن سعيد، حيثي تلتزم مجموعة كيمجي رامداس بلعب دور فعال في التنمية المستدامة والتطور الشامل لجميع شرائح المجتمع من خلال مبادرات وجهود المسؤولية الاجتماعية للشركات. </p>

              <h6 class="csr-hd-ar">إشراقة - جناح التنمية الاجتماعية لكيمجي رامداس</h6>

              <p class="csr-txt-ar">إشراقة، يُقصد بها في اللغة العربية وهج من أشعة شمس الفجر أو شمس الصباح، مما يدل على البدايات الجديدة والأمل. ستواصل إشراقة بناء إرثها على الركائز الأساسية للصحة والتعليم ورفاهية المجتمع والتي تقود اليوم مشاركتها مع المجتمع العماني على أساس غير هادف للربح</p>

              <p class="csr-lnktxt-en"><a href="https://www.eshraqa.om/" target="_blank">eshraqa.com</a> لمزيد من المعلومات يرجى زيارة موقع إشراقة</p>
            </div>
             </div>
        </div>
      </div>
            {/* FOOTER STARTS HERE  */}
            <div class="main-fluid">
              <FooterAR />
            </div>
            {/* FOOTER ENDS HERE  */}
    </div>
  );
}

export default ARAboutUsPage;