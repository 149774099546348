import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { render } from "react-dom";

import './index.scss';

import reportWebVitals from './reportWebVitals';

import HomePage from "./home/index";
import OurteamPage from "./team/team";
import AboutUsPage from "./aboutus/aboutus";
import OurInsuranceSolutions from "./ourinsurancesolutions/ourinsurancesolutions";

import TravelInsurance from "./personalinsurance/travelinsurance/travelinsurance";
import LifeInsurance from "./personalinsurance/lifeinsurance/lifeinsurance";
import PersonalAccident from "./personalinsurance/personalaccident/personalaccident";
import RelocationInsurance from "./personalinsurance/relocationinsurance/relocationinsurance";
import HealthInsurance from "./personalinsurance/healthinsurance/healthinsurance";
import ArtInsurance from "./personalinsurance/artinsurance/artinsurance";
import GolfInsurance from "./personalinsurance/golfinsurance/golfinsurance";
import MotorInsurance from "./personalinsurance/motorinsurance/motorinsurance";
import HousemaidInsurance from "./personalinsurance/housemaidinsurance/housemaidinsurance";
import HomeInsurance from "./personalinsurance/homeinsurance/homeinsurance";
import YachtInsurance from "./personalinsurance/yachtinsurance/yachtinsurance";

import GroupPersonalAccidentInsurance from "./businessinsurance/grouppersonalaccidentinsurance/grouppersonalaccidentinsurance";
import PropertyInsurance from "./businessinsurance/propertyinsurance/propertyinsurance";
import TerrorismandPoliticalRiskInsurance from "./businessinsurance/terrorismandpoliticalriskinsurance/terrorismandpoliticalriskinsurance";
import GroupHealthInsurance from "./businessinsurance/grouphealthinsurance/grouphealthinsurance";
import GroupLifeInsurance from "./businessinsurance/grouplifeinsurance/grouplifeinsurance";
import FidelityGuaranteeInsurance from "./businessinsurance/fidelityguaranteeinsurance/fidelityguaranteeinsurance";
import CyberCrimeInsurance from "./businessinsurance/cybercrimeinsurance/cybercrimeinsurance";
import BurglaryInsurance from "./businessinsurance/burglaryinsurance/burglaryinsurance";
import ProjectInsurance from "./businessinsurance/projectinsurance/projectinsurance";
import JewellersBlockInsurance from "./businessinsurance/jewellersblockinsurance/jewellersblockinsurance";
import StockThroughputInsurance from "./businessinsurance/stockthroughputinsurance/stockthroughputinsurance";
import WorkmenCompensationInsurance from "./businessinsurance/workmencompensationinsurance/workmencompensationinsurance";
import HauliersFullValueCargoInsurance from "./businessinsurance/hauliersfullvaluecargoinsurance/hauliersfullvaluecargoinsurance";
import SpecialityRiskEventInsurance from "./businessinsurance/specialityriskeventinsurance/specialityriskeventinsurance";
import LiabilityInsurance from "./businessinsurance/liabilityinsurance/liabilityinsurance";
import MotorFleetInsurance from "./businessinsurance/motorfleetinsurance/motorfleetinsurance";
import MarineInsurance from "./businessinsurance/marineinsurance/marineinsurance";
import EnergyInsurance from "./businessinsurance/energyinsurance/energyinsurance";

import RiskInspectionServices from "./valuedservices/riskinspectionservices/riskinspectionservices";
import ThermographyTestingInsurance from "./valuedservices/thermographytestinginsurance/thermographytestinginsurance";
import ValuationServices from "./valuedservices/valuationservices/valuationservices";
import InsuranceAudits from "./valuedservices/insuranceaudits/insuranceaudits";
import EmployeeWellnessProgram from "./valuedservices/employeewellnessprogram/employeewellnessprogram";
import PolicyRenewalAlerts from "./valuedservices/policyrenewalalerts/policyrenewalalerts";

import ReportaClaim from "./customerservice/reportaclaim/reportaclaim";
import ServiceExcellence from "./customerservice/serviceexcellence/serviceexcellence";
import InhouseKRAutomotiveGarage from "./customerservice/inhousekrautomotivegarage/inhousekrautomotivegarage";
import ClaimConsultancyServices from "./customerservice/claimconsultancyservices/claimconsultancyservices";
import ErpBasedSolutions from "./customerservice/erpbasedsolutions/erpbasedsolutions";


import ContactUsPage from "./contactus/contactus";
import PrivacyPolicy from "./privacypolicy/privacypolicy";

import ARHomePage from "./arhome/index";
import ARAboutUsPage from "./araboutus/araboutus";
import AROurInsuranceSolutions from "./arourinsurancesolutions/arourinsurancesolutions";
import ARContactUsPage from "./arcontactus/arcontactus";
import ARPrivacyPolicy from "./arprivacypolicy/arprivacypolicy";
import AROurteamPage from "./arteam/arteam";

import ARPropertyInsurance from "./businessinsurance/arpropertyinsurance/arpropertyinsurance";
import ARProjectInsurance from "./businessinsurance/arprojectinsurance/arprojectinsurance";
import ARTerrorismandPoliticalRiskInsurance from "./businessinsurance/arterrorismandpoliticalriskinsurance/arterrorismandpoliticalriskinsurance";
import ARMotorFleetInsurance from "./businessinsurance/armotorfleetinsurance/armotorfleetinsurance";
import ARGroupHealthInsurance from "./businessinsurance/argrouphealthinsurance/argrouphealthinsurance";
import ARGroupLifeInsurance from "./businessinsurance/argrouplifeinsurance/argrouplifeinsurance";
import ARFidelityGuaranteeInsurance from "./businessinsurance/arfidelityguaranteeinsurance/arfidelityguaranteeinsurance";
import ARBurglaryInsurance from "./businessinsurance/arburglaryinsurance/arburglaryinsurance";
import ARWorkmenCompensationInsurance from "./businessinsurance/arworkmencompensationinsurance/arworkmencompensationinsurance";
import ARGroupPersonalAccidentInsurance from "./businessinsurance/argrouppersonalaccidentinsurance/argrouppersonalaccidentinsurance";
import ARCyberCrimeInsurance from "./businessinsurance/arcybercrimeinsurance/arcybercrimeinsurance";
import ARJewellersBlockInsurance from "./businessinsurance/arjewellersblockinsurance/arjewellersblockinsurance";
import ARStockThroughputInsurance from "./businessinsurance/arstockthroughputinsurance/arstockthroughputinsurance";
import ARHauliersFullValueCargoInsurance from "./businessinsurance/arhauliersfullvaluecargoinsurance/arhauliersfullvaluecargoinsurance";
import ARSpecialityRiskEventInsurance from "./businessinsurance/arspecialityriskeventinsurance/arspecialityriskeventinsurance";
import ARLiabilityInsurance from "./businessinsurance/arliabilityinsurance/arliabilityinsurance";
import ARMarineInsurance from "./businessinsurance/armarineinsurance/armarineinsurance";
import AREnergyInsurance from "./businessinsurance/arenergyinsurance/arenergyinsurance";

import ARMotorInsurance from "./personalinsurance/armotorinsurance/armotorinsurance";
import ARTravelInsurance from "./personalinsurance/artravelinsurance/artravelinsurance";
import ARPersonalAccident from "./personalinsurance/arpersonalaccident/arpersonalaccident";
import ARRelocationInsurance from "./personalinsurance/arrelocationinsurance/arrelocationinsurance";
import ARHealthInsurance from "./personalinsurance/arhealthinsurance/arhealthinsurance";
import ARArtInsurance from "./personalinsurance/arartinsurance/arartinsurance";
import ARGolfInsurance from "./personalinsurance/argolfinsurance/argolfinsurance";
import ARHousemaidInsurance from "./personalinsurance/arhousemaidinsurance/arhousemaidinsurance";
import ARYachtInsurance from "./personalinsurance/aryachtinsurance/aryachtinsurance";
import ARHomeInsurance from "./personalinsurance/arhomeinsurance/arhomeinsurance";
import ARLifeInsurance from "./personalinsurance/arlifeinsurance/arlifeinsurance";

import ARRiskInspectionServices from "./valuedservices/arriskinspectionservices/arriskinspectionservices";
import ARInsuranceAudits from "./valuedservices/arinsuranceaudits/arinsuranceaudits";
import ARValuationServices from "./valuedservices/arvaluationservices/arvaluationservices";
import ARThermographyTestingInsurance from "./valuedservices/arthermographytestinginsurance/arthermographytestinginsurance";
import AREmployeeWellnessProgram from "./valuedservices/aremployeewellnessprogram/aremployeewellnessprogram";

import ARReportaClaim from "./customerservice/arreportaclaim/arreportaclaim";
import ARClaimConsultancyServices from "./customerservice/arclaimconsultancyservices/arclaimconsultancyservices";
import ARServiceExcellence from "./customerservice/arserviceexcellence/arserviceexcellence";
import ARInhouseKRAutomotiveGarage from "./customerservice/arinhousekrautomotivegarage/arinhousekrautomotivegarage";
import ARErpBasedSolutions from "./customerservice/arerpbasedsolutions/arerpbasedsolutions";


//import ARTeam from "./arteam/arteam";
//import ARContactUsPage from "./arcontactus/arcontactus";
//import ARPersonalInsurance from "./arpersonalinsurance/arpersonalinsurance";
import Page from './page/page';
import PageNotFound from './PageNotFound';
import CoinowlPage from './coinowl/coinowl';

// import './App.css';
import { HelmetProvider } from 'react-helmet-async';


import 'bootstrap/dist/css/bootstrap.min.css';

const root = document.getElementById("root");
render(<React.StrictMode>
  <HelmetProvider>
  <Router>
    <div className="App">
      <div className="content">
        <Switch>
          {/* <Route exact path="/">
            <HomePage />
          </Route> */}
          <Route exact path="/"
            render={props => (
              <Page {...props} component={HomePage} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/aboutus">
            <AboutUsPage />
          </Route> */}
          <Route exact path="/aboutus" 
            render={props => (
              <Page {...props} component={AboutUsPage} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/our-insurance-solutions">
            <OurInsuranceSolutions />
          </Route> */}
          <Route exact path="/our-insurance-solutions" 
            render={props => (
              <Page {...props} component={OurInsuranceSolutions} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/team">
            <OurteamPage />
          </Route> */}
          <Route exact path="/team" 
            render={props => (
              <Page {...props} component={OurteamPage} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/contactus">
            <ContactUsPage />
          </Route> */}
          <Route exact path="/contactus" 
            render={props => (
              <Page {...props} component={ContactUsPage} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/travel-insurance">
            <TravelInsurance />
          </Route> */}
          <Route exact path="/travel-insurance" 
            render={props => (
              <Page {...props} component={TravelInsurance} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/life-insurance">
            <LifeInsurance />
          </Route> */}
          <Route exact path="/life-insurance" 
            render={props => (
              <Page {...props} component={LifeInsurance} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/personal-accident">
            <PersonalAccident />
          </Route> */}
          <Route exact path="/personal-accident" 
            render={props => (
              <Page {...props} component={PersonalAccident} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/relocation-insurance">
            <RelocationInsurance />
          </Route> */}
          <Route exact path="/relocation-insurance" 
            render={props => (
              <Page {...props} component={RelocationInsurance} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/health-insurance">
            <HealthInsurance />
          </Route> */}
          <Route exact path="/health-insurance" 
            render={props => (
              <Page {...props} component={HealthInsurance} title="Best Health Insurance in Oman | KR Insurance" />
            )}
          />
          {/* <Route exact path="/art-insurance">
            <ArtInsurance />
          </Route> */}
          <Route exact path="/art-insurance" 
            render={props => (
              <Page {...props} component={ArtInsurance} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/golf-insurance">
            <GolfInsurance />
          </Route> */}
          <Route exact path="/golf-insurance" 
            render={props => (
              <Page {...props} component={GolfInsurance} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/motor-insurance">
            <MotorInsurance />
          </Route> */}
          <Route exact path="/motor-insurance" 
            render={props => (
              <Page {...props} component={MotorInsurance} title="Trusted and Reliable Motor Insurance in Oman | KR Insurance" />
            )}
          />
          {/* <Route exact path="/housemaid-insurance">
            <HousemaidInsurance />
          </Route> */}
          <Route exact path="/housemaid-insurance" 
            render={props => (
              <Page {...props} component={HousemaidInsurance} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/home-insurance">
            <HomeInsurance />
          </Route> */}
          <Route exact path="/home-insurance" 
            render={props => (
              <Page {...props} component={HomeInsurance} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/yacht-insurance">
            <YachtInsurance />
          </Route> */}
          <Route exact path="/yacht-insurance" 
            render={props => (
              <Page {...props} component={YachtInsurance} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />          
          {/* <Route exact path="/group-personal-accident-insurance">
            <GroupPersonalAccidentInsurance />
          </Route> */}
          <Route exact path="/group-personal-accident-insurance" 
            render={props => (
              <Page {...props} component={GroupPersonalAccidentInsurance} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/property-insurance">
            <PropertyInsurance />
          </Route> */}
          <Route exact path="/property-insurance" 
            render={props => (
              <Page {...props} component={PropertyInsurance} title="Best Business Property Insurance - KR Insuranc" />
            )}
          />
          {/* <Route exact path="/terrorism-and-political-risk-insurance">
            <TerrorismandPoliticalRiskInsurance />
          </Route> */}
          <Route exact path="/terrorism-and-political-risk-insurance" 
            render={props => (
              <Page {...props} component={TerrorismandPoliticalRiskInsurance} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/group-health-insurance">
            <GroupHealthInsurance />
          </Route> */}
          <Route exact path="/group-health-insurance" 
            render={props => (
              <Page {...props} component={GroupHealthInsurance} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/group-life-insurance">
            <GroupLifeInsurance />
          </Route> */}
          <Route exact path="/group-life-insurance" 
            render={props => (
              <Page {...props} component={GroupLifeInsurance} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/fidelity-guarantee-insurance">
            <FidelityGuaranteeInsurance />
          </Route> */}
          <Route exact path="/fidelity-guarantee-insurance" 
            render={props => (
              <Page {...props} component={FidelityGuaranteeInsurance} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/cyber-crime-insurance">
            <CyberCrimeInsurance />
          </Route> */}
          <Route exact path="/cyber-crime-insurance" 
            render={props => (
              <Page {...props} component={CyberCrimeInsurance} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/burglary-insurance">
            <BurglaryInsurance />
          </Route> */}
          <Route exact path="/burglary-insurance" 
            render={props => (
              <Page {...props} component={BurglaryInsurance} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/project-insurance">
            <ProjectInsurance />
          </Route> */}
          <Route exact path="/project-insurance" 
            render={props => (
              <Page {...props} component={ProjectInsurance} title="Trusted Provider of Project Insurance in Oman - KR Insurance" />
            )}
          />
          {/* <Route exact path="/jewellers-block-insurance">
            <JewellersBlockInsurance />
          </Route> */}
          <Route exact path="/jewellers-block-insurance" 
            render={props => (
              <Page {...props} component={JewellersBlockInsurance} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/stock-throughput-insurance">
            <StockThroughputInsurance />
          </Route> */}
          <Route exact path="/stock-throughput-insurance" 
            render={props => (
              <Page {...props} component={StockThroughputInsurance} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/workmen-compensation-insurance">
            <WorkmenCompensationInsurance />
          </Route> */}
          <Route exact path="/workmen-compensation-insurance" 
            render={props => (
              <Page {...props} component={WorkmenCompensationInsurance} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/hauliers-full-value-cargo-insurance">
            <HauliersFullValueCargoInsurance />
          </Route> */}
          <Route exact path="/hauliers-full-value-cargo-insurance" 
            render={props => (
              <Page {...props} component={HauliersFullValueCargoInsurance} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/speciality-risk-event-insurance">
            <SpecialityRiskEventInsurance />
          </Route> */}
          <Route exact path="/speciality-risk-event-insurance" 
            render={props => (
              <Page {...props} component={SpecialityRiskEventInsurance} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/liability-insurance">
            <LiabilityInsurance />
          </Route> */}
          <Route exact path="/liability-insurance" 
            render={props => (
              <Page {...props} component={LiabilityInsurance} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/motor-fleet-insurance">
            <MotorFleetInsurance />
          </Route> */}
          <Route exact path="/motor-fleet-insurance" 
            render={props => (
              <Page {...props} component={MotorFleetInsurance} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/marine-insurance">
            <MarineInsurance />
          </Route> */}
          <Route exact path="/marine-insurance" 
            render={props => (
              <Page {...props} component={MarineInsurance} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/energy-insurance">
            <EnergyInsurance />
          </Route> */}
          <Route exact path="/energy-insurance" 
            render={props => (
              <Page {...props} component={EnergyInsurance} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/risk-inspection-services">
            <RiskInspectionServices />
          </Route> */}
          <Route exact path="/risk-inspection-services" 
            render={props => (
              <Page {...props} component={RiskInspectionServices} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/thermography-testing-insurance">
            <ThermographyTestingInsurance />
          </Route> */}
          <Route exact path="/thermography-testing-insurance" 
            render={props => (
              <Page {...props} component={ThermographyTestingInsurance} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/valuation-services">
            <ValuationServices />
          </Route> */}
          <Route exact path="/valuation-services" 
            render={props => (
              <Page {...props} component={ValuationServices} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/insurance-audits">
            <InsuranceAudits />
          </Route> */}
          <Route exact path="/insurance-audits" 
            render={props => (
              <Page {...props} component={InsuranceAudits} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/employee-wellness-program">
            <EmployeeWellnessProgram />
          </Route> */}
          <Route exact path="/employee-wellness-program" 
            render={props => (
              <Page {...props} component={EmployeeWellnessProgram} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />          
          {/* <Route exact path="/policy-renewalalerts">
            <PolicyRenewalAlerts />
          </Route> */}
          <Route exact path="/policy-renewalalerts" 
            render={props => (
              <Page {...props} component={PolicyRenewalAlerts} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/report-a-claim">
            <ReportaClaim />
          </Route> */}
          <Route exact path="/report-a-claim" 
            render={props => (
              <Page {...props} component={ReportaClaim} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/service-excellence">
            <ServiceExcellence />
          </Route> */}
          <Route exact path="/service-excellence" 
            render={props => (
              <Page {...props} component={ServiceExcellence} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/in-house-kr-automotive-garage">
            <InhouseKRAutomotiveGarage />
          </Route> */}
          <Route exact path="/in-house-kr-automotive-garage" 
            render={props => (
              <Page {...props} component={InhouseKRAutomotiveGarage} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/claim-consultancy-services">
            <ClaimConsultancyServices />
          </Route> */}
          <Route exact path="/claim-consultancy-services" 
            render={props => (
              <Page {...props} component={ClaimConsultancyServices} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/erp-based-solutions">
            <ErpBasedSolutions />
          </Route> */}
          <Route exact path="/erp-based-solutions" 
            render={props => (
              <Page {...props} component={ErpBasedSolutions} title="Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />
          {/* <Route exact path="/privacy-policy">
            <PrivacyPolicy />
          </Route> */}
          <Route exact path="/privacy-policy" 
            render={props => (
              <Page {...props} component={PrivacyPolicy} title="PrivacyPolicy | Trusted Insurance for Brokers & Insurance Companies in Oman" />
            )}
          />




          {/* ARBIC PAGES URLs*/}
          {/* <Route exact path="/ar">
            <ARHomePage />
          </Route> */}
          <Route exact path="/ar" 
            render={props => (
              <Page {...props} component={ARHomePage} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/omanbr-ar">
            <ARHomePage />
          </Route> */}
          {/* <Route exact path="/aboutus-ar">
            <ARAboutUsPage />
          </Route> */}
          <Route exact path="/aboutus-ar" 
            render={props => (
              <Page {...props} component={ARAboutUsPage} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/contactus-ar">
            <ARContactUsPage />
          </Route> */}
          <Route exact path="/contactus-ar" 
            render={props => (
              <Page {...props} component={ARContactUsPage} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/our-insurance-solutions-ar">
            <AROurInsuranceSolutions />
          </Route> */}
          <Route exact path="/our-insurance-solutions-ar" 
            render={props => (
              <Page {...props} component={AROurInsuranceSolutions} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/privacy-policy-ar">
            <ARPrivacyPolicy />
          </Route> */}
          <Route exact path="/privacy-policy-ar" 
            render={props => (
              <Page {...props} component={ARPrivacyPolicy} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/team-ar">
            <AROurteamPage />
          </Route> */}
          <Route exact path="/team-ar" 
            render={props => (
              <Page {...props} component={AROurteamPage} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/property-insurance-ar">
            <ARPropertyInsurance />
          </Route> */}
          <Route exact path="/property-insurance-ar" 
            render={props => (
              <Page {...props} component={ARPropertyInsurance} title="KR ‫تأمين‬ - ‫التجارية‬ ‫الممتلكات‬ ‫على‬ ‫تأمين‬ ‫أفضل‬" />
            )}
          />
          {/* <Route exact path="/project-insurance-ar">
            <ARProjectInsurance />
          </Route> */}
          <Route exact path="/project-insurance-ar" 
            render={props => (
              <Page {...props} component={ARProjectInsurance} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/terrorism-and-political-risk-insurance-ar">
            <ARTerrorismandPoliticalRiskInsurance />
          </Route> */}
          <Route exact path="/terrorism-and-political-risk-insurance-ar" 
            render={props => (
              <Page {...props} component={ARTerrorismandPoliticalRiskInsurance} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/motor-fleet-insurance-ar">
            <ARMotorFleetInsurance />
          </Route> */}
          <Route exact path="/motor-fleet-insurance-ar" 
            render={props => (
              <Page {...props} component={ARMotorFleetInsurance} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/group-health-insurance-ar">
            <ARGroupHealthInsurance />
          </Route> */}
          <Route exact path="/group-health-insurance-ar" 
            render={props => (
              <Page {...props} component={ARGroupHealthInsurance} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/group-life-insurance-ar">
            <ARGroupLifeInsurance />
          </Route> */}
          <Route exact path="/group-life-insurance-ar" 
            render={props => (
              <Page {...props} component={ARGroupLifeInsurance} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/fidelity-guarantee-insurance-ar">
            <ARFidelityGuaranteeInsurance />
          </Route> */}
          <Route exact path="/fidelity-guarantee-insurance-ar" 
            render={props => (
              <Page {...props} component={ARFidelityGuaranteeInsurance} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/burglary-insurance-ar">
            <ARBurglaryInsurance />
          </Route> */}
          <Route exact path="/burglary-insurance-ar" 
            render={props => (
              <Page {...props} component={ARBurglaryInsurance} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/workmen-compensation-insurance-ar">
            <ARWorkmenCompensationInsurance />
          </Route> */}
          <Route exact path="/workmen-compensation-insurance-ar" 
            render={props => (
              <Page {...props} component={ARWorkmenCompensationInsurance} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/group-personal-accident-insurance-ar">
            <ARGroupPersonalAccidentInsurance />
          </Route> */}
          <Route exact path="/group-personal-accident-insurance-ar" 
            render={props => (
              <Page {...props} component={ARGroupPersonalAccidentInsurance} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/cyber-crime-insurance-ar">
            <ARCyberCrimeInsurance />
          </Route> */}
          <Route exact path="/cyber-crime-insurance-ar" 
            render={props => (
              <Page {...props} component={ARCyberCrimeInsurance} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/jewellers-block-insurance-ar">
            <ARJewellersBlockInsurance />
          </Route> */}
          <Route exact path="/jewellers-block-insurance-ar" 
            render={props => (
              <Page {...props} component={ARJewellersBlockInsurance} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/stock-throughput-insurance-ar">
            <ARStockThroughputInsurance />
          </Route> */}
          <Route exact path="/stock-throughput-insurance-ar" 
            render={props => (
              <Page {...props} component={ARStockThroughputInsurance} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/hauliers-full-value-cargo-insurance-ar">
            <ARHauliersFullValueCargoInsurance />
          </Route> */}
          <Route exact path="/hauliers-full-value-cargo-insurance-ar" 
            render={props => (
              <Page {...props} component={ARHauliersFullValueCargoInsurance} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/speciality-risk-event-insurance-ar">
            <ARSpecialityRiskEventInsurance />
          </Route> */}
          <Route exact path="/speciality-risk-event-insurance-ar" 
            render={props => (
              <Page {...props} component={ARSpecialityRiskEventInsurance} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/liability-insurance-ar">
            <ARLiabilityInsurance />
          </Route> */}
          <Route exact path="/liability-insurance-ar" 
            render={props => (
              <Page {...props} component={ARLiabilityInsurance} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/marine-insurance-ar">
            <ARMarineInsurance />
          </Route> */}
          <Route exact path="/marine-insurance-ar" 
            render={props => (
              <Page {...props} component={ARMarineInsurance} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/energy-insurance-ar">
            <AREnergyInsurance />
          </Route> */}
          <Route exact path="/energy-insurance-ar" 
            render={props => (
              <Page {...props} component={AREnergyInsurance} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/motor-insurance-ar">
            <ARMotorInsurance />
          </Route> */}
          <Route exact path="/motor-insurance-ar" 
            render={props => (
              <Page {...props} component={ARMotorInsurance} title="‫تأمين‬ | ‫عمان‬ ‫في‬ ‫به‬ ‫وموثوق‬ ‫به‬ ‫موثوق‬ ‫سيارات‬ ‫تأمين‬" />
            )}
          />
          {/* <Route exact path="/travel-insurance-ar">
            <ARTravelInsurance />
          </Route> */}
          <Route exact path="/travel-insurance-ar" 
            render={props => (
              <Page {...props} component={ARTravelInsurance} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/personal-accident-ar">
            <ARPersonalAccident />
          </Route> */}
          <Route exact path="/personal-accident-ar" 
            render={props => (
              <Page {...props} component={ARPersonalAccident} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/relocation-insurance-ar">
            <ARRelocationInsurance />
          </Route> */}
          <Route exact path="/relocation-insurance-ar" 
            render={props => (
              <Page {...props} component={ARRelocationInsurance} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/health-insurance-ar">
            <ARHealthInsurance />
          </Route> */}
          <Route exact path="/health-insurance-ar" 
            render={props => (
              <Page {...props} component={ARHealthInsurance} title="‫تأمين‬ | ‫عمان‬ ‫في‬ ‫صحي‬ ‫تأمين‬ ‫أفضل‬" />
            )}
          />
          {/* <Route exact path="/art-insurance-ar">
            <ARArtInsurance />
          </Route> */}
          <Route exact path="/art-insurance-ar" 
            render={props => (
              <Page {...props} component={ARArtInsurance} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/golf-insurance-ar">
            <ARGolfInsurance />
          </Route> */}
          <Route exact path="/golf-insurance-ar" 
            render={props => (
              <Page {...props} component={ARGolfInsurance} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/housemaid-insurance-ar">
            <ARHousemaidInsurance />
          </Route> */}
          <Route exact path="/housemaid-insurance-ar" 
            render={props => (
              <Page {...props} component={ARHousemaidInsurance} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/yacht-insurance-ar">
            <ARYachtInsurance />
          </Route> */}
          <Route exact path="/yacht-insurance-ar" 
            render={props => (
              <Page {...props} component={ARYachtInsurance} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/home-insurance-ar">
            <ARHomeInsurance />
          </Route> */}
          <Route exact path="/home-insurance-ar" 
            render={props => (
              <Page {...props} component={ARHomeInsurance} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/life-insurance-ar">
            <ARLifeInsurance />
          </Route> */}
          <Route exact path="/life-insurance-ar" 
            render={props => (
              <Page {...props} component={ARLifeInsurance} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/risk-inspection-services-ar">
            <ARRiskInspectionServices />
          </Route> */}
          <Route exact path="/risk-inspection-services-ar" 
            render={props => (
              <Page {...props} component={ARRiskInspectionServices} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/insurance-audits-ar">
            <ARInsuranceAudits />
          </Route> */}
          <Route exact path="/insurance-audits-ar" 
            render={props => (
              <Page {...props} component={ARInsuranceAudits} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/valuation-services-ar">
            <ARValuationServices />
          </Route> */}
          <Route exact path="/valuation-services-ar" 
            render={props => (
              <Page {...props} component={ARValuationServices} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/thermography-testing-insurance-ar">
            <ARThermographyTestingInsurance />
          </Route> */}
          <Route exact path="/thermography-testing-insurance-ar" 
            render={props => (
              <Page {...props} component={ARThermographyTestingInsurance} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/employee-wellness-program-ar">
            <AREmployeeWellnessProgram />
          </Route> */}
          <Route exact path="/employee-wellness-program-ar" 
            render={props => (
              <Page {...props} component={AREmployeeWellnessProgram} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/report-a-claim-ar">
            <ARReportaClaim />
          </Route> */}
          <Route exact path="/report-a-claim-ar" 
            render={props => (
              <Page {...props} component={ARReportaClaim} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/claim-consultancy-services-ar">
            <ARClaimConsultancyServices />
          </Route> */}
          <Route exact path="/claim-consultancy-services-ar" 
            render={props => (
              <Page {...props} component={ARClaimConsultancyServices} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/service-excellence-ar">
            <ARServiceExcellence />
          </Route> */}
          <Route exact path="/service-excellence-ar" 
            render={props => (
              <Page {...props} component={ARServiceExcellence} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/in-house-kr-automotive-garage-ar">
            <ARInhouseKRAutomotiveGarage />
          </Route> */}
          <Route exact path="/in-house-kr-automotive-garage-ar" 
            render={props => (
              <Page {...props} component={ARInhouseKRAutomotiveGarage} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          {/* <Route exact path="/erp-based-solutions-ar">
            <ARErpBasedSolutions />
          </Route> */}
          <Route exact path="/erp-based-solutions-ar" 
            render={props => (
              <Page {...props} component={ARErpBasedSolutions} title="‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬." />
            )}
          />
          
          <Route exact path="/coinowl" 
            render={props => (
              <Page {...props} component={CoinowlPage} title="" />
            )}
          />

          
          <Route exact path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </div>
    </div>
  </Router>
  </HelmetProvider>
  {/* <App /> */}

</React.StrictMode>, root);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
