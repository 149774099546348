import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './terrorismandpoliticalriskinsurance.scss';

const TerrorismandPoliticalRiskInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div className="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div className="dsk-tab-btn-viewmode-en">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1>Business Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-en">
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "property-insurance"}>Property Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "project-insurance"}>Project Insurance</div>
                <div className="inner-banner-btn-item-active-en" onClick={() => window.location.href = "terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "motor-fleet-insurance"}>Motor Fleet Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "group-health-insurance"}>Group Health Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "group-life-insurance"}>Group Life Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href ="burglary-insurance"}>Burglary Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "workmen-compensation-insurance"}>Workmen Compensation Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "group-personal-accident-insurance"}>Group Personal Accident Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "cyber-crime-insurance"}>Cyber Crime Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "jewellers-block-insurance"}>Jewellers Block Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "stock-throughput-insurance"}>Stock Throughput Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "liability-insurance"}>Liability Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "marine-insurance"}>Marine Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "energy-insurance"}>Energy Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="bdy-innercont-en">
            <div className="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-lft-en">
                <h2 className="pagetitle-en">Terrorism and Political Risk Insurance</h2>
                <p className="pg-txt-con">Terrorism, war and Political Risk exposures are increasing every day, and many industries face the serious threat of such incidents. KR Insurance Services offers coverage that will protect many types of businesses operating throughout the world. Terrorism and wider political violence policies are designed to pick up the gaps left behind by exclusions in property policies.Terrorism, war and Political Risk exposures are increasing every day, and many industries face the serious threat of such incidents. KR Insurance Services offers coverage that will protect many types of businesses operating throughout the world. Terrorism and wider political violence policies are designed to pick up the gaps left behind by exclusions in property policies.</p>

                <h3 className="terrpolirskinsu-page-sbu-title-en">Main Cover / Terrorism and Political Risk Insurance</h3>

              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/terrorism_and_political_risk_insurance.png"} alt="terrorism and political risk insurance" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

            <p className="pg-txt-con">Terrorism and political violence insurance covers an insured against physical loss and damage, as well as business interruption costs, as a result of a terrorist act or acts of political violence.</p>

            <p className="pg-txt-con">Political Risk refers to the negative consequences that result from the action or inaction of government. It is usually highest in developing countries where the economy or politics are unstable. Political Risk Insurance can mitigate the negative impact of these risks, and assist companies to grow their businesses.</p>

            <p className="pg-txt-con">Major exclusions are -</p>

            <div className="innbdy-keypoint-en">
              <div className="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="innbdy-keypoint-item-en">Loss or damage arising from nuclear, chemical, biological or radiological (NCBR) attacks, although limited and expensive capacity is available upon request</div>

              <div className="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="innbdy-keypoint-item-en">Loss or damage caused by confiscation, nationalization, or any result of any order of public or government authority which deprives the insured of the use or value of its property</div>

              <div className="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="innbdy-keypoint-item-en">Loss or damage by electronic means: cyber / computer hacking, although as with NCBR coverage, there are standalone products being developed</div>

              <div className="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="innbdy-keypoint-item-en">Business interruption as a result of a threat or hoax. Again, Lloyd’s insurers are working to develop products to fill this gap</div>

            </div>
            <h3 className="bdyinner-whykr-title-en">Why KR Insurance Services ?</h3>
            <div className="clearfix"></div>

            <div className="bdyinner-whykr-keypoints-en">
              <div className="bdyinner-whykr-kepoints-ftitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
              <div className="bdyinner-whykr-kepoints-secitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
              <div className="bdyinner-whykr-kepoints-ftitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
              <div className="bdyinner-whykr-kepoints-secitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
            </div>
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec">
                    <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
                  </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
      <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1>Business Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-en">
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "property-insurance"}>Property Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "project-insurance"}>Project Insurance</div>
                <div className="inner-banner-btn-item-active-en" onClick={() => window.location.href = "terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "motor-fleet-insurance"}>Motor Fleet Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "group-health-insurance"}>Group Health Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "group-life-insurance"}>Group Life Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href ="burglary-insurance"}>Burglary Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "workmen-compensation-insurance"}>Workmen Compensation Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "group-personal-accident-insurance"}>Group Personal Accident Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "cyber-crime-insurance"}>Cyber Crime Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "jewellers-block-insurance"}>Jewellers Block Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "stock-throughput-insurance"}>Stock Throughput Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "liability-insurance"}>Liability Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "marine-insurance"}>Marine Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "energy-insurance"}>Energy Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="tab-imgtxt-en">
            <h2>Terrorism and Political Risk Insurance</h2>
            <div className="tabs-cont-en">
              <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/terrorism_and_political_risk_insurance.png"} alt="terrorism and political risk insurance" />

              <p className="">Terrorism, war and Political Risk exposures are increasing every day, and many industries face the serious threat of such incidents. KR Insurance Services offers coverage that will protect many types of businesses operating throughout the world. Terrorism and wider political violence policies are designed to pick up the gaps left behind by exclusions in property policies.Terrorism, war and Political Risk exposures are increasing every day, and many industries face the serious threat of such incidents. KR Insurance Services offers coverage that will protect many types of businesses operating throughout the world. Terrorism and wider political violence policies are designed to pick up the gaps left behind by exclusions in property policies.</p>
              <h3 className="tab-sub-title-en">Main Cover / Terrorism and Political Risk Insurance</h3>

              <p className="pg-txt-con">Terrorism and political violence insurance covers an insured against physical loss and damage, as well as business interruption costs, as a result of a terrorist act or acts of political violence.</p>
              
              <p className="pg-txt-con">Political Risk refers to the negative consequences that result from the action or inaction of government. It is usually highest in developing countries where the economy or politics are unstable. Political Risk Insurance can mitigate the negative impact of these risks, and assist companies to grow their businesses.</p>
              
              <p className="pg-txt-con">Major exclusions are -</p>

              <div className="tabkyfet-en">
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Loss or damage arising from nuclear, chemical, biological or radiological (NCBR) attacks, although limited and expensive capacity is available upon request</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Loss or damage caused by confiscation, nationalization, or any result of any order of public or government authority which deprives the insured of the use or value of its property</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Loss or damage by electronic means: cyber / computer hacking, although as with NCBR coverage, there are standalone products being developed</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Business interruption as a result of a threat or hoax. Again, Lloyd’s insurers are working to develop products to fill this gap</div>
              </div>
              
              <h3 className="tab-whykrinsu-title-en">Why KR Insurance Services ?</h3>
              
              <div className="tab-whykrinsu-pins-list-en">
                <div className="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
                <div className="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
                <div className="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
                <div className="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
              </div>
              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec">
                <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
              </div>
              {/* ask for quotes button starts here */}
            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div className="inn-banner-bg-main-en hidden-desktop hidden-md-device hidden-lg">
        <div className="dropdown-sec">
          <select className="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"property-insurance"}>Property Insurance</option>
            <option value={"project-insurance"}>Project Insurance</option>
            <option value={"terrorism-and-political-risk-insurance"} selected className="active">Terrorism and Political Risk Insurance</option>
            <option value={"motor-fleet-insurance"}>Motor Fleet Insurance</option>
            <option value={"group-health-insurance"}>Group Health Insurance</option>
            <option value={"group-life-insurance"}>Group life Insurance</option>
            <option value={"fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</option>
            <option value={"burglary-insurance"}>Burglary Insurance</option>
            <option value={"workmen-compensation-insurance"}>Workmen Compensation Insurance</option>
            <option value={"group-personal-accident-insurance"}>Group Personal Accident Insurance</option>
            <option value={"cyber-crime-insurance"}>Cyber Crime Insurance</option>
            <option value={"jewellers-block-insurance"}>Jewellers Block Insurance</option>
            <option value={"stock-throughput-insurance"}>Stock Throughput Insurance</option>
            <option value={"hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</option>
            <option value={"speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</option>
            <option value={"liability-insurance"}>Liability Insurance</option>
            <option value={"marine-insurance"}>Marine Insurance</option>
            <option value={"energy-insurance"}>Energy Insurance</option>
          </select>
        </div>
        <div class="innner-mob-body-main-en">
          <h1 class="inner-mob-heading-en">Terrorism and Political Risk Insurance</h1>
          <div class="innner-mob-body-img-en">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/terrorism_and_political_risk_insurance.png"} alt="terrorism and political risk insurance" />
          </div>

          <p className="inner-mob-body-txt-en">Terrorism, war and Political Risk exposures are increasing every day, and many industries face the serious threat of such incidents. KR Insurance Services offers coverage that will protect many types of businesses operating throughout the world. Terrorism and wider political violence policies are designed to pick up the gaps left behind by exclusions in property policies.Terrorism, war and Political Risk exposures are increasing every day, and many industries face the serious threat of such incidents. KR Insurance Services offers coverage that will protect many types of businesses operating throughout the world. Terrorism and wider political violence policies are designed to pick up the gaps left behind by exclusions in property policies.</p>

          <h3 className="inner-mob-main-cover-title-en">Main Cover / Terrorism and Political Risk Insurance</h3>

          <p className="inner-mob-body-txt-en">Terrorism and political violence insurance covers an insured against physical loss and damage, as well as business interruption costs, as a result of a terrorist act or acts of political violence.</p>

          <p className="inner-mob-body-txt-en">Political Risk refers to the negative consequences that result from the action or inaction of government. It is usually highest in developing countries where the economy or politics are unstable. Political Risk Insurance can mitigate the negative impact of these risks, and assist companies to grow their businesses.</p>

          <p className="inner-mob-body-txt-en">Major exclusions are -</p>

          <div className="inner-mob-pins-list-en">
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Loss or damage arising from nuclear, chemical, biological or radiological (NCBR) attacks, although limited and expensive capacity is available upon request</div>

            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Loss or damage caused by confiscation, nationalization, or any result of any order of public or government authority which deprives the insured of the use or value of its property</div>

            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Loss or damage by electronic means: cyber / computer hacking, although as with NCBR coverage, there are standalone products being developed</div>

            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Business interruption as a result of a threat or hoax. Again, Lloyd’s insurers are working to develop products to fill this gap</div>
          </div>


          <h3 class="inner-mob-main-cover-title-en">Why KR Insurance Services ?</h3>

          <div class="inner-mob-bdy-whykrlist-en">
            <div class="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div class="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div class="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div class="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>
          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec">
                    <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
                  </div>
            {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default TerrorismandPoliticalRiskInsurance;