import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import { Helmet } from 'react-helmet-async';
import './armotorinsurance.scss';

const ARMotorInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
    <Helmet>
      <title>‫تأمين‬ | ‫عمان‬ ‫في‬ ‫به‬ ‫وموثوق‬ ‫به‬ ‫موثوق‬ ‫سيارات‬ ‫تأمين‬</title>
      <meta name="keywords" content="Trusted and Reliable Motor Insurance in Oman"/>
      <meta name="description" content="‫تقدم‬ .‫عمان‬ ‫في‬ ‫ت‬ ‫السيارا‬ ‫على‬ ‫شامل‬ ‫تأمين‬ ‫مع‬ ‫مركبتك‬ ‫احم‬ KR ‫اآلن‬ ‫اقتباس‬ ‫على‬ ‫احصل‬ .‫استثنائية‬ ‫وخدمة‬ ‫موثوقة‬ ‫تغطية‬ ‫للتأمين‬." /> 
    </Helmet>
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      {/* <div class="d-none d-md-block"> */}
      <div className="dsk-tab-btn-viewmode-ar">
        <div class="inn-banner-bg-main-en">
          <div class="inner-con-en">
            <div class="inner-banner-btn-en">
              <h1>التأمين الشخصي </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="inner-banner-btn-ar">
                <div class="inner-banner-btn-item-active-ar" onClick={() => window.location.href = "motor-insurance-ar"}>التأمين على السيارات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "travel-insurance-ar"}>التأمين أثناء السفر</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "personal-accident-ar"}>تأمين الحوادث الشخصية</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "relocation-insurance-ar"}>التأمين علي الانتقال</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "health-insurance-ar"}>التأمين الصحي</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "art-insurance-ar"}>التأمين الفني</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "golf-insurance-ar"}>التأمين على الجولف</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "housemaid-insurance-ar"}>تأمين العمالة المنزلية</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "yacht-insurance-ar"}>التأمين على اليخوت</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "home-insurance-ar"}>التأمين على المنازل</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "life-insurance-ar"}>التأمين على الحياة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="inner-con-en">
          <div class="bdy-innercont-en">
            <div class="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="innerbdy-dlex-lft-ar">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/motor_insurance_ar.png"} alt="التأمين على السيارات" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="innerbdy-dlex-rgt-ar">
                <h2 class="pagetitle-ar">التأمين على السيارات</h2>
                <p class="pg-txt-con-ar">أتفكِّر في شراء سيارة للمرّة الأولى، أم أنكَ لا زِلتَ راضيًا عن سيارتك الحالية وتودّ حمايتها بعمل بوليصة تأمين؟</p>

                <p class="pg-txt-con-ar">توفّر لكَ كيمجي رامداس لخدمات التأمين العديد من خدمات التأمين على السيارات، كما تقدّم بعض الميزات الفريدة من نوعها لبوليصات التأمين على السيارات. تشمل تلك الميزات الاختيار بين التغطية التأمينية الشاملة أو بوليصة التأمين للغير. إذا كنت تمتلك مركبة ذات قيمة عالية، فتساعدك كيمجي رامداس لخدمات التأمين في وضع خطة تأمينية مُصمَّمة خصيصًا، لتلاءم جميع احتياجاتك. تُتاح جميع تلك الخطط بأسعار معقولة بالإضافة إلى خدمات مضمونة ذات</p>

                <p class="pg-txt-con-ar">جودة عالية. تقدم كيمجي رامداس لخدمات التأمين الخدمات ذات القيمة المُضافة التالية</p>

                <div class="innbdy-rgt-keypoint-ar">
                  <div class="innbdy-rgt-keypoint-item-ar">خدمات التوصيل إلى باب المنزل – يمكن إعداد بوليصة التأمين للعملاء وتوصيلها حتى باب منزلك</div>
                  <div class="innbdy-rgt-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div class="innbdy-rgt-keypoint-item-ar">أسعار مخفضة شاملة لأساطيل السيارات الكبيرة</div>
                  <div class="innbdy-rgt-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div class="innbdy-rgt-keypoint-item-ar">خدمات الاستقبال المُميز في مرائبنا المفضلة للإصلاح</div>
                  <div class="innbdy-rgt-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div class="innbdy-rgt-keypoint-item-ar">كوبونات خصم لغسيل المركبات وصيانتها من خلال مرائب شبكتنا (اختياري)</div>
                  <div class="innbdy-rgt-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                </div>

              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
            <div class="bdyinner-keypoint-ar">
              <div class="bdyinner-keypoint-item-ar">توافر مركبات بديلة للاحتياجات الطارئة بمقابل مادي (بمقابل مادي)</div>
              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="bdyinner-keypoint-item-ar">مدير خدمات متخصص للمساعدة في توثيق المطالبات، وتسجيلها حتى الفصل فيها</div>
              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="bdyinner-keypoint-item-ar">نحن وسيط التأمين الوحيد الذي يوفّر مرائب سيارات داخلية والمرخصة من قبل العديد من شركات التأمين المرموقة في سلطنة عمان. يمكننا تقديم خدمات إصلاح عالية الجودة وسريعة لسيارتك. (مرآب مصنّف؛ ومعتمد من قِبل شرطة عمان السلطانية؛ ومن قِبل الجهة المسؤولة؛ ومركبات زارة الدفاع)</div>
              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            </div>

            <p class="pg-txt-con-ar">فضلًا عن تخصصنا في تصميم المنتجات المُعَدّة خصيصًا للمركبات ذات القيمة العالية، على سبيل المثال فيراري وأستون مارتن وبنتلي ورويس، إلخ، وشاحنات وناقلات وحافلات وسيارات صالون وسيارات الدفع الرباعي وأنواع خاصة أخرى من المركبات</p>

            <p class="pg-txt-con-ar">نحن نتحمل مسؤولية الفصل في المطالبات تحمُّلًا كاملًا، بدايةً من التنبيه بالمُطالبة، وحتى تسليم السيارة من المرائب</p>

            <h3 class="bdyinner-whykr-title-ar">التغطية الرئيسية/ التأمين على السيارات</h3>

            <p class="pg-txt-con-ar">المزايا الرئيسية للتغطية التأمينية على المركبات وما تشمله</p>
            <br />
            {/* QUESTION SECTION STARTS HERE */}
            <div class="bdyinner-keypoint-ar">
              <div class="bdyinner-keypoint-item-ar">التغطية التأمينية الشاملة أو التغطية التأمينية تجاه الغير</div>

              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="bdyinner-keypoint-item-ar">تغطية الأضرار التي تلحق بالغير - الأضرار التي تلحق بالممتلكات والإصابات الجسدية</div>

              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="bdyinner-keypoint-item-ar">الأضرار التي تلحق بالمركبة المؤمن عليها بسبب مخاطر مُعينة كحوادث الطرق والاصطدام والانقلاب والسرقة والإضرار المُتعمَّد والإشتعال والانفجار والكوارث الطبيعية</div>

              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="bdyinner-keypoint-item-ar">تغطية الحوادث الشخصية</div>

              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="bdyinner-keypoint-item-ar">توفير خدمة المساعدة على الطريق</div>

              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="bdyinner-keypoint-item-ar">خيارات إصلاح المركبة بالوكالة</div>

              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="bdyinner-keypoint-item-ar">تغطية قطع الغيار الهالكة</div>

              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="bdyinner-keypoint-item-ar">تغطية ما يلحق بالمركبة بفعل لطرق الوعرة</div>

              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="bdyinner-keypoint-item-ar">تغطية التكاليف الطبية</div>
              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            </div>
            {/* QUESTION SECTION ENDS HERE */}

            <h3 className="bdyinner-whykr-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>
            <div className="clearfix"></div>
            <div className="bdyinner-whykr-keypoints-ar">
              <div className="bdyinner-whykr-kepoints-secitem-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-whykr-kepoints-ftitem-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-whykr-kepoints-secitem-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div className="bdyinner-whykr-kepoints-ftitem-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>

            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
                    <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار </a>
                  </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div class="inn-banner-bg-main-en">
          <div class="inner-con-en">
            <div class="inner-banner-btn-en">
              <h1 class="motorinsu-banner-heading-ar">التأمين الشخصي </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="inner-banner-btn-ar">
              <div class="inner-banner-btn-item-active-ar" onClick={() => window.location.href = "motor-insurance-ar"}>التأمين على السيارات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "travel-insurance-ar"}>التأمين أثناء السفر</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "personal-accident-ar"}>تأمين الحوادث الشخصية</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "relocation-insurance-ar"}>التأمين علي الانتقال</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "health-insurance-ar"}>التأمين الصحي</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "art-insurance-ar"}>التأمين الفني</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "golf-insurance-ar"}>التأمين على الجولف</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "housemaid-insurance-ar"}>تأمين العمالة المنزلية</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "yacht-insurance-ar"}>التأمين على اليخوت</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "home-insurance-ar"}>التأمين على المنازل</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "life-insurance-ar"}>التأمين على الحياة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="inner-con-en">
          <div className="tab-imgtxt-ar">
                <h2>التأمين على السيارات</h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/motor_insurance_ar.png"} alt="motor insurance" />
              <p>أتفكِّر في شراء سيارة للمرّة الأولى، أم أنكَ لا زِلتَ راضيًا عن سيارتك الحالية وتودّ حمايتها بعمل بوليصة تأمين؟</p>
            <p>توفّر لكَ كيمجي رامداس لخدمات التأمين العديد من خدمات التأمين على السيارات، كما تقدّم بعض الميزات الفريدة من نوعها لبوليصات التأمين على السيارات. تشمل تلك الميزات الاختيار بين التغطية التأمينية الشاملة أو بوليصة التأمين للغير. إذا كنت تمتلك مركبة ذات قيمة عالية، فتساعدك كيمجي رامداس لخدمات التأمين في وضع خطة تأمينية مُصمَّمة خصيصًا، لتلاءم جميع احتياجاتك. تُتاح جميع تلك الخطط بأسعار معقولة بالإضافة إلى خدمات مضمونة ذات</p>
            <p>جودة عالية. تقدم كيمجي رامداس لخدمات التأمين الخدمات ذات القيمة المُضافة التالية</p>

            <div class="tabkyfet-ar">
              <div class="tabkeyfet-txt-ar">خدمات التوصيل إلى باب المنزل – يمكن إعداد بوليصة التأمين للعملاء وتوصيلها حتى باب منزلك</div>
              <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="tabkeyfet-txt-ar">أسعار مخفضة شاملة لأساطيل السيارات الكبيرة</div>
              <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="tabkeyfet-txt-ar">خدمات الاستقبال المُميز في مرائبنا المفضلة للإصلاح</div>
              <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="tabkeyfet-txt-ar">كوبونات خصم لغسيل المركبات وصيانتها من خلال مرائب شبكتنا (اختياري)</div>
              <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="tabkeyfet-txt-ar">توافر مركبات بديلة للاحتياجات الطارئة بمقابل مادي (بمقابل مادي)</div>
              <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="tabkeyfet-txt-ar">مدير خدمات متخصص للمساعدة في توثيق المطالبات، وتسجيلها حتى الفصل فيها</div>
              <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="tabkeyfet-txt-ar">نحن وسيط التأمين الوحيد الذي يوفّر مرائب سيارات داخلية والمرخصة من قبل العديد من شركات التأمين المرموقة في سلطنة عمان. يمكننا تقديم خدمات إصلاح عالية الجودة وسريعة لسيارتك (مرآب مصنّف؛ ومعتمد من قِبل شرطة عمان السلطانية؛ ومن قِبل الجهة المسؤولة؛ ومركبات زارة الدفاع)</div>
              <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            </div>

            <p>فضلًا عن تخصصنا في تصميم المنتجات المُعَدّة خصيصًا للمركبات ذات القيمة العالية، على سبيل المثال فيراري وأستون مارتن وبنتلي ورويس، إلخ، وشاحنات وناقلات وحافلات وسيارات صالون وسيارات الدفع الرباعي وأنواع خاصة أخرى من المركبات</p>

            <p>نحن نتحمل مسؤولية الفصل في المطالبات تحمُّلًا كاملًا، بدايةً من التنبيه بالمُطالبة، وحتى تسليم السيارة من المرائب</p>

            <h3 class="bdyinner-whykr-title-ar">التغطية الرئيسية/ التأمين على السيارات</h3>

            <p>المزايا الرئيسية للتغطية التأمينية على المركبات وما تشمله</p>

            <div class="tabkyfet-ar">
              <div class="tabkeyfet-txt-ar">التغطية التأمينية الشاملة أو التغطية التأمينية تجاه الغير</div>
              <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="tabkeyfet-txt-ar">Cover for third party damages - property damages & bodily injury</div>
              <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="tabkeyfet-txt-ar">الأضرار التي تلحق بالمركبة المؤمن عليها بسبب مخاطر مُعينة كحوادث الطرق والاصطدام والانقلاب والسرقة والإضرار المُتعمَّد والإشتعال والانفجار والكوارث الطبيعية</div>
              <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="tabkeyfet-txt-ar">تغطية الحوادث الشخصية</div>
              <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="tabkeyfet-txt-ar">توفير خدمة المساعدة على الطريق</div>
              <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="tabkeyfet-txt-ar">خيارات إصلاح المركبة بالوكالة</div>
              <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="tabkeyfet-txt-ar">تغطية قطع الغيار الهالكة</div>
              <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="tabkeyfet-txt-ar">تغطية ما يلحق بالمركبة بفعل لطرق الوعرة</div>
              <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div class="tabkeyfet-txt-ar">تغطية التكاليف الطبية</div>
              <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            </div>
            
            <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
            
            <div class="tab-whykrinsu-pins-list-ar">
              <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
            </div>

            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار </a>
            </div>
            {/* ask for quotes button starts here */}

            </div>
          </div>
          
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="inn-banner-bg-main-en hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"motor-insurance-ar"} selected class="active">التأمين على السيارات</option>
            <option value={"travel-insurance-ar"}>التأمين أثناء السفر</option>
            <option value={"personal-accident-ar"}>تأمين الحوادث الشخصية</option>
            <option value={"relocation-insurance-ar"}>التأمين علي الانتقال</option>
            <option value={"health-insurance-ar"}>التأمين الصحي</option>
            <option value={"art-insurance-ar"}>التأمين الفني</option>
            <option value={"golf-insurance-ar"}>التأمين على الجولف</option>
            <option value={"housemaid-insurance-ar"}>تأمين العمالة المنزلية</option>
            <option value={"home-insurance-ar"}>التأمين على المنازل</option>
            <option value={"yacht-insurance-ar"}>التأمين على اليخوت</option>
            <option value={"life-insurance-ar"}>التأمين على الحياة</option>
          </select>
        </div>
        <div class="innner-mob-body-main-en">
          <h1 class="inner-mob-heading-en">التأمين على السيارات</h1>
          <div class="innner-mob-body-img-en">
            <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/motor_insurance_ar.png"} alt="motor insurance" />
          </div>
          <p class="inner-mob-body-txt-ar">أتفكِّر في شراء سيارة للمرّة الأولى، أم أنكَ لا زِلتَ راضيًا عن سيارتك الحالية وتودّ حمايتها بعمل بوليصة تأمين؟</p>

          <p class="inner-mob-body-txt-ar">توفّر لكَ كيمجي رامداس لخدمات التأمين العديد من خدمات التأمين على السيارات، كما تقدّم بعض الميزات الفريدة من نوعها لبوليصات التأمين على السيارات. تشمل تلك الميزات الاختيار بين التغطية التأمينية الشاملة أو بوليصة التأمين للغير. إذا كنت تمتلك مركبة ذات قيمة عالية، فتساعدك كيمجي رامداس لخدمات التأمين في وضع خطة تأمينية مُصمَّمة خصيصًا، لتلاءم جميع احتياجاتك. تُتاح جميع تلك الخطط بأسعار معقولة بالإضافة إلى خدمات مضمونة ذات</p>

          <p class="inner-mob-body-txt-ar">جودة عالية. تقدم كيمجي رامداس لخدمات التأمين الخدمات ذات القيمة المُضافة التالية</p>

          <div class="inner-mob-pins-list-ar">
            <div class="inner-mob-pins-list-item-ar">خدمات التوصيل إلى باب المنزل – يمكن إعداد بوليصة التأمين للعملاء وتوصيلها حتى باب منزلك</div>
            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div class="inner-mob-pins-list-item-ar">أسعار مخفضة شاملة لأساطيل السيارات الكبيرة</div>
            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div class="inner-mob-pins-list-item-ar">خدمات الاستقبال المُميز في مرائبنا المفضلة للإصلاح</div>
            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div class="inner-mob-pins-list-item-ar">كوبونات خصم لغسيل المركبات وصيانتها من خلال مرائب شبكتنا (اختياري)</div>
            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div class="inner-mob-pins-list-item-ar">توافر مركبات بديلة للاحتياجات الطارئة بمقابل مادي (بمقابل مادي)</div>
            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div class="inner-mob-pins-list-item-ar">مدير خدمات متخصص للمساعدة في توثيق المطالبات، وتسجيلها حتى الفصل فيها</div>
            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div class="inner-mob-pins-list-item-ar">نحن وسيط التأمين الوحيد الذي يوفّر مرائب سيارات داخلية والمرخصة من قبل العديد من شركات التأمين المرموقة في سلطنة عمان. يمكننا تقديم خدمات إصلاح عالية الجودة وسريعة لسيارتك. (مرآب مصنّف؛ ومعتمد من قِبل شرطة عمان السلطانية؛ ومن قِبل الجهة المسؤولة؛ ومركبات زارة الدفاع)</div>
            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
          </div>

          <p class="inner-mob-body-txt-ar">فضلًا عن تخصصنا في تصميم المنتجات المُعَدّة خصيصًا للمركبات ذات القيمة العالية، على سبيل المثال فيراري وأستون مارتن وبنتلي ورويس، إلخ، وشاحنات وناقلات وحافلات وسيارات صالون وسيارات الدفع الرباعي وأنواع خاصة أخرى من المركبات</p>

          <p class="inner-mob-body-txt-ar">نحن نتحمل مسؤولية الفصل في المطالبات تحمُّلًا كاملًا، بدايةً من التنبيه بالمُطالبة، وحتى تسليم السيارة من المرائب</p>

          <h3 class="motorinsu-subtitle-ar">التغطية الرئيسية/ التأمين على السيارات</h3>

          <p class="inner-mob-body-txt-ar">المزايا الرئيسية للتغطية التأمينية على المركبات وما تشمله</p>

          {/* QUESTION SECTION STARTS HERE */}
          <div class="inner-mob-pins-list-ar">
            <div class="inner-mob-pins-list-item-ar">التغطية التأمينية الشاملة أو التغطية التأمينية تجاه الغير</div>
            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div class="inner-mob-pins-list-item-ar">تغطية الأضرار التي تلحق بالغير - الأضرار التي تلحق بالممتلكات والإصابات الجسدية</div>
            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div class="inner-mob-pins-list-item-ar">الأضرار التي تلحق بالمركبة المؤمن عليها بسبب مخاطر مُعينة كحوادث الطرق والاصطدام والانقلاب والسرقة والإضرار المُتعمَّد والإشتعال والانفجار والكوارث الطبيعية</div>
            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div class="inner-mob-pins-list-item-ar">تغطية الحوادث الشخصية</div>
            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div class="inner-mob-pins-list-item-ar">توفير خدمة المساعدة على الطريق</div>
            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div class="inner-mob-pins-list-item-ar">خيارات إصلاح المركبة بالوكالة</div>
            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div class="inner-mob-pins-list-item-ar">تغطية قطع الغيار الهالكة</div>
            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div class="inner-mob-pins-list-item-ar">تغطية ما يلحق بالمركبة بفعل لطرق الوعرة</div>
            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div class="inner-mob-pins-list-item-ar">تغطية التكاليف الطبية</div>
            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
          </div>
          {/* QUESTION SECTION ENDS HERE */}

          <h3 className="inner-mob-main-cover-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>

          <div className="inner-mob-bdy-whykrlist-ar">
            <div className="inner-mob-bdy-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar"> أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>

          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec-ar">
                    <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار </a>
                  </div>
            {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARMotorInsurance;