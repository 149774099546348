import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import './footer.scss';

const FooterEN = () => {

  return (
    <div>
      {/* DESKTOP FOOTER STARTS HERE  */}
      <div class="kr-footer-en d-none d-md-none d-lg-block">
        <div class="ft-wdcon-en">
          <div class="ft-con-sec-en">
            <div class="ft-con-itemone-en">
              <p class="pb-3 desc-en">We focus on the delivery of best insurance<br /> services with wider insurance coverage,<br /> defining risk management programs and <br />bringing cost benefits to our customers. </p>

              <div class="smedia-en">
                <a href="https://www.linkedin.com/company/khimji-ramdas-insurance-services/?viewAsMember=true" target="_blank"><img src={process.env.PUBLIC_URL + "/footer_images/in.png"} alt="Linedin Icon" width="23" height="22" /></a>
                <a href="https://www.instagram.com/gettameen/" target="_blank"><img src={process.env.PUBLIC_URL + "/footer_images/insta.png"} alt="Instagram Icon" width="23" height="23" /></a>
                <a href="https://www.facebook.com/Gettameencom-108908225267493" target="_blank"><img src={process.env.PUBLIC_URL + "/footer_images/fb.png"} alt="Facebook Icon" width="23" height="23" /></a>
              </div>
            </div>
            <div class="ft-con-itemtwo-en">
              <div class="footer-links-en">
                <a href="/">Home</a>
                <a href="/aboutus">About Us</a>
                <a href="/our-insurance-solutions">Our Insurance Solutions</a>
                <a href="/team">Our Team</a>
                <a href="/contactus">Contact Us</a>
                <a href="/privacy-policy">Privacy Policy</a>
              </div>
            </div>
            <div class="ft-con-itemthree-en">
              <p class="address-en">Khimji Ramdas Insurance Services LLC,<br />
                Box 19, PC 100, Muscat Sultanate Oman<br />
                Mail - <a href="mailto:insurance@kr.om">insurance@kr.om</a><br />
                Tel: <a href="tel:+968 93879145">+968 93879145</a>
              </p>
              <a class="get-direction-en" href="https://www.google.com/maps/place/Khimji+Ramdas+Insurance+Services+LLC/@23.5972628,58.5378933,17z/data=!3m1!4b1!4m5!3m4!1s0x3e91f9e718b31f1b:0x8f9b5a9a6f457d55!8m2!3d23.5972629!4d58.540082" target="_blank"><img src={process.env.PUBLIC_URL + "/homepage_images/map.png"} alt="map icon" width="30" height="29" /><br />Get Directions</a>
            </div>
          </div>
        </div>
        <div class="d-block">
          <p class="copywrights-en">Copyright @ Khimji Ramdas Insurance Services. All Rights Reserved</p>
        </div>
      </div>
      {/* DESKTOP FOOTER ENDS HERE  */}

      {/* TAB FOOTER STARTS HERE  */}
      <div class="kr-tabfooter-en d-none d-md-block d-lg-none">
        <div class="tab-ft-con-sec-en">
          <div class="tabft-con-itemone-en"> We focus on the delivery of best insurance services with wider insurance coverage, defining risk management programs and bringing cost benefits to our customers.

            <div class="tabmediaicon-en">
              <a href="https://www.linkedin.com/company/khimji-ramdas-insurance-services/?viewAsMember=true" target="_blank">
                <img src={process.env.PUBLIC_URL + "/footer_images/in.png"} alt="Linkedin Icon" width="23" height="22" />
              </a>
              <a href="https://www.instagram.com/gettameen/" target="_blank">
                <img src={process.env.PUBLIC_URL + "/footer_images/insta.png"} alt="Instagram Icon" width="23" height="23" />
              </a>
              <a href="https://www.facebook.com/Gettameencom-108908225267493" target="_blank">
                <img src={process.env.PUBLIC_URL + "/footer_images/fb.png"} alt="Facebook Icon" width="23" height="23" />
              </a>
            </div>
          </div>
          <div class="tabftlinks-en">
            <a href="/">Home</a>
            <a href="/aboutus">About Us</a>
            <a href="/our-insurance-solutions">Our Insurance Solutions</a>
            <a href="/team">Our Team</a>
            <a href="/contactus">Contact Us</a>
            <a href="/privacy-policy">Privacy Policy</a>
          </div>
          <div class="ft-con-itemthree-en">
            <p class="address-en">Khimji Ramdas Insurance Services LLC,<br />
              Box 19, PC 100, Muscat Sultanate Oman<br />
              Mail - <a href="mailto:insurance@kr.om">insurance@kr.om</a><br />
              Tel: <a href="tel:+968 93879145">+968 93879145</a>
            </p>
            <a class="get-direction-en" href="https://www.google.com/maps/place/Khimji+Ramdas+Insurance+Services+LLC/@23.5972628,58.5378933,17z/data=!3m1!4b1!4m5!3m4!1s0x3e91f9e718b31f1b:0x8f9b5a9a6f457d55!8m2!3d23.5972629!4d58.540082" target="_blank" alt="map icon"><img src={process.env.PUBLIC_URL + "/homepage_images/map.png"} alt="Map Icon" width="30" height="29" /><br />Get Directions</a>
          </div>

          <div className="cleafix"></div>
          <div class="tabcw-en">Copyright @ Khimji Ramdas Insurance Services. All Rights Reserved</div>
        </div>
      </div>
      {/* TAB FOOTER ENDS HERE  */}


      {/* MOBILE FOOTER STARTS HERE  */}
      <div class="kr-mbfooter-en d-block d-sm-none">
        <img src="https://cdn.insuranceinbox.com/omanbr/footer_images/footer_bg2.png" className="mob-ftimg" alt="footerimg" />
        <div class="ft-mobwdcon-en">
          <div class="mobft-con-sec-en">
            <div class="mobft-con-itemone-en">
              We focus on the delivery of best insurance services with wider insurance coverage, defining risk management programs and  bringing cost benefits to our customers.
            </div>
            <div class="mobsmedia-en">
              <a href="https://www.linkedin.com/company/khimji-ramdas-insurance-services/?viewAsMember=true" target="_blank"><img src={process.env.PUBLIC_URL + "/footer_images/in.png"} alt="Linkedin Icon" width="23" height="22" /></a>
              <a href="https://www.instagram.com/gettameen/" target="_blank"><img src={process.env.PUBLIC_URL + "/footer_images/insta.png"} alt="Instagram Icon" width="23" height="23" /></a>
              <a href="https://www.facebook.com/Gettameencom-108908225267493" alt="Facebook Icon" target="_blank"><img src={process.env.PUBLIC_URL + "/footer_images/fb.png"} alt="Facebook Icon" width="23" height="23" /></a>
            </div>
          </div>

          <div className="mobft-lnk-add">
            <div className="mobftlnkadd-item">
              <a href="/">Home</a>
              <a href="/aboutus">About Us</a>
              <a href="/our-insurance-solutions">Our Insurance Solutions</a>
              <a href="/team">Our Team</a>
              <a href="/contactus">Contact Us</a>
              <a href="/privacy-policy">Privacy Policy</a>
            </div>
            <div className="mobftlnkadd-additem">
              Khimji Ramdas Insurance Services LLC,<br />
              Box 19, PC 100, Muscat Sultanate Oman<br />
              Mail - <a href="mailto:insurance@kr.om">insurance@kr.om</a> Tel: <a href="tel:+968 93879145">+968 93879145</a>

              <a class="mobget-direction-en" href="https://www.google.com/maps/place/Khimji+Ramdas+Insurance+Services+LLC/@23.5972628,58.5378933,17z/data=!3m1!4b1!4m5!3m4!1s0x3e91f9e718b31f1b:0x8f9b5a9a6f457d55!8m2!3d23.5972629!4d58.540082" target="_blank"><img src={process.env.PUBLIC_URL + "/homepage_images/map.png"} width="30" height="29" alt="Map Icon" /> Get Directions</a>

            </div>
          </div>
          <div class="ft-con-itemthree-en"><p class="address-en"></p></div>
        </div>
        <p class="mobcopywrights-en">Copyright @ Khimji Ramdas Insurance Services.<br />All Rights  Reserved</p>
      </div>
      {/* MOBILE FOOTER ENDS HERE  */}

    </div>
  );
}
export default FooterEN;