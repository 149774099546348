import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './hauliersfullvaluecargoinsurance.scss';

const HauliersFullValueCargoInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-en">
        <div class="inn-banner-bg-main-en">
          <div class="inner-con-en">
            <div class="inner-banner-btn-en">
              <h1>Business Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="inner-banner-btn-en">
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "property-insurance"}>Property Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "project-insurance"}>Project Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "motor-fleet-insurance"}>Motor Fleet Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "group-health-insurance"}>Group Health Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "group-life-insurance"}>Group Life Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "burglary-insurance"}>Burglary Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "workmen-compensation-insurance"}>Workmen Compensation Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "group-personal-accident-insurance"}>Group Personal Accident Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "cyber-crime-insurance"}>Cyber Crime Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "jewellers-block-insurance"}>Jewellers Block Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "stock-throughput-insurance"}>Stock Throughput Insurance</div>
                <div class="inner-banner-btn-item-active-en" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "liability-insurance"}>Liability Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "marine-insurance"}>Marine Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "energy-insurance"}>Energy Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="inner-con-en">
          <div class="bdy-innercont-en">
            <div class="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="innerbdy-dlex-lft-en">
                <h2 class="pagetitle-en">Hauliers Full Value Cargo Insurance</h2>
                <p class="pg-txt-con">The purpose of this policy is to provide protection cover to the haulier/carrier for loss or damage to goods handled for customers in their capacity as haulier (or freight forwarder).</p>

                <p class="pg-txt-con">A special insurance product which caters for small and medium sized hauliers operating with or without specific contracts of carriage transporting goods by truck within the GCC area.  It provides full value cover against loss or damage to goods whilst in the Haulier’s care, custody and control. </p>

                <p class="pg-txt-con">Competitive rates apply to annual haulage charges declared on monthly bordereaux.</p>

                <p class="pg-txt-con">The policy covers the delay caused due to blockage of roads and adverse weather conditions. It also covers the loss settled upto the full value of goods.</p>

              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="innerbdy-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/hauliers_full_value_cargo_insurance.png"} alt="Hauliers Full Value Cargo Insurance" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

            <h3 class="bdyinner-heading-en">Main Cover / Hauliers Full Value Cargo Insurance</h3>

            <p class="pg-txt-con">Key Points of Haulier's Legal Liability Insurance Cover</p>

            <div class="bdyinner-keypoint-en">
              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">Liabilities for loss of, or damage to, cargo</div>

              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">Liabilities arising from errors and omissions including delay and unauthorized delivery</div>

              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">Third party liabilities</div>

              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">Fines for regulatory breach such as customs, pollution and safety at work</div>

              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">Investigation, defense and mitigation costs</div>

              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">Disposal costs following an accident</div>

              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">Quarantine and disinfection costs</div>
            </div>

            <p class="pg-txt-con"><strong>Additional cover</strong></p>

            <div class="bdyinner-keypoint-en">
              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">Additional cover for logistics operators can include:</div>

              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">Business interruption risks</div>

              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">FMC bonds for NVOCs</div>

              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">General average and salvage guarantees and contributions</div>

              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">Loss of or damage to owned or leased equipment</div>

              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">Property insurance such as buildings and contents, machinery and handling equipment</div>

              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">Road and rail infrastructure</div>

              <div class="bdyinner-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-keypoint-item-en">Ships agency liabilities</div>
            </div>

            <h3 class="bdyinner-whykr-title-en">Why KR Insurance Services ?</h3>

            <div class="clearfix"></div>

            <div class="bdyinner-whykr-keypoints-en">
              <div class="bdyinner-whykr-kepoints-ftitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
              <div class="bdyinner-whykr-kepoints-secitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
              <div class="bdyinner-whykr-kepoints-ftitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
              <div class="bdyinner-whykr-kepoints-secitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
            </div>

            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec">
              <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
            </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
        <div class="inn-banner-bg-main-en">
          <div class="inner-con-en">
            <div class="inner-banner-btn-en">
              <h1 class="hauvalcarinsu-banner-heading-en">Business Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="inner-banner-btn-en">
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "property-insurance"}>Property Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "project-insurance"}>Project Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "motor-fleet-insurance"}>Motor Fleet Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "group-health-insurance"}>Group Health Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "group-life-insurance"}>Group Life Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "burglary-insurance"}>Burglary Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "workmen-compensation-insurance"}>Workmen Compensation Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "group-personal-accident-insurance"}>Group Personal Accident Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "cyber-crime-insurance"}>Cyber Crime Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "jewellers-block-insurance"}>Jewellers Block Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "stock-throughput-insurance"}>Stock Throughput Insurance</div>
                <div class="inner-banner-btn-item-active-en" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "liability-insurance"}>Liability Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "marine-insurance"}>Marine Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "energy-insurance"}>Energy Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="inner-con-en">
          <div className="tab-imgtxt-en">
            <h2>Hauliers Full Value Cargo Insurance</h2>
            <div className="tabs-cont-en">
              <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/hauliers_full_value_cargo_insurance.png"} alt="Hauliers Full Value Cargo Insurance" />
              <p>The purpose of this policy is to provide protection cover to the haulier/carrier for loss or damage to goods handled for customers in their capacity as haulier (or freight forwarder).</p>

              <p>A special insurance product which caters for small and medium sized hauliers operating with or without specific contracts of carriage transporting goods by truck within the GCC area.  It provides full value cover against loss or damage to goods whilst in the Haulier's care, custody and control. </p>

              <p>Competitive rates apply to annual haulage charges declared on monthly bordereaux.</p>

              <p>The policy covers the delay caused due to blockage of roads and adverse weather conditions. It also covers the loss settled upto the full value of goods.</p>

              <h3 className="tab-sub-title-en">Main Cover / Hauliers Full Value Cargo Insurance</h3>

              <p>Key Points of Haulier's Legal Liability Insurance Cover</p>
              
              <div class="tabkyfet-en">
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Liabilities for loss of, or damage to, cargo</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Liabilities arising from errors and omissions including delay and unauthorized delivery</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Third party liabilities</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Fines for regulatory breach such as customs, pollution and safety at work</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Investigation, defense and mitigation costs</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Disposal costs following an accident</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Quarantine and disinfection costs</div>
              </div>
              
              <p><strong>Additional cover</strong></p>
              
              <div class="tabkyfet-en">
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Additional cover for logistics operators can include:</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Business interruption risks</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">FMC bonds for NVOCs</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">General average and salvage guarantees and contributions</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Loss of or damage to owned or leased equipment</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Property insurance such as buildings and contents, machinery and handling equipment</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Road and rail infrastructure</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en">Ships agency liabilities</div>
              </div>

              <h3 class="tab-whykrinsu-title-en">Why KR Insurance Services ?</h3>
              
              <div class="tab-whykrinsu-pins-list-en">
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
              </div>
              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec">
                <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
              </div>
              {/* ask for quotes button starts here */}

            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="inn-banner-bg-main-en hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"property-insurance"}>Property Insurance</option>
            <option value={"project-insurance"}>Project Insurance</option>
            <option value={"terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</option>
            <option value={"motor-fleet-insurance"}>Motor Fleet Insurance</option>
            <option value={"group-health-insurance"}>Group Health Insurance</option>
            <option value={"group-life-insurance"}>Group life Insurance</option>
            <option value={"fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</option>
            <option value={"burglary-insurance"}>Burglary Insurance</option>
            <option value={"workmen-compensation-insurance"}>Workmen Compensation Insurance</option>
            <option value={"group-personal-accident-insurance"}>Group Personal Accident Insurance</option>
            <option value={"cyber-crime-insurance"}>Cyber Crime Insurance</option>
            <option value={"jewellers-block-insurance"}>Jewellers Block Insurance</option>
            <option value={"stock-throughput-insurance"}>Stock Throughput Insurance</option>
            <option value={"hauliers-full-value-cargo-insurance"} selected class="active">Hauliers Full Value Cargo Insurance</option>
            <option value={"speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</option>
            <option value={"liability-insurance"}>Liability Insurance</option>
            <option value={"marine-insurance"}>Marine Insurance</option>
            <option value={"energy-insurance"}>Energy Insurance</option>
          </select>
        </div>
        <div class="innner-mob-body-main-en">
          <h1 class="inner-mob-heading-en">Hauliers Full Value Cargo Insurance</h1>
          <div class="innner-mob-body-img-en">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/hauliers_full_value_cargo_insurance.png"} alt="Hauliers Full Value Cargo Insurance" />
          </div>

          <p class="inner-mob-body-txt-en">The purpose of this policy is to provide protection cover to the haulier/carrier for loss or damage to goods handled for customers in their capacity as haulier (or freight forwarder).</p>

          <p class="inner-mob-body-txt-en">A special insurance product which caters for small and medium sized hauliers operating with or without specific contracts of carriage transporting goods by truck within the GCC area. It provides full value cover against loss or damage to goods whilst in the Haulier’s care, custody and control.</p>

          <p class="inner-mob-body-txt-en">Competitive rates apply to annual haulage charges declared on monthly bordereaux.</p>

          <p class="inner-mob-body-txt-en">The policy covers the delay caused due to blockage of roads and adverse weather conditions. It also covers the loss settled upto the full value of goods.</p>

          <h3 class="hauvalcarinsu-subtitle-en">Main Cover / Hauliers Full Value Cargo Insurance</h3>

          <p class="inner-mob-body-txt-en">Key Points of Haulier's Legal Liability Insurance Cover</p>

          <div class="inner-mob-pins-list-en">
            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Liabilities for loss of, or damage to, cargo</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Liabilities arising from errors and omissions including delay and unauthorized delivery</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Third party liabilities</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Fines for regulatory breach such as customs, pollution and safety at work</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Investigation, defense and mitigation costs</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Disposal costs following an accident</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Quarantine and disinfection costs</div>
          </div>

          <p class="inner-mob-body-txt-en"><strong>Additional cover</strong></p>

          <div class="inner-mob-pins-list-en">
            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Additional cover for logistics operators can include:</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Business interruption risks</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">FMC bonds for NVOCs</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">General average and salvage guarantees and contributions</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Loss of or damage to owned or leased equipment</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Property insurance such as buildings and contents, machinery and handling equipment</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Road and rail infrastructure</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Ships agency liabilities</div>
          </div>

          <h3 class="inner-mob-main-cover-title-en">Why KR Insurance Services ?</h3>
          
          <div class="inner-mob-bdy-whykrlist-en">
            <div class="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div class="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div class="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div class="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>
          
          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec">
            <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
          </div>
          {/* ask for quotes button starts here */}

        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default HauliersFullValueCargoInsurance;