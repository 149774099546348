import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './valuationservices.scss';

const ValuationServices = () => {

  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }

  }, [])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div className="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div className="d-none d-md-block">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1 className="valuservices-banner-heading-en">Valued Services</h1>

              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-en">
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "risk-inspection-services"}>Risk Inspection Services</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "insurance-audits"}>Insurance Audits</div>
                <div className="inner-banner-btn-item-active-en" onClick={() => window.location.href = "valuation-services"}>Valuation services</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "thermography-testing-insurance"}>Thermography Testing</div>
                {/* <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "policy-renewalalerts"}>Policy Renewal Alerts</div> */}
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "employee-wellness-program"}>Employee Wellness Program</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="bdy-innercont-en">
            <div className="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-lft-en">
                <h2 className="pagetitle-en">Valuation services</h2>

                <p className="pg-txt-con">We offer valuation services to support your organizations efforts to provide accurate financial data for insurance coverage purposes, so that the recovery funds meet with recovery needs post-event.</p>

                <p className="pg-txt-con">With our broad industry experience and with professionals who understand our client’s business, we offer time and cost effective valuation services to our clients.</p>

                <p className="pg-txt-con">We at KR Insurance Services, look forward to provide you with our valuation services to optimize your insurance covers.</p>

                <h3 className="bdyinner-heading-en">Why KR Insurance Services ?</h3>
                <div className="clearfix"></div>

                <div className="bdyinner-lft-whykr-keypoints-en">
                  <div className="bdyinner-whykr-kepoints-ftitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
                  <div className="bdyinner-lft-whykr-kepoints-secitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
                  <div className="bdyinner-whykr-kepoints-ftitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
                  <div className="bdyinner-lft-whykr-kepoints-secitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
                </div>

              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/riskmanagementservices_images/tab_images/valuation_services.png"} alt="valuation services" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div className="inn-banner-bg-main-en hidden-desktop hidden-md-device hidden-lg">
        <div className="dropdown-sec">
          <select className="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"risk-inspection-services"}>Risk Inspection Services</option>
            <option value={"insurance-audits"}>Insurance Audits</option>
            <option value={"valuation-services"} selected className="active">Valuation Services</option>
            <option value={"thermography-testing-insurance"}>Thermography Testing</option>
            {/* <option value={"policy-renewalalerts"}>Policy Renewal Alerts</option> */}
            <option value={"employee-wellness-program"}>Employee Wellness Program</option>
          </select>
        </div>
        <div className="innner-mob-body-main-en">
          <h1 className="inner-mob-heading-en">Valuation Services</h1>
          <div className="innner-mob-body-img-en">
            <img src={process.env.PUBLIC_URL + "/riskmanagementservices_images/tab_images/valuation_services.png"} alt="valuation services" />
          </div>

          <p className="inner-mob-body-txt-en">We offer valuation services to support your organizations efforts to provide accurate financial data for insurance coverage purposes, so that the recovery funds meet with recovery needs post-event.</p>

          <p className="inner-mob-body-txt-en">With our broad industry experience and with professionals who understand our client’s business, we offer time and cost effective valuation services to our clients.</p>

          <p className="inner-mob-body-txt-en">We at KR Insurance Services, look forward to provide you with our valuation services to optimize your insurance covers.</p>

          <h3 className="inner-mob-main-cover-title-en">Why KR Insurance Services ?</h3>

          <div className="inner-mob-bdy-whykrlist-en">
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>
          
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ValuationServices;
