import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './artinsurance.scss';

const ArtInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }

  }, [])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div className="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div className="dsk-tab-btn-viewmode-en">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1>Personal Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-en">
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "motor-insurance"}>Motor Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "travel-insurance"}>Travel Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "personal-accident"}>Personal Accident Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "relocation-insurance"}>Relocation Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "health-insurance"}>Health Insurance</div>
                <div className="inner-banner-btn-item-active-en" onClick={() => window.location.href = "art-insurance"}>Art insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "golf-insurance"}>Golf Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "housemaid-insurance"}>Housemaid Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "yacht-insurance"}>Yacht Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "home-insurance"}>Home Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "life-insurance"}>Life Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="bdy-innercont-en">
            <div className="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-lft-en">
                <h2 className="pagetitle-en">Art Insurance</h2>
                <p className="pg-txt-con">Art is something that touches your heart, but your mind races through worries about what would happen if the artwork is damaged. It could be your household contents, collectables and musical instruments. Wondering what to do if they are destroyed and how to bear the restoration expenses?</p>

                <h3 className="bdyinner-heading-en">Main Cover / Art Insurance</h3>

                <p className="pg-txt-con">KR INSURANCE SERVICES offers a coverage plan designed for collector’s dealers and other connoisseurs of Arts. This plan provides an extensive coverage and is something that every art lover must possess.</p>

                <h3 className="bdyinner-whykr-title-en">Why KR Insurance Services ?</h3>

            <div className="clearfix"></div>

            <div className="bdyinner-lft-whykr-keypoints-en">
              <div className="bdyinner-whykr-kepoints-ftitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
              <div className="bdyinner-lft-whykr-kepoints-secitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
              <div className="bdyinner-whykr-kepoints-ftitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
              <div className="bdyinner-lft-whykr-kepoints-secitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
            </div>

                {/* ask for quotes button starts here */}
                <div className="askquto-btn-sec">
                  <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
                </div>
                {/* ask for quotes button starts here */}
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/art_insurance.png"} alt="art insurance" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
            {/* <div className="artinsu-pins-list-en">
                  <div className="artinsu-lt-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
                  <div className="artinsu-lt-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
                  <div className="artinsu-lt-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
                  <div className="artinsu-lt-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
                </div> */}

          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1 className="artinsu-banner-heading-en">Personal Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-en">
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "motor-insurance"}>Motor Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "travel-insurance"}>Travel Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "personal-accident"}>Personal Accident Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "relocation-insurance"}>Relocation Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "health-insurance"}>Health Insurance</div>
                <div className="inner-banner-btn-item-active-en" onClick={() => window.location.href = "art-insurance"}>Art insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "golf-insurance"}>Golf Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "housemaid-insurance"}>Housemaid Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "yacht-insurance"}>Yacht Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "home-insurance"}>Home Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "life-insurance"}>Life Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="tab-imgtxt-en">
            <h2>Art Insurance</h2>
            <div className="tabs-cont-en">
              <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/art_insurance.png"} alt="art insurance" />

              <p>Art is something that touches your heart, but your mind races through worries about what would happen if the artwork is damaged. It could be your household contents, collectables and musical instruments. Wondering what to do if they are destroyed and how to bear the restoration expenses?</p>

              <p className="tab-subhead-title-en">Main Cover / Art Insurance</p>

              <p>KR INSURANCE SERVICES offers a coverage plan designed for collector's dealers and other connoisseurs of Arts. This plan provides an extensive coverage and is something that every art lover must possess.</p>

              <h3 className="tab-whykrinsu-title-en">Why KR Insurance Services ?</h3>

              <div className="tab-whykrinsu-pins-list-en">
                <div className="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
                <div className="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
                <div className="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
                <div className="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
              </div>
              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec">
                <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
              </div>
              {/* ask for quotes button starts here */}
            </div>
          </div>

        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div className="inn-banner-bg-main-en hidden-desktop hidden-md-device hidden-lg">
        <div className="dropdown-sec">
          <select className="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"motor-insurance"}>Motor Insurance</option>
            <option value={"travel-insurance"}>Travel Insurance</option>
            <option value={"personal-accident"}>Personal Accident Insurance</option>
            <option value={"relocation-insurance"}>Relocation Insurance</option>
            <option value={"health-insurance"}>Health Insurance</option>
            <option value={"art-insurance"} selected className="active">Art insurance</option>
            <option value={"golf-insurance"}>Golf Insurance</option>
            <option value={"housemaid-insurance"}>Housemaid Insurance</option>
            <option value={"home-insurance"}>Home Insurance</option>
            <option value={"yacht-insurance"}>Yacht Insurance</option>
            <option value={"life-insurance"}>Life Insurance</option>
          </select>
        </div>
        <div className="innner-mob-body-main-en">
          <h1 className="inner-mob-heading-en">Art Insurance</h1>
          <div className="innner-mob-body-img-en">
            <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/art_insurance.png"} alt="art insurance" />
          </div>
          <p className="inner-mob-body-txt-en">Art is something that touches your heart, but your mind races through worries about what would happen if the artwork is damaged. It could be your household contents, collectables and musical instruments. Wondering what to do if they are destroyed and how to bear the restoration expenses?</p>

          <h3 className="inner-mob-main-cover-title-en">Main Cover / Art Insurance</h3>

          <p className="inner-mob-body-txt-en">KR INSURANCE SERVICES offers a coverage plan designed for collector’s dealers and other connoisseurs of Arts. This plan provides an extensive coverage and is something that every art lover must possess.</p>

          <h3 className="inner-mob-main-cover-title-en">Why KR Insurance Services ?</h3>

          <div className="inner-mob-bdy-whykrlist-en">
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>

          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec">
            <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
          </div>
          {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ArtInsurance;