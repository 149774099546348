import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './serviceexcellence.scss';

const ServiceExcellence = () => {

  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }

  }, [])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div className="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div className="dsk-tab-btn-viewmode-en">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1>Customer Services</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-en">
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "report-a-claim"}>Report a Claim</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "claim-consultancy-services"}>Claim Consultancy Services</div>
                <div className="inner-banner-btn-item-active-en" onClick={() => window.location.href = "service-excellence"}>Service Excellence</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "in-house-kr-automotive-garage"}>In house KR Automotive Garage</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "erp-based-solutions"}>ERP Based Solutions</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="bdy-innercont-en">
            {/* <h2 className="pagetitle-en">Excellence in Service Delivery</h2> */}
            <div className="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-lft-en">
                <h2 className="pagetitle-en">Service Excellence</h2>

                <p className="pg-txt-con">KR Insurance Services has got functional expertise into advisory services, underwriting, expert claims servicing and client retention services to ensure increased efficiency in the deliverables -  </p>

                <div className="innbdy-lft-keypoint-en">
                  <div className="innbdy-lft-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-lft-keypoint-item-en"><u>Advisory services</u> aim to achieve customer delight through dedicated client relationship management, risk inspections, policy audit, route surveys, wellness programs, credit reports renewal reminders etc.</div>

                  <div className="innbdy-lft-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-lft-keypoint-item-en"><u>Specialist Underwriting</u> teams to arrange the best terms from insurers and re-insurers, ensuring complete cover of risk in hand.</div>

                  <div className="innbdy-lft-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-lft-keypoint-item-en"><u>Expert Claims servicing</u> teams work towards smooth claim settlement across business lines ensuring business continuity.</div>

                  <div className="innbdy-lft-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-lft-keypoint-item-en"><u>Dedicated Retention team</u> for exceeding client’s service expectations and retaining clients with us on a year to year basis.</div>
                </div>

                <h3 className="bdyinner-heading-en">Why KR Insurance Services ?</h3>
                <div className="clearfix"></div>

                <div className="bdyinner-lft-whykr-keypoints-en">
                  <div className="bdyinner-whykr-kepoints-ftitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
                  <div className="bdyinner-lft-whykr-kepoints-secitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
                  <div className="bdyinner-whykr-kepoints-ftitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
                  <div className="bdyinner-lft-whykr-kepoints-secitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
                </div>

              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/customer_service/tab_images/service_excellence.png"} alt="service excellence" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1>Customer Services</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-en">
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "report-a-claim"}>Report a Claim</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "claim-consultancy-services"}>Claim Consultancy Services</div>
                <div className="inner-banner-btn-item-active-en" onClick={() => window.location.href = "service-excellence"}>Service Excellence</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "in-house-kr-automotive-garage"}>In house KR Automotive Garage</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "erp-based-solutions"}>ERP Based Solutions</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="tab-imgtxt-en">
            <h2>Service Excellence</h2>
            <div className="tabs-cont-en">
              <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/customer_service/tab_images/service_excellence.png"} alt="service excellence" />
              <p>KR Insurance Services has got functional expertise into advisory services, underwriting, expert claims servicing and client retention services to ensure increased efficiency in the deliverables -  </p>
              <p><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> <u>Advisory services</u> aim to achieve customer delight through dedicated client relationship management, risk inspections, policy audit, route surveys, wellness programs, credit reports renewal reminders etc.</p>
              <p><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> <u>Specialist Underwriting</u> teams to arrange the best terms from insurers and re-insurers, ensuring complete cover of risk in hand.</p>
              <p><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> <u>Expert Claims servicing</u> teams work towards smooth claim settlement across business lines ensuring business continuity.</p>
              <p><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> <u>Dedicated Retention team</u> for exceeding client's service expectations and retaining clients with us on a year to year basis.</p>

              <h3 className="tab-whykrinsu-title-en">Why KR Insurance Services ?</h3>

              <div className="tab-whykrinsu-pins-list-en">
                <div className="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
                <div className="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
                <div className="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
                <div className="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div className="inn-banner-bg-main-en hidden-desktop hidden-md-device hidden-lg">
        <div className="dropdown-sec">
          <select className="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"claim-consultancy-services"}>Claim Consultancy Services</option>
            <option value={"service-excellence"} selected className="active">Service Excellence</option>
            <option value={"in-house-kr-automotive-garage"}>In house KR Automotive Garage</option>
            <option value={"erp-based-solutions"}>ERP Based Solutions</option>
          </select>
        </div>
        <div className="innner-mob-body-main-en">
          <h1 className="inner-mob-heading-en">Service Excellence</h1>
          <div className="innner-mob-body-img-en">
            <img src={process.env.PUBLIC_URL + "/customer_service/tab_images/service_excellence.png"} alt="service excellence" />
          </div>

          <p className="inner-mob-body-txt-en">KR Insurance Services has got functional expertise into advisory services, underwriting, expert claims servicing and client retention services to ensure increased efficiency in the deliverables -  </p>

          <div className="inner-mob-pins-list-en">
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en"><u>Advisory services</u> aim to achieve customer delight through dedicated client relationship management, risk inspections, policy audit, route surveys, wellness programs, credit reports renewal reminders etc.</div>

            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en"><u>Specialist Underwriting</u> teams to arrange the best terms from insurers and re-insurers, ensuring complete cover of risk in hand.</div>

            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en"><u>Expert Claims servicing</u> teams work towards smooth claim settlement across business lines ensuring business continuity.</div>

            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en"><u>Dedicated Retention team</u> for exceeding client’s service expectations and retaining clients with us on a year to year basis.</div>
          </div>

          <h3 className="inner-mob-main-cover-title-en">Why KR Insurance Services ?</h3>

          <div className="inner-mob-bdy-whykrlist-en">
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>

        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ServiceExcellence;
