import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import { Helmet } from 'react-helmet-async';
import './arterrorismandpoliticalriskinsurance.scss';

const ARTerrorismandPoliticalRiskInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
    <Helmet>
      <title>‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬.</title>
      <meta name="keywords" content="Project insurance, Property Project Insurance"/>
      <meta name="description" content="KR ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫لوسطاء‬ ‫موثوق‬ ‫خيار‬ ‫هو‬ ‫للتأمين‬ ‫عمان‬. ‫عمان‬ ‫في‬ ‫التأمين‬ ‫شركات‬ ‫ألفضل‬ ‫التأمين‬ ‫حلول‬ ‫إلى‬ ‫للوصول‬ ‫معنا‬ ‫شارك‬ ." />
    </Helmet>
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-ar">
        <div class="inn-banner-bg-main-en">
          <div class="inner-con-en">
            <div class="inner-banner-btn-en">
              <h1>تأمين الأعمال </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="inner-banner-btn-ar">
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="inner-banner-btn-item-active-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="inner-con-en">
          <div class="bdy-innercont-en">
            <div class="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="innerbdy-dlex-lft-ar">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/terrorism_and_political_risk_insurance_ar.png"} alt="التأمين ضد الإرهاب والمخاطر السياسية " />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="innerbdy-dlex-rgt-ar">
                <h2 class="pagetitle-ar">التأمين ضد الإرهاب والمخاطر السياسية </h2>
                <p class="pg-txt-con-ar">يتزايد التعرض للأعمال الإرهابية والحربية والمخاطر السياسية كل يوم، وتواجه العديد من الصناعات تهديدًا خطيرًا بمثل هذه الحوادث. تقدم شركة كيمجي رامداس لخدمات التأمين تغطية تأمينية من شأنها حماية العديد من أنواع الشركات العاملة في جميع أنحاء العالم. تم وضع بوالص التأمين ضد الأعمال الإرهابية والعنف السياسي الأوسع نطاقًا لسد الفجوات التي خلفتها الاستثناءات في بوالص التأمين على الأملاك، حيث تتزايد الأعمال الإرهابية والحربية والمخاطر السياسية كل يوم، وتواجه العديد من الصناعات تهديدًا خطيرًا بمثل هذه الحوادث. تقدم شركة كيمجي رامداس لخدمات التأمين تغطية من شأنها حماية العديد من أنواع الشركات العاملة في جميع أنحاء العالم. تم تصميم بوليصات التأمين ضد الإرهاب والعنف السياسي الأوسع نطاقًا لسد الفجوات التي خلفتها الاستثناءات في بوليصات التأمين على الأملاك</p>

                <h3 class="bdyinner-heading-ar">التغطية الرئيسية / التأمين ضد الإرهاب والمخاطر السياسية</h3>

              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

            <p class="pg-txt-con-ar">يغطي التأمين ضد الإرهاب والعنف السياسي المؤمن عليه ضد الخسائر والأضرار المادية، فضلاً عن تكاليف انقطاع الأعمال، نتيجة للأعمال الإرهابية أو أعمال العنف السياسي. تشير المخاطر السياسية إلى النتائج السلبية التي تنجم عن أفعال الحكومة أو تقاعسها. عادة ما تكون النسبة أعلى في البلدان النامية حيث تكون الأوضاع الاقتصادية والسياسية غير مستقرة. يمكن للتأمين ضد المخاطر السياسية تخفيف الأثر السلبي لهذه المخاطر، ومساعدة الشركات على تنمية أعمالها</p>

            <div class="bdyinner-keypoint-ar">
              <div class="bdyinner-keypoint-item-ar">التأمين ضد الخسارة أو الضرر الناجم عن الهجمات النووية أو الكيميائية أو البيولوجية أو الإشعاعية، وكذلك القدرة المحدودة والمكلفة متوفرتان عند الطلب</div>
              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

              <div class="bdyinner-keypoint-item-ar">التأمين ضد الخسارة أو الضرر الناجمين عن المصادرة أو التأميم أو كنتيجة لأي أمر صادر عن سلطة عامة أو حكومية يحظر على المؤمن عليه استخدام ممتلكاته أو قيمتها</div>
              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

              <div class="bdyinner-keypoint-item-ar">التأمين ضد الخسارة أو التلف بالوسائل الإلكترونية: القرصنة الإلكترونية / القرصنة الحاسوبية، على الرغم من أنه يجري تطوير منتجات قائمة بذاتها كما هو الحال مع التأمين ضد الخسارة أو الضرر الناجم عن الهجمات النووية أو الكيميائية أو البيولوجية أو الإشعاعية</div>
              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

              <div class="bdyinner-keypoint-item-ar">انقطاع العمل نتيجة تهديد أو خداع. مرة أخرى، تعمل شركات التأمين على تطوير منتجات لسد هذه الفجوة</div>
              <div class="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>

            <h3 class="bdyinner-whykr-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>
            <div class="clearfix"></div>
            <div class="bdyinner-whykr-keypoints-ar">
              <div class="bdyinner-whykr-kepoints-secitem-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-whykr-kepoints-ftitem-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="bdyinner-whykr-kepoints-secitem-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div class="bdyinner-whykr-kepoints-ftitem-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>
            
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div class="inn-banner-bg-main-en">
          <div class="inner-con-en">
            <div class="inner-banner-btn-en">
              <h1 class="terrpolirskinsu-banner-heading-ar">تأمين الأعمال </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="inner-banner-btn-ar">
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "property-insurance-ar"}>تأمين الممتلكات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "project-insurance-ar"}>تأمين المشاريع </div>
                <div class="inner-banner-btn-item-active-ar" onClick={() => window.location.href = "terrorism-and-political-risk-insurance-ar"}>التأمين ضد الإرهاب والمخاطر السياسية </div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "motor-fleet-insurance-ar"}>تأمين أسطول المركبات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-health-insurance-ar"}>التأمين الصحي الجماعي</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-life-insurance-ar"}>التأمين الجماعي على الحياة</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "burglary-insurance-ar"}>التأمين ضد السرقة</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "jewellers-block-insurance-ar"}>التأمين على المجوهرات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "stock-throughput-insurance-ar"}>تأمين صافي المخزون</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "liability-insurance-ar"}>التأمين عن المسؤولية</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "marine-insurance-ar"}>التأمين البحري</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "energy-insurance-ar"}>التأمين على الطاقة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="inner-con-en">
          <div className="tab-imgtxt-ar">
            <h2>التأمين ضد الإرهاب والمخاطر السياسية </h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/terrorism_and_political_risk_insurance_ar.png"} alt="التأمين ضد الإرهاب والمخاطر السياسية " />

              <p>يتزايد التعرض للأعمال الإرهابية والحربية والمخاطر السياسية كل يوم، وتواجه العديد من الصناعات تهديدًا خطيرًا بمثل هذه الحوادث. تقدم شركة كيمجي رامداس لخدمات التأمين تغطية تأمينية من شأنها حماية العديد من أنواع الشركات العاملة في جميع أنحاء العالم. تم وضع بوالص التأمين ضد الأعمال الإرهابية والعنف السياسي الأوسع نطاقًا لسد الفجوات التي خلفتها الاستثناءات في بوالص التأمين على الأملاك، حيث تتزايد الأعمال الإرهابية والحربية والمخاطر السياسية كل يوم، وتواجه العديد من الصناعات تهديدًا خطيرًا بمثل هذه الحوادث. تقدم شركة كيمجي رامداس لخدمات التأمين تغطية من شأنها حماية العديد من أنواع الشركات العاملة في جميع أنحاء العالم. تم تصميم بوليصات التأمين ضد الإرهاب والعنف السياسي الأوسع نطاقًا لسد الفجوات التي خلفتها الاستثناءات في بوليصات التأمين على الأملاك</p>

              <h3 class="tab-sub-title-ar">التغطية الرئيسية / التأمين ضد الإرهاب والمخاطر السياسية</h3>

              <p>يغطي التأمين ضد الإرهاب والعنف السياسي المؤمن عليه ضد الخسائر والأضرار المادية، فضلاً عن تكاليف انقطاع الأعمال، نتيجة للأعمال الإرهابية أو أعمال العنف السياسي. تشير المخاطر السياسية إلى النتائج السلبية التي تنجم عن أفعال الحكومة أو تقاعسها. عادة ما تكون النسبة أعلى في البلدان النامية حيث تكون الأوضاع الاقتصادية والسياسية غير مستقرة. يمكن للتأمين ضد المخاطر السياسية تخفيف الأثر السلبي لهذه المخاطر، ومساعدة الشركات على تنمية أعمالها</p>

              <div className="tabkyfet-ar">
                <div className="tabkeyfet-txt-ar">التأمين ضد الخسارة أو الضرر الناجم عن الهجمات النووية أو الكيميائية أو البيولوجية أو الإشعاعية، وكذلك القدرة المحدودة والمكلفة متوفرتان عند الطلب</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">التأمين ضد الخسارة أو الضرر الناجمين عن المصادرة أو التأميم أو كنتيجة لأي أمر صادر عن سلطة عامة أو حكومية يحظر على المؤمن عليه استخدام ممتلكاته أو قيمتها.</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">التأمين ضد الخسارة أو التلف بالوسائل الإلكترونية: القرصنة الإلكترونية / القرصنة الحاسوبية، على الرغم من أنه يجري تطوير منتجات قائمة بذاتها كما هو الحال مع التأمين ضد الخسارة أو الضرر الناجم عن الهجمات النووية أو الكيميائية أو البيولوجية أو الإشعاعية</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">انقطاع العمل نتيجة تهديد أو خداع. مرة أخرى، تعمل شركات التأمين على تطوير منتجات لسد هذه الفجوة</div>
                <div class="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>
              
              <h3 class="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>
              
              <div class="tab-whykrinsu-pins-list-ar">
                <div class="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div class="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="inn-banner-bg-main-en hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"project-insurance-ar"}>تأمين المشاريع </option>
            <option value={"property-insurance-ar"}>تأمين الممتلكات</option>
            <option value={"terrorism-and-political-risk-insurance-ar"} selected class="active">التأمين ضد الإرهاب والمخاطر السياسية </option>
            <option value={"motor-fleet-insurance-ar"}>تأمين أسطول المركبات</option>
            <option value={"group-health-insurance-ar"}>التأمين الصحي الجماعي</option>
            <option value={"group-life-insurance-ar"}>التأمين الجماعي على الحياة</option>
            <option value={"fidelity-guarantee-insurance-ar"}>تأمين ضد حالات خيانة الأمانة</option>
            <option value={"burglary-insurance-ar"}>التأمين ضد السرقة</option>
            <option value={"workmen-compensation-insurance-ar"}>تأمين لتعويض العمال</option>
            <option value={"group-personal-accident-insurance-ar"}>التأمين الجماعي ضد الحوادث الشخصية</option>
            <option value={"cyber-crime-insurance-ar"}>التأمين ضد الجرائم الإلكترونية</option>
            <option value={"jewellers-block-insurance-ar"}>التأمين على المجوهرات</option>
            <option value={"stock-throughput-insurance-ar"}>تأمين صافي المخزون</option>
            <option value={"hauliers-full-value-cargo-insurance-ar"}>تأمين كامل القيمة بالنسبة للبضائع الخاصة بالمتعهدين بالنقل</option>
            <option value={"speciality-risk-event-insurance-ar"}>مخاطر التخصص / التأمين أثناء الفعاليات</option>
            <option value={"liability-insurance-ar"}>التأمين عن المسؤولية</option>
            <option value={"marine-insurance-ar"}>التأمين البحري</option>
            <option value={"energy-insurance-ar"}>التأمين على الطاقة</option>
          </select>
        </div>
        <div class="innner-mob-body-main-en">
          <h1 class="inner-mob-heading-en">التأمين ضد الإرهاب والمخاطر السياسية </h1>
          <div class="innner-mob-body-img-en">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/terrorism_and_political_risk_insurance_ar.png"} alt="التأمين ضد الإرهاب والمخاطر السياسية " />
          </div>

          <p class="inner-mob-body-txt-ar">يتزايد التعرض للأعمال الإرهابية والحربية والمخاطر السياسية كل يوم، وتواجه العديد من الصناعات تهديدًا خطيرًا بمثل هذه الحوادث. تقدم شركة كيمجي رامداس لخدمات التأمين تغطية تأمينية من شأنها حماية العديد من أنواع الشركات العاملة في جميع أنحاء العالم. تم وضع بوالص التأمين ضد الأعمال الإرهابية والعنف السياسي الأوسع نطاقًا لسد الفجوات التي خلفتها الاستثناءات في بوالص التأمين على الأملاك، حيث تتزايد الأعمال الإرهابية والحربية والمخاطر السياسية كل يوم، وتواجه العديد من الصناعات تهديدًا خطيرًا بمثل هذه الحوادث. تقدم شركة كيمجي رامداس لخدمات التأمين تغطية من شأنها حماية العديد من أنواع الشركات العاملة في جميع أنحاء العالم. تم تصميم بوليصات التأمين ضد الإرهاب والعنف السياسي الأوسع نطاقًا لسد الفجوات التي خلفتها الاستثناءات في بوليصات التأمين على الأملاك</p>

          <h3 class="terrpolirskinsu-subtitle-ar">التغطية الرئيسية / التأمين ضد الإرهاب والمخاطر السياسية</h3>

          <p class="inner-mob-body-txt-ar">يغطي التأمين ضد الإرهاب والعنف السياسي المؤمن عليه ضد الخسائر والأضرار المادية، فضلاً عن تكاليف انقطاع الأعمال، نتيجة للأعمال الإرهابية أو أعمال العنف السياسي. تشير المخاطر السياسية إلى النتائج السلبية التي تنجم عن أفعال الحكومة أو تقاعسها. عادة ما تكون النسبة أعلى في البلدان النامية حيث تكون الأوضاع الاقتصادية والسياسية غير مستقرة. يمكن للتأمين ضد المخاطر السياسية تخفيف الأثر السلبي لهذه المخاطر، ومساعدة الشركات على تنمية أعمالها</p>

          <div class="inner-mob-pins-list-ar">
            <div class="inner-mob-pins-list-item-ar">التأمين ضد الخسارة أو الضرر الناجم عن الهجمات النووية أو الكيميائية أو البيولوجية أو الإشعاعية، وكذلك القدرة المحدودة والمكلفة متوفرتان عند الطلب</div>
            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div class="inner-mob-pins-list-item-ar">التأمين ضد الخسارة أو الضرر الناجمين عن المصادرة أو التأميم أو كنتيجة لأي أمر صادر عن سلطة عامة أو حكومية يحظر على المؤمن عليه استخدام ممتلكاته أو قيمتها</div>
            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

            <div class="inner-mob-pins-list-item-ar">التأمين ضد الخسارة أو التلف بالوسائل الإلكترونية: القرصنة الإلكترونية / القرصنة الحاسوبية، على الرغم من أنه يجري تطوير منتجات قائمة بذاتها كما هو الحال مع التأمين ضد الخسارة أو الضرر الناجم عن الهجمات النووية أو الكيميائية أو البيولوجية أو الإشعاعية</div>
            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            
            <div class="inner-mob-pins-list-item-ar">انقطاع العمل نتيجة تهديد أو خداع. مرة أخرى، تعمل شركات التأمين على تطوير منتجات لسد هذه الفجوة</div>
            <div class="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>


          <h3 class="inner-mob-main-cover-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>

          <div class="inner-mob-bdy-whykrlist-ar">
            <div class="inner-mob-bdy-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-bdy-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-bdy-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-bdy-whykrlist-item-ar"> أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>
          
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARTerrorismandPoliticalRiskInsurance;