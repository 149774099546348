
import { useEffect, useState, React } from 'react';
import { Link } from "react-router-dom";

import './coinowl.scss';

const CoinowlPage = () => {

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoader(false);
    }, 2500);
    return () => clearTimeout(timer);
    
  }, []);
  
  useEffect(() =>
  {

    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }

  }
  ,[])
  const [loader, setLoader] = useState(true)
  return (
    <div className='coinowl-main'>
        {/* filterbar section starts here */}
        <div className='filterbar'>
            <div className='filterbar-item'>Total Market Cap : <span className='price'>$823,451,00</span> <span className='incpri'>+3%</span></div>
            <div className='filterbar-item'>24 Hours Volume : <span className='price'>$23,451,000</span></div>
            <div className='filterbar-item'>Dominance : BTC <span className='price'>40.2% </span> ETH <span>15.3%</span></div>
            <div className='filterbar-item'>Gas : <span className='price'>36 Gwei</span></div>
        </div>
        {/* filterbar section ends here */}
        {/* LOGO AND BUTTONS SECTION STARTS HERE */}
        <div className='logo-n-btn-sec'>
            <div className='logosec'>
                <div className='logoinc'>
                    <img src={process.env.PUBLIC_URL + "/coinowl/coinowl_logo.svg"} alt="coinowl logo" />
                </div>
                <div className='logotxt'>Coinowl</div>
            </div>
            <div className='btnsec'>
                <div className='loginbtn'>
                    <button className='btnlogin'><label>Login</label></button>
                </div>
                <div className="toggle">
                    <input type="checkbox"/>
                    <label></label>
                </div>
            </div>
        </div>
        {/* LOGO AND BUTTONS SECTION STARTS HERE */}
        {/* TAB SECTION STARTS HERE */}
        <div className='tabs-sec'>
            <div className="tabs">
                <input type="radio" id="radio-1" name="tabs" checked />
                <label className="tab" for="radio-1">Overview</label>
                <input type="radio" id="radio-2" name="tabs" checked />
                <label className="tab" for="radio-2">Markets</label>
                <input type="radio" id="radio-3" name="tabs" checked />
                <label className="tab" for="radio-3">Tokenomics</label>
                <span className="glider"></span>
                <label className="tab" for="radio-4">Unlocks</label>
                <span className="glider"></span>
                <label className="tab" for="radio-5">Investors/Funds Raising</label>
                <span className="glider"></span>
            </div>
        </div>
        {/* TAB SECTION ENDS HERE */}
        {/* BODY SECTION STARTS HERE */}
        <div className='bdysec'>
            <div className='bdy-bdcrm'>
                <div className='bdcrm-item'>Cryptocurrencies</div>
                <div className='dccrm-inc-item'>&gt;</div>
                <div className='bdcrm-item'>Coins</div>
                <div className='dccrm-inc-item'>&gt;</div>
                <div className='bdcrm-item-active'>Polygon</div>
            </div>
            <div className='polygon-sec'>
                <div className='polygonitem-img'>
                    <img src={process.env.PUBLIC_URL + "/coinowl/poligon_img_01.svg"} alt="poligon" />
                </div>
                <div className='polygon-nm-item'>Polygon</div>
                <div className='polygon-matxt-item'>Matic</div>
                <div className='polygon-lbl-item'>
                    <span>#22</span>
                </div>
            </div>
            <div className='polygnbtn-sec'>
                <div className='polg-inc01'><img src={process.env.PUBLIC_URL + "/coinowl/polg_inc01.svg"} alt="polginc" /></div>
                <div className='polg-txt01'>0xgfse....</div>
                <div className='polg-txt01'>gx5252</div>
                <div className='polg-txt02'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="9" height="10" viewBox="0 0 9 10" fill="none">
                        <path d="M5.12605 3.31433H1.75019C1.33587 3.31433 1 3.69219 1 4.1583V7.95614C1 8.42225 1.33587 8.8001 1.75019 8.8001H5.12605C5.54037 8.8001 5.87624 8.42225 5.87624 7.95614V4.1583C5.87624 3.69219 5.54037 3.31433 5.12605 3.31433Z" stroke="#ECECEC" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M7.16864 5.67641H7.44996C7.59918 5.67641 7.74229 5.59737 7.84781 5.45668C7.95333 5.316 8.0126 5.12518 8.0126 4.92622V1.55036C8.0126 1.3514 7.95333 1.16058 7.84781 1.0199C7.74229 0.879209 7.59918 0.800171 7.44996 0.800171H4.91807C4.76884 0.800171 4.62573 0.879209 4.52022 1.0199C4.4147 1.16058 4.35542 1.3514 4.35542 1.55036V1.92546" stroke="#ECECEC" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div className='polg-fximg'>
                    <img src={process.env.PUBLIC_URL + "/coinowl/fox_img.svg"} alt='fox' />
                </div>
                <div className='polg-inc-lst'><img src={process.env.PUBLIC_URL + "/coinowl/up.svg"} alt='arrow' /></div>
            </div>
            <div className='prflx'>
                <div className='pfitem'>$19.001</div>
                <div className='pfitem-dc'>
                    <div className='pfitemdc-inc'><img src={process.env.PUBLIC_URL + "/coinowl/growth_up_wht.svg"} alt='growthup' /></div>
                    <div className='pfitemdc-rst'>2.05%</div>
                </div>
            </div>
            <div className='progres-sec'>
                <div className='progress-bar'>
                <div className="progress-section" data-aos="fade-left" data-aos-once="true">
                    <div className="task-progress">
                        <progress className="progress progress1" max="100" value="80"></progress>
                    </div>
                </div>
                </div>
                <div className='selc'>
                    <div class="dropdown">
                        <button type="button" class="btn btnclr dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        24h
                        </button>
                        <ul class="dropdown-menu">
                            <li><a class="dropdown-item" href="#">12h</a></li>
                            <li><a class="dropdown-item" href="#">6h</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='lowhight-sec'>
                <div className='lowitem'>Low: $0.05</div>
                <div className='highitem'>High: $24.50</div>
            </div>
            <div className='bxsec'>
                <div className='bxsec-item'>
                    <div className='bxsecitm-fnd'>
                        <div className='fnd-item'>M.Cap</div>
                        <div className='fnd-qusinc'><img src={process.env.PUBLIC_URL + "/coinowl/quest_inc.svg"} alt='growthup' /></div>
                    </div>
                    <div className='bxsecitem-amt'>$350M</div>
                </div>
                <div className='bxsec-item'>
                    <div className='bxsecitm-fnd'>
                        <div className='fnd-item'>FDV</div>
                        <div className='fnd-qusinc'><img src={process.env.PUBLIC_URL + "/coinowl/quest_inc.svg"} alt='growthup' /></div>
                    </div>
                    <div className='bxsecitem-amt'>$350M</div>
                </div>
                <div className='bxsec-item'>
                    <div className='bxsecitm-fnd'>
                        <div className='fnd-item'>24h Vol</div>
                        <div className='fnd-qusinc'><img src={process.env.PUBLIC_URL + "/coinowl/quest_inc.svg"} alt='growthup' /></div>
                    </div>
                    <div className='bxsecitem-amt'>$350M</div>
                </div>
                <div className='bxsec-item'>
                    <div className='bxsecitm-fnd'>
                        <div className='fnd-item'>Next Unlock</div>
                        <div className='fnd-qusinc'><img src={process.env.PUBLIC_URL + "/coinowl/quest_inc.svg"} alt='growthup' /></div>
                    </div>
                    <div className='bxsecitem-amt'>$200M <span>($10M)</span></div>
                </div>
                <div className='bxsec-item'>
                    <div className='bxsecitm-fnd'>
                        <div className='fnd-item'>M.Cap</div>
                        <div className='fnd-qusinc'><img src={process.env.PUBLIC_URL + "/coinowl/quest_inc.svg"} alt='growthup' /></div>
                    </div>
                    <div className='bxsecitem-amt'>$350M</div>
                </div>
                <div className='bxsec-item'>
                    <div className='bxsecitm-fnd'>
                        <div className='fnd-item'>M.Cap</div>
                        <div className='fnd-qusinc'><img src={process.env.PUBLIC_URL + "/coinowl/quest_inc.svg"} alt='growthup' /></div>
                    </div>
                    <div className='bxsecitem-amt'>$350M</div>
                </div>
                <div className='bxsec-item'>
                    <div className='bxsecitm-fnd'>
                        <div className='fnd-item'>M.Cap</div>
                        <div className='fnd-qusinc'><img src={process.env.PUBLIC_URL + "/coinowl/quest_inc.svg"} alt='growthup' /></div>
                    </div>
                    <div className='bxsecitem-amt'>$350M</div>
                </div>
            </div>
            <div className='bxactivites'>
                <div className='bxact-itm-mn'>
                    <div className='bxmn-listitem'>
                        <div className='bxmnlt-inc'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
<g clip-path="url(#clip0_1_1066)">
    <path d="M3.5 9.80005V9.30005C3.5 8.63701 3.76339 8.00112 4.23223 7.53228C4.70107 7.06344 5.33696 6.80005 6 6.80005M6 6.80005C6.66304 6.80005 7.29893 7.06344 7.76777 7.53228C8.23661 8.00112 8.5 8.63701 8.5 9.30005V9.80005M6 6.80005C6.39782 6.80005 6.77936 6.64201 7.06066 6.36071C7.34196 6.0794 7.5 5.69787 7.5 5.30005C7.5 4.90222 7.34196 4.52069 7.06066 4.23939C6.77936 3.95808 6.39782 3.80005 6 3.80005C5.60218 3.80005 5.22064 3.95808 4.93934 4.23939C4.65804 4.52069 4.5 4.90222 4.5 5.30005C4.5 5.69787 4.65804 6.0794 4.93934 6.36071C5.22064 6.64201 5.60218 6.80005 6 6.80005ZM0.5 9.80005V9.30005C0.5 8.90222 0.658035 8.52069 0.93934 8.23939C1.22064 7.95808 1.60218 7.80005 2 7.80005M2 7.80005C2.26522 7.80005 2.51957 7.69469 2.70711 7.50716C2.89464 7.31962 3 7.06527 3 6.80005C3 6.53483 2.89464 6.28048 2.70711 6.09294C2.51957 5.90541 2.26522 5.80005 2 5.80005C1.73478 5.80005 1.48043 5.90541 1.29289 6.09294C1.10536 6.28048 1 6.53483 1 6.80005C1 7.06527 1.10536 7.31962 1.29289 7.50716C1.48043 7.69469 1.73478 7.80005 2 7.80005ZM11.5 9.80005V9.30005C11.5 8.90222 11.342 8.52069 11.0607 8.23939C10.7794 7.95808 10.3978 7.80005 10 7.80005M10 7.80005C10.2652 7.80005 10.5196 7.69469 10.7071 7.50716C10.8946 7.31962 11 7.06527 11 6.80005C11 6.53483 10.8946 6.28048 10.7071 6.09294C10.5196 5.90541 10.2652 5.80005 10 5.80005C9.73478 5.80005 9.48043 5.90541 9.29289 6.09294C9.10536 6.28048 9 6.53483 9 6.80005C9 7.06527 9.10536 7.31962 9.29289 7.50716C9.48043 7.69469 9.73478 7.80005 10 7.80005Z" stroke="#575571" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</g>
<defs>
<clipPath id="clip0_1_1066">
<rect width="12" height="12" fill="white" transform="translate(0 0.800049)"/>
</clipPath>
</defs>
</svg></div>
                        <div className='bxmnlt-txt'>Community</div>
                    </div>
                    <div className='bxmn-listiteminc'>
                        <div className='bxmnltinc-icon'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
                                <g clip-path="url(#clip0_1_1074)"><path d="M6 12.8C9.31371 12.8 12 10.1138 12 6.80005C12 3.48634 9.31371 0.800049 6 0.800049C2.68629 0.800049 0 3.48634 0 6.80005C0 10.1138 2.68629 12.8 6 12.8Z" fill="#FF4500"/>
                                <path d="M9.99142 6.85725C9.99142 6.37087 9.59809 5.98457 9.11875 5.98457C8.89232 5.98427 8.67451 6.0714 8.51078 6.22781C7.90993 5.79857 7.08728 5.51967 6.17172 5.4839L6.57222 3.60979L7.87407 3.8887C7.88847 4.21776 8.1602 4.48246 8.49648 4.48246C8.83979 4.48246 9.11875 4.20356 9.11875 3.86006C9.11875 3.51675 8.83984 3.23779 8.49648 3.23779C8.25329 3.23779 8.03861 3.38086 7.93848 3.58832L6.48648 3.28073C6.44354 3.27351 6.40061 3.28073 6.37197 3.3022C6.3362 3.32367 6.31478 3.35939 6.3077 3.40232L5.86407 5.49098C4.93422 5.51967 4.09726 5.79857 3.48929 6.23498C3.32554 6.07858 3.10772 5.99146 2.88128 5.99179C2.39486 5.99179 2.00861 6.38512 2.00861 6.86446C2.00861 7.22207 2.22315 7.52245 2.52367 7.65848C2.50908 7.74594 2.50189 7.83447 2.5022 7.92314C2.5022 9.26779 4.06876 10.3623 6.00006 10.3623C7.9314 10.3623 9.49797 9.27501 9.49797 7.92314C9.49791 7.83449 9.49073 7.74598 9.4765 7.65848C9.77687 7.52245 9.99142 7.21486 9.99142 6.85725ZM3.99714 7.47951C3.99714 7.1362 4.27604 6.85725 4.61954 6.85725C4.96286 6.85725 5.24181 7.13615 5.24181 7.47951C5.24181 7.82287 4.9629 8.10192 4.61954 8.10192C4.27609 8.10895 3.99714 7.82287 3.99714 7.47951ZM7.48075 9.1319C7.05156 9.56114 6.23607 9.58978 6.00006 9.58978C5.75687 9.58978 4.94143 9.55392 4.51928 9.1319C4.45501 9.06754 4.45501 8.96737 4.51928 8.90301C4.58368 8.83875 4.68381 8.83875 4.74822 8.90301C5.02009 9.17489 5.59234 9.26779 6.00006 9.26779C6.40782 9.26779 6.98715 9.17484 7.25181 8.90301C7.31622 8.83875 7.41634 8.83875 7.48075 8.90301C7.53793 8.96737 7.53793 9.06754 7.48075 9.1319ZM7.36623 8.109C7.02287 8.109 6.74397 7.83009 6.74397 7.48673C6.74397 7.14337 7.02287 6.86446 7.36623 6.86446C7.70968 6.86446 7.98859 7.14337 7.98859 7.48673C7.98859 7.82282 7.70968 8.109 7.36623 8.109Z" fill="white"/></g><defs>
                                    <clipPath id="clip0_1_1074">
                                        <rect width="12" height="12" fill="white" transform="translate(0 0.800049)"/>
                                    </clipPath>
                                    </defs>
                            </svg>
</div>
<div className='bxmnltinc-icon'><svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8" fill="none">
  <path d="M8.17913 0.881792C7.59741 0.610613 6.96757 0.413789 6.31149 0.300069C6.30574 0.299885 6.30002 0.300964 6.29473 0.303232C6.28944 0.305499 6.28471 0.308898 6.28088 0.313191C6.20215 0.457528 6.1103 0.645603 6.04906 0.789941C5.35318 0.684968 4.64549 0.684968 3.94961 0.789941C3.88838 0.64123 3.79653 0.457528 3.71342 0.313191C3.70905 0.304443 3.69593 0.300069 3.68281 0.300069C3.02673 0.413789 2.40127 0.610613 1.81517 0.881792C1.8108 0.881792 1.80642 0.886165 1.80205 0.890539C0.612361 2.6707 0.284322 4.40274 0.446154 6.1173C0.446154 6.12604 0.450528 6.13479 0.459276 6.13917C1.24657 6.71651 2.00325 7.06642 2.75118 7.29824C2.7643 7.30261 2.77742 7.29824 2.78179 7.28949C2.95675 7.04893 3.11421 6.79524 3.2498 6.52844C3.25854 6.51094 3.2498 6.49345 3.2323 6.48907C2.98299 6.39285 2.7468 6.27913 2.51499 6.14791C2.49749 6.13917 2.49749 6.11292 2.51061 6.0998C2.55873 6.06481 2.60684 6.02544 2.65495 5.99045C2.6637 5.98171 2.67682 5.98171 2.68557 5.98608C4.19017 6.67278 5.81287 6.67278 7.29998 5.98608C7.30873 5.98171 7.32185 5.98171 7.3306 5.99045C7.37871 6.02982 7.42683 6.06481 7.47494 6.10417C7.49243 6.1173 7.49243 6.14354 7.47056 6.15229C7.24312 6.28788 7.00256 6.39722 6.75325 6.49345C6.73576 6.49782 6.73138 6.51969 6.73576 6.53281C6.87572 6.79962 7.03318 7.0533 7.20376 7.29386C7.21688 7.29824 7.23 7.30261 7.24312 7.29824C7.99543 7.06642 8.7521 6.71651 9.5394 6.13917C9.54815 6.13479 9.55252 6.12604 9.55252 6.1173C9.74497 4.13594 9.23323 2.41701 8.19662 0.890539C8.19225 0.886165 8.18788 0.881792 8.17913 0.881792ZM3.47724 5.07194C3.02673 5.07194 2.65058 4.65643 2.65058 4.14469C2.65058 3.63295 3.01798 3.21743 3.47724 3.21743C3.94086 3.21743 4.30827 3.63732 4.30389 4.14469C4.30389 4.65643 3.93649 5.07194 3.47724 5.07194ZM6.52581 5.07194C6.07531 5.07194 5.69915 4.65643 5.69915 4.14469C5.69915 3.63295 6.06656 3.21743 6.52581 3.21743C6.98944 3.21743 7.35684 3.63732 7.35247 4.14469C7.35247 4.65643 6.98944 5.07194 6.52581 5.07194Z" fill="white"/>
</svg></div>
<div className='bxmnltinc-icon'><svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
  <path d="M11.081 3.62801C10.6993 3.79686 10.2945 3.90775 9.88 3.95701C10.3169 3.69573 10.6438 3.28452 10.8 2.80001C10.39 3.04401 9.9405 3.21501 9.472 3.30751C9.1573 2.9708 8.74018 2.74749 8.28547 2.6723C7.83077 2.59712 7.36397 2.67426 6.95763 2.89174C6.55129 3.10922 6.22819 3.45485 6.03855 3.87491C5.84892 4.29496 5.80337 4.7659 5.909 5.21451C5.07755 5.17284 4.26416 4.95677 3.52164 4.58034C2.77912 4.20391 2.12406 3.67554 1.599 3.02951C1.41314 3.34874 1.31547 3.71163 1.316 4.08101C1.316 4.80601 1.685 5.44651 2.246 5.82151C1.914 5.81106 1.58931 5.7214 1.299 5.56001V5.58601C1.2991 6.06886 1.46618 6.53682 1.77192 6.91054C2.07766 7.28426 2.50324 7.54075 2.9765 7.63651C2.6683 7.72003 2.34515 7.73234 2.0315 7.67251C2.16493 8.08813 2.425 8.45161 2.77529 8.71207C3.12558 8.97252 3.54856 9.11691 3.985 9.12501C3.55124 9.46567 3.05458 9.7175 2.52344 9.8661C1.99229 10.0147 1.43706 10.0571 0.889496 9.99101C1.84534 10.6057 2.95804 10.9321 4.0945 10.931C7.941 10.931 10.0445 7.74451 10.0445 4.98101C10.0445 4.89101 10.042 4.80001 10.038 4.71101C10.4474 4.4151 10.8008 4.04852 11.0815 3.62851L11.081 3.62801Z" fill="#5548EC"/>
</svg></div>
<div className='bxmnltinc-arrow-icon'><svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.31952 1.21051C-0.106507 1.63654 -0.106507 2.32726 0.31952 2.75329L3.36631 5.80008L0.31952 8.84687C-0.106507 9.2729 -0.106507 9.96363 0.31952 10.3897C0.745546 10.8157 1.43627 10.8157 1.8623 10.3897L5.68048 6.57147C6.10651 6.14544 6.10651 5.45472 5.68048 5.02869L1.8623 1.21051C1.43627 0.784485 0.745546 0.784485 0.31952 1.21051Z" fill="white"/>
</svg></div>
                    </div>
                </div>
                <div className='bxact-itm'>
                    <div className='bxresources-listitem'>
                        <div className='bxresources-inc'>
                            <div className='bxresources-inc'><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 13" fill="none">
  <path d="M6 3.80005L5 2.80005H1V10.8H11V3.80005H6ZM7 8.80005H3V7.80005H7V8.80005ZM9 6.80005H3V5.80005H9V6.80005Z" fill="#575571"/>
</svg></div>
                        </div>
                        <div className='bxresources-txt'>Source</div>
                    </div>
                    <div className='bxresources-lnksinc'>
                        <div className='bxresources-itms'>
                            <div className='bxres-itms-inc'><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 13 13" fill="none">
  <path d="M3.9182 6.49856C3.9182 5.13556 3.9182 4.45406 4.3397 4.03056C4.7617 3.60706 5.4402 3.60706 6.7982 3.60706H8.2382C9.5957 3.60706 10.2742 3.60706 10.6962 4.03056C11.1182 4.45406 11.1182 5.13556 11.1182 6.49856V8.90856C11.1182 10.2716 11.1182 10.9531 10.6962 11.3766C10.2747 11.8001 9.5957 11.8001 8.2382 11.8001H6.7982C5.4402 11.8001 4.7617 11.8001 4.3397 11.3766C3.9182 10.9531 3.9182 10.2716 3.9182 8.90856V6.49856Z" fill="white"/>
  <path opacity="0.5" d="M2.70419 2.38605C2.11819 2.97155 2.11819 3.91455 2.11819 5.80005V6.80005C2.11819 8.68555 2.11819 9.62855 2.70419 10.214C3.01269 10.523 3.42069 10.669 4.01419 10.738C3.91819 10.318 3.91819 9.74005 3.91819 8.90805V6.49855C3.91819 5.13555 3.91819 4.45405 4.33969 4.03055C4.76169 3.60705 5.44019 3.60705 6.79819 3.60705H8.23819C9.06419 3.60705 9.63819 3.60705 10.0572 3.70205C9.98819 3.10555 9.84219 2.69605 9.53219 2.38605C8.94669 1.80005 8.00369 1.80005 6.11819 1.80005C4.23269 1.80005 3.28969 1.80005 2.70419 2.38605Z" fill="white"/>
</svg></div>
                            <div className='bxres-itms-txt'>Resources</div>
                            <div className='bxres-itms-inc'><svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.53375 0.566316C8.17873 0.211293 7.60313 0.211293 7.2481 0.566316L4.70911 3.10531L2.17012 0.566316C1.81509 0.211293 1.23949 0.211293 0.884468 0.566316C0.529445 0.921338 0.529445 1.49694 0.884468 1.85196L4.06629 5.03378C4.42131 5.3888 4.99691 5.3888 5.35193 5.03378L8.53375 1.85196C8.88877 1.49694 8.88877 0.921338 8.53375 0.566316Z" fill="white"/>
</svg></div>
                        </div>
                        <div className='bxresources-itms'>
                            <div className='bxres-itms-inc'><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
  <path d="M6.79999 1.92505C5.83581 1.92505 4.89328 2.21096 4.09159 2.74663C3.2899 3.28231 2.66505 4.04368 2.29608 4.93447C1.9271 5.82526 1.83056 6.80546 2.01866 7.75111C2.20676 8.69677 2.67106 9.56541 3.35284 10.2472C4.03463 10.929 4.90327 11.3933 5.84893 11.5814C6.79458 11.7695 7.77478 11.6729 8.66557 11.304C9.55636 10.935 10.3177 10.3101 10.8534 9.50845C11.3891 8.70676 11.675 7.76423 11.675 6.80005C11.6736 5.50754 11.1596 4.26836 10.2456 3.35441C9.33168 2.44047 8.0925 1.92641 6.79999 1.92505ZM5.5639 8.67505H8.03608C7.78437 9.53474 7.36249 10.3096 6.79999 10.9199C6.23749 10.3096 5.81562 9.53474 5.5639 8.67505ZM5.39374 7.92505C5.26937 7.18021 5.26937 6.41989 5.39374 5.67505H8.20624C8.33061 6.41989 8.33061 7.18021 8.20624 7.92505H5.39374ZM2.67499 6.80005C2.67466 6.41963 2.7272 6.04101 2.83108 5.67505H4.6339C4.52202 6.42088 4.52202 7.17922 4.6339 7.92505H2.83108C2.7272 7.55909 2.67466 7.18047 2.67499 6.80005ZM8.03608 4.92505H5.5639C5.81562 4.06536 6.23749 3.29052 6.79999 2.68021C7.36249 3.29052 7.78437 4.06536 8.03608 4.92505ZM8.96608 5.67505H10.7689C10.977 6.41061 10.977 7.18949 10.7689 7.92505H8.96608C9.07796 7.17922 9.07796 6.42088 8.96608 5.67505ZM10.4736 4.92505H8.8128C8.62143 4.17201 8.30004 3.45816 7.86311 2.81567C8.42133 2.96568 8.94196 3.23086 9.39155 3.59414C9.84113 3.95743 10.2097 4.41078 10.4736 4.92505ZM5.73687 2.81567C5.29994 3.45816 4.97855 4.17201 4.78718 4.92505H3.1264C3.39027 4.41078 3.75885 3.95743 4.20843 3.59414C4.65802 3.23086 5.17865 2.96568 5.73687 2.81567ZM3.1264 8.67505H4.78718C4.97855 9.42809 5.29994 10.1419 5.73687 10.7844C5.17865 10.6344 4.65802 10.3692 4.20843 10.006C3.75885 9.64267 3.39027 9.18932 3.1264 8.67505ZM7.86311 10.7844C8.30004 10.1419 8.62143 9.42809 8.8128 8.67505H10.4736C10.2097 9.18932 9.84113 9.64267 9.39155 10.006C8.94196 10.3692 8.42133 10.6344 7.86311 10.7844Z" fill="white"/>
</svg></div>
                            <div className='bxres-itms-txt'>Website</div>
                        </div>
                        <div className='bxresources-arrow-icon'><svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.31952 1.21051C-0.106507 1.63654 -0.106507 2.32726 0.31952 2.75329L3.36631 5.80008L0.31952 8.84687C-0.106507 9.2729 -0.106507 9.96363 0.31952 10.3897C0.745546 10.8157 1.43627 10.8157 1.8623 10.3897L5.68048 6.57147C6.10651 6.14544 6.10651 5.45472 5.68048 5.02869L1.8623 1.21051C1.43627 0.784485 0.745546 0.784485 0.31952 1.21051Z" fill="white"/>
</svg></div>
                    </div>
                </div>
                <div className='bxact-itm'>
                    <div className='bxresources-listitem'>
                        <div className='bxresources-inc'>
                            <div className='bxresources-inc'><svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
  <g clip-path="url(#clip0_1_1116)">
    <path d="M8.75 9.55001L6.94042 7.74043M6.94042 7.74043C7.24995 7.43089 7.49549 7.06342 7.66301 6.65899C7.83053 6.25456 7.91675 5.8211 7.91675 5.38335C7.91675 4.94559 7.83053 4.51213 7.66301 4.1077C7.49549 3.70327 7.24995 3.3358 6.94042 3.02626C6.63088 2.71673 6.26341 2.47119 5.85898 2.30367C5.45455 2.13615 5.02109 2.04993 4.58334 2.04993C4.14558 2.04993 3.71212 2.13615 3.30769 2.30367C2.90326 2.47119 2.53579 2.71673 2.22625 3.02626C1.60111 3.6514 1.24992 4.49927 1.24992 5.38335C1.24992 6.26742 1.60111 7.11529 2.22625 7.74043C2.85139 8.36557 3.69926 8.71676 4.58334 8.71676C5.46741 8.71676 6.31528 8.36557 6.94042 7.74043Z" stroke="#575571" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </g>
  <defs>
    <clipPath id="clip0_1_1116">
      <rect width="10" height="10" fill="white" transform="translate(0 0.800049)"/>
    </clipPath>
  </defs>
</svg></div>
                        </div>
                        <div className='bxresources-txt'>Explorers</div>
                    </div>
                    <div className='bxresources-lnksinc'>
                        <div className='bxresources-itms'>
                            <div className='bxres-itms-inc'><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 13 13" fill="none">
  <path d="M3.9182 6.49856C3.9182 5.13556 3.9182 4.45406 4.3397 4.03056C4.7617 3.60706 5.4402 3.60706 6.7982 3.60706H8.2382C9.5957 3.60706 10.2742 3.60706 10.6962 4.03056C11.1182 4.45406 11.1182 5.13556 11.1182 6.49856V8.90856C11.1182 10.2716 11.1182 10.9531 10.6962 11.3766C10.2747 11.8001 9.5957 11.8001 8.2382 11.8001H6.7982C5.4402 11.8001 4.7617 11.8001 4.3397 11.3766C3.9182 10.9531 3.9182 10.2716 3.9182 8.90856V6.49856Z" fill="white"/>
  <path opacity="0.5" d="M2.70419 2.38605C2.11819 2.97155 2.11819 3.91455 2.11819 5.80005V6.80005C2.11819 8.68555 2.11819 9.62855 2.70419 10.214C3.01269 10.523 3.42069 10.669 4.01419 10.738C3.91819 10.318 3.91819 9.74005 3.91819 8.90805V6.49855C3.91819 5.13555 3.91819 4.45405 4.33969 4.03055C4.76169 3.60705 5.44019 3.60705 6.79819 3.60705H8.23819C9.06419 3.60705 9.63819 3.60705 10.0572 3.70205C9.98819 3.10555 9.84219 2.69605 9.53219 2.38605C8.94669 1.80005 8.00369 1.80005 6.11819 1.80005C4.23269 1.80005 3.28969 1.80005 2.70419 2.38605Z" fill="white"/>
</svg></div>
                            <div className='bxres-itms-txt'>Resources</div>
                            <div className='bxres-itms-inc'><svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.53375 0.566316C8.17873 0.211293 7.60313 0.211293 7.2481 0.566316L4.70911 3.10531L2.17012 0.566316C1.81509 0.211293 1.23949 0.211293 0.884468 0.566316C0.529445 0.921338 0.529445 1.49694 0.884468 1.85196L4.06629 5.03378C4.42131 5.3888 4.99691 5.3888 5.35193 5.03378L8.53375 1.85196C8.88877 1.49694 8.88877 0.921338 8.53375 0.566316Z" fill="white"/>
</svg></div>
                        </div>
                        <div className='bxresources-itms'>
                            <div className='bxres-itms-inc'><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
  <path d="M6.79999 1.92505C5.83581 1.92505 4.89328 2.21096 4.09159 2.74663C3.2899 3.28231 2.66505 4.04368 2.29608 4.93447C1.9271 5.82526 1.83056 6.80546 2.01866 7.75111C2.20676 8.69677 2.67106 9.56541 3.35284 10.2472C4.03463 10.929 4.90327 11.3933 5.84893 11.5814C6.79458 11.7695 7.77478 11.6729 8.66557 11.304C9.55636 10.935 10.3177 10.3101 10.8534 9.50845C11.3891 8.70676 11.675 7.76423 11.675 6.80005C11.6736 5.50754 11.1596 4.26836 10.2456 3.35441C9.33168 2.44047 8.0925 1.92641 6.79999 1.92505ZM5.5639 8.67505H8.03608C7.78437 9.53474 7.36249 10.3096 6.79999 10.9199C6.23749 10.3096 5.81562 9.53474 5.5639 8.67505ZM5.39374 7.92505C5.26937 7.18021 5.26937 6.41989 5.39374 5.67505H8.20624C8.33061 6.41989 8.33061 7.18021 8.20624 7.92505H5.39374ZM2.67499 6.80005C2.67466 6.41963 2.7272 6.04101 2.83108 5.67505H4.6339C4.52202 6.42088 4.52202 7.17922 4.6339 7.92505H2.83108C2.7272 7.55909 2.67466 7.18047 2.67499 6.80005ZM8.03608 4.92505H5.5639C5.81562 4.06536 6.23749 3.29052 6.79999 2.68021C7.36249 3.29052 7.78437 4.06536 8.03608 4.92505ZM8.96608 5.67505H10.7689C10.977 6.41061 10.977 7.18949 10.7689 7.92505H8.96608C9.07796 7.17922 9.07796 6.42088 8.96608 5.67505ZM10.4736 4.92505H8.8128C8.62143 4.17201 8.30004 3.45816 7.86311 2.81567C8.42133 2.96568 8.94196 3.23086 9.39155 3.59414C9.84113 3.95743 10.2097 4.41078 10.4736 4.92505ZM5.73687 2.81567C5.29994 3.45816 4.97855 4.17201 4.78718 4.92505H3.1264C3.39027 4.41078 3.75885 3.95743 4.20843 3.59414C4.65802 3.23086 5.17865 2.96568 5.73687 2.81567ZM3.1264 8.67505H4.78718C4.97855 9.42809 5.29994 10.1419 5.73687 10.7844C5.17865 10.6344 4.65802 10.3692 4.20843 10.006C3.75885 9.64267 3.39027 9.18932 3.1264 8.67505ZM7.86311 10.7844C8.30004 10.1419 8.62143 9.42809 8.8128 8.67505H10.4736C10.2097 9.18932 9.84113 9.64267 9.39155 10.006C8.94196 10.3692 8.42133 10.6344 7.86311 10.7844Z" fill="white"/>
</svg></div>
                            <div className='bxres-itms-txt'>Website</div>
                        </div>
                        <div className='bxresources-arrow-icon'><svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.31952 1.21051C-0.106507 1.63654 -0.106507 2.32726 0.31952 2.75329L3.36631 5.80008L0.31952 8.84687C-0.106507 9.2729 -0.106507 9.96363 0.31952 10.3897C0.745546 10.8157 1.43627 10.8157 1.8623 10.3897L5.68048 6.57147C6.10651 6.14544 6.10651 5.45472 5.68048 5.02869L1.8623 1.21051C1.43627 0.784485 0.745546 0.784485 0.31952 1.21051Z" fill="white"/>
</svg></div>
                    </div>
                </div>
                <div className='bxact-itm'>
                    <div className='bxresources-listitem'>
                        <div className='bxresources-inc'>
                            <div className='bxresources-inc'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
  <path d="M10.5 9.80005V10.3C10.5 10.5653 10.3946 10.8196 10.2071 11.0072C10.0196 11.1947 9.76522 11.3 9.5 11.3H2.5C2.23478 11.3 1.98043 11.1947 1.79289 11.0072C1.60536 10.8196 1.5 10.5653 1.5 10.3V3.30005C1.5 3.03483 1.60536 2.78048 1.79289 2.59294C1.98043 2.40541 2.23478 2.30005 2.5 2.30005H9.5C9.76522 2.30005 10.0196 2.40541 10.2071 2.59294C10.3946 2.78048 10.5 3.03483 10.5 3.30005V3.80005H6C5.73478 3.80005 5.48043 3.90541 5.29289 4.09294C5.10536 4.28048 5 4.53483 5 4.80005V8.80005C5 9.06527 5.10536 9.31962 5.29289 9.50716C5.48043 9.69469 5.73478 9.80005 6 9.80005M6 8.80005H11V4.80005H6M8 7.55005C7.80109 7.55005 7.61032 7.47103 7.46967 7.33038C7.32902 7.18973 7.25 6.99896 7.25 6.80005C7.25 6.60114 7.32902 6.41037 7.46967 6.26972C7.61032 6.12907 7.80109 6.05005 8 6.05005C8.19891 6.05005 8.38968 6.12907 8.53033 6.26972C8.67098 6.41037 8.75 6.60114 8.75 6.80005C8.75 6.99896 8.67098 7.18973 8.53033 7.33038C8.38968 7.47103 8.19891 7.55005 8 7.55005Z" fill="#575571"/>
</svg>
                            </div>
                        </div>
                        <div className='bxresources-txt'>Wallets</div>
                    </div>
                    <div className='bxresources-lnksinc'>
                        <div className='bxresources-itms'>
                            <div className='bxres-itms-inc'><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 13 13" fill="none">
  <path d="M3.9182 6.49856C3.9182 5.13556 3.9182 4.45406 4.3397 4.03056C4.7617 3.60706 5.4402 3.60706 6.7982 3.60706H8.2382C9.5957 3.60706 10.2742 3.60706 10.6962 4.03056C11.1182 4.45406 11.1182 5.13556 11.1182 6.49856V8.90856C11.1182 10.2716 11.1182 10.9531 10.6962 11.3766C10.2747 11.8001 9.5957 11.8001 8.2382 11.8001H6.7982C5.4402 11.8001 4.7617 11.8001 4.3397 11.3766C3.9182 10.9531 3.9182 10.2716 3.9182 8.90856V6.49856Z" fill="white"/>
  <path opacity="0.5" d="M2.70419 2.38605C2.11819 2.97155 2.11819 3.91455 2.11819 5.80005V6.80005C2.11819 8.68555 2.11819 9.62855 2.70419 10.214C3.01269 10.523 3.42069 10.669 4.01419 10.738C3.91819 10.318 3.91819 9.74005 3.91819 8.90805V6.49855C3.91819 5.13555 3.91819 4.45405 4.33969 4.03055C4.76169 3.60705 5.44019 3.60705 6.79819 3.60705H8.23819C9.06419 3.60705 9.63819 3.60705 10.0572 3.70205C9.98819 3.10555 9.84219 2.69605 9.53219 2.38605C8.94669 1.80005 8.00369 1.80005 6.11819 1.80005C4.23269 1.80005 3.28969 1.80005 2.70419 2.38605Z" fill="white"/>
</svg></div>
                            <div className='bxres-itms-txt'>Resources</div>
                            <div className='bxres-itms-inc'><svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.53375 0.566316C8.17873 0.211293 7.60313 0.211293 7.2481 0.566316L4.70911 3.10531L2.17012 0.566316C1.81509 0.211293 1.23949 0.211293 0.884468 0.566316C0.529445 0.921338 0.529445 1.49694 0.884468 1.85196L4.06629 5.03378C4.42131 5.3888 4.99691 5.3888 5.35193 5.03378L8.53375 1.85196C8.88877 1.49694 8.88877 0.921338 8.53375 0.566316Z" fill="white"/>
</svg></div>
                        </div>
                        <div className='bxresources-itms'>
                            <div className='bxres-itms-inc'><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
  <path d="M6.79999 1.92505C5.83581 1.92505 4.89328 2.21096 4.09159 2.74663C3.2899 3.28231 2.66505 4.04368 2.29608 4.93447C1.9271 5.82526 1.83056 6.80546 2.01866 7.75111C2.20676 8.69677 2.67106 9.56541 3.35284 10.2472C4.03463 10.929 4.90327 11.3933 5.84893 11.5814C6.79458 11.7695 7.77478 11.6729 8.66557 11.304C9.55636 10.935 10.3177 10.3101 10.8534 9.50845C11.3891 8.70676 11.675 7.76423 11.675 6.80005C11.6736 5.50754 11.1596 4.26836 10.2456 3.35441C9.33168 2.44047 8.0925 1.92641 6.79999 1.92505ZM5.5639 8.67505H8.03608C7.78437 9.53474 7.36249 10.3096 6.79999 10.9199C6.23749 10.3096 5.81562 9.53474 5.5639 8.67505ZM5.39374 7.92505C5.26937 7.18021 5.26937 6.41989 5.39374 5.67505H8.20624C8.33061 6.41989 8.33061 7.18021 8.20624 7.92505H5.39374ZM2.67499 6.80005C2.67466 6.41963 2.7272 6.04101 2.83108 5.67505H4.6339C4.52202 6.42088 4.52202 7.17922 4.6339 7.92505H2.83108C2.7272 7.55909 2.67466 7.18047 2.67499 6.80005ZM8.03608 4.92505H5.5639C5.81562 4.06536 6.23749 3.29052 6.79999 2.68021C7.36249 3.29052 7.78437 4.06536 8.03608 4.92505ZM8.96608 5.67505H10.7689C10.977 6.41061 10.977 7.18949 10.7689 7.92505H8.96608C9.07796 7.17922 9.07796 6.42088 8.96608 5.67505ZM10.4736 4.92505H8.8128C8.62143 4.17201 8.30004 3.45816 7.86311 2.81567C8.42133 2.96568 8.94196 3.23086 9.39155 3.59414C9.84113 3.95743 10.2097 4.41078 10.4736 4.92505ZM5.73687 2.81567C5.29994 3.45816 4.97855 4.17201 4.78718 4.92505H3.1264C3.39027 4.41078 3.75885 3.95743 4.20843 3.59414C4.65802 3.23086 5.17865 2.96568 5.73687 2.81567ZM3.1264 8.67505H4.78718C4.97855 9.42809 5.29994 10.1419 5.73687 10.7844C5.17865 10.6344 4.65802 10.3692 4.20843 10.006C3.75885 9.64267 3.39027 9.18932 3.1264 8.67505ZM7.86311 10.7844C8.30004 10.1419 8.62143 9.42809 8.8128 8.67505H10.4736C10.2097 9.18932 9.84113 9.64267 9.39155 10.006C8.94196 10.3692 8.42133 10.6344 7.86311 10.7844Z" fill="white"/>
</svg></div>
                            <div className='bxres-itms-txt'>Website</div>
                        </div>
                        <div className='bxresources-arrow-icon'><svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.31952 1.21051C-0.106507 1.63654 -0.106507 2.32726 0.31952 2.75329L3.36631 5.80008L0.31952 8.84687C-0.106507 9.2729 -0.106507 9.96363 0.31952 10.3897C0.745546 10.8157 1.43627 10.8157 1.8623 10.3897L5.68048 6.57147C6.10651 6.14544 6.10651 5.45472 5.68048 5.02869L1.8623 1.21051C1.43627 0.784485 0.745546 0.784485 0.31952 1.21051Z" fill="white"/>
</svg></div>
                    </div>
                </div>
                <div className='bxact-itm'>
                    <div className='bxresources-listitem'>
                        <div className='bxresources-inc'>
                            <div className='bxresources-inc'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
  <path d="M8.8505 8.52404H9.8505M8.8505 6.79904L9.3505 6.52404V8.52404M4.015 9.23079V11.3308L1.379 9.22729M1.758 2.26904H10.242C10.3427 2.26924 10.4391 2.30941 10.5102 2.38071C10.5813 2.45201 10.6211 2.54862 10.621 2.64929V8.84679C10.6211 8.94747 10.5813 9.04407 10.5102 9.11538C10.4391 9.18668 10.3427 9.22684 10.242 9.22704H1.37875V2.64954C1.37865 2.59965 1.38839 2.55023 1.40739 2.5041C1.4264 2.45798 1.45431 2.41605 1.48953 2.38071C1.52475 2.34537 1.56659 2.31733 1.61266 2.29816C1.65872 2.279 1.70811 2.26911 1.758 2.26904Z" stroke="#575571" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                            </div>
                        </div>
                        <div className='bxresources-txt'>Search on</div>
                    </div>
                    <div className='bxresources-lnksinc'>
                        <div className='bxresources-itms'>
                            <div className='bxres-itms-inc'><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 13 13" fill="none">
  <path d="M3.9182 6.49856C3.9182 5.13556 3.9182 4.45406 4.3397 4.03056C4.7617 3.60706 5.4402 3.60706 6.7982 3.60706H8.2382C9.5957 3.60706 10.2742 3.60706 10.6962 4.03056C11.1182 4.45406 11.1182 5.13556 11.1182 6.49856V8.90856C11.1182 10.2716 11.1182 10.9531 10.6962 11.3766C10.2747 11.8001 9.5957 11.8001 8.2382 11.8001H6.7982C5.4402 11.8001 4.7617 11.8001 4.3397 11.3766C3.9182 10.9531 3.9182 10.2716 3.9182 8.90856V6.49856Z" fill="white"/>
  <path opacity="0.5" d="M2.70419 2.38605C2.11819 2.97155 2.11819 3.91455 2.11819 5.80005V6.80005C2.11819 8.68555 2.11819 9.62855 2.70419 10.214C3.01269 10.523 3.42069 10.669 4.01419 10.738C3.91819 10.318 3.91819 9.74005 3.91819 8.90805V6.49855C3.91819 5.13555 3.91819 4.45405 4.33969 4.03055C4.76169 3.60705 5.44019 3.60705 6.79819 3.60705H8.23819C9.06419 3.60705 9.63819 3.60705 10.0572 3.70205C9.98819 3.10555 9.84219 2.69605 9.53219 2.38605C8.94669 1.80005 8.00369 1.80005 6.11819 1.80005C4.23269 1.80005 3.28969 1.80005 2.70419 2.38605Z" fill="white"/>
</svg></div>
                            <div className='bxres-itms-txt'>Resources</div>
                            <div className='bxres-itms-inc'><svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.53375 0.566316C8.17873 0.211293 7.60313 0.211293 7.2481 0.566316L4.70911 3.10531L2.17012 0.566316C1.81509 0.211293 1.23949 0.211293 0.884468 0.566316C0.529445 0.921338 0.529445 1.49694 0.884468 1.85196L4.06629 5.03378C4.42131 5.3888 4.99691 5.3888 5.35193 5.03378L8.53375 1.85196C8.88877 1.49694 8.88877 0.921338 8.53375 0.566316Z" fill="white"/>
</svg></div>
                        </div>
                        <div className='bxresources-itms'>
                            <div className='bxres-itms-inc'><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
  <path d="M6.79999 1.92505C5.83581 1.92505 4.89328 2.21096 4.09159 2.74663C3.2899 3.28231 2.66505 4.04368 2.29608 4.93447C1.9271 5.82526 1.83056 6.80546 2.01866 7.75111C2.20676 8.69677 2.67106 9.56541 3.35284 10.2472C4.03463 10.929 4.90327 11.3933 5.84893 11.5814C6.79458 11.7695 7.77478 11.6729 8.66557 11.304C9.55636 10.935 10.3177 10.3101 10.8534 9.50845C11.3891 8.70676 11.675 7.76423 11.675 6.80005C11.6736 5.50754 11.1596 4.26836 10.2456 3.35441C9.33168 2.44047 8.0925 1.92641 6.79999 1.92505ZM5.5639 8.67505H8.03608C7.78437 9.53474 7.36249 10.3096 6.79999 10.9199C6.23749 10.3096 5.81562 9.53474 5.5639 8.67505ZM5.39374 7.92505C5.26937 7.18021 5.26937 6.41989 5.39374 5.67505H8.20624C8.33061 6.41989 8.33061 7.18021 8.20624 7.92505H5.39374ZM2.67499 6.80005C2.67466 6.41963 2.7272 6.04101 2.83108 5.67505H4.6339C4.52202 6.42088 4.52202 7.17922 4.6339 7.92505H2.83108C2.7272 7.55909 2.67466 7.18047 2.67499 6.80005ZM8.03608 4.92505H5.5639C5.81562 4.06536 6.23749 3.29052 6.79999 2.68021C7.36249 3.29052 7.78437 4.06536 8.03608 4.92505ZM8.96608 5.67505H10.7689C10.977 6.41061 10.977 7.18949 10.7689 7.92505H8.96608C9.07796 7.17922 9.07796 6.42088 8.96608 5.67505ZM10.4736 4.92505H8.8128C8.62143 4.17201 8.30004 3.45816 7.86311 2.81567C8.42133 2.96568 8.94196 3.23086 9.39155 3.59414C9.84113 3.95743 10.2097 4.41078 10.4736 4.92505ZM5.73687 2.81567C5.29994 3.45816 4.97855 4.17201 4.78718 4.92505H3.1264C3.39027 4.41078 3.75885 3.95743 4.20843 3.59414C4.65802 3.23086 5.17865 2.96568 5.73687 2.81567ZM3.1264 8.67505H4.78718C4.97855 9.42809 5.29994 10.1419 5.73687 10.7844C5.17865 10.6344 4.65802 10.3692 4.20843 10.006C3.75885 9.64267 3.39027 9.18932 3.1264 8.67505ZM7.86311 10.7844C8.30004 10.1419 8.62143 9.42809 8.8128 8.67505H10.4736C10.2097 9.18932 9.84113 9.64267 9.39155 10.006C8.94196 10.3692 8.42133 10.6344 7.86311 10.7844Z" fill="white"/>
</svg></div>
                            <div className='bxres-itms-txt'>Website</div>
                        </div>
                        <div className='bxresources-arrow-icon'><svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.31952 1.21051C-0.106507 1.63654 -0.106507 2.32726 0.31952 2.75329L3.36631 5.80008L0.31952 8.84687C-0.106507 9.2729 -0.106507 9.96363 0.31952 10.3897C0.745546 10.8157 1.43627 10.8157 1.8623 10.3897L5.68048 6.57147C6.10651 6.14544 6.10651 5.45472 5.68048 5.02869L1.8623 1.21051C1.43627 0.784485 0.745546 0.784485 0.31952 1.21051Z" fill="white"/>
</svg></div>
                    </div>
                </div>
                <div className='bxact-itm'>
                    <div className='bxresources-listitem'>
                        <div className='bxresources-inc'>
                            <div className='bxresources-inc'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
  <g clip-path="url(#clip0_1_1152)">
    <path opacity="0.2" d="M11.1403 7.97192L7.17187 11.9404C7.10156 12.0106 7.00621 12.0501 6.9068 12.0501C6.80738 12.0501 6.71204 12.0106 6.64172 11.9404L1.98469 7.28474C1.91449 7.21446 1.87505 7.11922 1.875 7.01989V2.67505H6.21984C6.31917 2.67509 6.41442 2.71454 6.48469 2.78474L11.1403 7.44036C11.1753 7.47521 11.2031 7.51662 11.2221 7.56223C11.241 7.60784 11.2508 7.65675 11.2508 7.70614C11.2508 7.75554 11.241 7.80444 11.2221 7.85005C11.2031 7.89566 11.1753 7.93708 11.1403 7.97192Z" fill="#575571"/>
    <path d="M11.4052 7.17505L6.75 2.5199C6.68061 2.44995 6.59801 2.39449 6.50699 2.35675C6.41598 2.31901 6.31837 2.29974 6.21984 2.30005H1.875C1.77554 2.30005 1.68016 2.33956 1.60984 2.40989C1.53951 2.48021 1.5 2.5756 1.5 2.67505V7.0199C1.49969 7.11842 1.51896 7.21603 1.5567 7.30704C1.59444 7.39806 1.6499 7.48066 1.71984 7.55005L6.375 12.2052C6.44465 12.2749 6.52733 12.3301 6.61834 12.3678C6.70934 12.4055 6.80689 12.4249 6.90539 12.4249C7.0039 12.4249 7.10144 12.4055 7.19244 12.3678C7.28345 12.3301 7.36614 12.2749 7.43578 12.2052L11.4052 8.23583C11.4748 8.16619 11.5301 8.0835 11.5678 7.9925C11.6055 7.90149 11.6249 7.80395 11.6249 7.70544C11.6249 7.60694 11.6055 7.5094 11.5678 7.41839C11.5301 7.32739 11.4748 7.2447 11.4052 7.17505ZM6.90516 11.6751L2.25 7.0199V3.05005H6.21984L10.875 7.70521L6.90516 11.6751ZM4.5 4.73755C4.5 4.8488 4.46701 4.95756 4.4052 5.05006C4.34339 5.14256 4.25554 5.21466 4.15276 5.25723C4.04998 5.29981 3.93688 5.31095 3.82776 5.28924C3.71865 5.26754 3.61842 5.21397 3.53975 5.1353C3.46109 5.05663 3.40751 4.95641 3.38581 4.84729C3.3641 4.73818 3.37524 4.62508 3.41782 4.52229C3.46039 4.41951 3.53249 4.33166 3.62499 4.26985C3.71749 4.20804 3.82625 4.17505 3.9375 4.17505C4.08668 4.17505 4.22976 4.23432 4.33525 4.3398C4.44074 4.44529 4.5 4.58837 4.5 4.73755Z" fill="#575571"/>
  </g>
  <defs>
    <clipPath id="clip0_1_1152">
      <rect width="12" height="12" fill="white" transform="translate(0 0.800049)"/>
    </clipPath>
  </defs>
</svg>
                            </div>
                        </div>
                        <div className='bxresources-txt'>Tags</div>
                    </div>
                    <div className='bxresources-lnksinc'>
                        <div className='bxresources-itms'>
                            <div className='bxres-itms-inc'><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 13 13" fill="none">
  <path d="M3.9182 6.49856C3.9182 5.13556 3.9182 4.45406 4.3397 4.03056C4.7617 3.60706 5.4402 3.60706 6.7982 3.60706H8.2382C9.5957 3.60706 10.2742 3.60706 10.6962 4.03056C11.1182 4.45406 11.1182 5.13556 11.1182 6.49856V8.90856C11.1182 10.2716 11.1182 10.9531 10.6962 11.3766C10.2747 11.8001 9.5957 11.8001 8.2382 11.8001H6.7982C5.4402 11.8001 4.7617 11.8001 4.3397 11.3766C3.9182 10.9531 3.9182 10.2716 3.9182 8.90856V6.49856Z" fill="white"/>
  <path opacity="0.5" d="M2.70419 2.38605C2.11819 2.97155 2.11819 3.91455 2.11819 5.80005V6.80005C2.11819 8.68555 2.11819 9.62855 2.70419 10.214C3.01269 10.523 3.42069 10.669 4.01419 10.738C3.91819 10.318 3.91819 9.74005 3.91819 8.90805V6.49855C3.91819 5.13555 3.91819 4.45405 4.33969 4.03055C4.76169 3.60705 5.44019 3.60705 6.79819 3.60705H8.23819C9.06419 3.60705 9.63819 3.60705 10.0572 3.70205C9.98819 3.10555 9.84219 2.69605 9.53219 2.38605C8.94669 1.80005 8.00369 1.80005 6.11819 1.80005C4.23269 1.80005 3.28969 1.80005 2.70419 2.38605Z" fill="white"/>
</svg></div>
                            <div className='bxres-itms-txt'>Resources</div>
                            <div className='bxres-itms-inc'><svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M8.53375 0.566316C8.17873 0.211293 7.60313 0.211293 7.2481 0.566316L4.70911 3.10531L2.17012 0.566316C1.81509 0.211293 1.23949 0.211293 0.884468 0.566316C0.529445 0.921338 0.529445 1.49694 0.884468 1.85196L4.06629 5.03378C4.42131 5.3888 4.99691 5.3888 5.35193 5.03378L8.53375 1.85196C8.88877 1.49694 8.88877 0.921338 8.53375 0.566316Z" fill="white"/>
</svg></div>
                        </div>
                        <div className='bxresources-itms'>
                            <div className='bxres-itms-inc'><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
  <path d="M6.79999 1.92505C5.83581 1.92505 4.89328 2.21096 4.09159 2.74663C3.2899 3.28231 2.66505 4.04368 2.29608 4.93447C1.9271 5.82526 1.83056 6.80546 2.01866 7.75111C2.20676 8.69677 2.67106 9.56541 3.35284 10.2472C4.03463 10.929 4.90327 11.3933 5.84893 11.5814C6.79458 11.7695 7.77478 11.6729 8.66557 11.304C9.55636 10.935 10.3177 10.3101 10.8534 9.50845C11.3891 8.70676 11.675 7.76423 11.675 6.80005C11.6736 5.50754 11.1596 4.26836 10.2456 3.35441C9.33168 2.44047 8.0925 1.92641 6.79999 1.92505ZM5.5639 8.67505H8.03608C7.78437 9.53474 7.36249 10.3096 6.79999 10.9199C6.23749 10.3096 5.81562 9.53474 5.5639 8.67505ZM5.39374 7.92505C5.26937 7.18021 5.26937 6.41989 5.39374 5.67505H8.20624C8.33061 6.41989 8.33061 7.18021 8.20624 7.92505H5.39374ZM2.67499 6.80005C2.67466 6.41963 2.7272 6.04101 2.83108 5.67505H4.6339C4.52202 6.42088 4.52202 7.17922 4.6339 7.92505H2.83108C2.7272 7.55909 2.67466 7.18047 2.67499 6.80005ZM8.03608 4.92505H5.5639C5.81562 4.06536 6.23749 3.29052 6.79999 2.68021C7.36249 3.29052 7.78437 4.06536 8.03608 4.92505ZM8.96608 5.67505H10.7689C10.977 6.41061 10.977 7.18949 10.7689 7.92505H8.96608C9.07796 7.17922 9.07796 6.42088 8.96608 5.67505ZM10.4736 4.92505H8.8128C8.62143 4.17201 8.30004 3.45816 7.86311 2.81567C8.42133 2.96568 8.94196 3.23086 9.39155 3.59414C9.84113 3.95743 10.2097 4.41078 10.4736 4.92505ZM5.73687 2.81567C5.29994 3.45816 4.97855 4.17201 4.78718 4.92505H3.1264C3.39027 4.41078 3.75885 3.95743 4.20843 3.59414C4.65802 3.23086 5.17865 2.96568 5.73687 2.81567ZM3.1264 8.67505H4.78718C4.97855 9.42809 5.29994 10.1419 5.73687 10.7844C5.17865 10.6344 4.65802 10.3692 4.20843 10.006C3.75885 9.64267 3.39027 9.18932 3.1264 8.67505ZM7.86311 10.7844C8.30004 10.1419 8.62143 9.42809 8.8128 8.67505H10.4736C10.2097 9.18932 9.84113 9.64267 9.39155 10.006C8.94196 10.3692 8.42133 10.6344 7.86311 10.7844Z" fill="white"/>
</svg></div>
                            <div className='bxres-itms-txt'>Website</div>
                        </div>
                        <div className='bxresources-arrow-icon'><svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.31952 1.21051C-0.106507 1.63654 -0.106507 2.32726 0.31952 2.75329L3.36631 5.80008L0.31952 8.84687C-0.106507 9.2729 -0.106507 9.96363 0.31952 10.3897C0.745546 10.8157 1.43627 10.8157 1.8623 10.3897L5.68048 6.57147C6.10651 6.14544 6.10651 5.45472 5.68048 5.02869L1.8623 1.21051C1.43627 0.784485 0.745546 0.784485 0.31952 1.21051Z" fill="white"/>
</svg></div>
                    </div>
                </div>
                
                <div className='bxact-hide-item'>Hide Social Handles/Links</div>
            </div>
        </div>
        {/* BODY SECTION STARTS HERE */}
    </div>
  );
}

export default CoinowlPage;