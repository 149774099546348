import { useEffect, useState, React } from 'react';
import { Link } from "react-router-dom";
import HeaderAR from "../headerAR/headerar";
import FooterAR from "../footerAR/footerar";
import './arprivacypolicy.scss';

const ARPrivacyPolicy = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])
  
  return (
    <div class="main-fluid">
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="d-none d-md-block">
        <div class="privacy-policy-bannermain-ar">
          <div class="privacy-policy-banner-con-ar">
            <div class="privacy-policy-banner-sec-ar">
              <h1 class="privacy-policy-heading-ar">سياسة الخصوصية</h1>
            </div>
          </div>
        </div>
        <div class="privacy-policy-banner-con-ar">
          <div class="pri-pol-conent-ar pt-4">
            <p>ترحب بك شركة كيمجي رامداس لخدمات التأمين في موقعها الإلكتروني وتتطلع إلى تفاعل مُجدي معك</p>

            <p>تلتزم شركة كيمجي رامداس لخدمات التأمين بالحفاظ على خصوصية بياناتك. ونقصد بـ "بياناتك" أي معلومات عنك، تقدمها إلينا، بنفسك أو من خلال أطراف ثالثة. تتعلق هذه السياسة بخصوصية بياناتك على موقع</p>

            <p>تحترم شركة كيمجي رامداس لخدمات التأمين حقك في الخصوصية. إذ تتمتع أي معلومات شخصية تشاركها معنا بالخصوصية ونحتفظ بها في سرية تامة</p>

            <p>تضمن لك شركة كيمجي رامداس لخدمات التأمين أنه لن يتم استخدام/الكشف عن بياناتك الشخصية من قبلها، باستثناء لغرض القيام بالأعمال التجارية المستهدفة معك، أو إذا كان مطلوبًا الكشف عنها بموجب الإجراءات القانونية الواجبة.</p>

            <p>تضمن لك شركة كيمجي رامداس لخدمات التأمين، أنه في حالة مشاركة بياناتك الشخصية مع الشركات التابعة لها وشركائها التجاريين وما إلى ذلك، فإن مشاركة البيانات هذه ستكون لغرض القيام بالأعمال التجارية المستهدفة معك</p>

            <p>تحتفظ شركة كيمجي رامداس لخدمات التأمين بحقوقها في جمع وتحليل ونشر أنماط الاستخدام الإجمالي للموقع لجميع زوارها بهدف تعزيز الخدمات المقدمة لزوارها. وهذا يشمل مشاركة البيانات مع الشركات التابعة لها وشركائها التجاريين كممارسة تجارية عامة.</p>

            <p>يجوز لشركة كيمجي رامداس لخدمات التأمين في إطار أعمالها أن تعقد مسابقات واستقصاءات مباشرة على النحو الذي يسمح به القانون وتحتفظ بحقها في استخدام ونشر المعلومات التي يتم جمعها لتعزيز خدماتها للزوار. ويشمل ذلك أيضا مشاركة البيانات مع الشركات التابعة لها وشركائها التجاريين كممارسة تجارية عامة</p>

            <p>بينما تضمن لك شركة كيمجي رامداس لخدمات التأمين بأنها ستبذل قصارى جهدها لضمان خصوصية وأمن بياناتك الشخصية، فإنها لن تكون مسؤولة بأي شكل من الأشكال عن أي انتهاك أو إساءة استخدام لبياناتك الشخصية من قبل أشخاص غير مصرح لهم بالاستخدام، نتيجة لإساءة استخدام بيئة الإنترنت</p>

            <p>تحتفظ شركة كيمجي رامداس لخدمات التأمين بحقوقها في مراجعة سياسة الخصوصية هذه من وقت لآخر، وفقًا لتقديرها، بهدف جعل السياسة أكثر ملاءمة للمستخدمين</p>

            <p>خلال تصميم موقعنا على الانترنت، حرصنا على لفت انتباهك إلى سياسة الخصوصية هذه حتى تكون على دراية تامة بالشروط التي قد تقرر بموجبها مشاركة بياناتك الشخصية معنا. وفقًا لذلك، إذا قررت مشاركة بياناتك الشخصية معنا، فستفترض شركة كيمجي رامداس لخدمات التأمين أنه ليس لديك اعتراضات على شروط هذه الخصوصية
</p>
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="privacy-policy-bannermain-ar hidden-desktop hidden-md-device hidden-lg">
        <div class="pri-pol-conent-ar">
          <h1 class="privacy-title-ar">Privacy Policy</h1>
          <p>ترحب بك شركة كيمجي رامداس لخدمات التأمين في موقعها الإلكتروني وتتطلع إلى تفاعل مُجدي معك</p>

          <p>تلتزم شركة كيمجي رامداس لخدمات التأمين بالحفاظ على خصوصية بياناتك. ونقصد بـ "بياناتك" أي معلومات عنك، تقدمها إلينا، بنفسك أو من خلال أطراف ثالثة. تتعلق هذه السياسة بخصوصية بياناتك على موقع</p>

          <p>تحترم شركة كيمجي رامداس لخدمات التأمين حقك في الخصوصية. إذ تتمتع أي معلومات شخصية تشاركها معنا بالخصوصية ونحتفظ بها في سرية تامة</p>

          <p>تضمن لك شركة كيمجي رامداس لخدمات التأمين أنه لن يتم استخدام/الكشف عن بياناتك الشخصية من قبلها، باستثناء لغرض القيام بالأعمال التجارية المستهدفة معك، أو إذا كان مطلوبًا الكشف عنها بموجب الإجراءات القانونية الواجبة.</p>

          <p>تضمن لك شركة كيمجي رامداس لخدمات التأمين، أنه في حالة مشاركة بياناتك الشخصية مع الشركات التابعة لها وشركائها التجاريين وما إلى ذلك، فإن مشاركة البيانات هذه ستكون لغرض القيام بالأعمال التجارية المستهدفة معك.</p>

          <p>تحتفظ شركة كيمجي رامداس لخدمات التأمين بحقوقها في جمع وتحليل ونشر أنماط الاستخدام الإجمالي للموقع لجميع زوارها بهدف تعزيز الخدمات المقدمة لزوارها. وهذا يشمل مشاركة البيانات مع الشركات التابعة لها وشركائها التجاريين كممارسة تجارية عامة.</p>

          <p>يجوز لشركة كيمجي رامداس لخدمات التأمين في إطار أعمالها أن تعقد مسابقات واستقصاءات مباشرة على النحو الذي يسمح به القانون وتحتفظ بحقها في استخدام ونشر المعلومات التي يتم جمعها لتعزيز خدماتها للزوار. ويشمل ذلك أيضا مشاركة البيانات مع الشركات التابعة لها وشركائها التجاريين كممارسة تجارية عامة.</p>

          <p>بينما تضمن لك شركة كيمجي رامداس لخدمات التأمين بأنها ستبذل قصارى جهدها لضمان خصوصية وأمن بياناتك الشخصية، فإنها لن تكون مسؤولة بأي شكل من الأشكال عن أي انتهاك أو إساءة استخدام لبياناتك الشخصية من قبل أشخاص غير مصرح لهم بالاستخدام، نتيجة لإساءة استخدام بيئة الإنترنت</p>

          <p>تحتفظ شركة كيمجي رامداس لخدمات التأمين بحقوقها في مراجعة سياسة الخصوصية هذه من وقت لآخر، وفقًا لتقديرها، بهدف جعل السياسة أكثر ملاءمة للمستخدمين</p>

          <p>خلال تصميم موقعنا على الانترنت، حرصنا على لفت انتباهك إلى سياسة الخصوصية هذه حتى تكون على دراية تامة بالشروط التي قد تقرر بموجبها مشاركة بياناتك الشخصية معنا. وفقًا لذلك، إذا قررت مشاركة بياناتك الشخصية معنا، فستفترض شركة كيمجي رامداس لخدمات التأمين أنه ليس لديك اعتراضات على شروط هذه الخصوصية</p>
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARPrivacyPolicy;