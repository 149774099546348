import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './yachtinsurance.scss';

const YachtInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }

  }, [])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div className="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div className="dsk-tab-btn-viewmode-en">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1>Personal Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-en">
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "motor-insurance"}>Motor Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "travel-insurance"}>Travel Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "personal-accident"}>Personal Accident Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "relocation-insurance"}>Relocation Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "health-insurance"}>Health Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "art-insurance"}>Art insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "golf-insurance"}>Golf Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "housemaid-insurance"}>Housemaid Insurance</div>
                <div className="inner-banner-btn-item-active-en" onClick={() => window.location.href = "yacht-insurance"}>Yacht Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "home-insurance"}>Home Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "life-insurance"}>Life Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="bdy-innercont-en">
            <div className="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-lft-en">
                <h2 className="pagetitle-en">Yacht Insurance</h2>
                <p className="pg-txt-con">A Comprehensive list of products</p>

                <div className="innbdy-lft-keypoint-en">
                  <div className="innbdy-lft-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-lft-keypoint-item-en">Peaceful underwriting procedure with a customized cover.</div>
                  <div className="innbdy-lft-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-lft-keypoint-item-en">Local and regional trading limits.</div>
                  <div className="innbdy-lft-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-lft-keypoint-item-en">Specialized teams to assist you.</div>
                  <div className="innbdy-lft-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-lft-keypoint-item-en">With Our Insurance solution, You will sail with Peace of Mind.</div>
                </div>
                <h3 className="bdyinner-heading-en">Main Cover / Yacht Insurance</h3>

                <p className="pg-txt-con">KR Insurance Services offers yacht insurance plan, which is a highly flexible cover to cater the boat owners' requirements; whether an accidental loss or any damage to the ship including any liabilities towards third parties. Your ship is covered whilst in use or moored afloat, or it could be ashore and also during transit.</p>

                <p className="pg-txt-con">Some of the additional incentives are Uninsured Boater coverage, Search & Rescue and bonuses like a no claim rebate.</p>

                <h3 className="bdyinner-whykr-title-en">Why KR Insurance Services ?</h3>

                <div className="clearfix"></div>

                <div className="bdyinner-whykr-keypoints-en">
                  <div className="bdyinner-whykr-kepoints-ftitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
                  <div className="bdyinner-whykr-kepoints-secitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
                  <div className="bdyinner-whykr-kepoints-ftitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
                  <div className="bdyinner-whykr-kepoints-secitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
                </div>

                {/* ask for quotes button starts here */}
                <div className="askquto-btn-sec">
                  <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
                </div>
                {/* ask for quotes button starts here */}
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/yatch_insurance.png"} alt="yatch insurance" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1>Personal Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-en">
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "motor-insurance"}>Motor Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "travel-insurance"}>Travel Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "personal-accident"}>Personal Accident Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "relocation-insurance"}>Relocation Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "health-insurance"}>Health Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "art-insurance"}>Art insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "golf-insurance"}>Golf Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "housemaid-insurance"}>Housemaid Insurance</div>
                <div className="inner-banner-btn-item-active-en" onClick={() => window.location.href = "yacht-insurance"}>Yacht Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "home-insurance"}>Home Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "life-insurance"}>Life Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="tab-imgtxt-en">
            <h2>Yacht Insurance</h2>
            <div className="tabs-cont-en">
              <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/yatch_insurance.png"} alt="yatch insurance" />
              <p>A Comprehensive list of products</p>

              <p><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />Peaceful underwriting procedure with a customized cover.</p>
              <p><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />Local and regional trading limits.</p>
              <p><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />Specialized teams to assist you.</p>
              <p><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />With Our Insurance solution, You will sail with Peace of Mind.</p>

              <p className="tab-subhead-title-en">Main Cover / Yacht Insurance</p>

              <p>KR Insurance Services offers yacht insurance plan, which is a highly flexible cover to cater the boat owners' requirements; whether an accidental loss or any damage to the ship including any liabilities towards third parties. Your ship is covered whilst in use or moored afloat, or it could be ashore and also during transit.</p>

              <p>Some of the additional incentives are Uninsured Boater coverage, Search & Rescue and bonuses like a no claim rebate.</p>

              <h3 className="tab-whykrinsu-title-en">Why KR Insurance Services ?</h3>

              <div className="tab-whykrinsu-pins-list-en">
                <div className="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
                <div className="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
                <div className="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
                <div className="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
              </div>
              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec">
                <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
              </div>
              {/* ask for quotes button starts here */}
            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div className="inn-banner-bg-main-en hidden-desktop hidden-md-device hidden-lg">
        <div className="dropdown-sec">
          <select className="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"motor-insurance"}>Motor Insurance</option>
            <option value={"travel-insurance"}>Travel Insurance</option>
            <option value={"personal-accident"}>Personal Accident Insurance</option>
            <option value={"relocation-insurance"}>Relocation Insurance</option>
            <option value={"health-insurance"}>Health Insurance</option>
            <option value={"art-insurance"}>Art insurance</option>
            <option value={"golf-insurance"}>Golf Insurance</option>
            <option value={"housemaid-insurance"}>Housemaid Insurance</option>
            <option value={"home-insurance"}>Home Insurance</option>
            <option value={"yacht-insurance"} selected className="active">Yacht Insurance</option>
            <option value={"life-insurance"}>Life Insurance</option>
          </select>
        </div>
        <div className="innner-mob-body-main-en">
          <h1 className="inner-mob-heading-en">Yacht Insurance</h1>
          <div className="innner-mob-body-img-en">
            <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/yatch_insurance.png"} alt="yatch insurance" />
          </div>
          <p className="inner-mob-body-txt-en">A Comprehensive list of products</p>

          <div className="inner-mob-pins-list-en">
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Peaceful underwriting procedure with a customized cover.</div>

            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Local and regional trading limits.</div>

            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">Specialized teams to assist you.</div>

            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-en">With Our Insurance solution, You will sail with Peace of Mind.</div>
          </div>

          <p className="inner-mob-body-txt-en">These are the key features of KR Insurance Services Yacht Insurance</p>

          <p className="inner-mob-body-txt-en">It is the most comprehensive protection plan for your precious boat. It could be brand new power packed motor cruiser or a pleasant sailing dingy.</p>

          <h3 className="inner-mob-main-cover-title-en">Main Cover / Yacht Insurance</h3>

          <p className="inner-mob-body-txt-en">KR Insurance Services offers yacht insurance plan, which is a highly flexible cover to cater the boat owners' requirements; whether an accidental loss or any damage to the ship including any liabilities towards third parties. Your ship is covered whilst in use or moored afloat, or it could be ashore and also during transit.</p>

          <p className="inner-mob-body-txt-en">Some of the additional incentives are Uninsured Boater coverage, Search & Rescue and bonuses like a no claim rebate.</p>

          <h3 className="inner-mob-main-cover-title-en">Why KR Insurance Services ?</h3>

          <div className="inner-mob-bdy-whykrlist-en">
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>
          
          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec">
            <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
          </div>
          {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default YachtInsurance;