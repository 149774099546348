import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import { Helmet } from 'react-helmet-async';
import './arclaimconsultancyservices.scss';

const ARClaimConsultancyServices = () => {

  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }

  }, [])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div className="main-fluid">
      <Helmet>
        <title>‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬.</title>
        <meta name="keywords" content="Employee Wellness Progaram Insurance" />
        <meta name="description" content="KR ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫لوسطاء‬ ‫موثوق‬ ‫خيار‬ ‫هو‬ ‫للتأمين‬ ‫عمان‬. ‫عمان‬ ‫في‬ ‫التأمين‬ ‫شركات‬ ‫ألفضل‬ ‫التأمين‬ ‫حلول‬ ‫إلى‬ ‫للوصول‬ ‫معنا‬ ‫شارك‬." />
      </Helmet>
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div className="dsk-tab-btn-viewmode-ar">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-ar">
              <h1>خدمات استشارات للمطالبة</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-ar">
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "report-a-claim-ar"}>الإبلاغ عن مطالبة </div>
                <div className="inner-banner-btn-item-active-ar" onClick={() => window.location.href = "claim-consultancy-services-ar"}>خدمات استشارات المطالبات </div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "service-excellence-ar"}>التميز في الخدمة </div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "in-house-kr-automotive-garage-ar"}>مرأب سيارات شركة كيمجي رامداس الداخلية</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "erp-based-solutions-ar"}>الحلول القائمة على تخطيط موارد المؤسسة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="bdy-innercont-en">
            <div className="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-lft-ar">
                <img src={process.env.PUBLIC_URL + "/customer_service/tab_images/claim_handling_ar.png"} alt="خدمات استشارات المطالبات" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-rgt-ar">
                <h2 className="pagetitle-ar">خدمات استشارات المطالبات</h2>
                <div className="clearfix"></div>
                <div className="innbdy-rgt-keypoint-ar">
                  <div className="innbdy-rgt-keypoint-item-ar">فريق متخصص ومؤهل وذوي خبرة للتعامل مع خطوط مختلفة من المطالبات</div>

                  <div className="innbdy-rgt-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-rgt-keypoint-item-ar">خبرة في التعامل مع مطالبات المعاملات والمعقدة لعملائنا المرموقين</div>

                  <div className="innbdy-rgt-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-rgt-keypoint-item-ar">علاقات قوية مع جميع شركات التأمين ومقيّمي الخسائر عبر منطقة دول مجلس التعاون الخليجي</div>

                  <div className="innbdy-rgt-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-rgt-keypoint-item-ar">نهج المطالبة الخاصة بالمنتج والعمليات الشائعة</div>

                  <div className="innbdy-rgt-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-rgt-keypoint-item-ar">التقنية تساعد على فهم بنود وشروط البوليصة</div>

                  <div className="innbdy-rgt-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="innbdy-rgt-keypoint-item-ar">برامج تدريبية تعتمد على العملاء الذين يرغبون في المشاركة الدورية لنظام المعلومات الإدارية</div>
                  <div className="innbdy-rgt-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                </div>

                <h3 className="bdyinner-whykr-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>
                <div className="clearfix"></div>
                <div className="bdyinner-rgt-whykr-keypoints-ar">
                  <div className="bdyinner-whykr-kepoints-ftitem-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="bdyinner-lft-whykr-kepoints-secitem-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div className="bdyinner-whykr-kepoints-ftitem-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                  <div className="bdyinner-lft-whykr-kepoints-secitem-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                </div>

              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-ar">
              <h1 className="claimhandinsu-banner-heading-ar">خدمات استشارات للمطالبة</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-ar">
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "report-a-claim-ar"}>الإبلاغ عن مطالبة </div>
                <div className="inner-banner-btn-item-active-ar" onClick={() => window.location.href = "claim-consultancy-services-ar"}>خدمات استشارات المطالبات </div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "service-excellence-ar"}>التميز في الخدمة </div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "in-house-kr-automotive-garage-ar"}>مرأب سيارات شركة كيمجي رامداس الداخلية</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "erp-based-solutions-ar"}>الحلول القائمة على تخطيط موارد المؤسسة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="tab-imgtxt-ar">
            <h2>خدمات استشارات المطالبات</h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/customer_service/tab_images/claim_handling_ar.png"} alt="خدمات استشارات المطالبات" />

              <p className="tabimg-list">
                فريق متخصص ومؤهل وذوي خبرة للتعامل مع خطوط مختلفة من المطالبات
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
              </p>
              <p className="tabimg-list">
                خبرة في التعامل مع مطالبات المعاملات والمعقدة لعملائنا المرموقين
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
              </p>
              <p className="tabimg-list">
                علاقات قوية مع جميع شركات التأمين ومقيّمي الخسائر عبر منطقة دول مجلس التعاون الخليجي
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
              </p>
              <p className="tabimg-list">
                نهج المطالبة الخاصة بالمنتج والعمليات الشائعة
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
              </p>
              <p className="tabimg-list-wdth">
                التقنية تساعد على فهم بنود وشروط البوليصة
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
              </p>
              <p className="tabimg-list-wdth">
                برامج تدريبية تعتمد على العملاء الذين يرغبون في المشاركة الدورية لنظام المعلومات الإدارية
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" />
              </p>

              <h3 className="tab-whykrinsu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>

              <div className="tab-whykrinsu-pins-list-ar">
                <div className="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div className="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div className="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div className="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div className="inn-banner-bg-main-en hidden-desktop hidden-md-device hidden-lg">
        <div className="dropdown-sec">
          <select className="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"report-a-claim-ar"}>الإبلاغ عن مطالبة </option>
            <option value={"claim-consultancy-services-ar"} selected className="active">خدمات استشارات المطالبات</option>
            <option value={"service-excellence-ar"}>التميز في الخدمة </option>
            <option value={"in-house-kr-automotive-garage-ar"}>مرأب سيارات شركة كيمجي رامداس الداخلية</option>
            <option value={"erp-based-solutions-ar"}>الحلول القائمة على تخطيط موارد المؤسسة</option>
          </select>
        </div>
        <div className="innner-mob-body-main-en">
          <h1 className="inner-mob-heading-en">خدمات استشارات المطالبات</h1>
          <div className="innner-mob-body-img-en">
            <img src={process.env.PUBLIC_URL + "/customer_service/tab_images/claim_handling_ar.png"} alt="خدمات استشارات المطالبات" />
          </div>

          <div className="inner-mob-pins-list-ar">
            <div className="inner-mob-pins-list-item-ar">فريق متخصص ومؤهل وذوي خبرة للتعامل مع خطوط مختلفة من المطالبات</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">خبرة في التعامل مع مطالبات المعاملات والمعقدة لعملائنا المرموقين</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">علاقات قوية مع جميع شركات التأمين ومقيّمي الخسائر عبر منطقة دول مجلس التعاون الخليجي</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">نهج المطالبة الخاصة بالمنتج والعمليات الشائعة</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">التقنية تساعد على فهم بنود وشروط البوليصة</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">برامج تدريبية تعتمد على العملاء الذين يرغبون في المشاركة الدورية لنظام المعلومات الإدارية</div>
            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>

          <h3 className="inner-mob-main-cover-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>

          <div className="inner-mob-bdy-whykrlist-ar">
            <div className="inner-mob-bdy-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar"> أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>

        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARClaimConsultancyServices;
