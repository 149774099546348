import { Link } from "react-router-dom";
import { useState, useEffect, React } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './reportaclaim.scss';

const ReportaClaim = () => {

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoader(false);
    }, 2000);
    return () => clearTimeout(timer);

  }, []);

  useEffect(() => {

    // FOR DESKTOP 
    var script = document.createElement("script");
    script.id = "ff-script";
    script.src = "https://formfacade.com/include/118341088851196171519/form/1FAIpQLSf4juQKJx37puPKBUkunZVkTJc5sC4TgyEBsejvIB3Q8VoyUA/classic.js?div=ff-compose";
    script.defer = true;
    script.async = true;
    document.body.appendChild(script);

    // FOR TAB 
    var scriptTab = document.createElement("script");
    scriptTab.id = "ff-script";
    scriptTab.src = "https://formfacade.com/include/118341088851196171519/form/1FAIpQLSf4juQKJx37puPKBUkunZVkTJc5sC4TgyEBsejvIB3Q8VoyUA/classic.js?div=ff-compose-Tab";
    scriptTab.defer = true;
    scriptTab.async = true;
    document.body.appendChild(scriptTab);

    // FOR MOBILE 
    var scriptMob = document.createElement("script");
    scriptMob.id = "ff-script";
    scriptMob.src = "https://formfacade.com/include/118341088851196171519/form/1FAIpQLSf4juQKJx37puPKBUkunZVkTJc5sC4TgyEBsejvIB3Q8VoyUA/classic.js?div=ff-compose-Mob";
    scriptMob.defer = true;
    scriptMob.async = true;
    document.body.appendChild(scriptMob);

    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }

  }, [])


  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [loader, setLoader] = useState(true)

  return (
    <div className="main-fluid">
      {loader ?
        <div className="loadermain">
          <div className="loader" id="timer"></div>
        </div>
        : null}
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div className="dsk-view">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1>Customer Services</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-en">
                <div className="inner-banner-btn-item-active-en" onClick={() => window.location.href = "report-a-claim"}>Report a Claim</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "claim-consultancy-services"}>Claim Consultancy Services</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "service-excellence"}>Service Excellence</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "in-house-kr-automotive-garage"}>In house KR Automotive Garage</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "erp-based-solutions"}>ERP Based Solutions</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="bdy-innercont-en">
            <div className="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-lft-en">
                  <iframe width="100%" height="900" scrolling="no" seamless="seamless" frameborder="0" src="https://formfaca.de/sm/9D1zdrtid"></iframe>
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/customer_service/tab_images/claim_handling.png"} alt="Claim Handling" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}


      {/* TAB VIEW TOP AND TABS SECTION STARTS HERE */}
      <div className="tab-view">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1 className="reportclaim-banner-heading-en">Customer Services</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-en">
                <div className="inner-banner-btn-item-active-en" onClick={() => window.location.href = "report-a-claim"}>Report a Claim</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "claim-consultancy-services"}>Claim Consultancy Services</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "service-excellence"}>Service Excellence</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "in-house-kr-automotive-garage"}>In house KR Automotive Garage</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "erp-based-solutions"}>ERP Based Solutions</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="reportclaim-bdy-en">
            <div className="tabreportclaim-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div className="tabreportclaim-dlex-lft-en">
                {/* <h2 className="tabpagetitle-en">Report a Claim</h2> */}

                {/* <div id="ff-compose-Tab"></div> */}

                {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSf4juQKJx37puPKBUkunZVkTJc5sC4TgyEBsejvIB3Q8VoyUA/viewform?embedded=true" width="100%" height="1500" frameborder="0" marginheight="0" marginwidth="0" style={{margin:"0px"}}>Loading…</iframe> */}

                <iframe width="100%" height="900" frameborder="0" src="https://formfaca.de/sm/9D1zdrtid"></iframe>



                {/* <h3 className="reportclaim-whykrinsu-title-en">Why KR Insurance Services ?</h3>
              <div className="clearfix"></div>

              <div className="reportclaim-pins-list-lftdksp-en">
                <div className="reportclaim-lt-one-lftdksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
                <div className="reportclaim-lt-two-lftdksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
                <div className="reportclaim-lt-one-lftdksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
                <div className="reportclaim-lt-two-lftdksp-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
              </div> */}

              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div className="tabreportclaim-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/customer_service/tab_images/claim_handling.png"} alt="Claim Handling" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>


          </div>
        </div>
      </div>
      {/* TAB VIEW TOP AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div className="inn-banner-bg-main-en mobile-view">
        <div className="dropdown-sec">
          <select className="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"report-a-claim"} selected className="active">Report a Claim</option>
            <option value={"claim-consultancy-services"}>Claim Consultancy Services</option>
            <option value={"service-excellence"}>Service Excellence</option>
            <option value={"in-house-kr-automotive-garage"}>In house KR Automotive Garage</option>
            <option value={"erp-based-solutions"}>ERP Based Solutions</option>
          </select>
        </div>
        <div className="innner-mob-body-main-en">
          {/* <h1 className="inner-mob-heading-en">Report a Claim</h1> */}
          {/* <div className="innner-mob-body-img-en">
            <img src={process.env.PUBLIC_URL + "/customer_service/tab_images/claim_handling.png"} alt="Claim Handling" />
          </div> */}

          {/* <div id="ff-compose-Mob"></div> */}

          {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSf4juQKJx37puPKBUkunZVkTJc5sC4TgyEBsejvIB3Q8VoyUA/viewform?embedded=true" width="100%" height="1500" frameborder="0" marginheight="0" marginwidth="0" style={{margin:"0px"}}>Loading…</iframe> */}

          <iframe width="100%" height="950" frameborder="0" src="https://formfaca.de/sm/9D1zdrtid"></iframe>

        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ReportaClaim;
