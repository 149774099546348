import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './employeewellnessprogram.scss';

const EmployeeWellnessProgram = () => {

  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }

  }, [])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div className="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div className="d-none d-md-block">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1>Valued Services</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-en">
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "risk-inspection-services"}>Risk Inspection Services</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "insurance-audits"}>Insurance Audits</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "valuation-services"}>Valuation services</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "thermography-testing-insurance"}>Thermography Testing</div>
                {/* <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "policy-renewalalerts"}>Policy Renewal Alerts</div> */}
                <div className="inner-banner-btn-item-active-en" onClick={() => window.location.href = "employee-wellness-program"}>Employee Wellness Program</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="bdy-innercont-en">
            {/* <h2 className="pagetitle-en">Insurance Audits</h2> */}
            <div className="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-lft-en">
                <h2 className="pagetitle-en">KR Insurance's People Wellness Program</h2>

                <p className="pg-txt-con"><strong>We create customized and exciting wellness programs for you!</strong></p>

                <p className="pg-txt-con">In our endeavor to deliver the best services and to add value to your health insurance program, we propose value-added services to the employees associated with your esteemed organization. This is purely an initiative taken at our end for the benefit of your employees with nil cost implications.</p>

                <p className="pg-txt-con">KR Insurance Services have tied up with renowned hospitals in Oman for bringing health awareness and stimulating good health practices among all your employees.</p>

                <p className="pg-txt-con">We at KR Insurance Services look forward for conducting an insurance audit for your organization’s existing insurance policies and ensuring best insurance covers for the risk associated with your business.</p>

                <h3 className="bdyinn-lft-page-sbu-title-en">Health Sessions</h3>

                <div className="clearfix"></div>
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/riskmanagementservices_images/tab_images/employee_wellness_program.png"} alt="employee wellness program" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

            <p className="pg-txt-con">The health wellness session will be conducted by renowned medical practitioners and the duration of the session shall be between 25 to 40 minutes. The sessions will be delivered in your office via audio-video presentations. You can choose any one of the following topics for our proposed health wellness sessions - </p>

            <h3 className="bdyinn-page-sbu-title-en">Health Camps </h3>

            <p className="pg-txt-con">The health camp will be organized inside your office building by a medical practitioner and nurses, supported by best-in-class medical equipment for conducting it. We will be requiring space i.e. a meeting room or some unoccupied area, to set up our health camp and to maintain employee privacy. Employees will be benefitted from one-to-one consultation with the General Practitioner. All the employees, attending the health camp will be provided with their medical reports.</p>

            <p className="pg-txt-con">Please contact us for more details <a href="mailto:insurance@kr.om">insurance@kr.om</a></p>



            <h3 className="bdyinner-whykr-title-en">Why KR Insurance Services ?</h3>

            <div className="clearfix"></div>

            <div className="bdyinner-whykr-keypoints-en">
              <div className="bdyinner-whykr-kepoints-ftitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
              <div className="bdyinner-whykr-kepoints-secitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
              <div className="bdyinner-whykr-kepoints-ftitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
              <div className="bdyinner-whykr-kepoints-secitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
            </div>

          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div className="inn-banner-bg-main-en hidden-desktop hidden-md-device hidden-lg">
        <div className="dropdown-sec">
          <select className="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"risk-inspection-services"}>Risk Inspection Services</option>
            <option value={"insurance-audits"}>Insurance Audits</option>
            <option value={"valuation-services"}>Valuation Services</option>
            <option value={"thermography-testing-insurance"}>Thermography Testing</option>
            {/* <option value={"policy-renewalalerts"}>Policy Renewal Alerts</option> */}
            <option value={"employee-wellness-program"} selected className="active">Employee Wellness Program</option>
          </select>
        </div>
        <div className="innner-mob-body-main-en">
          <h1 className="inner-mob-heading-en">KR Insurance's People Wellness Program</h1>
          <div className="innner-mob-body-img-en">
            <img src={process.env.PUBLIC_URL + "/riskmanagementservices_images/tab_images/employee_wellness_program.png"} alt="employee wellness program" />
          </div>

          <p className="inner-mob-body-txt-en"><strong>We create customized and exciting wellness programs for you!</strong></p>

          <p className="inner-mob-body-txt-en">In our endeavor to deliver the best services and to add value to your health insurance program, we propose value-added services to the employees associated with your esteemed organization. This is purely an initiative taken at our end for the benefit of your employees with nil cost implications.</p>

          <p className="inner-mob-body-txt-en">KR Insurance Services have tied up with renowned hospitals in Oman for bringing health awareness and stimulating good health practices among all your employees.</p>

          <p className="inner-mob-body-txt-en">We at KR Insurance Services look forward for conducting an insurance audit for your organization’s existing insurance policies and ensuring best insurance covers for the risk associated with your business.</p>

          <h3 className="inner-mob-main-cover-title-en">Health Sessions</h3>

          <p className="inner-mob-body-txt-en">The health wellness session will be conducted by renowned medical practitioners and the duration of the session shall be between 25 to 40 minutes. The sessions will be delivered in your office via audio-video presentations. You can choose any one of the following topics for our proposed health wellness sessions -</p>

          <h3 className="inner-mob-main-cover-title-en">Health Camps</h3>

          <p className="inner-mob-body-txt-en">The health camp will be organized inside your office building by a medical practitioner and nurses, supported by best-in-class medical equipment for conducting it. We will be requiring space i.e. a meeting room or some unoccupied area, to set up our health camp and to maintain employee privacy. Employees will be benefitted from one-to-one consultation with the General Practitioner. All the employees, attending the health camp will be provided with their medical reports.</p>

          <p className="inner-mob-body-txt-en">Please contact us for more details <a href="mailto:insurance@kr.om">insurance@kr.om</a></p>


          <h3 className="inner-mob-main-cover-title-en">Why KR Insurance Services ?</h3>

          <div className="inner-mob-bdy-whykrlist-en">
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>

        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default EmployeeWellnessProgram;
