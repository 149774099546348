import { useEffect, useState, React } from 'react';
import { Link } from "react-router-dom";
import HeaderEN from "../headerEN/headeren";
import FooterEN from "../footerEN/footer";
import './privacypolicy.scss';

const HomePage = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])
  
  return (
    <div class="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="d-none d-md-block">
        <div class="privacy-policy-bannermain-en">
          <div class="privacy-policy-banner-con-en">
            <div class="privacy-policy-banner-sec-en">
              <h1 class="privacy-policy-heading-en">Privacy Policy</h1>
            </div>
          </div>
        </div>
        <div class="privacy-policy-banner-con-en">
          <div class="pri-pol-conent-en pt-4">
            <p>KR Insurance services welcome you to its website and looks forward to a meaningful interaction with you.</p>
            <p>KR Insurance services are committed to keeping your information private. By 'your information' we mean any information about you that you or third parties provide to us. This policy relates to the privacy of your information at www.krinsurance.com</p>

            <p>KR Insurance services respect your right to privacy. Any personal information that you share with us shall be entitled to privacy and kept confidential.</p>

            <p>KR Insurance services assures you that your personal information shall not be used/disclosed by it, save for the purpose of doing the intended business with you, or if required to be disclosed under the due process of law.</p>

            <p>KR Insurance services assures you that in the event of your personal information being shared with its subsidiaries, business associates etc., such sharing of information shall be for the purpose of doing the intended business with you.</p>

            <p>KR Insurance services reserves its rights to collect, analyze and disseminate aggregate site usage patterns of all its visitors with a view to enhancing services to its visitors. This includes sharing the information with its subsidiaries, and business associates as a general business practice.</p>

            <p>In the course of its business KR Insurance services may hold on-line contests and surveys as permitted by law and it reserves its right to use and disseminate the information so collected to enhance its services to the visitors. This shall also include sharing the information with its subsidiaries and business associates as a general business practice.</p>

            <p>While KR Insurance services assures you that it will do its best to ensure the privacy and security of your personal information, it shall not be responsible in any manner whatsoever for any violation or misuse of your personal information by unauthorized persons consequent to misuse of the internet environment.</p>

            <p>KR Insurance services reserves its rights to revise this privacy policy from time to time at its discretion with a view to making the policy more user friendly.</p>

            <p>In the design of our website, we have taken care to draw your attention to this privacy policy so that you are aware of the terms under which you may decide to share your personal information with us. Accordingly, should you choose to share your personal information with us, KR Insurance services will assume that you have no objections to the terms of this privacy</p>
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="privacy-policy-bannermain-en hidden-desktop hidden-md-device hidden-lg">
        <div class="pri-pol-conent-en">
          <h1 class="privacy-title-en">Privacy Policy</h1>
          <p>KR Insurance services welcome you to its website and looks forward to a meaningful interaction with you.</p>
          <p>KR Insurance services are committed to keeping your information private. By 'your information' we mean any information about you that you or third parties provide to us. This policy relates to the privacy of your information at www.krinsurance.com</p>

          <p>KR Insurance services respect your right to privacy. Any personal information that you share with us shall be entitled to privacy and kept confidential.</p>

          <p>KR Insurance services assures you that your personal information shall not be used/disclosed by it, save for the purpose of doing the intended business with you, or if required to be disclosed under the due process of law.</p>

          <p>KR Insurance services assures you that in the event of your personal information being shared with its subsidiaries, business associates etc., such sharing of information shall be for the purpose of doing the intended business with you.</p>

          <p>KR Insurance services reserves its rights to collect, analyze and disseminate aggregate site usage patterns of all its visitors with a view to enhancing services to its visitors. This includes sharing the information with its subsidiaries, and business associates as a general business practice.</p>

          <p>In the course of its business KR Insurance services may hold on-line contests and surveys as permitted by law and it reserves its right to use and disseminate the information so collected to enhance its services to the visitors. This shall also include sharing the information with its subsidiaries and business associates as a general business practice.</p>

          <p>While KR Insurance services assures you that it will do its best to ensure the privacy and security of your personal information, it shall not be responsible in any manner whatsoever for any violation or misuse of your personal information by unauthorized persons consequent to misuse of the internet environment.</p>

          <p>KR Insurance services reserves its rights to revise this privacy policy from time to time at its discretion with a view to making the policy more user friendly.</p>

          <p>In the design of our website, we have taken care to draw your attention to this privacy policy so that you are aware of the terms under which you may decide to share your personal information with us. Accordingly, should you choose to share your personal information with us, KR Insurance services will assume that you have no objections to the terms of this privacy</p>
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default HomePage;