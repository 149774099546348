import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './grouphealthinsurance.scss';

const GroupHealthInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }

  }, [])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-en">
        <div class="inn-banner-bg-main-en">
          <div class="inner-con-en">
            <div class="inner-banner-btn-en">
              <h1>Business Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="inner-banner-btn-en">
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "property-insurance"}>Property Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "project-insurance"}>Project Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "motor-fleet-insurance"}>Motor Fleet Insurance</div>
                <div class="inner-banner-btn-item-active-en" onClick={() => window.location.href = "group-health-insurance"}>Group Health Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "group-life-insurance"}>Group Life Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "burglary-insurance"}>Burglary Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "workmen-compensation-insurance"}>Workmen Compensation Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "group-personal-accident-insurance"}>Group Personal Accident Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "cyber-crime-insurance"}>Cyber Crime Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "jewellers-block-insurance"}>Jewellers Block Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "stock-throughput-insurance"}>Stock Throughput Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "liability-insurance"}>Liability Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "marine-insurance"}>Marine Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "energy-insurance"}>Energy Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="inner-con-en">
          <div class="bdy-innercont-en">
            <div class="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="innerbdy-dlex-lft-en">
                <h2 class="pagetitle-en">Group Health Insurance</h2>

                <p class="pg-txt-con">A business needs to ensure the safety of its first resource; its employees. The Group Health Care / Group Medi-claim policy provided by KR Insurance Services caters to all employees of the business, irrespective of the size of the entity. We provide insurance covers to large, medium and small size organizations; employee strength ranging from 5 employees onwards.</p>

                <p class="pg-txt-con">KR insurance service offers a range of medical insurance plans and healthcare solutions which is an excellent blend to suit your corporate requirements as well as your budget.</p>

                <p class="pg-txt-con"><strong>KR Insurance Services provides prompt and best value added services to corporates, the key features are –</strong></p>

                <div class="innbdy-lft-keypoint-en">
                  <div class="innbdy-lft-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="innbdy-lft-keypoint-item-en">Single window solutions through dedicated relationship manager.</div>

                  <div class="innbdy-lft-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="innbdy-lft-keypoint-item-en">Arranging wider terms, tailor made policies at best discounted pricing from the insurers.</div>

                  <div class="innbdy-lft-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                  <div class="innbdy-lft-keypoint-item-en">Clients get benefitted through our In-depth knowledge of insurance terms, policy placement, claim settlement services and other insurance practices.</div>
                </div>

              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="innerbdy-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/group_health_insurance.png"} alt="Group Health Insurance" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
            <div class="innbdy-keypoint-en">
              <div class="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="innbdy-keypoint-item-en">Hassel free &amp; prompt services leads to the savings for our clients in terms of man-hours and administrative costs.</div>

              <div class="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="innbdy-keypoint-item-en">Renewal reminder services – auto generated renewal reminders.</div>

              <div class="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="innbdy-keypoint-item-en">Insurance audit with NIL cost implications of your existing insurance policies.</div>

              <div class="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="innbdy-keypoint-item-en">Personalized claim assistance through our dedicated and experienced team.</div>

              <div class="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="innbdy-keypoint-item-en">Analysis of previous claim data to suggest improvement on loss ratios.</div>

              <div class="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="innbdy-keypoint-item-en">Priority hospitalization on case to case basis.</div>

              <div class="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="innbdy-keypoint-item-en">Through our network partners In India, we are arranging quotations for planned hospitalizations in India.</div>

              <div class="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="innbdy-keypoint-item-en">In-house medical team for obtaining second opinion on claims (if required).</div>

              <div class="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="innbdy-keypoint-item-en">Tailor made policy options matching to the different requirements of the customers.</div>

              <div class="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="innbdy-keypoint-item-en">Wellness programs – Health awareness sessions, Basic Health check-ups, Eye/Dental checkups, Art of living session for group of employees, Session with Dietician etc.</div>

              <div class="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="innbdy-keypoint-item-en">Policy awareness program for employees through – presentation, help-desk, tailor-made mailers and messages.</div>

              <div class="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="innbdy-keypoint-item-en">Weekly health tips mailer for the employees of your organization (optional).</div>

              <div class="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="innbdy-keypoint-item-en">Tagging of important clients / employees for additional attention while handling their claims and delivering services to them – red carpet services.</div>
            </div>

            <h3 class="bdyinner-heading-en">Main Cover / Group Health Insurance</h3>

            <p class="pg-txt-con">The tailor-made healthcare solutions and medical insurance plans offers the following features:- </p>

            <p class="pg-txt-con">1. Cashless treatment in Hospitals.</p>

            <p class="pg-txt-con">2. Hospitalization benefits per insured per year up to policy sum insured value.</p>

            <p class="pg-txt-con"><strong>Inpatient Treatment</strong></p>

            <p class="pg-txt-con">1. Daily Room and Board / Hospitalization Accommodation</p>

            <p class="pg-txt-con">2. Hospitalization Class</p>

            <p class="pg-txt-con">3. Intensive Care Unit</p>

            <p class="pg-txt-con">4. Consultant's, Surgeon, &amp; Anesthetist Fees</p>

            <p class="pg-txt-con">5. Diagnostic Tests</p>

            <p class="pg-txt-con">6. CT Scan, MRI and PET scans received as an in-patient</p>

            <p class="pg-txt-con">7. Various Therapies including Physiotherapy, Chemotherapy, radiation therapy etc. charges</p>

            <p class="pg-txt-con">8. Companion Room and Board Accommodation</p>

            <p class="pg-txt-con">9. In-hospital Cash Benefit (for In-patient treatment received completely free of charge)</p>

            <p class="pg-txt-con">10. Use of hospital medical equipment (e.g. heart and lung support systems, etc.)</p>

            <p class="pg-txt-con">11. Recipient Organ transplantation service, excluding any charges related to Donor</p>

            <p class="pg-txt-con">12. Medical Evacuation &amp; Repatriation costs for the transport of mortal remains to the country of origin</p>

            <p class="pg-txt-con"><strong>Out Patient Treatment</strong></p>

            <p class="pg-txt-con">1. Medical Practitioner charges for consultations / Specialist Fees</p>

            <p class="pg-txt-con">Outpatient Surgery</p>

            <p class="pg-txt-con">2. Day Care Treatment (Treatment that takes place in the hospital but does not require overnight stay)</p>

            <p class="pg-txt-con">3. Prescriptions Drugs</p>

            <p class="pg-txt-con">4. Diagnostics (X-Ray, MRI, CT Scan, Ultra Sound, etc.) and Laboratory tests</p>

            <p class="pg-txt-con">5. Physiotherapy [Required Pre-authorization]</p>

            <p class="pg-txt-con">6. Coinsurance for Treatment at Non-designated Providers within the territorial limit, Excluding Government Hospitals and Health centers (after the other applicable deductible and benefit coinsurance)</p>

            <p class="pg-txt-con"><strong>Other Benefits</strong></p>

            <p class="pg-txt-con">1. Accidental Damage to natural teeth following an accident</p>

            <p class="pg-txt-con">2. Alternative Medicine (e.g. Ayurveda, Homeopathy, Acupuncture and Herbal Treatments)</p>

            <p class="pg-txt-con">3. Pre-existing Conditions</p>

            <h3 class="bdyinner-whykr-title-en">Why KR Insurance Services ?</h3>

            <div class="clearfix"></div>

            <div class="bdyinner-whykr-keypoints-en">
              <div class="bdyinner-whykr-kepoints-ftitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
              <div class="bdyinner-whykr-kepoints-secitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
              <div class="bdyinner-whykr-kepoints-ftitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
              <div class="bdyinner-whykr-kepoints-secitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
            </div>
            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec">
              <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
            </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
        <div class="inn-banner-bg-main-en">
          <div class="inner-con-en">
            <div class="inner-banner-btn-en">
              <h1>Business Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="inner-banner-btn-en">
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "property-insurance"}>Property Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "project-insurance"}>Project Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "motor-fleet-insurance"}>Motor Fleet Insurance</div>
                <div class="inner-banner-btn-item-active-en" onClick={() => window.location.href = "group-health-insurance"}>Group Health Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "group-life-insurance"}>Group Life Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "burglary-insurance"}>Burglary Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "workmen-compensation-insurance"}>Workmen Compensation Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "group-personal-accident-insurance"}>Group Personal Accident Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "cyber-crime-insurance"}>Cyber Crime Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "jewellers-block-insurance"}>Jewellers Block Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "stock-throughput-insurance"}>Stock Throughput Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "liability-insurance"}>Liability Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "marine-insurance"}>Marine Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "energy-insurance"}>Energy Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="inner-con-en">
          <div className="tab-imgtxt-en">
            <h2>Group Health Insurance</h2>
            <div className="tabs-cont-en">
              <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/group_health_insurance.png"} alt="Group Health Insurance" />

              <p>A business needs to ensure the safety of its first resource; its employees. The Group Health Care / Group Medi-claim policy provided by KR Insurance Services caters to all employees of the business, irrespective of the size of the entity. We provide insurance covers to large, medium and small size organizations; employee strength ranging from 5 employees onwards.</p>

              <p>KR insurance service offers a range of medical insurance plans and healthcare solutions which is an excellent blend to suit your corporate requirements as well as your budget.</p>

              <p><strong>KR Insurance Services provides prompt and best value added services to corporates, the key features are -</strong></p>

              <div className="tabkyfet-en">
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Single window solutions through dedicated relationship manager.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Arranging wider terms, tailor made policies at best discounted pricing from the insurers.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Clients get benefitted through our In-depth knowledge of insurance terms, policy placement, claim settlement services and other insurance practices.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Hassel free &amp; prompt services leads to the savings for our clients in terms of man-hours and administrative costs.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Renewal reminder services - auto generated renewal reminders.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Insurance audit with NIL cost implications of your existing insurance policies.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Personalized claim assistance through our dedicated and experienced team.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Analysis of previous claim data to suggest improvement on loss ratios.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Priority hospitalization on case to case basis.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Through our network partners In India, we are arranging quotations for planned hospitalizations in India.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">In-house medical team for obtaining second opinion on claims (if required).</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Tailor made policy options matching to the different requirements of the customers.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Wellness programs - Health awareness sessions, Basic Health check-ups, Eye/Dental checkups, Art of living session for group of employees, Session with Dietician etc.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Policy awareness program for employees through - presentation, help-desk, tailor-made mailers and messages.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Weekly health tips mailer for the employees of your organization (optional).</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Tagging of important clients / employees for additional attention while handling their claims and delivering services to them - red carpet services.</div>
              </div>

              <h3 class="tab-sub-title-en">Main Cover / Group Health Insurance</h3>

              <p>The tailor-made healthcare solutions and medical insurance plans offers the following features:- </p>
              <p>1. Cashless treatment in Hospitals.</p>
              <p>2. Hospitalization benefits per insured per year up to policy sum insured value.</p>
              <p><strong>Inpatient Treatment</strong></p>
              <p>1. Daily Room and Board / Hospitalization Accommodation</p>
              <p>2. Hospitalization Class</p>
              <p>3. Intensive Care Unit</p>
              <p>4. Consultant's, Surgeon, &amp; Anesthetist Fees</p>
              <p>5. Diagnostic Tests</p>
              <p>6. CT Scan, MRI and PET scans received as an in-patient</p>
              <p>7. Various Therapies including Physiotherapy, Chemotherapy, radiation therapy etc. charges</p>
              <p>8. Companion Room and Board Accommodation</p>
              <p>9. In-hospital Cash Benefit (for In-patient treatment received completely free of charge)</p>
              <p>10. Use of hospital medical equipment (e.g. heart and lung support systems, etc.)</p>
              <p>11. Recipient Organ transplantation service, excluding any charges related to Donor</p>
              <p>12. Medical Evacuation &amp; Repatriation costs for the transport of mortal remains to the country of origin</p>
              <p><strong>Out Patient Treatment</strong></p>
              <p>1. Medical Practitioner charges for consultations / Specialist Fees</p>
              <p>Outpatient Surgery</p>
              <p>2. Day Care Treatment (Treatment that takes place in the hospital but does not require overnight stay)</p>
              <p>3. Prescriptions Drugs</p>
              <p>4. Diagnostics (X-Ray, MRI, CT Scan, Ultra Sound, etc.) and Laboratory tests</p>
              <p>5. Physiotherapy [Required Pre-authorization]</p>
              <p>6. Coinsurance for Treatment at Non-designated Providers within the territorial limit, Excluding Government Hospitals and Health centers (after the other applicable deductible and benefit coinsurance)</p>
              <p><strong>Other Benefits</strong></p>
              <p>1. Accidental Damage to natural teeth following an accident</p>
              <p>2. Alternative Medicine (e.g. Ayurveda, Homeopathy, Acupuncture and Herbal Treatments)</p>
              <p>3. Pre-existing Conditions</p>

              <h3 class="tab-whykrinsu-title-en">Why KR Insurance Services ?</h3>

              <div class="tab-whykrinsu-pins-list-en">
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
              </div>

              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec">
                <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
              </div>
              {/* ask for quotes button starts here */}
            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="inn-banner-bg-main-en hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"property-insurance"}>Property Insurance</option>
            <option value={"project-insurance"}>Project Insurance</option>
            <option value={"terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</option>
            <option value={"motor-fleet-insurance"}>Motor Fleet Insurance</option>
            <option value={"group-health-insurance"} selected class="active">Group Health Insurance</option>
            <option value={"group-life-insurance"}>Group life Insurance</option>
            <option value={"fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</option>
            <option value={"burglary-insurance"}>Burglary Insurance</option>
            <option value={"workmen-compensation-insurance"}>Workmen Compensation Insurance</option>
            <option value={"group-personal-accident-insurance"}>Group Personal Accident Insurance</option>
            <option value={"cyber-crime-insurance"}>Cyber Crime Insurance</option>
            <option value={"jewellers-block-insurance"}>Jewellers Block Insurance</option>
            <option value={"stock-throughput-insurance"}>Stock Throughput Insurance</option>
            <option value={"hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</option>
            <option value={"speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</option>
            <option value={"liability-insurance"}>Liability Insurance</option>
            <option value={"marine-insurance"}>Marine Insurance</option>
            <option value={"energy-insurance"}>Energy Insurance</option>
          </select>
        </div>
        <div class="innner-mob-body-main-en">
          <h1 class="inner-mob-heading-en">Group Health Insurance</h1>
          <div class="innner-mob-body-img-en">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/group_health_insurance.png"} alt="Group Health Insurance" />
          </div>

          <p class="inner-mob-body-txt-en">A business needs to ensure the safety of its first resource; its employees. The Group Health Care / Group Medi-claim policy provided by KR Insurance Services caters to all employees of the business, irrespective of the size of the entity. We provide insurance covers to large, medium and small size organizations; employee strength ranging from 5 employees onwards.</p>

          <p class="inner-mob-body-txt-en">KR insurance service offers a range of medical insurance plans and healthcare solutions which is an excellent blend to suit your corporate requirements as well as your budget.</p>

          <p class="inner-mob-body-txt-en"><strong>KR Insurance Services provides prompt and best value added services to corporates, the key features are –</strong></p>


          <div class="inner-mob-pins-list-en">
            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Single window solutions through dedicated relationship manager.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Arranging wider terms, tailor made policies at best discounted pricing from the insurers.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Clients get benefitted through our In-depth knowledge of insurance terms, policy placement, claim settlement services and other insurance practices.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Hassel free &amp; prompt services leads to the savings for our clients in terms of man-hours and administrative costs.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Renewal reminder services – auto generated renewal reminders.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Insurance audit with NIL cost implications of your existing insurance policies.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Personalized claim assistance through our dedicated and experienced team.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Analysis of previous claim data to suggest improvement on loss ratios.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Priority hospitalization on case to case basis.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Through our network partners In India, we are arranging quotations for planned hospitalizations in India.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">In-house medical team for obtaining second opinion on claims (if required).</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Tailor made policy options matching to the different requirements of the customers.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Wellness programs – Health awareness sessions, Basic Health check-ups, Eye/Dental checkups, Art of living session for group of employees, Session with Dietician etc.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Policy awareness program for employees through – presentation, help-desk, tailor-made mailers and messages.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Weekly health tips mailer for the employees of your organization (optional).</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en">Tagging of important clients / employees for additional attention while handling their claims and delivering services to them – red carpet services.</div>

          </div>

          <h3 class="inner-mob-main-cover-title-en">Main Cover / Group Health Insurance</h3>

          <p class="inner-mob-body-txt-en">The tailor-made healthcare solutions and medical insurance plans offers the following features:- </p>

          <p class="inner-mob-body-txt-en">1. Cashless treatment in Hospitals.</p>

          <p class="grphlthinsu-mob-numtxt-en">2. Hospitalization benefits per insured per year up to policy sum insured value.</p>

          <p class="grphlthinsu-mob-numtxt-en"><strong>Inpatient Treatment</strong></p>

          <p class="grphlthinsu-mob-numtxt-en">1. Daily Room and Board / Hospitalization Accommodation</p>

          <p class="grphlthinsu-mob-numtxt-en">2. Hospitalization Class</p>

          <p class="grphlthinsu-mob-numtxt-en">3. Intensive Care Unit</p>

          <p class="grphlthinsu-mob-numtxt-en">4. Consultant's, Surgeon, &amp; Anesthetist Fees</p>

          <p class="grphlthinsu-mob-numtxt-en">5. Diagnostic Tests</p>

          <p class="grphlthinsu-mob-numtxt-en">6. CT Scan, MRI and PET scans received as an in-patient</p>

          <p class="grphlthinsu-mob-numtxt-en">7. Various Therapies including Physiotherapy, Chemotherapy, radiation therapy etc. charges</p>

          <p class="grphlthinsu-mob-numtxt-en">8. Companion Room and Board Accommodation</p>

          <p class="grphlthinsu-mob-numtxt-en">9. In-hospital Cash Benefit (for In-patient treatment received completely free of charge)</p>

          <p class="grphlthinsu-mob-numtxt-en">10.Use of hospital medical equipment (e.g. heart and lung support systems, etc.)</p>

          <p class="grphlthinsu-mob-numtxt-en">11. Recipient Organ transplantation service, excluding any charges related to Donor</p>

          <p class="grphlthinsu-mob-numtxt-en">12. Medical Evacuation &amp; Repatriation costs for the transport of mortal remains to the country of origin</p>

          <p class="grphlthinsu-mob-numtxt-en"><strong>Out Patient Treatment</strong></p>

          <p class="grphlthinsu-mob-numtxt-en">1. Medical Practitioner charges for consultations / Specialist Fees</p>

          <p class="grphlthinsu-mob-numtxt-en">Outpatient Surgery</p>

          <p class="grphlthinsu-mob-numtxt-en">2. Day Care Treatment (Treatment that takes place in the hospital but does not require overnight stay)</p>

          <p class="grphlthinsu-mob-numtxt-en">3. Prescriptions Drugs</p>

          <p class="grphlthinsu-mob-numtxt-en">4. Diagnostics (X-Ray, MRI, CT Scan, Ultra Sound, etc.) and Laboratory tests</p>

          <p class="grphlthinsu-mob-numtxt-en">5. Physiotherapy [Required Pre-authorization]</p>

          <p class="grphlthinsu-mob-numtxt-en">6. Coinsurance for Treatment at Non-designated Providers within the territorial limit, Excluding Government Hospitals and Health centers (after the other applicable deductible and benefit coinsurance)</p>

          <p class="grphlthinsu-mob-numtxt-en"><strong>Other Benefits</strong></p>

          <p class="grphlthinsu-mob-numtxt-en">1. Accidental Damage to natural teeth following an accident</p>

          <p class="grphlthinsu-mob-numtxt-en">2. Alternative Medicine (e.g. Ayurveda, Homeopathy, Acupuncture and Herbal Treatments)</p>

          <p class="grphlthinsu-mob-numtxt-en">3. Pre-existing Conditions</p>


          <h3 class="inner-mob-main-cover-title-en">Why KR Insurance Services ?</h3>

          <div class="inner-mob-bdy-whykrlist-en">
            <div class="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div class="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div class="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div class="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>

          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec">
            <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
          </div>
          {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default GroupHealthInsurance;