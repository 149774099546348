import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import { Helmet } from 'react-helmet-async';
import './motorinsurance.scss';

const MotorInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }

  }, [])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div className="main-fluid">
      <Helmet>
        <title>Trusted and Reliable Motor Insurance in Oman | KR Insurance</title>
        <meta name="keywords" content="Trusted and Reliable Motor Insurance in Oman"/>
        <meta name="description" content="Protect your vehicle with comprehensive motor insurance in Oman. KR Insurance offers reliable coverage and exceptional service. Get a quote now."/>
      </Helmet>
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      {/* <div className="d-none d-md-block"> */}
      <div className="dsk-tab-btn-viewmode-en">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1>Personal Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-en">
                <div className="inner-banner-btn-item-active-en" onClick={() => window.location.href = "motor-insurance"}>Motor Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "travel-insurance"}>Travel Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "personal-accident"}>Personal Accident Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "relocation-insurance"}>Relocation Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "health-insurance"}>Health Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "art-insurance"}>Art insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "golf-insurance"}>Golf Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "housemaid-insurance"}>Housemaid Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "yacht-insurance"}>Yacht Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "home-insurance"}>Home Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "life-insurance"}>Life Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="bdy-innercont-en">
            <div className="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-lft-en">
                <h2 className="pagetitle-en">Motor Insurance</h2>
                <p className="pg-txt-con">Are you considering buying your first Car or are you still satisfied with your present car and need some protection in the form of an Insurance policy? KR Insurance Services provides you with a multitude of Motor Insurance services and also offers some unique features for motor insurance policies. These features include a choice between a comprehensive Insurance Coverage or a Third party Insurance policy. In the event if you own a High-valued vehicle, KR Insurance Services also assist you in making a customized Tailor made insurance plan suiting to all of your needs. All these plans are available at an affordable premium with an assured high-quality services.</p>

                <p className="pg-txt-con">The value added services as offered by KR Insurance Services are -</p>

                <div className="innbdy-lft-keypoint-en">
                  <div className="innbdy-lft-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div className="innbdy-lft-keypoint-item-en">Door delivery services – policy can be arranged to individual customer at their door steps.</div>
                  <div className="innbdy-lft-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div className="innbdy-lft-keypoint-item-en">Blanket discounted rates for large motor fleets.</div>
                  <div className="innbdy-lft-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div className="innbdy-lft-keypoint-item-en">Red carpet services at our preferred garages for repairs.</div>
                  <div className="innbdy-lft-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                  <div className="innbdy-lft-keypoint-item-en">Discount coupons for washing and servicing of vehicles through our network garages (optional).</div>
                </div>

              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/motor_insurance.png"} alt="motor insurance" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
            <div className="innbdy-keypoint-en">
              <div className="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div className="innbdy-keypoint-item-en">Availability of alternate vehicles for urgent requirements on paid basis (on paid basis).</div>
              <div className="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div className="innbdy-keypoint-item-en">Dedicated service manager for assisting on claim documentations, claim registration till claim settlement.</div>
              <div className="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div className="innbdy-keypoint-item-en">We are the only Insurance broker who has got in-house automobile garages authorized by many reputed insurance companies in Oman. We can provide high end and prompt repair services to your vehicles. (A rated garage ; ROP approved ; POD approved; MOD vehicles)</div>
            </div>

            <p className="pg-txt-con">We are also specialized in designing tailor made products for High Value Vehicles, e.g. Ferrari, Aston Martin, Bentley and Royce, etc. trucks, tankers, busses, saloon, SUV & other special Types of vehicles.</p>

            <p className="pg-txt-con">We take the complete responsibility of claim settlement from claim intimation to the delivery of vehicle from garages.</p>

            <h3 className="bdyinner-heading-en">Main Cover / Motor Insurance</h3>

            <p className="pg-txt-con">Key features of Motor Insurance covers and what do they cover:-</p>
            <br />
            {/* QUESTION SECTION STARTS HERE */}
            <div className="innbdy-keypoint-en">
              <div className="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div className="innbdy-keypoint-item-en">Comprehensive or Third Party insurance covers.</div>

              <div className="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div className="innbdy-keypoint-item-en">Cover for third party damages – property damages & bodily injury.</div>

              <div className="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div className="innbdy-keypoint-item-en">Own damages to the Insured vehicles because of named perils like road accidents, collision, overturning, theft, malicious harm, hearth and explosion and natural calamities.</div>

              <div className="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div className="innbdy-keypoint-item-en">Personal accident cover.</div>

              <div className="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div className="innbdy-keypoint-item-en">Road side assistance.</div>

              <div className="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div className="innbdy-keypoint-item-en">Agency repair options.</div>

              <div className="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div className="innbdy-keypoint-item-en">Cover for depreciation of spare parts.</div>

              <div className="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div className="innbdy-keypoint-item-en">Off-road cover.</div>

              <div className="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              <div className="innbdy-keypoint-item-en">Medical Expenses cover</div>
            </div>
            {/* QUESTION SECTION ENDS HERE */}

            <h3 className="bdyinner-whykr-title-en">Why KR Insurance Services ?</h3>

            <div className="clearfix"></div>

            <div className="bdyinner-whykr-keypoints-en">
              <div className="bdyinner-whykr-kepoints-ftitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
              <div className="bdyinner-whykr-kepoints-secitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
              <div className="bdyinner-whykr-kepoints-ftitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
              <div className="bdyinner-whykr-kepoints-secitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
            </div>

            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec">
              <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
            </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1>Personal Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-en">
                <div className="inner-banner-btn-item-active-en" onClick={() => window.location.href = "motor-insurance"}>Motor Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "travel-insurance"}>Travel Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "personal-accident"}>Personal Accident Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "relocation-insurance"}>Relocation Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "health-insurance"}>Health Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "art-insurance"}>Art insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "golf-insurance"}>Golf Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "housemaid-insurance"}>Housemaid Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "yacht-insurance"}>Yacht Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "home-insurance"}>Home Insurance</div>
                <div className="inner-banner-btn-item-en" onClick={() => window.location.href = "life-insurance"}>Life Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="tab-imgtxt-en">
            <h2>Motor Insurance</h2>
            <div className="tabs-cont-en">
              <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/motor_insurance.png"} alt="motor insurance" />
              <p>Are you considering buying your first Car or are you still satisfied with your present car and need some protection in the form of an Insurance policy? KR Insurance Services provides you with a multitude of Motor Insurance services and also offers some unique features for motor insurance policies. These features include a choice between a comprehensive Insurance Coverage or a Third party Insurance policy. In the event if you own a High-valued vehicle, KR Insurance Services also assist you in making a customized Tailor made insurance plan suiting to all of your needs. All these plans are available at an affordable premium with an assured high-quality services.</p>
              <p>The value added services as offered by KR Insurance Services are -</p>
              <div className="tabkyfet-en">
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Door delivery services – policy can be arranged to individual customer at their door steps.</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Blanket discounted rates for large motor fleets.</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Red carpet services at our preferred garages for repairs.</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Discount coupons for washing and servicing of vehicles through our network garages (optional).</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Availability of alternate vehicles for urgent requirements on paid basis (on paid basis).</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Dedicated service manager for assisting on claim documentations, claim registration till claim settlement.</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">We are the only Insurance broker who has got in-house automobile garages authorized by many reputed insurance companies in Oman. We can provide high end and prompt repair services to your vehicles. (A rated garage ; ROP approved ; POD approved; MOD vehicles)</div>
              </div>

              <p>We are also specialized in designing tailor made products for High Value Vehicles, e.g. Ferrari, Aston Martin, Bentley and Royce, etc. trucks, tankers, busses, saloon, SUV & other special Types of vehicles.</p>

              <p>We take the complete responsibility of claim settlement from claim intimation to the delivery of vehicle from garages.</p>

              <h3 className="motorinsu-page-sbu-title-en">Main Cover / Motor Insurance</h3>

              <p>Key features of Motor Insurance covers and what do they cover:-</p>

              <div className="tabkyfet-en">
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Comprehensive or Third Party insurance covers.</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Cover for third party damages - property damages & bodily injury.</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Own damages to the Insured vehicles because of named perils like road accidents, collision, overturning, theft, malicious harm, hearth and explosion and natural calamities.</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Personal accident cover.</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Road side assistance.</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Agency repair options.</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Cover for depreciation of spare parts.</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Off-road cover.</div>
                <div className="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-en">Medical Expenses cover</div>
              </div>

              <h3 className="tab-whykrinsu-title-en">Why KR Insurance Services ?</h3>

              <div className="tab-whykrinsu-pins-list-en">
                <div className="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
                <div className="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
                <div className="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
                <div className="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
              </div>

              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec">
                <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
              </div>
              {/* ask for quotes button starts here */}

            </div>
          </div>

        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div className="inn-banner-bg-main-en hidden-desktop hidden-md-device hidden-lg">
        <div className="dropdown-sec">
          <select className="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"motor-insurance"} selected className="active">Motor Insurance</option>
            <option value={"travel-insurance"}>Travel Insurance</option>
            <option value={"personal-accident"}>Personal Accident Insurance</option>
            <option value={"relocation-insurance"}>Relocation Insurance</option>
            <option value={"health-insurance"}>Health Insurance</option>
            <option value={"art-insurance"}>Art insurance</option>
            <option value={"golf-insurance"}>Golf Insurance</option>
            <option value={"housemaid-insurance"}>Housemaid Insurance</option>
            <option value={"home-insurance"}>Home Insurance</option>
            <option value={"yacht-insurance"}>Yacht Insurance</option>
            <option value={"life-insurance"}>Life Insurance</option>
          </select>
        </div>
        <div className="innner-mob-body-main-en">
          <h1 className="inner-mob-heading-en">Motor Insurance</h1>
          <div className="innner-mob-body-img-en">
            <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/motor_insurance.png"} alt="motor insurance" />
          </div>
          <p className="inner-mob-body-txt-en">Are you considering buying your first Car or are you still satisfied with your present car and need some protection in the form of an Insurance policy? KR Insurance Services provides you with a multitude of Motor Insurance services and also offers some unique features for motor insurance policies. These features include a choice between a comprehensive Insurance Coverage or a Third party Insurance policy. In the event if you own a High-valued vehicle, KR Insurance Services also assist you in making a customized Tailor made insurance plan suiting to all of your needs. All these plans are available at an affordable premium with an assured high-quality services.</p>

          <p className="inner-mob-body-txt-en">The value added services as offered by KR Insurance Services are -</p>

          <div className="inner-mob-pins-list-en">
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div className="inner-mob-pins-list-item-en">Door delivery services – policy can be arranged to individual customer at their door steps.</div>
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div className="inner-mob-pins-list-item-en">Blanket discounted rates for large motor fleets.</div>
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div className="inner-mob-pins-list-item-en">Red carpet services at our preferred garages for repairs.</div>
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div className="inner-mob-pins-list-item-en">Discount coupons for washing and servicing of vehicles through our network garages (optional).</div>
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div className="inner-mob-pins-list-item-en">Availability of alternate vehicles for urgent requirements on paid basis (on paid basis).</div>
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div className="inner-mob-pins-list-item-en">Dedicated service manager for assisting on claim documentations, claim registration till claim settlement.</div>
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div className="inner-mob-pins-list-item-en">We are the only Insurance broker who has got in-house automobile garages authorized by many reputed insurance companies in Oman. We can provide high end and prompt repair services to your vehicles. (A rated garage ; ROP approved ; POD approved; MOD vehicles)</div>
          </div>

          <p className="inner-mob-body-txt-en">We are also specialized in designing tailor made products for High Value Vehicles, e.g. Ferrari, Aston Martin, Bentley and Royce, etc. trucks, tankers, busses, saloon, SUV & other special Types of vehicles.</p>

          <p className="inner-mob-body-txt-en">We take the complete responsibility of claim settlement from claim intimation to the delivery of vehicle from garages.</p>

          <h3 className="inner-mob-main-cover-title-en">Main Cover / Motor Insurance</h3>

          <p className="inner-mob-body-txt-en">Key features of Motor Insurance covers and what do they cover:-</p>

          <h4 className="motorinsu-subtitle-en">The key features of Travel Insurance are - </h4>

          {/* QUESTION SECTION STARTS HERE */}
          <div className="inner-mob-pins-list-en">
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div className="inner-mob-pins-list-item-en">Comprehensive or Third Party insurance covers.</div>
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div className="inner-mob-pins-list-item-en">Cover for third party damages – property damages & bodily injury.</div>
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div className="inner-mob-pins-list-item-en">Own damages to the Insured vehicles because of named perils like road accidents, collision, overturning, theft, malicious harm, hearth and explosion and natural calamities.</div>
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div className="inner-mob-pins-list-item-en">Personal accident cover.</div>
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div className="inner-mob-pins-list-item-en">Road side assistance.</div>
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div className="inner-mob-pins-list-item-en">Agency repair options.</div>
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div className="inner-mob-pins-list-item-en">Cover for depreciation of spare parts.</div>
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div className="inner-mob-pins-list-item-en">Off-road cover.</div>
            <div className="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
            <div className="inner-mob-pins-list-item-en">Medical Expenses cover</div>
          </div>
          {/* QUESTION SECTION ENDS HERE */}

          <h3 className="inner-mob-main-cover-title-en">Why KR Insurance Services ?</h3>

          <div className="inner-mob-bdy-whykrlist-en">
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>

          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec">
            <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
          </div>
          {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default MotorInsurance;