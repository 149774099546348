import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import { Helmet } from 'react-helmet-async';
import './arrelocationinsurance.scss';

const ARRelocationInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }

  }, [])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div className="main-fluid">
    <Helmet>
      <title>‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬.</title>
      <meta name="keywords" content="Relocation Insurance" />
      <meta name="description" content="KR ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫لوسطاء‬ ‫موثوق‬ ‫خيار‬ ‫هو‬ ‫للتأمين‬ ‫عمان‬. ‫عمان‬ ‫في‬ ‫التأمين‬ ‫شركات‬ ‫ألفضل‬ ‫التأمين‬ ‫حلول‬ ‫إلى‬ ‫للوصول‬ ‫معنا‬ ‫شارك‬." />
    </Helmet>
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div className="dsk-tab-btn-viewmode-ar">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1>التأمين الشخصي </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-ar">
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "motor-insurance-ar"}>التأمين على السيارات</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "travel-insurance-ar"}>التأمين أثناء السفر</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "personal-accident-ar"}>تأمين الحوادث الشخصية</div>
                <div className="inner-banner-btn-item-active-ar" onClick={() => window.location.href = "relocation-insurance-ar"}>التأمين علي الانتقال</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "health-insurance-ar"}>التأمين الصحي</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "art-insurance-ar"}>التأمين الفني</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "golf-insurance-ar"}>التأمين على الجولف</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "housemaid-insurance-ar"}>تأمين العمالة المنزلية</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "yacht-insurance-ar"}>التأمين على اليخوت</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "home-insurance-ar"}>التأمين على المنازل</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "life-insurance-ar"}>التأمين على الحياة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="bdy-innercont-en">
            <div className="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-lft-ar">
                <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/relocation_insurance_ar.png"} alt="relocation insurance" />
              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div className="innerbdy-dlex-rgt-ar">
                <h2 className="pagetitle-ar">التأمين علي الانتقال </h2>
                <p className="pg-txt-con-ar">هل حان الوقت للمغادرة؟ هل ستنتقل إلى مكان جديد؟ هل هذا المكان في مدينة جديدة أم أنك تخطط للانتقال إلى بلد آخر أو مجرد الانتقال إلى منزل جديد في آخر الشارع؟ لابد أنك قلق بشأن ممتلكاتك الثمينة كيف تضمن ألا يحدث لها شيء غير مرغوب فيه؟ <br /><br /></p>

                <p className="pg-txt-con-ar">تقدم شركة كيمجي رامداس لخدمات التأمين برامج تأمين مصممة خصيصاً لهذا الغرض، أي "تأمين الانتقال"، ويحمي برنامج تغطية التأمين الخاص بالنقل أصولك وتحميها من اللحظة التي تبدأ فيها بحزم حقائبك إلى الوقت الذي تصل فيه إلى منزلك الجديد وتفرغها <br /><br /></p>
                <h3 className="bdyinner-heading-ar">التغطية الرئيسية / تأمين الانتقال </h3>

                <p className="pg-txt-con-ar">برنامج تغطية النقل هو برنامج فريد من نوعه يوفر فوائد تختلف عن البرامج المتوفرة في السوق، حيث تتوفر خيارات مختلفة للاختيار من بينها حسب العبوة المطلوبة</p>

              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

            {/* QUESTION SECTION STARTS HERE */}
            <div className="bdyinner-keypoint-ar">
              <div className="bdyinner-keypoint-item-ar">الشروط الكاملة – الأمتعة المعبأة باحترافية</div>
              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

              <div className="bdyinner-keypoint-item-ar">الشروط المقيدة – الأمتعة المعبأة من قِبل المالك</div>
              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

              <div className="bdyinner-keypoint-item-ar">تغطية للمركبات الآلية </div>
              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

              <div className="bdyinner-keypoint-item-ar">ملحقات خاصة - للأمتعة الشخصية </div>
              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>

              <div className="bdyinner-keypoint-item-ar">امتدادات التخزين </div>
              <div className="bdyinner-keypoint-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>
            {/* QUESTION SECTION ENDS HERE */}

            <h3 className="bdyinner-whykr-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>
            <div className="clearfix"></div>
            <div className="bdyinner-whykr-keypoints-ar">
              <div className="bdyinner-whykr-kepoints-secitem-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-whykr-kepoints-ftitem-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div className="bdyinner-whykr-kepoints-secitem-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              <div className="bdyinner-whykr-kepoints-ftitem-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            </div>


            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec-ar">
              <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار</a>
            </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-ar">
        <div className="inn-banner-bg-main-en">
          <div className="inner-con-en">
            <div className="inner-banner-btn-en">
              <h1 className="relocationinsu-banner-heading-ar">التأمين الشخصي </h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div className="inner-banner-btn-ar">
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "motor-insurance-ar"}>التأمين على السيارات</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "travel-insurance-ar"}>التأمين أثناء السفر</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "personal-accident-ar"}>تأمين الحوادث الشخصية</div>
                <div className="inner-banner-btn-item-active-ar" onClick={() => window.location.href = "relocation-insurance-ar"}>التأمين علي الانتقال</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "health-insurance-ar"}>التأمين الصحي</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "art-insurance-ar"}>التأمين الفني</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "golf-insurance-ar"}>التأمين على الجولف</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "housemaid-insurance-ar"}>تأمين العمالة المنزلية</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "yacht-insurance-ar"}>التأمين على اليخوت</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "home-insurance-ar"}>التأمين على المنازل</div>
                <div className="inner-banner-btn-item-ar" onClick={() => window.location.href = "life-insurance-ar"}>التأمين على الحياة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div className="inner-con-en">
          <div className="tab-imgtxt-ar">
            <h2>التأمين علي الانتقال </h2>
            <div className="tabs-cont-ar">
              <img className="rgt-img-ar" src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/relocation_insurance_ar.png"} alt="relocation insurance" />
              <p>هل حان الوقت للمغادرة؟ هل ستنتقل إلى مكان جديد؟ هل هذا المكان في مدينة جديدة أم أنك تخطط للانتقال إلى بلد آخر أو مجرد الانتقال إلى منزل جديد في آخر الشارع؟ لابد أنك قلق بشأن ممتلكاتك الثمينة كيف تضمن ألا يحدث لها شيء غير مرغوب فيه؟ </p>

              <p>تقدم شركة كيمجي رامداس لخدمات التأمين برامج تأمين مصممة خصيصاً لهذا الغرض، أي "تأمين الانتقال"، ويحمي برنامج تغطية التأمين الخاص بالنقل أصولك وتحميها من اللحظة التي تبدأ فيها بحزم حقائبك إلى الوقت الذي تصل فيه إلى منزلك الجديد وتفرغها.</p>

              <h3 className="motorinsu-page-sbu-title-ar">التغطية الرئيسية / تأمين الانتقال </h3>

              <p>برنامج تغطية النقل هو برنامج فريد من نوعه يوفر فوائد تختلف عن البرامج المتوفرة في السوق، حيث تتوفر خيارات مختلفة للاختيار من بينها حسب العبوة المطلوبة</p>

              <div className="tabkyfet-ar">
                <div className="tabkeyfet-txt-ar">الشروط الكاملة – الأمتعة المعبأة باحترافية</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">الشروط المقيدة – الأمتعة المعبأة من قِبل المالك</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">تغطية للمركبات الآلية</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">ملحقات خاصة - للأمتعة الشخصية</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
                <div className="tabkeyfet-txt-ar">امتدادات التخزين</div>
                <div className="tabkeyfet-img-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /></div>
              </div>

              <h3 className="motorinsu-page-sbu-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟</h3>

              <div className="tab-whykrinsu-pins-list-ar">
                <div className="tab-whykrinsu-pinlft-two-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div className="tab-whykrinsu-pinlft-one-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div className="tab-whykrinsu-pinlft-two-ar">أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
                <div className="tab-whykrinsu-pinlft-one-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> </div>
              </div>

              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec-ar">
                <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار</a>
              </div>
              {/* ask for quotes button starts here */}

            </div>
          </div>

        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div className="inn-banner-bg-main-en hidden-desktop hidden-md-device hidden-lg">
        <div className="dropdown-sec">
          <select className="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"motor-insurance-ar"}>التأمين على السيارات</option>
            <option value={"travel-insurance-ar"}>التأمين أثناء السفر</option>
            <option value={"personal-accident-ar"}>تأمين الحوادث الشخصية</option>
            <option value={"relocation-insurance-ar"} selected className="active">التأمين علي الانتقال</option>
            <option value={"health-insurance-ar"}>التأمين الصحي</option>
            <option value={"art-insurance-ar"}>التأمين الفني</option>
            <option value={"golf-insurance-ar"}>التأمين على الجولف</option>
            <option value={"housemaid-insurance-ar"}>تأمين العمالة المنزلية</option>
            <option value={"home-insurance-ar"}>التأمين على المنازل</option>
            <option value={"yacht-insurance-ar"}>التأمين على اليخوت</option>
            <option value={"life-insurance-ar"}>التأمين على الحياة</option>
          </select>
        </div>
        <div className="innner-mob-body-main-en">
          <h1 className="inner-mob-heading-en">التأمين علي الانتقال </h1>
          <div className="innner-mob-body-img-en">
            <img src={process.env.PUBLIC_URL + "/personalinsurance_images/tab_images/relocation_insurance_ar.png"} alt="relocation insurance" />
          </div>
          <p className="inner-mob-body-txt-ar">هل حان الوقت للمغادرة؟ هل ستنتقل إلى مكان جديد؟ هل هذا المكان في مدينة جديدة أم أنك تخطط للانتقال إلى بلد آخر أو مجرد الانتقال إلى منزل جديد في آخر الشارع؟ لابد أنك قلق بشأن ممتلكاتك الثمينة كيف تضمن ألا يحدث لها شيء غير مرغوب فيه؟ </p>

          <p className="inner-mob-body-txt-ar">تقدم شركة كيمجي رامداس لخدمات التأمين برامج تأمين مصممة خصيصاً لهذا الغرض، أي "تأمين الانتقال"، ويحمي برنامج تغطية التأمين الخاص بالنقل أصولك وتحميها من اللحظة التي تبدأ فيها بحزم حقائبك إلى الوقت الذي تصل فيه إلى منزلك الجديد وتفرغها</p>

          <h3 className="relocationinsu-subtitle-ar">التغطية الرئيسية / تأمين الانتقال </h3>

          <p className="inner-mob-body-txt-ar">برنامج تغطية النقل هو برنامج فريد من نوعه يوفر فوائد تختلف عن البرامج المتوفرة في السوق، حيث تتوفر خيارات مختلفة للاختيار من بينها حسب العبوة المطلوبة</p>

          {/* QUESTION SECTION STARTS HERE */}
          <div className="inner-mob-pins-list-ar">
            <div className="inner-mob-pins-list-item-ar">الشروط الكاملة – الأمتعة المعبأة باحترافية</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">الشروط المقيدة – الأمتعة المعبأة من قِبل المالك</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">تغطية للمركبات الآلية</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">ملحقات خاصة - للأمتعة الشخصية</div>

            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-pins-list-item-ar">امتدادات التخزين</div>
            <div className="inner-mob-pins-list-itemimg-ar"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>
          {/* QUESTION SECTION ENDS HERE */}

          <h3 className="inner-mob-main-cover-title-ar">ما الذي يُميز شركة كيمجي رامداس لخدمات التأمين عن غيرها؟ </h3>

          <div className="inner-mob-bdy-whykrlist-ar">
            <div className="inner-mob-bdy-whykrlist-item-ar">أقل الأسعار <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar">خدمات المطالبات الفورية <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar">مقارنات عروض أسعار واسعة <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div className="inner-mob-bdy-whykrlist-item-ar"> أفضل ممارسات صناعة التأمين <img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
          </div>

          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec-ar">
            <a href="/contactus-ar" className="askquto-btn-ar">للإستفسار</a>
          </div>
          {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARRelocationInsurance;