import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "./headerEN/headeren";
import FooterEN from "./footerEN/footer";
import './css/404page.scss';


const PageNotFound = () => {
    return ( 
        <div>
            <HeaderEN />
        <div className="not-found  text-center">
            <h2>Sorry ...!!</h2>
            <p>Page your looking is not found.</p>
            <img src={process.env.PUBLIC_URL + '/404notfound.jpg'} alt="404image" />
            <div className="back-btn"><Link to="/" className="newblog">Back to Home</Link></div>
        </div>
        {/* FOOTER SECTION STARTS HERE */}
        <div class="main-fluid">
              <FooterEN />
            </div>
        {/* FOOTER SECTION ENDS HERE */}
        </div>
     );
}
 
export default PageNotFound;