import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import HeaderEN from "../../headerEN/headeren";
import FooterEN from "../../footerEN/footer";
import './liabilityinsurance.scss';

const LiabilityInsurance = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [tab, setTab] = useState(0)

  return (
    <div class="main-fluid">
      <HeaderEN />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div class="dsk-tab-btn-viewmode-en">
        <div class="inn-banner-bg-main-en">
          <div class="inner-con-en">
            <div class="inner-banner-btn-en">
              <h1>Business Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="inner-banner-btn-en">
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "property-insurance"}>Property Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "project-insurance"}>Project Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "motor-fleet-insurance"}>Motor Fleet Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "group-health-insurance"}>Group Health Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "group-life-insurance"}>Group Life Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "burglary-insurance"}>Burglary Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "workmen-compensation-insurance"}>Workmen Compensation Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "group-personal-accident-insurance"}>Group Personal Accident Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "cyber-crime-insurance"}>Cyber Crime Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "jewellers-block-insurance"}>Jewellers Block Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "stock-throughput-insurance"}>Stock Throughput Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</div>
                <div class="inner-banner-btn-item-active-en" onClick={() => window.location.href = "liability-insurance"}>Liability Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "marine-insurance"}>Marine Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "energy-insurance"}>Energy Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="inner-con-en">
          <div class="bdy-innercont-en">
            <div class="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="innerbdy-dlex-lft-en">
                <h2 class="pagetitle-en">Liability Insurance</h2>

                <p class="pg-txt-con">Modern business liability insurance exposures arise from many sources: from third parties, from products, from the environment, from emerging risks. They are growing in size and in complexity, but remain similar in one crucial dimension - their long-term nature.</p>

                <p class="pg-txt-con">Major corporates are facing business liability risks across multiple worldwide locations need a reliable long-term partner with global reach.</p>

                <p class="pg-txt-con">KR Insurance Services who are also global network partners of Willis Towers Watson in Oman offers financial </p>

                <p class="pg-txt-con">We meet this challenge with an expert team of underwriters, claims and risk consulting professionals (including lawyers, engineers, scientists and industry specialists) supported by major capacity for the largest business liability insurance challenges, embedded in a service networkin more than 160 countries worldwide.</p>

                <h3 class="bdyinner-heading-en">Main Cover / Liability Insurance</h3>

              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="innerbdy-dlex-rgt-en">
                <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/liability_insurance.png"} alt="Liability Insurance" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>

            <p class="pg-txt-con">Overview of corporate liability insurance risks are as under –</p>

            <div class="innbdy-keypoint-en">
              <div class="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="innbdy-keypoint-item-en"><strong>Public liability insurance </strong> - Cover for liability arising from the insured's operations or premises to third parties.</div>

              <div class="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="innbdy-keypoint-item-en"><strong>Products liability insurance</strong> - Cover for property damages or bodily injury caused by the insured's products or work performed.</div>

              <div class="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="innbdy-keypoint-item-en"><strong>Environmental impairment liability</strong> - Pollution claims caused by the insured's activities.</div>

              <div class="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="innbdy-keypoint-item-en"><strong>Crisis management solutions</strong> - Wide-ranging crisis management insurance solutions that include product contamination and product recall insurance as well as terrorism and political violence coverage.</div>

              {/* <div class="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="innbdy-keypoint-item-en"><strong> Clinical trials insurance </strong> - Covers the legal liability of sponsors (<a href="http://www.agcs.allianz.com/sectors/pharmaceuticals/" target="_blank">pharmaceutical</a> companies) and investigators (doctors, hospitals, clinical research organizations) arising out of clinical trials.</div> */}
              <div class="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="innbdy-keypoint-item-en"><strong> Clinical trials insurance </strong> - Covers the legal liability of sponsors (pharmaceutical companies) and investigators (doctors, hospitals, clinical research organizations) arising out of clinical trials.</div>

              <div class="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="innbdy-keypoint-item-en"><strong> Employer's liability / D&O insurance</strong> - Covering the employer's liability for workplace accidents / diseases of the employees. Directors and Officers Liability solutions.</div>

              <div class="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="innbdy-keypoint-item-en"><strong> IT liability insurance</strong> - Protects against emerging liability exposures on a pure financial loss basis: for example, infringement of privacy rights, inadvertent transmission of computer viruses, tampering and other 'cyber threats'.</div>

              {/* <div class="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="innbdy-keypoint-item-en"><strong>Management liability insurance</strong> - Covered through our <a href="http://www.agcs.allianz.com/services/financial-lines/" target="_blank">financial lines</a> services, including <a href="http://www.agcs.allianz.com/insights/white-papers-and-case-studies/introduction-to-do-insurance/" target="_blank">Directors &amp; Officers</a> and professional indemnity insurance.</div> */}
              <div class="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="innbdy-keypoint-item-en"><strong>Management liability insurance</strong> - Covered through our financial lines services, including Directors &amp; Officers and professional indemnity insurance.</div>

              {/* <div class="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="innbdy-keypoint-item-en"><strong>Aerospace and aviation liability</strong> - Covered through our <a href="http://www.agcs.allianz.com/services/aviation/" target="_blank">aviation insurance</a> teams for <a href="http://www.agcs.allianz.com/services/aviation/airlines/" target="_blank">airlines</a>, <a href="http://www.agcs.allianz.com/services/aviation/general-aviation/" target="_blank">general aviation</a> and the <a href="http://www.agcs.allianz.com/services/aviation/aerospace/" target="_blank">aerospace</a> industry. Physical damage and liability cover for manufacturers and suppliers as well as for airports, airfields, re-fuellers and associated service providers.</div> */}
              <div class="innbdy-keypoint-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
              <div class="innbdy-keypoint-item-en"><strong>Aerospace and aviation liability</strong> - Covered through our aviation insurance teams for airlines, general aviation and the aerospace industry. Physical damage and liability cover for manufacturers and suppliers as well as for airports, airfields, re-fuellers and associated service providers.</div>

            </div>

            <h3 className="bdyinner-whykr-title-en">Why KR Insurance Services ?</h3>

            <div className="clearfix"></div>

            <div className="bdyinner-whykr-keypoints-en">
              <div className="bdyinner-whykr-kepoints-ftitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
              <div className="bdyinner-whykr-kepoints-secitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
              <div className="bdyinner-whykr-kepoints-ftitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
              <div className="bdyinner-whykr-kepoints-secitem-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
            </div>

            {/* ask for quotes button starts here */}
            <div className="askquto-btn-sec">
              <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
            </div>
            {/* ask for quotes button starts here */}
          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}

      {/* TAB-VIEW AND TABS SECTION STARTS HERE */}
      <div className="tab-btn-viewmode-en">
        <div class="inn-banner-bg-main-en">
          <div class="inner-con-en">
            <div class="inner-banner-btn-en">
              <h1>Business Insurance</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="inner-banner-btn-en">
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "property-insurance"}>Property Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "project-insurance"}>Project Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "motor-fleet-insurance"}>Motor Fleet Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "group-health-insurance"}>Group Health Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "group-life-insurance"}>Group Life Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "burglary-insurance"}>Burglary Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "workmen-compensation-insurance"}>Workmen Compensation Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "group-personal-accident-insurance"}>Group Personal Accident Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "cyber-crime-insurance"}>Cyber Crime Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "jewellers-block-insurance"}>Jewellers Block Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "stock-throughput-insurance"}>Stock Throughput Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</div>
                <div class="inner-banner-btn-item-active-en" onClick={() => window.location.href = "liability-insurance"}>Liability Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "marine-insurance"}>Marine Insurance</div>
                <div class="inner-banner-btn-item-en" onClick={() => window.location.href = "energy-insurance"}>Energy Insurance</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="inner-con-en">
          <div className="tab-imgtxt-en">
            <h2>Liability Insurance</h2>
            <div className="tabs-cont-en">
              <img className="rgt-img-en" src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/liability_insurance.png"} alt="Liability Insurance" />

              <p>Modern business liability insurance exposures arise from many sources: from third parties, from products, from the environment, from emerging risks. They are growing in size and in complexity, but remain similar in one crucial dimension - their long-term nature.</p>

              <p>Major corporates are facing business liability risks across multiple worldwide locations need a reliable long-term partner with global reach.</p>

              <p>KR Insurance Services who are also global network partners of Willis Towers Watson in Oman offers financial </p>
              <p>We meet this challenge with an expert team of underwriters, claims and risk consulting professionals (including lawyers, engineers, scientists and industry specialists) supported by major capacity for the largest business liability insurance challenges, embedded in a service networkin more than 160 countries worldwide.</p>

              <h3 class="tab-sub-title-en">Main Cover / Liability Insurance</h3>
              <p>Overview of corporate liability insurance risks are as under -</p>

              <div class="tabkyfet-en">
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en"><strong>Public liability insurance </strong> - Cover for liability arising from the insured's operations or premises to third parties.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en"><strong>Products liability insurance</strong> - Cover for property damages or bodily injury caused by the insured's products or work performed.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en"><strong>Environmental impairment liability</strong> - Pollution claims caused by the insured's activities.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en"><strong>Crisis management solutions</strong> - Wide-ranging crisis management insurance solutions that include product contamination and product recall insurance as well as terrorism and political violence coverage.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en"><strong> Clinical trials insurance </strong> - Covers the legal liability of sponsors (pharmaceutical companies) and investigators (doctors, hospitals, clinical research organizations) arising out of clinical trials.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en"><strong> Employer's liability / D&O insurance</strong> - Covering the employer's liability for workplace accidents / diseases of the employees. Directors and Officers Liability solutions.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en"><strong> IT liability insurance</strong> - Protects against emerging liability exposures on a pure financial loss basis: for example, infringement of privacy rights, inadvertent transmission of computer viruses, tampering and other 'cyber threats'.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en"><strong>Management liability insurance</strong> - Covered through our financial lines services, including Directors &amp; Officers and professional indemnity insurance.</div>
                <div class="tabkeyfet-img-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
                <div class="tabkeyfet-txt-en"><strong>Aerospace and aviation liability</strong> - Covered through our aviation insurance teams for airlines, general aviation and the aerospace industry. Physical damage and liability cover for manufacturers and suppliers as well as for airports, airfields, re-fuellers and associated service providers.</div>
              </div>
              
              <h3 class="tab-whykrinsu-title-en">Why KR Insurance Services ?</h3>
              
              <div class="tab-whykrinsu-pins-list-en">
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Lowest Quotations</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Prompt Claim Services</div>
                <div class="tab-whykrinsu-pinlft-one-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Wide Quote Comparisons</div>
                <div class="tab-whykrinsu-pinlft-two-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="sheild" /> Best Insurance Industry Practices</div>
              </div>
              
              {/* ask for quotes button starts here */}
              <div className="askquto-btn-sec">
                <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
              </div>
              {/* ask for quotes button starts here */}
            </div>
          </div>
        </div>
      </div>
      {/* TAB-VIEW AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="inn-banner-bg-main-en hidden-desktop hidden-md-device hidden-lg">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"property-insurance"}>Property Insurance</option>
            <option value={"project-insurance"}>Project Insurance</option>
            <option value={"terrorism-and-political-risk-insurance"}>Terrorism and Political Risk Insurance</option>
            <option value={"motor-fleet-insurance"}>Motor Fleet Insurance</option>
            <option value={"group-health-insurance"}>Group Health Insurance</option>
            <option value={"group-life-insurance"}>Group life Insurance</option>
            <option value={"fidelity-guarantee-insurance"}>Fidelity Guarantee Insurance</option>
            <option value={"burglary-insurance"}>Burglary Insurance</option>
            <option value={"workmen-compensation-insurance"}>Workmen Compensation Insurance</option>
            <option value={"group-personal-accident-insurance"}>Group Personal Accident Insurance</option>
            <option value={"cyber-crime-insurance"}>Cyber Crime Insurance</option>
            <option value={"jewellers-block-insurance"}>Jewellers Block Insurance</option>
            <option value={"stock-throughput-insurance"}>Stock Throughput Insurance</option>
            <option value={"hauliers-full-value-cargo-insurance"}>Hauliers Full Value Cargo Insurance</option>
            <option value={"speciality-risk-event-insurance"}>Speciality Risk / Event Insurance</option>
            <option value={"liability-insurance"} selected class="active">Liability Insurance</option>
            <option value={"marine-insurance"}>Marine Insurance</option>
            <option value={"energy-insurance"}>Energy Insurance</option>
          </select>
        </div>
        <div class="innner-mob-body-main-en">
          <h1 className="inner-mob-heading-en">Liability Insurance</h1>
          <div class="innner-mob-body-img-en">
            <img src={process.env.PUBLIC_URL + "/businessinsurance_images/tab_images/liability_insurance.png"} alt="Liability Insurance" />
          </div>

          <p class="inner-mob-body-txt-en">Modern business liability insurance exposures arise from many sources: from third parties, from products, from the environment, from emerging risks. They are growing in size and in complexity, but remain similar in one crucial dimension - their long-term nature.</p>

          <p class="inner-mob-body-txt-en">Major corporates are facing business liability risks across multiple worldwide locations need a reliable long-term partner with global reach.</p>

          <p class="inner-mob-body-txt-en">KR Insurance Services who are also global network partners of Willis Towers Watson in Oman offers financial </p>

          <p class="inner-mob-body-txt-en">
            We meet this challenge with an expert team of underwriters, claims and risk consulting professionals (including lawyers, engineers, scientists and industry specialists) supported by major capacity for the largest business liability insurance challenges, embedded in a service network in more than 160 countries worldwide.</p>

          <h3 class="inner-mob-main-cover-title-en">Main Cover / Liability Insurance</h3>

          <p class="inner-mob-body-txt-en">Overview of corporate liability insurance risks are as under - </p>

          <div class="inner-mob-pins-list-en">
            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en"><strong>Public liability insurance </strong> - Cover for liability arising from the insured's operations or premises to third parties.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en"><strong>Products liability insurance</strong> - Cover for property damages or bodily injury caused by the insured's products or work performed.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en"><strong>Environmental impairment liability</strong> - Pollution claims caused by the insured's activities.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en"><strong>Crisis management solutions</strong> - Wide-ranging crisis management insurance solutions that include product contamination and product recall insurance as well as terrorism and political violence coverage.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en"><strong> Clinical trials insurance </strong> - Covers the legal liability of sponsors (pharmaceutical companies) and investigators (doctors, hospitals, clinical research organizations) arising out of clinical trials.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en"><strong> Employer's liability / D&O insurance</strong> - Covering the employer's liability for workplace accidents / diseases of the employees. Directors and Officers Liability solutions.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en"><strong> IT liability insurance</strong> - Protects against emerging liability exposures on a pure financial loss basis: for example, infringement of privacy rights, inadvertent transmission of computer viruses, tampering and other 'cyber threats'.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en"><strong>Management liability insurance</strong> - Covered through our financial lines services, including Directors &amp; Officers and professional indemnity insurance.</div>

            <div class="inner-mob-pins-list-itemimg-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /></div>
            <div class="inner-mob-pins-list-item-en"><strong>Aerospace and aviation liability</strong> - Covered through our aviation insurance teams for airlines, general aviation and the aerospace industry. Physical damage and liability cover for manufacturers and suppliers as well as for airports, airfields, re-fuellers and associated service providers.</div>
            </div>
            
            <h3 className="inner-mob-main-cover-title-en">Why KR Insurance Services ?</h3>

          <div className="inner-mob-bdy-whykrlist-en">
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Lowest Quotations</div>
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Prompt Claim Services</div>
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Wide Quote Comparisons</div>
            <div className="inner-mob-bdy-whykrlist-item-en"><img src={process.env.PUBLIC_URL + "/personalinsurance_images/shield.svg"} alt="shield" /> Best Insurance Industry Practices</div>
          </div>

          {/* ask for quotes button starts here */}
          <div className="askquto-btn-sec">
            <a href="/contactus" className="askquto-btn">Ask for Quotes</a>
          </div>
          {/* ask for quotes button starts here */}
        </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterEN />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default LiabilityInsurance;