import { Link } from "react-router-dom";
import { useState, useEffect, React } from "react";
import HeaderAR from "../../headerAR/headerar";
import FooterAR from "../../footerAR/footerar";
import { Helmet } from 'react-helmet-async';
import './arreportaclaim.scss';

const ARReportaClaim = () => {

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoader(false);
    }, 2000);
    return () => clearTimeout(timer);
    
  }, []);

  useEffect(() =>
  {
    // FOR DESKTOP 
    var script = document.createElement("script");
    script.id = "ff-script";
    script.src = "https://formfacade.com/include/118341088851196171519/form/1FAIpQLSf4juQKJx37puPKBUkunZVkTJc5sC4TgyEBsejvIB3Q8VoyUA/classic.js?div=ff-compose";
    script.defer = true;
    script.async = true;
    document.body.appendChild(script);

    // FOR TAB 
    var scriptTab = document.createElement("script");
    scriptTab.id = "ff-script";
    scriptTab.src = "https://formfacade.com/include/118341088851196171519/form/1FAIpQLSf4juQKJx37puPKBUkunZVkTJc5sC4TgyEBsejvIB3Q8VoyUA/classic.js?div=ff-compose-Tab";
    scriptTab.defer = true;
    scriptTab.async = true;
    document.body.appendChild(scriptTab);

    // FOR MOBILE 
    var scriptMob = document.createElement("script");
    scriptMob.id = "ff-script";
    scriptMob.src = "https://formfacade.com/include/118341088851196171519/form/1FAIpQLSf4juQKJx37puPKBUkunZVkTJc5sC4TgyEBsejvIB3Q8VoyUA/classic.js?div=ff-compose-Mob";
    scriptMob.defer = true;
    scriptMob.async = true;
    document.body.appendChild(scriptMob);

    const handleContextmenu = e => {
      e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
      document.removeEventListener('contextmenu', handleContextmenu)
    }
    
  },[])


  const [tabsList, setTabsList] = useState([{ text: "Travel Insurance", url: "travel-insurance" }, { text: "Life Insurance", url: "life-insurance" }])
  const [loader, setLoader] = useState(true)

  return (
    <div class="main-fluid">
      { loader? 
      <div className="loadermain">
        <div class="loader" id="timer"></div>
      </div>
      :null }
      <Helmet>
        <title>‫عمان‬ ‫سلطنة‬ ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫للوسطاء‬ ‫موثوق‬ ‫تأمين‬.</title>
        <meta name="keywords" content="Employee Wellness Progaram Insurance" />
        <meta name="description" content="KR ‫في‬ ‫التأمين‬ ‫وشركات‬ ‫لوسطاء‬ ‫موثوق‬ ‫خيار‬ ‫هو‬ ‫للتأمين‬ ‫عمان‬. ‫عمان‬ ‫في‬ ‫التأمين‬ ‫شركات‬ ‫ألفضل‬ ‫التأمين‬ ‫حلول‬ ‫إلى‬ ‫للوصول‬ ‫معنا‬ ‫شارك‬." />
      </Helmet>
      <HeaderAR />
      {/* DESK TOP AND TABS SECTION STARTS HERE */}
      <div className="dsk-view">
        <div class="inn-banner-bg-main-en">
          <div class="inner-con-en">
            <div class="inner-banner-btn-ar">
              <h1>خدمات استشارات للمطالبة</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="inner-banner-btn-ar">
                <div class="inner-banner-btn-item-active-ar" onClick={() => window.location.href = "report-a-claim-ar"}>الإبلاغ عن مطالبة </div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "claim-consultancy-services-ar"}>خدمات استشارات المطالبات </div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "service-excellence-ar"}>التميز في الخدمة </div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "in-house-kr-automotive-garage-ar"}>مرأب سيارات شركة كيمجي رامداس الداخلية</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "erp-based-solutions-ar"}>الحلول القائمة على تخطيط موارد المؤسسة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="inner-con-en">
          <div class="bdy-innercont-en">
            <div class="inner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="innerbdy-dlex-lft-ar">
                <img src={process.env.PUBLIC_URL + "/customer_service/tab_images/claim_handling_ar.png"} alt="Claim Handling" />
              {/* <div className="dskclaimform-ar"> */}
                
                {/* <div id="ff-compose"></div> */}
                {/* <iframe width="100%" height="1600" scrolling="no" seamless="seamless" frameborder="0" src="https://formfaca.de/sm/9D1zdrtid"></iframe> */}

                {/* </div> */}

              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="innerbdy-dlex-rgt-ar">
              <iframe width="100%" height="900" scrolling="no" seamless="seamless" frameborder="0" src="https://formfaca.de/sm/9D1zdrtid"></iframe>
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
            

          </div>
        </div>
      </div>
      {/* DESK TOP AND TABS SECTION ENDS HERE */}


      {/* TAB VIEW TOP AND TABS SECTION STARTS HERE */}
      <div className="tab-view">
        <div class="inn-banner-bg-main-en">
          <div class="inner-con-en">
            <div class="inner-banner-btn-ar">
              <h1 class="reportclaim-banner-heading-en">خدمات استشارات للمطالبة</h1>
              {/* TAB BUTTON SECTION STARTS HERE */}
              <div class="inner-banner-btn-ar">
                <div class="inner-banner-btn-item-active-ar" onClick={() => window.location.href = "report-a-claim-ar"}>الإبلاغ عن مطالبة </div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "claim-consultancy-services-ar"}>خدمات استشارات المطالبات </div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "service-excellence-ar"}>التميز في الخدمة </div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "in-house-kr-automotive-garage-ar"}>مرأب سيارات شركة كيمجي رامداس الداخلية</div>
                <div class="inner-banner-btn-item-ar" onClick={() => window.location.href = "erp-based-solutions-ar"}>الحلول القائمة على تخطيط موارد المؤسسة</div>
              </div>
              {/* TAB BUTTON SECTION ENDS HERE */}
            </div>
          </div>
        </div>
        <div class="inner-con-en">
          <div class="bdy-innercont-en">
            <div class="tabinner-bdy-dflex-en">
              {/* LEFT SIDE IMAGE SECTION STARTS HERE */}
              <div class="tabinnerbdy-dlex-lft-ar">
                <h2 class="tabpagetitle-en">الإبلاغ عن مطالبة </h2>

                {/* <div id="ff-compose-Tab"></div> */}

                <iframe width="100%" height="900" frameborder="0" src="https://formfaca.de/sm/9D1zdrtid"></iframe>

              </div>
              {/* LEFT SIDE IMAGE SECTION ENDS HERE */}
              {/* RIGHT SIDE IMAGE SECTION STARTS HERE */}
              <div class="tabinnerbdy-dlex-rgt-ar">
                <img src={process.env.PUBLIC_URL + "/customer_service/tab_images/claim_handling_ar.png"} alt="Claim Handling" />
              </div>
              {/* RIGHT SIDE IMAGE SECTION ENDS HERE */}
            </div>
            

          </div>
        </div>
      </div>
      {/* TAB VIEW TOP AND TABS SECTION ENDS HERE */}

      {/* MOBILE PAGE STARTS HERE */}
      <div class="inn-banner-bg-main-en mobile-view">
        <div class="dropdown-sec">
          <select class="form-select" onChange={(e) => window.location.href = e.target.value}>
            <option value={"report-a-claim-ar"} selected class="active">الإبلاغ عن مطالبة </option>
            <option value={"claim-consultancy-services-ar"}>خدمات استشارات المطالبات</option>
            <option value={"service-excellence-ar"}>التميز في الخدمة </option>
            <option value={"in-house-kr-automotive-garage-ar"}>مرأب سيارات شركة كيمجي رامداس الداخلية</option>
            <option value={"erp-based-solutions-ar"}>الحلول القائمة على تخطيط موارد المؤسسة</option>
          </select>
        </div>
        <div class="innner-mob-body-main-en">
          {/* <h1 class="inner-mob-heading-en">الإبلاغ عن مطالبة </h1>
          <div class="innner-mob-body-img-en">
            <img src={process.env.PUBLIC_URL + "/customer_service/tab_images/claim_handling_ar.png"} alt="Claim Handling" />
          </div> */}

          {/* <div id="ff-compose-Mob"></div> */}

          {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSf4juQKJx37puPKBUkunZVkTJc5sC4TgyEBsejvIB3Q8VoyUA/viewform?embedded=true" width="100%" height="1500" frameborder="0" marginheight="0" marginwidth="0" style={{margin:"0px"}}>Loading…</iframe> */}
          
          <iframe width="100%" height="950" frameborder="0" src="https://formfaca.de/sm/9D1zdrtid"></iframe>
          
          </div>
      </div>
      {/* MOBILE PAGE ENDS HERE */}

      {/* FOOTER SECTION STARTS HERE */}
      <FooterAR />
      {/* FOOTER SECTION ENDS HERE */}
    </div>
  );
}

export default ARReportaClaim;
