import { Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import './headerar.scss';

const HeaderAR = () => {

  return (
        <header className="kr-header-ar">
          <div className=" hr-container-ar">
            <div className="hr-navigation-ar">
              <div className="navigation" >
                <input type="checkbox" className="navigation__checkbox" id="nav-toggle" />
                <label for="nav-toggle" className="navigation__button">
                  {/* <span className="navigation__icon" aria-label="toggle navigation menu"></span> */}
                  <span className="navigation__icon"></span>
                </label>
                <div className="navigation__background"></div>
                <nav className="navigation__nav" role="navigation">
                  <ul className="navigation__list">
                    <li className="navigation__item">
                      <a href="/ar" className="navigation__link">الصفحة الرئيسية </a>
                    </li>
                    <li className="navigation__item">
                      <a href="/aboutus-ar" className="navigation__link">معلومات عنا</a>
                    </li>
                    <li className="navigation__item">
                      <a href="/our-insurance-solutions-ar" className="navigation__link">اهكلتمن يتلا نيمأتلا تاربخو لولح</a>
                    </li>
                    <li className="navigation__item">
                      <a href="/team-ar" className="navigation__link">حلول التأمين لدينا</a>
                    </li>
                    <li className="navigation__item">
                      <a href="/contactus-ar" className="navigation__link">اتصل بنا</a>
                    </li>
                  </ul>
                </nav>
              </div>
            {/* <div className="ar-link" onClick={(e) => window.location.href = window.location.href.split("-ar")[0]}>EN</div> */}
            <div className="ar-link-en" onClick={(e) => window.location.href = window.location.pathname.includes("-ar") ? window.location.href.split("-ar")[0] : "/" }>EN</div>
              
              <span className="hidden-mobile">
                <a href="/contactus-ar" className="contactus-btn">اتصل بنا</a>

                <div className="hidden-desktop services hidden-md-device ms-2 nav-pb-rgt-ar" onClick={(e) => window.location.href = "/report-a-claim-ar"}>خدمات العملاء </div>

                <div className="hidden-desktop services hidden-md-device nav-pb-rgt-ar" onClick={(e) => window.location.href = "/risk-inspection-services-ar"}>خدمات قيمة</div>

                <div className="hidden-desktop services hidden-md-device nav-pb-rgt-ar" onClick={(e) => window.location.href = "/motor-insurance-ar"}>تأمين شخصي</div>

                <div className="hidden-desktop services hidden-md-device" onClick={(e) => window.location.href = "/property-insurance-ar"} >تأمين الأعمال</div>
                </span>
            </div>

            <div className="logo-ar">
              <Link to="/ar"> <img src={process.env.PUBLIC_URL + "/header_images/logo_200x55.webp"} alt="KR Logo" /></Link>
            </div>

          </div>
        </header>
  );
}
export default HeaderAR;