import { useEffect, useState, React } from 'react';
import { Link } from "react-router-dom";
import HeaderEN from "../headerEN/headeren";
import FooterEN from "../footerEN/footer";
import './ourinsurancesolutions.scss';

const OurInsuranceSolutions = () => {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  
  },[])

  return (
    <div>
      <div class="main-fluid">
        <HeaderEN />
      </div>
      {/* DESKTOP SECTION STARTS HERE */}
      <div class="d-none d-md-none d-lg-block">
        <div class="ourexp-con-en">
          <h2 class="ourexp-wedotitle-en">Our Insurance Solutions and Expertise</h2>
          <div class="ourexp-sc-en">            
            <div class="ourexp-rgt-en">
              <div class="ourinsol-dskmain-en">
                <div class="ourinsusol-dsk-list-en">
                  <div class="oursol-dsk-list-img-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-5.png"} class="imgone" alt="icon" /></div>
                  <div class="oursol-dsk-list-txt-en">
                    <p>We are a specialized insurance team who works closely with the customers to understand their insurance & risk management requirements.</p>
                  </div>
                </div>
                <div class="ourinsusol-dsk-list-en">
                  <div class="oursol-dsk-list-img-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-6.png"}  class="imgtwo" alt="icon" /></div>
                  <div class="oursol-dsk-list-txt-en">
                    <p>We deal with all the insurers in Oman. On behalf of our customers, our team arranges wider & competitive insurance terms.</p>
                  </div>
                </div>
                <div class="ourinsusol-dsk-list-en">
                  <div class="oursol-dsk-list-img-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-7.png"}  class="imgthree" alt="icon" /></div>
                  <div class="oursol-dsk-list-txt-en">
                    <p>We deliver insurance services and assist our customers in the settlement of insurance claims (if any) with an intent to exceed our customer's expectations.</p>
                  </div>
                </div>

              </div>
            </div>
            <div class="ourexp-lft-en">
              <div class="ourexp-sec-en">
              <a href="/property-insurance" class="ourexp-expcard-en">
                  <div class="expe-insimg-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-1.png"} /></div>
                  <div class="expe-insnm-en">Business Insurance</div>
                </a>
                <a href="/motor-insurance" class="ourexp-expcard-en">
                  <div class="expe-insimg-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-2.png"} /></div>
                  <div class="expe-insnm-en">Personal Insurance</div>
                </a>
                
                <a href="/risk-inspection-services" class="ourexp-expcard-en">
                  <div class="expe-insimg-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-3.png"} /></div>
                  <div class="expe-insnm-en">Valued Services</div>
                </a>
                <a href="/report-a-claim" class="ourexp-expcard-en">
                  <div class="expe-insimg-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-4.png"} /></div>
                  <div class="expe-insnm-en">Customer Services</div>
                </a>
              </div>
            
            
            </div>
          </div>
        </div>
      </div>
      {/* DESKTOP SECTION ENDS HERE */}

      {/* TAB SECTION STARTS HERE  */}
      <div className="d-none d-sm-none d-lg-none d-md-block">
        <div class="taboexp-con-en">
          <h2 class="taboexp-title-en">Our Insurance Solutions and Expertise</h2>
          <div className="oursecinc-en">
            <a href="/property-insurance" class="oursecinc-expcard-en">
                  <div class="oursecinc-insimg-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-1.png"} alt="Business Insurance" /></div>
                  <div class="oursecinc-insnm-en">Business Insurance</div>
                </a>
                <a href="/motor-insurance" class="oursecinc-expcard-en">
                  <div class="oursecinc-insimg-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-2.png"} alt="Personal Insurance" /></div>
                  <div class="oursecinc-insnm-en">Personal Insurance</div>
                </a>
                
                <a href="/risk-inspection-services" class="oursecinc-expcard-en">
                  <div class="oursecinc-insimg-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-3.png"} alt="Valued Services" /></div>
                  <div class="oursecinc-insnm-en">Valued Services</div>
                </a>
                <a href="/report-a-claim" class="oursecinc-expcard-en">
                  <div class="oursecinc-insimg-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-4.png"} alt="Customer Services" /></div>
                  <div class="oursecinc-insnm-en">Customer Services</div>
                </a>
          </div>
          <div class="oursecincsectab-en">
                <div class="oursecincsectab-list-en">
                  <div class="oursecincsectab-img-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-5.png"} alt="icon" /></div>
                  <div class="oursecincsectab-info-en"> We are a specialized insurance team who works closely with the customers to understand their insurance & risk management requirements.
                  </div>
                </div>
                <div class="oursecincsectab-list-en">
                  <div class="oursecincsectab-img-en">
                    <img src={process.env.PUBLIC_URL + "/homepage_images/icon-6.png"} className="simg-en" alt="icon" />
                    </div>
                  <div class="oursecincsectab-info-en">
                   We deal with all the insurers in Oman. On behalf of our customers, our team arranges wider & competitive insurance terms.
                  </div>
                </div>
                <div class="oursecincsectab-list-en">
                  <div class="oursecincsectab-img-en">
                    <img src={process.env.PUBLIC_URL + "/homepage_images/icon-7.png"} className="simg-en" alt="icon" />
                  </div>
                  <div class="oursecincsectab-info-en">
                    We deliver insurance services and assist our customers in the settlement of insurance claims (if any) with an intent to exceed our customer's expectations.
                  </div>
                </div>

              </div>
        </div>
      </div>
      {/* TAB SECTION ENDS HERE  */}


      {/* MOBILE SECTION STARTS HERE */}
      <div class="d-block d-sm-none d-md-none">
        <div class="ourexp-con-en">
          <h2 class="ourexp-wedotitle-en">Our Insurance Solutions and Expertise</h2>
          <div class="ourexp-sc-mob-en">
            
            <div class="ourexpcrd-mob-sec-en">
            <a href="/property-insurance" class="ourexp-crds-mob-en">
                <span class="ourexp-crds-mob-img-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-1.png"} alt="Business Insurance" /></span>
                <span class="ourexp-crds-mob-txt-en">Business Insurance</span>
              </a>
              <a href="/motor-insurance" class="ourexp-crds-mob-en">
                <span class="ourexp-crds-mob-img-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-2.png"} alt="Personal Insurance" /></span>
                <span class="ourexp-crds-mob-txt-en">Personal Insurance</span>
              </a>
              
              <a href="/risk-inspection-services" class="ourexp-crds-mob-en">
                <span class="ourexp-crds-mob-img-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-3.png"} alt="Valued Services" /></span>
                <span class="ourexp-crds-mob-txt-en">Valued Services</span>
              </a>
              <a href="/report-a-claim" class="ourexp-crds-mob-en">
                <span class="ourexp-crds-mob-img-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-4.png"} alt="Customer Services" /></span>
                <span class="ourexp-crds-mob-txt-en">Customer Services</span>
              </a>

            </div>

            <div class="clearfix"></div>

            <div class="ourinsu-sec-mob-en">
                <div class="ourinsu-list-mob-en">
                  <div class="ourinsu-list-img-mob-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-5.png"} alt="icon" /></div>
                  <div class="ourinsu-list-info-mob-en">
                    <p>We are a specialized insurance team who works closely with the customers to understand their insurance & risk management requirements.</p>
                  </div>
                </div>
                <div class="ourinsu-list-mob-en">
                  <div class="ourinsu-list-img-mob-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-6.png"} alt="icon" /></div>
                  <div class="ourinsu-list-info-mob-en">
                    <p>We deal with all the insurers in Oman. On behalf of our customers, our team arranges wider & competitive insurance terms.</p>
                  </div>
                </div>
                <div class="ourinsu-list-mob-en">
                  <div class="ourinsu-list-img-mob-en"><img src={process.env.PUBLIC_URL + "/homepage_images/icon-7.png"} alt="icon" /></div>
                  <div class="ourinsu-list-info-mob-en">
                    <p>We deliver insurance services and assist our customers in the settlement of insurance claims (if any) with an intent to exceed our customer's expectations.</p>
                  </div>
                </div>

              </div>
          </div>
        </div>
      </div>
      {/* MOBILE SECTION ENDS HERE */}

      <div class="main-fluid">
      <FooterEN />
      </div>
    </div>
  );
}

export default OurInsuranceSolutions;